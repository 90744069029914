import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastr } from 'react-redux-toastr';
import { useFormik } from 'formik';

import { Row, Col } from 'react-grid-system';

import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { TextEditor } from './../../containers';

import { ImageContainer } from './../../components';

import { PropsData, Order } from './../Utils/DataUtil';
import { Permission } from './../Utils/PermissonUtil';

import * as artistActions from './../../actions/artist';
import * as paymentActions from './../../actions/payment';
import * as editorActions from './../../actions/editor';

import * as Validator from './../Utils/Validator';

import { strings, colors, styles } from './../../assets/values';
import { getStyle } from './style';

import history from './../../history';

const validate = (values) => {
  const errors = {};
  const titleError = Validator.validateTitle(values.title);
  if (titleError) errors.title = titleError;
  return errors;
};

const ArtistMerchandiseItemEditor = (props) => {
  const [imageUrl, setImageUrl] = useState(strings.dummy.imageUrl);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (imageUrl !== props.merchandiseImageUrl) {
      setImageUrl(props.merchandiseImageUrl);
    }
  }, [props.merchandiseImageUrl]);

  const onSubmit = (data) => {
    const newData = {
      name: data.name,
      image: imageUrl,
      description_inline: data.description_inline,
      description: props.content,
      quantity: data.quantity,
      price: data.price,
      shipping_price: data.shipping_price,
      artist: props.artist.id,
      is_active: true,
    };
    if (props.initialValues.id) {
      props.updateArtistMerchandise(props.initialValues.id, newData)
        .then(() => {
          props.readArtistMerchandise(props.artist.id);
          history.push(`/${props.match.params.url}/${strings.routes.artistMerchandise}`);
        });
    } else {
      props.createArtistMerchandise(newData)
        .then((response) => {
          if (response.action.payload.ok) {
            toastr.success(strings.success, strings.postSuccess);
            props.readArtistMerchandise(props.artist.id);
            history.push(`/${props.match.params.url}/${strings.routes.artistMerchandise}`);
          } else toastr.error(strings.fail, strings, strings.postFail);
        });
    }
  };

  const onCancel = () => {
    history.push(`/${props.match.params.url}/${strings.routes.artistMerchandise}`);
  };

  const fileBrowserCallback = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const node = event.target;
    props.uploadArtistMerchandiseImage(node.files[0]);
    node.value = null;
  };

  const style = getStyle();
  const textFieldStyle = { display: 'block' };

  const formik = useFormik({
    initialValues: {
      name: props.initialValues.name,
      description_inline: props.initialValues.description_inline,
      description: props.initialValues.description,
      quantity: props.initialValues.quantity,
      price: props.initialValues.price,
      shipping_price: props.initialValues.shipping_price,
      artist: props.artist,
      is_active: props.initialValues.is_active,
    },
    validate: values => validate(values),
    onSubmit: values => onSubmit(values),
  });

  return (
    <div style={style.wrapper}>
      <Row>
        <Col lg={3}/>
        <Col lg={7}>
          <div style={style.rightContainer}>
            <Card>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageUpload}
                style={{ display: 'none' }}
              />
              <form onSubmit={formik.handleSubmit}>
                <CardContent style={{ color: colors.textBlack80, fontSize: styles.fontSize.md, lineHeight: 1.4 }}>
                  현재 상품판매 기능의 베타테스트를 진행하고 있습니다. 상품을 등록하시기 전에, 기획팀의 박주원(010-9835-3509) 혹은 개발팀의 김민식(010-7228-3786)으로 연락해주세요!
                </CardContent>
                <CardContent style={style.newPost.form && { paddingTop: 15 }}>
                  <h5>대표 이미지</h5>
                  <div>
                    <Button
                      variant="outlined"
                      onClick={(event) => { event.preventDefault(); fileBrowserCallback(); }}
                    >
                      이미지 업로드
                    </Button>
                  </div>
                  <ImageContainer
                    url={imageUrl} width={200} height={200}
                    style={{ marginTop: 10 }}
                    raw
                  />
                  <TextField
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    label={strings.name}
                    style={textFieldStyle}
                    margin="dense"
                  />
                  <TextField
                    name="description_inline"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    helperText="상품에 대한 한줄 설명을 적어주세요"
                    label={strings.simpleDescription}
                    style={textFieldStyle}
                    margin="dense"
                  />
                  <TextField
                    name="price"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    helperText="상품의 가격을 적어주세요"
                    label={strings.merchandisePrice}
                    style={textFieldStyle}
                    margin="dense"
                  />
                  <TextField
                    name="shipping_price"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    helperText="무료인 경우 0을 적어주세요"
                    label={strings.shippingPrice}
                    style={textFieldStyle}
                    margin="dense"
                  />
                  <TextField
                    name="quantity"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    helperText="판매가능한 전체 수량을 적어주세요"
                    label={strings.merchandiseQuantity}
                    style={textFieldStyle}
                    margin="dense"
                  />
                </CardContent>
                <CardContent style={{ paddingTop: 0 }}>
                  <h5>상세설명</h5>
                  <TextEditor initialContent={props.initialValues.description}/>
                </CardContent>
                <CardContent style={{ color: colors.textBlack80 }}>
                  <Typography>
                    구매자가 생기면 등록하신 이메일({props.artist.contact_email})로 알려드립니다.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    type="button"
                    onClick={(event) => { event.preventDefault(); onCancel(); }}
                  >
                    {strings.cancel}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    {strings.confirm}
                  </Button>
                </CardActions>
              </form>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

ArtistMerchandiseItemEditor.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  content: PropTypes.string,
  merchandiseImageUrl: PropTypes.string,
  initialValues: PropTypes.object,
  editing: PropTypes.bool,
  createArtistMerchandise: PropTypes.func.isRequired,
  readArtistMerchandise: PropTypes.func.isRequired,
  updateArtistMerchandise: PropTypes.func.isRequired,
  uploadArtistMerchandiseImage: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

ArtistMerchandiseItemEditor.defaultProps = {
  initialValues: {
    description: '',
  }
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    artist: state.artist.data[state.artist.active],
    content: state.editor.content,
    merchandiseImageUrl: state.artist.merchandiseImageUrl,
    initialValues: state.artist.merchandiseForm,
    permission: Permission.checkArtist(state.artist.data[state.artist.active], state.user.data),
    merchandise: PropsData.fromState(state.artist.data[state.artist.active].merchandise, Order.ASCENDING),
    creatingMerchadise: state.artist.creatingMerchadise,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...artistActions,
    ...editorActions,
    ...paymentActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistMerchandiseItemEditor);
