import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { Banner } from './../../components';
import { ArtistPost } from './../../containers';
import { PropsData, Order } from './../Utils/DataUtil';

import * as artistActions from './../../actions/artist';

const style = {
  readMoreContainer: {
    textAlign: 'center',
    paddingTop: 8,
    paddingBottom: 64,
  }
};

class HomeArtistPosts extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.readPosts();
  }

  onReadMorePosts = () => {
    this.props.readMorePosts(this.props._next);
  };

  render() {
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.posts) return (progress);
    return (
      <Row>
        <div style={{ paddingTop: 16, width: '100%' }}>
          <Col lg={6} sm={12} offset={{ lg: 3 }}>
            <Banner/>
            {this.props.posts.map(post =>
              <ArtistPost
                key={post.id}
                post={post}
                replyPermission={this.props.user !== null}
              />)}
          </Col>
          {this.props._next &&
            <Col lg={6} sm={12} offset={{ lg: 3 }}>
              <div style={style.readMoreContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => { event.preventDefault(); this.onReadMorePosts(); }}
                >
                  더 보기
                </Button>
              </div>
            </Col>
          }
        </div>
      </Row>
    );
  }
}

HomeArtistPosts.propTypes = {
  user: PropTypes.object,
  posts: PropTypes.array,
  readPosts: PropTypes.func.isRequired,
  readMorePosts: PropTypes.func.isRequired,
  _next: PropTypes.string,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const sortedPosts = PropsData.fromState(state.artist.posts, Order.DESCENDING);
  return {
    user: state.user.data,
    posts: sortedPosts,
    _next: state.artist._nextPosts,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeArtistPosts);
