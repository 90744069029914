import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Info from '@material-ui/icons/Info';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { DateTime } from './../../containers/Utils/DateTimeUtil';


class DashboardPurchase extends React.Component {
  render() {
    return (
      <TableRow key={this.props.id} hoverable>
        <TableCell>
          {this.props.buyer_name}
        </TableCell>
        <TableCell>
          {this.props.buyer_contact}
        </TableCell>
        <TableCell>
          {this.props.merchandise.name}
        </TableCell>
        <TableCell>
          {this.props.amount}
        </TableCell>
        <TableCell>
          {this.props.address.address}
        </TableCell>
        <TableCell>
          {DateTime.dateFormat(this.props.created_at)}
        </TableCell>
        <TableCell>
          <IconButton onClick={this.props.onClickPurchase}>
            <Info/>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

DashboardPurchase.propTypes = {
  id: PropTypes.number,
  buyer_name: PropTypes.string,
  buyer_contact: PropTypes.string,
  address: PropTypes.object,
  amount: PropTypes.number,
  merchandise: PropTypes.object,
  created_at: PropTypes.string,
  onClickPurchase: PropTypes.func,
};

export default DashboardPurchase;
