import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastr } from 'react-redux-toastr';
import { useFormik } from 'formik';

import { Row, Col, Container } from 'react-grid-system';

import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { CheerEditor } from '..';

import { Post as PostComponent, ArtistCheerPostReply } from '../../components';
import { Order } from '../Utils/DataUtil';
import { Permission } from '../Utils/PermissonUtil';

import * as artistActions from '../../actions/artist';

import { strings } from '../../assets/values';
import { getStyle } from './style';
import history from '../../history';


const CheerPost = (props) => {
  const [editing, setEditing] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [commentsExpanded, setCommentsExpanded] = useState(!!props.commentsExpanded);

  const onSubmit = (data) => {
    if (props.artist) {
      const artistId = props.post.artist.id || props.post.artist;
      return props.createArtistCheerReply(artistId, { ...data, cheer_post: props.post.id });
    }
    return props.createCheerReply({ ...data, cheer_post: props.post.id });
  };

  const formik = useFormik({
    initialValues: {
      body: '',
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const onDeleteCheerPost = () => {
    if (props.artist) {
      return props.deleteArtistCheer(props.post.id)
        .then(() => setConfirmDelete(false))
        .then(() => history.push(`/${props.match.params.url}/cheers/`));
    }
    return props.deleteCheerPost(props.post.id)
      .then(() => setConfirmDelete(false))
      .then(() => history.push(`${strings.routes.cheers}`));
  };

  const onDeleteCheerPostReply = () => {
    const artistId = props.post.artist.id || props.post.artist;
    props.readArtistCheer(artistId, props.post.id);
  };

  const handleCreateCheerPostLike = () => {
    if (props.artist) {
      return props.createArtistCheerLike(props.post.artist.id, props.post.id);
    }
    return props.createCheerPostLike(props.post.id);
  };

  const handleDeleteCheerPostLike = () => {
    if (props.artist) {
      return props.deleteArtistCheerLike(props.post.artist.id, props.post.id);
    }
    return props.deleteCheerPostLike(props.post.user_like);
  };

  const onLikeButtonClick = () => {
    if (!props.user) {
      return toastr.error('로그인이 필요합니다', '로그인 후 게시물을 추천할 수 있습니다');
    }
    if (props.post.is_user_liked) {
      return handleDeleteCheerPostLike();
    }
    return handleCreateCheerPostLike();
  };

  const openConfirmDelete = () => {
    setConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setConfirmDelete(false);
  };

  const openEditingForm = () => {
    setEditing(true);
  };

  const closeEditingForm = () => {
    setEditing(false);
  };

  const handleExpansionChange = (event, expanded) => {
    setCommentsExpanded(expanded);
  };

  const style = getStyle();
  const deleteCheerActions = [
    <Button key="cancel" color="primary" onClick={(event) => { event.preventDefault(); closeConfirmDelete(); }}>
      {strings.cancel}
    </Button>,
    <Button key="remove" colro="primary" onClick={(event) => { event.preventDefault(); onDeleteCheerPost(); }}>
      {strings.remove}
    </Button>,
  ];
  // const openIcon = <HardwareKeyboardArrowUp viewBox="0 0 30 30"/>;
  // const closeIcon = <HardwareKeyboardArrowDown viewBox="0 0 30 30"/>;

  return (
    <div style={style.post.wrapper}>
      <Card>
        {props.editingPermission &&
          <CardActions>
            <Button
              type="button"
              variant="outlined"
              onClick={(event) => { event.preventDefault(); openConfirmDelete(); }}
              disabled={editing}
            >
              {strings.remove}
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={(event) => { event.preventDefault(); openEditingForm(); }}
              disabled={editing}
            >
              {strings.edit}
            </Button>
          </CardActions>
        }
        <CardContent>
          {editing ?
            <CheerEditor
              onDismiss={closeEditingForm}
              initialValues={props.post}
              match={props.match}
              editing
            /> :
            <PostComponent
              {...props.post}
              body={props.post.body}
              url={props.post.artist && `${strings.appUrl}/${props.post.artist.url_artist}/${strings.routes.artistPosts}/${props.post.id}`}
              style={style.post.content}
            />
          }
          {!editing ?
            <div>
              <div style={{ display: 'inline-block', width: '50%', textAlign: 'left' }}>
                <Button
                  variant={props.post.is_user_liked ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={onLikeButtonClick}
                >
                  추천 ({props.post.like_count})
                </Button>
              </div>
              {Permission.checkWriter(props.post.user, props.user) &&
                <div style={{ display: 'inline-block', width: '50%', textAlign: 'right' }}>
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: '10px' }}
                    onClick={(event) => { event.preventDefault(); openConfirmDelete(); }}
                  >
                    {strings.remove}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={openEditingForm}
                  >
                    수정하기
                  </Button>
                </div>
              }
            </div> :
            null
          }
          <Divider style={style.post.reply.divider}/>
          <Accordion
            style={style.post.reply}
            expanded={commentsExpanded}
            onChange={handleExpansionChange}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                댓글 {`(${props.post.reply_count})`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                <Container>
                  {props.post.replies.sort(Order.ID_ASCENDING).map(reply =>
                    <ArtistCheerPostReply style={style.post.replyComponent} key={reply.id} onDeleteCheerPostReply={onDeleteCheerPostReply} {...reply}/>)}
                  {props.replyPermission &&
                    <Row>
                      <Col sm={2} md={2} lg={2} xl={2}>
                        <div style={style.post.replyNickname}>
                          {props.user.nickname}
                        </div>
                      </Col>
                      <Col sm={8} md={8} lg={8} xl={8}>
                        <TextField
                          fullWidth
                          value={formik.values.body}
                          onChange={formik.handleChange}
                          name="body"
                          label={strings.postReply}
                          margin="normal"
                        />
                      </Col>
                      <Col sm={2} md={2} lg={2} xl={2}>
                        <Button
                          color="primary"
                          variant="outlined"
                          style={style.post.replySubmit}
                          type="submit"
                          disabled={!formik.dirty || formik.isSubmitting}
                        >
                          {strings.confirm}
                        </Button>
                      </Col>
                    </Row>
                  }
                </Container>
                {!props.replyPermission &&
                  <div style={{ marginTop: 15 }}>
                    <Link href={strings.routes.signIn} to={strings.routes.signIn}>
                      <Button variant="outlined" size="small">로그인 후 댓글을 남길 수 있습니다</Button>
                    </Link>
                  </div>
                }
              </form>
            </AccordionDetails>
          </Accordion>
          {!!props.actions &&
            <div>
              <Divider style={style.post.reply.divider}/>
              <div style={{ marginTop: 15 }}>
                {props.actions}
              </div>
            </div>
          }
        </CardContent>
      </Card>
      <div>
        <Dialog open={confirmDelete} >
          <div style={{ width: '240px', height: '30px', padding: '40px' }}>
            {strings.wantDelete}
          </div>
          <DialogActions>
            {deleteCheerActions}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

CheerPost.propTypes = {
  user: PropTypes.object,
  post: PropTypes.object.isRequired,
  comments: PropTypes.array,
  commentsExpanded: PropTypes.bool,
  replyPermission: PropTypes.bool,
  editingPermission: PropTypes.bool,
  createArtistPostReply: PropTypes.func.isRequired,
  createArtistCheerReply: PropTypes.func.isRequired,
  createCheerReply: PropTypes.func.isRequired,
  readArtistPost: PropTypes.func.isRequired,
  readArtistCheer: PropTypes.func.isRequired,
  readCheerPost: PropTypes.func.isRequired,
  createArtistCheerLike: PropTypes.func.isRequired,
  deleteArtistCheerLike: PropTypes.func.isRequired,
  createCheerPostLike: PropTypes.func.isRequired,
  deleteCheerPostLike: PropTypes.func.isRequired,
  deleteArtistCheer: PropTypes.func.isRequired,
  deleteCheerPost: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  match: PropTypes.object.isRequired,
};

CheerPost.defaultProps = {
  type: 'artist',
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CheerPost);
