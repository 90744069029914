import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';

import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {
  ArtistInformation, ArtistPosts, ArtistPostsNew, ArtistSinglePost, ArtistSingleCheer,
  ArtistCheers, ArtistRewards, ArtistPurchase, ArtistPurchaseSuccess,
  ArtistMerchandise, ArtistMerchandiseItem, ArtistMerchandiseItemEditor,
  ArtistMerchandisePurchase, ArtistPostsSecondary
} from './../../containers';
import { NotFound, Forbidden } from './../../components';

import * as artistActions from './../../actions/artist';

import { strings } from './../../assets/values';
import { getStyle } from './style';

import history from './../../history';


const paths = strings.routes;

class Artist extends React.Component {
  static fetchData({ store, params }) {
    return store.dispatch(artistActions.getArtistByUrl(params.url))
      .then((response) => {
        if (!response.action.payload.ok) return store.dispatch(artistActions.startInitialize());
        return store.dispatch(artistActions.finishInitialize());
      });
  }

  UNSAFE_componentWillMount() {
    // When environment is not server or data is not fulfilld or route is not mached with state
    if (typeof window !== 'undefined' && (!this.props.artist || this.props.artist.url_artist !== this.props.match.params.url)) {
      this.props.startInitialize();
      this.props.getArtistByUrl(this.props.match.params.url)
        .then(() => this.props.finishInitialize());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params.url !== nextProps.match.params.url) {
      this.props.startInitialize();
      this.props.getArtistByUrl(nextProps.match.params.url)
        .then(() => this.props.finishInitialize());
    }
  }

  onUpdate = (data) => {
    this.props.updateArtist(this.props.artist.id, data);
  };

  getTabPath = () => {
    if (this.props.artist?.secondary_post_enabled) {
      const tabPaths = [
        paths.artistPosts,
        paths.artistPostsSecondary,
        paths.artistCheers,
        paths.artistMerchandise,
        paths.artistRewards,
      ];
      return tabPaths;
    }
    const tabPaths = [
      paths.artistPosts,
      paths.artistCheers,
      paths.artistMerchandise,
      paths.artistRewards,
    ];
    return tabPaths;
  }

  handleTabChange = (event, value) => {
    const tabPaths = this.getTabPath();
    const path = tabPaths[parseInt(value, 10)];
    history.push(`/${this.props.match.params.url}/${path}`);
  };

  render() {
    const tabPaths = this.getTabPath();
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.artist || this.props.initializing) return (progress);
    else if (this.props.responseStatus === 404) return (<NotFound/>);
    else if (this.props.responseStatus === 403) return (<Forbidden user={this.props.user}/>);
    const style = getStyle();
    const getPath = (path = '') => `${this.props.match.path}/${path}`;
    const getActivePath = (path) => {
      const pathArray = path.split('/');
      if (pathArray.length > 2) {
        const index = tabPaths.indexOf(pathArray[2]);
        return index >= 0 ? index : 0;
      }
      return 0;
    };

    return (
      <div>
        <Helmet>
          <title>{`${this.props.artist.name} | ${strings.appName}`}</title>
          <meta property="og:title" content={`${this.props.artist.name} | ${strings.appName}`}/>
          <meta property="og:description" content={`${this.props.artist.description_inline}`}/>
          <meta property="og:image" content={`${strings.s3.domain}/${strings.s3.bucket}/${this.props.artist.profile_image}`}/>
          <meta name="description" content={`${this.props.artist.description_inline}`}/>
        </Helmet>
        <Card style={style.header}>
          <ArtistInformation onUpdate={this.onUpdate} artist={this.props.artist}/>
          <Row>
            <div style={style.artist.tabWrapper}>
              <Col md={7} offset={{ md: 3 }}>
                <div style={style.artist.tabBody}>
                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    style={style.artist.tabs}
                    value={getActivePath(history.location.pathname)}
                    onChange={this.handleTabChange}
                  >
                    <Tab
                      style={style.artist.tabs.tab}
                      label={strings.artistPosts}
                    />
                    {this.props.artist.secondary_post_enabled && (
                      <Tab
                        style={style.artist.tabs.tab}
                        label={this.props.artist.secondary_post_label}
                      />
                    )}
                    <Tab
                      style={style.artist.tabs.tab}
                      label={strings.artistCheers}
                    />
                    <Tab
                      style={style.artist.tabs.tab}
                      label={strings.artistMerchandise}
                    />
                    <Tab
                      style={style.artist.tabs.tab}
                      label={strings.artistFunding}
                    />
                  </Tabs>
                </div>
              </Col>
            </div>
          </Row>
        </Card>
        <div style={style.artist.body}>
          <Switch>
            <Route exact path={getPath()} component={ArtistPosts}/>
            <Route path={getPath(`${paths.artistPosts}/:postNumber`)} component={ArtistSinglePost}/>
            <Route path={getPath(`${paths.artistCheers}/:postNumber`)} component={ArtistSingleCheer}/>
            <Route path={getPath(paths.artistPostsNew)} component={ArtistPostsNew}/>
            <Route path={getPath(paths.artistPosts)} component={ArtistPosts}/>
            <Route path={getPath(paths.artistPostsSecondary)} component={ArtistPostsSecondary}/>
            <Route path={getPath(paths.artistCheers)} component={ArtistCheers}/>
            <Route path={getPath(paths.artistRewards)} component={ArtistRewards}/>
            <Route path={getPath(paths.artistMerchandiseItemEditor)} component={ArtistMerchandiseItemEditor}/>
            <Route path={getPath(paths.artistMerchandisePurchase)} component={ArtistMerchandisePurchase}/>
            <Route path={getPath(`${paths.artistMerchandise}/:merchandiseId`)} component={ArtistMerchandiseItem}/>
            <Route path={getPath(paths.artistMerchandise)} component={ArtistMerchandise}/>
            <Route path={getPath(paths.artistPurchase)} component={ArtistPurchase}/>
            <Route path={getPath(paths.artistPurchaseSuccess)} component={ArtistPurchaseSuccess}/>
          </Switch>
        </div>
      </div>
    );
  }
}

Artist.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  getArtistByUrl: PropTypes.func.isRequired,
  readArtist: PropTypes.func.isRequired,
  updateArtist: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  responseStatus: PropTypes.number,
  initializing: PropTypes.bool,
  startInitialize: PropTypes.func.isRequired,
  finishInitialize: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    artist: state.artist.data[state.artist.active],
    initializing: state.artist.initializing,
    responseStatus: state.artist.responseStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...artistActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Artist);
