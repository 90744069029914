import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import {
  SupportTermsOfUse, SupportPrivacyPolicy, SupportTermsOfPayment, SupportFAQ,
  SupportGSPoint
} from './../../containers';
import { ContentWrapper } from './../../components';

import { strings } from './../../assets/values';

import { getStyle } from './style';


const paths = strings.routes;

class Support extends React.Component {
  render() {
    const style = getStyle();
    const getPath = (path = '') => `${this.props.match.path}/${path}`;
    return (
      <ContentWrapper cardStyle={style.card}>
        <Route path={getPath(paths.supportTermsOfUse)} component={SupportTermsOfUse}/>
        <Route path={getPath(paths.supportPrivacyPolicy)} component={SupportPrivacyPolicy}/>
        <Route path={getPath(paths.supportTermsOfPayment)} component={SupportTermsOfPayment}/>
        <Route path={getPath(paths.supportFAQ)} component={SupportFAQ}/>
        <Route path={getPath(paths.supportGSPoint)} component={SupportGSPoint}/>
      </ContentWrapper>
    );
  }
}

Support.propTypes = {
  match: PropTypes.object.isRequired,
};


export default Support;
