import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';

import { Hangul } from './../../containers/Utils/TextUtil';

import { strings, styles, colors } from './../../assets/values';

import history from './../../history';


class EncourageSupport extends React.Component {
  handleRoute = () => {
    if (!this.props.supporting) history.push(`/${this.props.url_artist}/rewards`);
  };

  render() {
    const buttonStyle = styles.muiComponents.raisedButton(0);
    let label = (<div style={{ padding: 12 }}>{strings.createSupport}</div>);
    if (this.props.name && !this.props.community) {
      label = (
        <div style={{ padding: 12, lineHeight: 1.3 }}>
          <div style={{ display: 'inline-block' }}>{this.props.name}</div>
          <div style={{ display: 'inline-block' }}>{Hangul.isClosed(this.props.name) ? '을' : '를'} {strings.encourageSupportButton1}</div>
          <div style={{ display: 'block' }}>{strings.encourageSupportButton2}</div>
        </div>
      );
    } else if (this.props.name && this.props.community) {
      label = (
        <div style={{ padding: 12, lineHeight: 1.3 }}>
          <div style={{ display: 'inline-block' }}>{this.props.name}</div>
          <div style={{ display: 'inline-block' }}>{Hangul.isClosed(this.props.name) ? '을' : '를'} {strings.encourageSupportButton1}</div>
          <div style={{ display: 'block' }}>{strings.encourageSupportButtonForCommunity}</div>
        </div>
      );
    }
    if (this.props.supporting) {
      label = (
        <div style={{ padding: 12 }}>
          {strings.nowSupporting}
          <DoneIcon
            style={{
              height: 20,
              width: 20,
              verticalAlign: 'text-top',
              color: colors.textWhite
            }}
          />
        </div>
      );
    }
    return (
      <Button
        variant="contained"
        style={{ buttonStyle, height: 'auto' }}
        // labelStyle={{ padding: 0 }}
        type="button"
        color="primary"
        fullWidth
        onClick={this.handleRoute}
      >
        {label}
      </Button>
    );
  }
}

EncourageSupport.propTypes = {
  name: PropTypes.string,
  community: PropTypes.bool,
  url_artist: PropTypes.string.isRequired,
  supporting: PropTypes.bool,
};

EncourageSupport.defaultProps = {
  community: false,
  supporting: false,
};

export default EncourageSupport;
