import {
  READ_USER,
  UPDATE_USER,
  RESET_USER,
  START_EDITING_USER_PROFILE_FORM,
  FINISH_EDITING_USER_PROFILE_FORM,
  START_EDITING_USER_SETTING_FORM,
  FINISH_EDITING_USER_SETTING_FORM,
} from './../actions/actionTypes';
import { setAuth, fulfilled } from './helper';


const INITIAL_USER_STATE = { data: null, editingProfile: false, editingSetting: false };

export default function (state = INITIAL_USER_STATE, action) {
  switch (action.type) {
    case fulfilled(READ_USER): {
      if (action.payload.ok) return { ...state, data: action.payload.data };
      setAuth(null);
      return { ...state, data: null };
    }
    case fulfilled(UPDATE_USER): {
      return { ...state, data: action.payload.data };
    }
    case RESET_USER: {
      return { ...state, data: null };
    }
    case START_EDITING_USER_PROFILE_FORM: {
      return { ...state, editingProfile: true };
    }
    case FINISH_EDITING_USER_PROFILE_FORM: {
      return { ...state, editingProfile: false };
    }
    case START_EDITING_USER_SETTING_FORM: {
      return { ...state, editingSetting: true };
    }
    case FINISH_EDITING_USER_SETTING_FORM: {
      return { ...state, editingSetting: false };
    }
    default: {
      return state;
    }
  }
}
