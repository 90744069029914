import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import { Visible } from 'react-grid-system';

import { ImageContainer } from './../../components';

// import { DateTime } from './../../containers/Utils/DateTimeUtil';

import { colors, strings, styles } from './../../assets/values';


const style = {
  mobileCard: {
    boxShadow: `inset 0 0 0 1px ${colors.textWhite60}`,
    backgroundColor: 'black',
    width: '100%',
    height: 420,
    marginBottom: -1,
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'left',
  },
  card: {
    boxShadow: `inset 0 0 0 1px ${colors.textWhite60}`,
    backgroundColor: 'black',
    width: '100%',
    maxWidth: '100%',
    height: 420,
    marginBottom: 5,
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'left',
    name: {
      marginTop: 10,
      fontWeight: styles.fontWeight.Medium,
      fontSize: styles.fontSize.lg,
      color: colors.textWhite,
    },
    tags: {
      marginTop: 10,
      tag: {
        display: 'inline-block',
        padding: 4,
        fontSize: styles.fontSize.sm,
        color: colors.textRed80,
      },
    },
    description_inline: {
      marginTop: 10,
      maxHeight: '2em',
      fontSize: styles.fontSize.md,
      color: colors.textWhite90,
      wordWrap: 'break-word',
      whiteSpace: 'pre-line',
    },
    supporters_count: {
      marginTop: 10,
      fontWeight: styles.fontWeight.Regular,
      fontSize: styles.fontSize.sm,
      color: colors.textWhite80,
      bottom: 40,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    newpost: {
      padding: 8,
      display: 'flex',
      flexWrap: 'wrap',
      position: 'absolute',
      backgroundColor: colors.primaryRed,
      fontSize: styles.fontSize.sm,
      color: colors.white,
      right: 0,
      bottom: 0,
    },
  },
};

class ArtistSlideCard extends React.Component {
  render() {
    return (
      <div style={{ padding: 12 }}>
        <Visible lg>
          <Card style={style.card} onClick={this.props.onClickCard}>
            <div>
              <ImageContainer url={this.props.artist.profile_image} height={250}/>
            </div>
            <div style={{ padding: 20 }}>
              <div style={style.card.name}>
                {this.props.artist.name}
              </div>
              <div style={style.card.description_inline}>
                {this.props.artist.description_inline}
              </div>
              <div style={style.card.tags}>
                {this.props.artist.tags.map(tag => <span style={style.card.tags.tag} key={tag.name}>#{tag.name}</span>)}
              </div>
              <div style={style.card.supporters_count}>
                {this.props.artist.supports_count} {strings.supportCount}
              </div>
            </div>
            <div style={{ clear: 'both' }}/>
            {/*
              <div style={style.card.newpost}>
                {DateTime.timeFromNow(this.props.artist.posts_updated_at)}
              </div>
            */}
          </Card>
        </Visible>
        <Visible xs sm md>
          <Card style={style.mobileCard} onClick={this.props.onClickCard}>
            <div>
              <ImageContainer url={this.props.artist.profile_image} height={250}/>
            </div>
            <div style={{ padding: 6 }}>
              <div style={style.card.name}>
                {this.props.artist.name}
              </div>
              <div style={style.card.description_inline}>
                {this.props.artist.description_inline}
              </div>
              <div style={style.card.tags}>
                {typeof this.props.artist.tags === 'object' && this.props.artist.tags.map(tag => <span style={style.card.tags.tag} key={tag.name}>#{tag.name}</span>)}
              </div>
              <div style={style.card.supporters_count}>
                {this.props.artist.supports_count} {strings.supportCount}
              </div>
            </div>
            <div style={{ clear: 'both' }}/>
            {/*
              <div style={style.card.newpost}>
                {DateTime.timeFromNow(this.props.artist.posts_updated_at)}
              </div>
            */}
          </Card>
        </Visible>
      </div>
    );
  }
}

ArtistSlideCard.propTypes = {
  artist: PropTypes.object.isRequired,
  onClickCard: PropTypes.func.isRequired,
};

export default ArtistSlideCard;
