import React from 'react';

import Card from '@material-ui/core/Card';


class Invitation extends React.Component {
  render() {
    return (
      <div style={{ paddingTop: 30, paddingBottom: 30 }}>
        <Card style={{ maxWidth: 768, margin: '0 auto' }}>
          <img style={{ width: '100%', margin: 0 }} src="/dist/invitation.jpg" alt="초대장"/>
        </Card>
      </div>
    );
  }
}

export default Invitation;
