import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import ShareIcon from '@material-ui/icons/Share';

import { ShareButtons } from 'react-share';

import { styles, strings } from './../../assets/values';
// import shareIconSrc from './../../assets/images/share.png';

const { FacebookShareButton, TwitterShareButton } = ShareButtons;

class SocialShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSharePopover: false,
      sharePopoverAnchor: null,
    };
  }

  handlePopover = (event) => {
    if (this.state.openSharePopover) this.setState({ openSharePopover: false });
    else this.setState({ openSharePopover: true });
    this.setState({ sharePopoverAnchor: event.currentTarget });
  };

  render() {
    return (
      <span>
        <IconButton
          onClick={this.handlePopover}
        >
          <ShareIcon/>
        </IconButton>
        <Popover
          open={this.state.openSharePopover}
          anchorEl={this.state.sharePopoverAnchor}
          onClose={this.handlePopover}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <FacebookShareButton url={this.props.url}>
            <Button style={this.props.innerButtonStyle}>
              {strings.shareWithFacebook}
            </Button>
          </FacebookShareButton>
          <TwitterShareButton url={this.props.url}>
            <Button style={this.props.innerButtonStyle}>
              {strings.shareWithTwitter}
            </Button>
          </TwitterShareButton>
        </Popover>
      </span>
    );
  }
}

SocialShare.propTypes = {
  url: PropTypes.string.isRequired,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  innerButtonStyle: PropTypes.object,
  label: PropTypes.string,
};

SocialShare.defaultProps = {
  label: strings.shareArtist,
  iconStyle: { padding: 14 },
  buttonStyle: styles.muiComponents.raisedButton(10),
  innerButtonStyle: { width: '100%' },
};

export default SocialShare;
