import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';

import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { ArtistReward } from './../../containers';

import { PropsData, Order } from './../Utils/DataUtil';
import { Permission } from './../Utils/PermissonUtil';

import * as artistActions from './../../actions/artist';
import * as paymentActions from './../../actions/payment';

import { strings } from '../../assets/values';
import { getStyle } from './style';

import history from './../../history';


const paths = strings.routes;

const cardStyle = {
  card: {
    marginBottom: 16,
    padding: 16,
    backgroundColor: 'white',
  },
};


class ArtistRewards extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.readArtistRewards(this.props.artist.id);
  }

  handleRewardPurchase = (reward) => {
    if (this.props.user === null) {
      return history.push(paths.signIn);
    }
    const data = { artist: { id: this.props.artist.id, url_artist: this.props.artist.url_artist }, reward: reward, updating: this.props.supporting };
    this.props.setSupportForm(data);
    return history.push(`/${this.props.match.params.url}/${paths.artistPurchase}`);
  };

  render() {
    const style = getStyle();
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.rewards) return (progress);
    return (
      <div style={style.wrapper}>
        <Row>
          <Col lg={3}/>
          <Col lg={8}>
            <div style={style.rightContainer}>
              <Card style={cardStyle.card}>
                {this.props.rewards.map(reward =>
                  <ArtistReward
                    key={reward.id}
                    onClickReward={() => this.handleRewardPurchase(reward)}
                    {...reward}
                    permission={this.props.permission}
                    onUpdateReward={this.props.updateArtistReward}
                    editing={false}
                    creating={false}
                  />)}
                {this.props.creatingReward &&
                  <ArtistReward
                    permission={this.props.permission}
                    artist={this.props.artist.id}
                    onUpdateReward={this.props.createArtistReward}
                    editing
                    creating
                  />
                }
                {this.props.permission &&
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: 16 }}
                    onClick={(event) => { event.preventDefault(); this.props.startCreateReward(); }}
                  >
                    리워드 추가
                  </Button>
                }
                <div style={{ clear: 'both' }}/>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArtistRewards.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  permission: PropTypes.bool,
  supporting: PropTypes.bool,
  readArtistRewards: PropTypes.func.isRequired,
  setSupportForm: PropTypes.func.isRequired,
  rewards: PropTypes.array,
  creatingReward: PropTypes.bool.isRequired,
  readArtist: PropTypes.func.isRequired,
  startCreateReward: PropTypes.func.isRequired,
  updateArtistReward: PropTypes.func.isRequired,
  createArtistReward: PropTypes.func.isRequired,
  finishInitialize: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    artist: state.artist.data[state.artist.active],
    permission: Permission.checkArtist(state.artist.data[state.artist.active], state.user.data),
    supporting: Permission.checkSupporter(state.artist.data[state.artist.active], state.user.data),
    rewards: PropsData.fromState(state.artist.data[state.artist.active].rewards, Order.ASCENDING),
    creatingReward: state.artist.creatingReward,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...artistActions,
    ...paymentActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistRewards);
