import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PageNumberPaginator } from './../../components';

import * as userActions from './../../actions/user';
import * as paymentActions from './../../actions/payment';

import { DateTime } from './../Utils/DateTimeUtil';

import { strings, colors } from './../../assets/values';
import { getStyle } from './style';

import history from './../../history';


class MyPurchases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }
  UNSAFE_componentWillMount() {
    this.props.readUser().then((response) => {
      if (!response.action.payload.ok) history.push('/');
      else {
        this.props.readPurchases(this.state.currentPage);
      }
    });
  }

  handlePurchasesRead = (page) => {
    this.props.readPurchases(page);
    this.setState({ currentPage: page });
  };

  render() {
    if (!this.props.purchases.results) {
      return (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress size={60} />
        </div>
      );
    }
    const style = getStyle();
    return (
      <div>
        <Card style={style.innerCard}>
          <CardHeader title="구매내역"/>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{strings.merchandise}</TableCell>
                  <TableCell>{strings.artist}</TableCell>
                  <TableCell>{strings.merchandisePrice}</TableCell>
                  <TableCell>{strings.shippingPrice}</TableCell>
                  <TableCell>{strings.merchandiseQuantity}</TableCell>
                  <TableCell>{strings.orderDate}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.purchases.results && this.props.purchases.results.map(purchase =>
                  <TableRow key={purchase.id || 0}>
                    <TableCell>
                      {purchase.merchandise.name}
                    </TableCell>
                    <TableCell>
                      {purchase.merchandise.artist && purchase.merchandise.artist.name}
                    </TableCell>
                    <TableCell>
                      {purchase.merchandise.price} 원
                    </TableCell>
                    <TableCell>
                      {purchase.merchandise.shipping_price} 원
                    </TableCell>
                    <TableCell>
                      {purchase.amount} 개
                    </TableCell>
                    <TableCell>
                      {DateTime.dateFormat(purchase.created_at)}
                    </TableCell>
                  </TableRow>)}
              </TableBody>
            </Table>
            <PageNumberPaginator
              currentPage={this.state.currentPage}
              count={this.props.purchases.count}
              onPageClick={this.handlePurchasesRead}
            />
          </CardContent>
        </Card>
        <CardContent style={{ color: colors.textBlack80 }}>
          <Typography>
            문제가 있으신가요?
            <a href={strings.kakaoChatUrl} target="blank" style={{ color: colors.primaryPink }}>
              {' 카카오톡 채널로 문의'}
            </a>
            해주시면 도와드리겠습니다 :)
          </Typography>
        </CardContent>
      </div>
    );
  }
}

MyPurchases.propTypes = {
  purchases: PropTypes.object,
  readUser: PropTypes.func.isRequired,
  readPurchases: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    purchases: state.payment.purchases,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...userActions,
    ...paymentActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPurchases);
