import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-grid-system';

import { styles } from './../../assets/values';

class RowWithLabel extends React.Component {
  render() {
    const { label, ...style } = this.props.style;
    return (
      <Row style={style}>
        <Col md={this.props.inline ? 4 : 12} lg={3}>
          <div style={label}>{this.props.label}</div>
        </Col>
        <Col md={this.props.inline ? 8 : 12} lg={9}>
          {this.props.children}
        </Col>
      </Row>
    );
  }
}

RowWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  style: PropTypes.object,
};

RowWithLabel.defaultProps = {
  inline: false,
  style: styles.rowWithLabel(30),
};

export default RowWithLabel;
