import {
  SIGN_IN, SIGN_IN_WITH_FACEBOOK, SIGN_OUT, LOAD_ACCESS_TOKEN,
  SET_PASSWORD, START_SETTING_USER_PASSWORD, FINISH_SETTING_USER_PASSWORD,
} from './../actions/actionTypes';
import { setAuth, fulfilled } from './helper';


const INITIAL_AUTH_STATE = { accessToken: null, settingPassword: false };

export default function (state = INITIAL_AUTH_STATE, action) {
  switch (action.type) {
    case fulfilled(SIGN_IN):
      setAuth(action.payload.data.token);
      return {
        ...state,
        accessToken: action.payload.data.token,
      };
    case fulfilled(SIGN_IN_WITH_FACEBOOK):
      setAuth(action.payload.data.sessionToken);
      return {
        ...state,
        accessToken: action.payload.data.sessionToken,
      };
    case SIGN_OUT:
      setAuth(null);
      return {
        ...state,
        accessToken: null,
      };
    case LOAD_ACCESS_TOKEN:
      setAuth(action.payload);
      return {
        ...state,
        accessToken: action.payload,
      };
    case fulfilled(SET_PASSWORD):
      setAuth(action.payload.data.token);
      return {
        ...state,
        accessToken: action.payload.data.token,
      };
    case START_SETTING_USER_PASSWORD: {
      return { ...state, settingPassword: true };
    }
    case FINISH_SETTING_USER_PASSWORD: {
      return { ...state, settingPassword: false };
    }
    default:
      return state;
  }
}
