import { api } from './api';

import {
  INITIALIZE_ARTIST_STATE,
  READ_ARTISTS,
  READ_MORE_ARTISTS,
  CREATE_ARTIST,
  GET_ARTIST_BY_URL,
  READ_ARTIST,
  UPDATE_ARTIST,
  SET_ARTISTS_ORDERING,
  READ_ARTIST_POST_TAGS,
  CLEAR_ARTIST_POSTS,
  READ_ARTIST_POSTS,
  READ_MORE_ARTIST_POSTS,
  CREATE_ARTIST_POST,
  READ_ARTIST_POST,
  UPDATE_ARTIST_POST,
  DELETE_ARTIST_POST,
  READ_POSTS,
  READ_MORE_POSTS,
  READ_ARTIST_SCHEDULES,
  CREATE_ARTIST_SCHEDULE,
  READ_ARTIST_POST_REPLIES,
  CREATE_ARTIST_POST_REPLY,
  DELETE_ARTIST_POST_REPLY,
  READ_ARTIST_CHEERS,
  READ_MORE_ARTIST_CHEERS,
  CREATE_ARTIST_CHEER,
  READ_ARTIST_CHEER,
  UPDATE_ARTIST_CHEER,
  DELETE_ARTIST_CHEER,
  READ_CHEER_POSTS,
  CREATE_CHEER_POST,
  UPDATE_CHEER_POST,
  DELETE_CHEER_POST,
  READ_NOTICED_CHEER_POSTS,
  READ_ARTIST_CHEER_REPLIES,
  CREATE_ARTIST_CHEER_REPLY,
  DELETE_ARTIST_CHEER_REPLY,
  CREATE_CHEER_REPLY,
  DELETE_CHEER_REPLY,
  CREATE_ARTIST_REWARD,
  READ_ARTIST_REWARDS,
  UPDATE_ARTIST_REWARD,
  DELETE_ARTIST_REWARD,
  CREATE_ARTIST_MERCHANDISE,
  READ_ARTIST_MERCHANDISE,
  UPDATE_ARTIST_MERCHANDISE,
  READ_ARTIST_MERCHANDISE_DETAIL,
  SET_ARTIST_MERCHANDISE_FORM,
  CREATE_ARTIST_FOLLOW,
  DELETE_ARTIST_FOLLOW,
  UPLOAD_ARTIST_PROFILE_IMAGE,
  UPLOAD_ARTIST_COVER_IMAGE,
  UPLOAD_ARTIST_MERCHANDISE_IMAGE,
  READ_ARTISTS_RECENTLY_UPDATED,
  READ_ARTIST_RECENT_SCHEDULES,
  START_INITIALIZE,
  FINISH_INITIALIZE,
  START_CREATE_REWARD,
  FINISH_CREATE_REWARD,
  CREATE_CHEER_POST_LIKE,
  DELETE_CHEER_POST_LIKE,
  CREATE_ARTIST_CHEER_LIKE,
  DELETE_ARTIST_CHEER_LIKE,
  READ_CHEER_POST,
  CREATE_ARTIST_POST_LIKE,
  DELETE_ARTIST_POST_LIKE,
} from './actionTypes';

export function initializeArtistState() {
  return {
    type: INITIALIZE_ARTIST_STATE,
  };
}

export function readArtists(tags, search, ordering) {
  const request = api.get('/artists/', { tags, search, ordering });
  return {
    type: READ_ARTISTS,
    payload: request,
    meta: { tags, search },
  };
}

export function readMoreArtists(params) {
  const request = api.get(`/artists/?${params}`);
  return {
    type: READ_MORE_ARTISTS,
    payload: request,
  };
}

export function setArtistsOrdering(ordering) {
  return {
    type: SET_ARTISTS_ORDERING,
    payload: ordering,
  };
}

export function createArtist(data) {
  const request = api.post('/artists/', data);
  return {
    type: CREATE_ARTIST,
    payload: request,
  };
}

export function getArtistByUrl(url) {
  const request = api.get('/artists/url/', { url });
  return {
    type: GET_ARTIST_BY_URL,
    payload: request,
    meta: { url: url },
  };
}

export function readArtist(id) {
  const request = api.get(`/artists/${id}/`);
  return {
    type: READ_ARTIST,
    payload: request,
    meta: { artistId: id },
  };
}

export function updateArtist(id, data) {
  const request = api.patch(`/artists/${id}/`, data);
  return {
    type: UPDATE_ARTIST,
    payload: request,
  };
}

export function clearArtistPosts(id) {
  return {
    type: CLEAR_ARTIST_POSTS,
    meta: { artistId: id },
  };
}

export function readArtistPosts(id, search, page = 1, pagination = 'page_number', variant = 'primary') {
  const request = api.get(`/artists/${id}/posts/`, {
    search,
    page,
    pagination,
    variant,
  });
  return {
    type: READ_ARTIST_POSTS,
    payload: request,
    meta: { artistId: id, search },
  };
}

export function readArtistPostTags(id) {
  const request = api.get(`/artists/${id}/post_tags/`);
  return {
    type: READ_ARTIST_POST_TAGS,
    payload: request,
    meta: { artistId: id },
  };
}

export function readMoreArtistPosts(id, params) {
  const request = api.get(`/artists/${id}/posts/?${params}`);
  return {
    type: READ_MORE_ARTIST_POSTS,
    payload: request,
    meta: { artistId: id },
  };
}

export function readPosts(search) {
  const request = api.get('/posts/', { search, variant: 'primary' });
  return {
    type: READ_POSTS,
    payload: request,
    meta: { search },
  };
}

export function readMorePosts(params) {
  const request = api.get(`/posts/?${params}`);
  return {
    type: READ_MORE_POSTS,
    payload: request,
  };
}

export function createArtistPost(data) {
  const request = api.post('/posts/', data);
  return {
    type: CREATE_ARTIST_POST,
    payload: request,
  };
}

export function readArtistPost(artistId, postId) {
  const request = api.get(`/posts/${postId}/`);
  return {
    type: READ_ARTIST_POST,
    payload: request,
    meta: { artistId: artistId, postId: postId },
  };
}

export function updateArtistPost(data, postId) {
  const request = api.patch(`/posts/${postId}/`, data);
  return {
    type: UPDATE_ARTIST_POST,
    payload: request,
  };
}

export function deleteArtistPost(postId) {
  const request = api.delete(`/posts/${postId}/`);
  return {
    type: DELETE_ARTIST_POST,
    payload: request,
  };
}

export function readArtistSchedules(id) {
  const request = api.get(`/artists/${id}/schedules/`);
  return {
    type: READ_ARTIST_SCHEDULES,
    payload: request,
    meta: { artistId: id },
  };
}

export function createArtistSchedule(id, data) {
  const request = api.post(`/artists/${id}/schedules/`, data);
  return {
    type: CREATE_ARTIST_SCHEDULE,
    payload: request,
  }; // TODO : IMPLEMENT THIS
}

export function readArtistPostReplies(id) {
  const request = api.get('/post_replies/');
  return {
    type: READ_ARTIST_POST_REPLIES,
    payload: request,
    meta: { artistId: id },
  }; // TODO : IMPLEMENT THIS
}

export function createArtistPostReply(id, data) {
  const request = api.post('/post_replies/', data);
  return {
    type: CREATE_ARTIST_POST_REPLY,
    payload: request,
    meta: { artistId: id },
  };
}

export function deleteArtistPostReply(id) {
  const request = api.delete(`/post_replies/${id}/`);
  return {
    type: DELETE_ARTIST_POST_REPLY,
    payload: request,
  };
}

export function readArtistCheers(id, page = 1, secret = null) {
  const request = api.get(`/artists/${id}/cheer_posts/`, { page, is_secret: secret });
  return {
    type: READ_ARTIST_CHEERS,
    payload: request,
    meta: { artistId: id },
  };
}

export function readMoreArtistCheers(id, params) {
  const request = api.get(`/artists/${id}/cheer_posts/?${params}`);
  return {
    type: READ_MORE_ARTIST_CHEERS,
    payload: request,
    meta: { artistId: id },
  };
}

export function readCheerPosts(page = 1) {
  const request = api.get('/cheer_posts/', { page });
  return {
    type: READ_CHEER_POSTS,
    payload: request,
  };
}

export function readNoticedCheerPosts(page = 1) {
  const request = api.get('/cheer_posts/', { page, noticed: true });
  return {
    type: READ_NOTICED_CHEER_POSTS,
    payload: request,
  };
}

export function readFeaturedCheerPosts(page = 1) {
  const request = api.get('/cheer_posts/', { page, featured: true });
  return {
    type: READ_CHEER_POSTS,
    payload: request,
  };
}

export function readPublicCheerPosts(page = 1) {
  const request = api.get('/cheer_posts/', { page, public: true });
  return {
    type: READ_CHEER_POSTS,
    payload: request,
  };
}

export function createCheerPost(data) {
  const request = api.post('/cheer_posts/', data);
  return {
    type: CREATE_CHEER_POST,
    payload: request,
  };
}

export function readCheerPost(id) {
  const request = api.get(`/cheer_posts/${id}/`);
  return {
    type: READ_CHEER_POST,
    payload: request,
  };
}

export function updateCheerPost(data, id) {
  const request = api.patch(`/cheer_posts/${id}/`, data);
  return {
    type: UPDATE_CHEER_POST,
    payload: request,
  };
}

// export function readMoreCheerPosts(params) {
//   const request = api.get(`/cheer_posts/?${params}`);
//   return {
//     type: READ_MORE_CHEER_POSTS,
//     payload: request,
//   };
// }

export function createArtistCheer(data, nickname) {
  const request = api.post('/cheer_posts/', data);
  return {
    type: CREATE_ARTIST_CHEER,
    payload: request,
    meta: { user: { nickname } },
  };
}

export function readArtistCheer(artistId, cheerId) {
  const request = api.get(`/cheer_posts/${cheerId}/`);
  const meta = {
    artistId: parseInt(artistId, 10),
    cheerId: parseInt(cheerId, 10),
  };
  return {
    type: READ_ARTIST_CHEER,
    payload: request,
    meta,
  };
}

export function updateArtistCheer(data, cheerId) {
  const request = api.patch(`/cheer_posts/${cheerId}/`, data);
  return {
    type: UPDATE_ARTIST_CHEER,
    payload: request,
  };
}

export function deleteArtistCheer(cheerId) {
  const request = api.delete(`/cheer_posts/${cheerId}/`);
  return {
    type: DELETE_ARTIST_CHEER,
    payload: request,
  };
}

export function deleteCheerPost(id) {
  const request = api.delete(`/cheer_posts/${id}/`);
  return {
    type: DELETE_CHEER_POST,
    payload: request,
    meta: {
      id,
    }
  };
}

export function readArtistCheerReplies(id) {
  const request = api.get('/cheer_post_replies/');
  return {
    type: READ_ARTIST_CHEER_REPLIES,
    payload: request,
    meta: { artistId: id },
  }; // TODO : IMPLEMENT THIS
}

export function createArtistCheerReply(id, data) {
  const request = api.post('/cheer_post_replies/', data);
  return {
    type: CREATE_ARTIST_CHEER_REPLY,
    payload: request,
    meta: { artistId: id },
  };
}

export function createCheerReply(data) {
  const request = api.post('/cheer_post_replies/', data);
  return {
    type: CREATE_CHEER_REPLY,
    payload: request,
  };
}

export function deleteArtistCheerReply(id) {
  const request = api.delete(`/cheer_post_replies/${id}/`);
  return {
    type: DELETE_ARTIST_CHEER_REPLY,
    payload: request,
  };
}

export function deleteCheerReply(id) {
  const request = api.delete(`/cheer_post_replies/${id}/`);
  return {
    type: DELETE_CHEER_REPLY,
    payload: request,
  };
}

export function readArtistRewards(id) {
  const request = api.get(`/artists/${id}/rewards/`);
  return {
    type: READ_ARTIST_REWARDS,
    payload: request,
    meta: { artistId: id },
  };
}

export function createArtistReward(data) {
  const request = api.post('/rewards/', data);
  return {
    type: CREATE_ARTIST_REWARD,
    payload: request,
  };
}

export function updateArtistReward(id, data) {
  const request = api.patch(`/rewards/${id}/`, data);
  return {
    type: UPDATE_ARTIST_REWARD,
    payload: request,
  };
}

export function deleteArtistReward(id) {
  const request = api.delete(`/rewards/${id}/`);
  return {
    type: DELETE_ARTIST_REWARD,
    payload: request,
    meta: { id },
  };
}

export function createArtistMerchandise(data) {
  const request = api.post('/merchandise/', data);
  return {
    type: CREATE_ARTIST_MERCHANDISE,
    payload: request,
  };
}

export function readArtistMerchandise(id) {
  const request = api.get(`artists/${id}/merchandise`);
  return {
    type: READ_ARTIST_MERCHANDISE,
    payload: request,
    meta: { artistId: id },
  };
}

export function updateArtistMerchandise(id, data) {
  const request = api.patch(`/merchandise/${id}/`, data);
  return {
    type: UPDATE_ARTIST_MERCHANDISE,
    payload: request,
  };
}

export function readArtistMerchandiseDetail(id) {
  const request = api.get(`/merchandise/${id}/`);
  return {
    type: READ_ARTIST_MERCHANDISE_DETAIL,
    payload: request,
  };
}

export function setArtistMerchandiseForm(data) {
  return {
    type: SET_ARTIST_MERCHANDISE_FORM,
    payload: data,
  };
}

export function createArtistFollow(artist) {
  const request = api.post('/follows/', { artist });
  return {
    type: CREATE_ARTIST_FOLLOW,
    payload: request,
  };
}

export function deleteArtistFollow(id) {
  const request = api.delete(`/follows/${id}/`);
  return {
    type: DELETE_ARTIST_FOLLOW,
    payload: request,
  };
}

export function uploadArtistProfileImage(data) {
  const formData = new FormData();
  formData.append('file', data);
  const request = api.post('/images/upload/', formData, { headers: { 'Content-Type': 'multipart/form-data; boundary=frontier' } });
  return {
    type: UPLOAD_ARTIST_PROFILE_IMAGE,
    payload: request,
  };
}

export function uploadArtistCoverImage(data) {
  const formData = new FormData();
  formData.append('file', data);
  const request = api.post('/images/upload/', formData, { headers: { 'Content-Type': 'multipart/form-data; boundary=frontier' } });
  return {
    type: UPLOAD_ARTIST_COVER_IMAGE,
    payload: request,
  };
}

export function uploadArtistMerchandiseImage(data) {
  const formData = new FormData();
  formData.append('file', data);
  const request = api.post('/images/upload/', formData, { headers: { 'Content-Type': 'multipart/form-data; boundary=frontier' } });
  return {
    type: UPLOAD_ARTIST_MERCHANDISE_IMAGE,
    payload: request,
  };
}

export function readArtistsRecentlyUpdated() {
  const request = api.get('/artists/recently_updated/');
  return {
    type: READ_ARTISTS_RECENTLY_UPDATED,
    payload: request,
  };
}

export function readArtistRecentSchedules() {
  const request = api.get('/schedules/upcoming/');
  return {
    type: READ_ARTIST_RECENT_SCHEDULES,
    payload: request,
  };
}

export function createArtistCheerLike(artist, cheer) {
  const request = api.post('/cheer_post_likes/', { cheer: cheer });
  return {
    type: CREATE_ARTIST_CHEER_LIKE,
    payload: request,
    meta: {
      artist
    }
  };
}

export function deleteArtistCheerLike(artist, id) {
  const request = api.delete(`/cheer_post_likes/${id}/`);
  return {
    type: DELETE_ARTIST_CHEER_LIKE,
    payload: request,
    meta: {
      artist
    }
  };
}

export function createCheerPostLike(cheer) {
  const request = api.post('/cheer_post_likes/', { cheer });
  return {
    type: CREATE_CHEER_POST_LIKE,
    payload: request,
  };
}

export function deleteCheerPostLike(id) {
  const request = api.delete(`/cheer_post_likes/${id}/`);
  return {
    type: DELETE_CHEER_POST_LIKE,
    payload: request,
  };
}

export function createArtistPostLike(artist, artistPost) {
  const request = api.post('/post_likes/', { artist_post: artistPost });
  return {
    type: CREATE_ARTIST_POST_LIKE,
    payload: request,
    meta: {
      artist
    }
  };
}

export function deleteArtistPostLike(artist, artistPost, id) {
  const request = api.delete(`/post_likes/${id}/`);
  return {
    type: DELETE_ARTIST_POST_LIKE,
    payload: request,
    meta: {
      artist,
      artistPost,
      id,
    }
  };
}


export function startInitialize() {
  return {
    type: START_INITIALIZE,
  };
}

export function finishInitialize() {
  return {
    type: FINISH_INITIALIZE,
  };
}

export function startCreateReward() {
  return {
    type: START_CREATE_REWARD,
  };
}

export function finishCreateReward() {
  return {
    type: FINISH_CREATE_REWARD,
  };
}
