import {
  READ_PAGE_VIEWS,
  READ_PAGE_VIEWS_USER_GENDER,
  READ_CHECKOUT,
} from './../actions/actionTypes';
import { fulfilled } from './helper';

const INITIAL_DASHBOARD_STATE = { analytics: {}, checkout: {} };

export default function (state = INITIAL_DASHBOARD_STATE, action) {
  switch (action.type) {
    case fulfilled(READ_PAGE_VIEWS):
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pageViews: action.payload.data,
        },
      };
    case fulfilled(READ_PAGE_VIEWS_USER_GENDER):
      return {
        ...state,
        analytics: {
          ...state.analytics,
          userGender: action.payload.data,
        },
      };
    case fulfilled(READ_CHECKOUT):
      return {
        ...state,
        checkout: action.payload.data,
      };
    default:
      return state;
  }
}
