import {
  READ_QUESTIONS,
  READ_ANNOUNCEMENTS, READ_MORE_ANNOUNCEMENTS,
} from './../actions/actionTypes';
import { fulfilled } from './helper';

const INITIAL_SUPPORT_STATE = { questions: [], announcements: {} };

export default function (state = INITIAL_SUPPORT_STATE, action) {
  const newData = {};
  switch (action.type) {
    case fulfilled(READ_QUESTIONS):
      return { ...state, questions: action.payload.data };
    case fulfilled(READ_ANNOUNCEMENTS):
      action.payload.data.results.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        announcements: newData,
        _nextAnnouncements: action.payload.data.next ? action.payload.data.next.split('?')[1] : null,
      };
    case fulfilled(READ_MORE_ANNOUNCEMENTS):
      action.payload.data.results.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        announcements: {
          ...state.data.announcements,
          ...newData,
        },
        _nextAnnouncements: action.payload.data.next ? action.payload.data.next.split('?')[1] : null,
      };
    default:
      return state;
  }
}

