import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import { RowWithLabel } from './../../components';

import * as artistActions from './../../actions/artist';
import * as userActions from './../../actions/user';
import * as paymentActions from './../../actions/payment';

import { strings, colors } from './../../assets/values';
import { getStyle } from './style';

class ArtistPurchaseSuccess extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.getArtistByUrl(this.props.match.params.url);
    this.props.readUser();
    this.props.initializeGSPoint();
  }

  componentDidMount() {
    if (!this.props.supportForm.updating) {
      const ga = ReactGA.ga();
      ga('require', 'ecommerce');
      ga('ecommerce:addTransaction', {
        id: this.props.supportForm.id, // 시스템에서 생성된 주문번호. 필수.
        revenue: this.props.supportForm.payment_amount, // 구매총액. 필수.
      });
      ga('ecommerce:addItem', {
        id: this.props.supportForm.id, // 시스템에서 생성된 주문번호. 필수.
        name: this.props.supportForm.artist.id, // 제품명. 필수.
        sku: this.props.supportForm.reward.id, // SKU 또는 제품고유번호. 선택사항.
        price: this.props.supportForm.payment_amount, // 제품 단가.
      });
      ga('ecommerce:send');
    }
  }

  render() {
    const style = getStyle();
    const defaultRowStyle = {
      ...style.rowWithLabel(),
      label: { marginTop: 0, color: colors.textPink },
    };
    return (
      <div style={style.wrapper}>
        <Row>
          <Col lg={3}/>
          <Col lg={7}>
            <div style={style.rightContainer}>
              <Card style={{ marginBottom: 40 }}>
                <CardHeader
                  title={this.props.supportForm.updating ?
                    strings.support.update.success :
                    strings.support.create.success
                  }
                />
                <div>
                  <CardHeader
                    title={this.props.supportForm.updating ?
                      strings.support.update.message :
                      strings.support.create.message
                    }
                  />
                  <CardContent>
                    <RowWithLabel label={strings.artistName} style={defaultRowStyle}>
                      {this.props.supportForm.artist.name}
                    </RowWithLabel>
                    <RowWithLabel label={strings.selectedReward} style={defaultRowStyle}>
                      {this.props.supportForm.reward.title}
                    </RowWithLabel>
                    <RowWithLabel label={strings.paymentAmount} style={defaultRowStyle}>
                      {this.props.supportForm.payment_amount}{strings.won}
                    </RowWithLabel>
                    <RowWithLabel label={strings.paymentDay} style={defaultRowStyle}>
                      {strings.perMonth} {this.props.supportForm.payment_day}{strings.day}
                    </RowWithLabel>
                    <p>위 내용으로 다음 달부터 매월 정기결제가 이루어집니다. 정기결제에 실패한 경우, 일주일 동안 다시 시도해 본 뒤 함께가기가 중단됩니다.</p>
                    <p>결제카드 변경은 마이페이지의 결제정보 메뉴에서 하실 수 있습니다.</p>
                    <p>결제 변경 및 중단은 마이페이지의 함께하는 아티스트 목록에서 하실 수 있습니다.</p>
                  </CardContent>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArtistPurchaseSuccess.propTypes = {
  user: PropTypes.object,
  supportForm: PropTypes.object,
  getArtistByUrl: PropTypes.func.isRequired,
  readUser: PropTypes.func.isRequired,
  initializeGSPoint: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    supportForm: state.payment.supportForm,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions, ...userActions, ...paymentActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistPurchaseSuccess);
