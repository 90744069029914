import { api } from './api';
import {
  READ_QUESTIONS, CREATE_CONTACT,
  READ_ANNOUNCEMENTS, READ_MORE_ANNOUNCEMENTS,
} from './actionTypes';

export function readQuestions() {
  const request = api.get('/questions/');
  return {
    payload: request,
    type: READ_QUESTIONS,
  };
}

export function createContact(data) {
  const request = api.post('/contacts/', data);
  return {
    payload: request,
    type: CREATE_CONTACT,
  };
}

export function readAnnouncements() {
  const request = api.get('/announcements');
  return {
    type: READ_ANNOUNCEMENTS,
    payload: request,
  };
}

export function readMoreAnnouncements(params) {
  const request = api.get(`/announcements/?${params}`);
  return {
    type: READ_MORE_ANNOUNCEMENTS,
    payload: request,
  };
}
