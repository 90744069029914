/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';

import { Visible } from 'react-grid-system';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { FlatButtonWithLink, MenuItemWithLink } from './../../components';
import ArtistsSearchForm from './../Artist/ArtistsSearchForm';
import * as Validator from './../Utils/Validator';

import * as authActions from './../../actions/auth';
import * as navigatorActions from './../../actions/navigator';
import * as userActions from './../../actions/user';

import logoSrc from './../../assets/images/logo.png';
import { strings, styles } from './../../assets/values';

import history from './../../history';

const { spacing } = styles.header;
const style = {
  title: {
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    maxWidth: 1140,
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 64,
  },
  header: {
    backgroundColor: '#fff',
  },
  negativeGutter: {
    marginLeft: -(spacing.gutterWidth / 2),
    marginRight: -(spacing.gutterWidth / 2),
  },
  leftCointainer: {
    flex: '1',
    textAlign: 'left',
  },
  rightCointainer: {
    flex: '1',
    textAlign: 'right',
  },
  logo: {
    cursor: 'pointer',
    height: 64,
    imgWithPadding: {
      paddingTop: spacing.logoVerticalPadding,
      paddingBottom: spacing.logoVerticalPadding,
      paddingLeft: spacing.gutterWidth / 2,
      paddingRight: spacing.gutterWidth / 2,
      height: 64 - (spacing.logoVerticalPadding * 2),
    },
    imgWithoutPadding: {
      paddingTop: spacing.logoVerticalPadding,
      paddingBottom: spacing.logoVerticalPadding,
      height: spacing.height - (spacing.logoVerticalPadding * 2),
    },
  },
  button: {
    height: 64,
  },
  onLeft: {
    float: 'left',
  },
  onRight: {
    float: 'right',
  },
  drawer: {
    top: 64,
  },
};
const navButtonStyle = (position) => {
  let positionStyle = style.onRight;
  if (position === 'left') positionStyle = style.onLeft;
  return { ...style.button, ...positionStyle };
};
const buttonOnLeft = navButtonStyle('left');
const buttonOnRight = navButtonStyle('right');
const paths = strings.routes;

class Logo extends React.Component {
  render() {
    return (
      <Link href="/" to="/" replace>
        <span style={style.logo}>
          {this.props.withPadding
            ? <img style={style.logo.imgWithPadding} src={logoSrc} alt={strings.appName}/>
            : <img style={style.logo.imgWithoutPadding} src={logoSrc} alt={strings.appName}/>
          }
        </span>
      </Link>
    );
  }
}

Logo.propTypes = {
  withPadding: PropTypes.bool,
};

class Header extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.loadAccessToken();
    if (this.props.accessToken) this.props.readUser().then(res => this.handleUserDetail(res));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.accessToken && nextProps.accessToken) {
      this.props.readUser().then(res => this.handleUserDetail(res));
    } else if (this.props.accessToken && !nextProps.accessToken) {
      this.props.resetUser();
      toastr.success(strings.success, strings.signOutSuccess);
      history.push('/');
    }
  }

  handleUserDetail = (response) => {
    const { email, nickname } = response.action.payload.data;
    if (Validator.validateEmail(email) || Validator.validateNickName(nickname)) {
      history.push(`${strings.routes.signUp}/detail`);
    }
  }

  signOut = () => {
    this.props.closeNavigatorPopover();
    this.props.signOut();
  };

  handleDrawer = () => {
    if (this.props.openDrawer) this.props.closeNavigatorDrawer();
    else this.props.openNavigatorDrawer();
  };

  openNavigatorPopover = (event) => {
    this.props.setNavigatorPopoverAnchor(event.currentTarget);
    this.props.openNavigatorPopover();
  };

  render() {
    const authorizedUser = this.props.user;
    const authorizedArtist = this.props.user && this.props.user.is_artist;

    return (
      <div style={style.header}>
        <Visible lg xl>
          <div style={style.container}>
            <div style={style.leftCointainer}>
              <FlatButtonWithLink path={paths.about} label={strings.header.about} style={buttonOnLeft}/>
              <FlatButtonWithLink path={paths.artists} label={strings.header.artists} style={buttonOnLeft}/>
              {!authorizedArtist && <FlatButtonWithLink path={paths.artistsStart} label={strings.header.artistsStart} style={buttonOnLeft}/>}
              {authorizedArtist && <FlatButtonWithLink path={`/${this.props.user.artist[0].url_artist}`} label={strings.header.myArtistPage} style={buttonOnLeft}/>}
              <FlatButtonWithLink path={`${paths.support}/${paths.supportFAQ}`} label={strings.faq} style={buttonOnLeft}/>
            </div>
            <Logo withPadding/>
            <div style={style.rightCointainer}>
              <ArtistsSearchForm style={{ display: 'inline-block', width: 180 }}/>
              {authorizedArtist && <FlatButtonWithLink path={paths.dashboard} label={strings.header.dashboard} style={buttonOnRight}/>}
              {!authorizedUser ?
                <FlatButtonWithLink path={paths.signIn} label={`${strings.header.signIn} / ${strings.header.signUp}`} style={buttonOnRight}/> :
                <Button style={buttonOnRight} onClick={this.openNavigatorPopover}>
                  {strings.header.myPage}
                </Button>
              }
            </div>
            <Menu
              keepMounted
              open={this.props.openPopover}
              anchorEl={this.props.popoverAnchor}
              onClose={this.props.closeNavigatorPopover}
            >
              <MenuItemWithLink path={`${paths.my}/${paths.myProfile}`} onClick={this.props.closeNavigatorPopover}>
                {strings.myProfile}
              </MenuItemWithLink>
              <MenuItemWithLink path={`${paths.my}/${paths.myArtists}`} onClick={this.props.closeNavigatorPopover}>
                {strings.myArtists}
              </MenuItemWithLink>
              <MenuItemWithLink path={`${paths.my}/${paths.myPurchases}`} onClick={this.props.closeNavigatorPopover}>
                {strings.myPurchases}
              </MenuItemWithLink>
              <MenuItemWithLink path={`${paths.my}/${paths.myPayments}`} onClick={this.props.closeNavigatorPopover}>
                {strings.myPayments}
              </MenuItemWithLink>
              <Divider/>
              <MenuItem onClick={this.signOut}>
                {strings.header.signOut}
              </MenuItem>
            </Menu>
          </div>
        </Visible>
        <Visible xs sm md>
          <AppBar color="default" position="static">
            <Toolbar>
              <IconButton onClick={this.handleDrawer}>
                <MenuIcon/>
              </IconButton>
              <Logo/>
            </Toolbar>
          </AppBar>
          <Drawer
            open={this.props.openDrawer}
            onClose={this.handleDrawer}
          >
            <Toolbar>
              <Logo/>
            </Toolbar>
            <ArtistsSearchForm style={{ padding: 15, paddingTop: 0 }}/>
            <Divider/>
            <MenuList
              onClick={this.handleDrawer}
            >
              <MenuItemWithLink path={paths.app}>
                {strings.header.main}
              </MenuItemWithLink>
              <MenuItemWithLink path={paths.about}>
                {strings.header.about}
              </MenuItemWithLink>
              <MenuItemWithLink path={paths.artists}>
                {strings.header.artists}
              </MenuItemWithLink>
              {!authorizedArtist &&
                <MenuItemWithLink path={paths.artistsStart}>
                  {strings.header.artistsStart}
                </MenuItemWithLink>
              }
              <MenuItemWithLink path={`${paths.support}/${paths.supportFAQ}`}>
                {strings.faq}
              </MenuItemWithLink>
              <Divider/>
              {authorizedArtist &&
                <MenuItemWithLink path={`/${this.props.user.artist[0].url_artist}`}>
                  {strings.header.myArtistPage}
                </MenuItemWithLink>
              }
              {authorizedArtist &&
                <MenuItemWithLink path={paths.dashboard}>
                  {strings.header.dashboard}
                </MenuItemWithLink>
              }
              {authorizedUser &&
                <MenuItemWithLink path={paths.my}>
                  {strings.header.myPage}
                </MenuItemWithLink>
              }
              {authorizedUser &&
                <MenuItem onClick={this.signOut}>
                  {strings.header.signOut}
                </MenuItem>
              }
              {!authorizedUser &&
                <MenuItemWithLink path={paths.signIn}>
                  {strings.header.signIn}
                </MenuItemWithLink>
              }
              {!authorizedUser &&
                <MenuItemWithLink path={paths.signUp}>
                  {strings.header.signUp}
                </MenuItemWithLink>
              }
            </MenuList>
          </Drawer>
        </Visible>
      </div>
    );
  }
}

Header.propTypes = {
  accessToken: PropTypes.string,
  activePath: PropTypes.string,
  user: PropTypes.object,
  artist: PropTypes.object,
  openDrawer: PropTypes.bool.isRequired,
  openPopover: PropTypes.bool.isRequired,
  popoverAnchor: PropTypes.object,
  loadAccessToken: PropTypes.func.isRequired,
  openNavigatorDrawer: PropTypes.func.isRequired,
  closeNavigatorDrawer: PropTypes.func.isRequired,
  openNavigatorPopover: PropTypes.func.isRequired,
  closeNavigatorPopover: PropTypes.func.isRequired,
  setNavigatorPopoverAnchor: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  readUser: PropTypes.func.isRequired,
  resetUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    artist: state.artist.data[state.artist.active],
    accessToken: state.auth.accessToken,
    activePath: state.navigator.path,
    openDrawer: state.navigator.openDrawer,
    openPopover: state.navigator.openPopover,
    popoverAnchor: state.navigator.popoverAnchor,
    user: state.user.data,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...authActions,
    ...navigatorActions,
    ...userActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
