import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';
import Button from '@material-ui/core/Button';
import { Banner } from './../../components';
import { CheerPost } from './../../containers';

import * as artistActions from './../../actions/artist';
import { Permission } from './../Utils/PermissonUtil';
import { strings } from './../../assets/values';

import history from './../../history';


class CommunitySinglePost extends React.Component {
  static fetchData({ store, params }) {
    return store.dispatch(artistActions.getArtistByUrl(params.url))
      .then((response) => {
        if (response.action.payload.ok) {
          store.dispatch(artistActions.finishInitialize());
          return store.dispatch(artistActions.readArtistCheer(response.action.payload.data.id, params.postNumber));
        }
        return Promise.resolve(null);
      });
  }

  UNSAFE_componentWillMount() {
    // When environment is not server or data is not fulfilld or route is not mached with state
    const singleCheer = this.getSingleCheer();
    if (typeof window !== 'undefined' && (!singleCheer || singleCheer.id !== this.props.match.params.postNumber)) {
      this.props.readCheerPost(this.props.match.params.postNumber);
    }
  }

  getSingleCheer = () => this.props.cheers[this.props.match.params.postNumber];

  handleGoBack = () => {
    history.push(`${strings.routes.cheers}/`);
  }

  render() {
    const singleCheer = this.getSingleCheer();
    // TODO : 미스매칭 됬을 때 컴포넌트를 이용해서 잘못 된 접근이라고 알려줍시다.
    return (
      <Row>
        <Col lg={6} sm={12} offset={{ lg: 3 }}>
          <div style={{ paddingTop: 16, paddingBottom: 16, width: '100%' }}>
            <Banner/>
            {!!singleCheer &&
            <CheerPost
              post={singleCheer}
              replyPermission={Permission.checkUser(this.props.user)}
              editingPermission={Permission.checkWriter(singleCheer.user, this.props.user)}
              commentsExpanded
              actions={[
                <Button
                  variant="outlined"
                  color="secondary"
                  key="goBack"
                  onClick={this.handleGoBack}
                >
                  전체 글 보기
                </Button>
              ]}
              type="cheer"
              match={this.props.match}
            />
            }
          </div>
        </Col>
      </Row>
    );
  }
}

CommunitySinglePost.propTypes = {
  user: PropTypes.object,
  cheers: PropTypes.object,
  readCheerPost: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    cheers: state.artist.cheers,
    fetching: state.artist.fetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunitySinglePost);
