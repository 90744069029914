import { api } from './api';
import {
  SIGN_IN_WITH_FACEBOOK,
  SIGN_IN,
  SIGN_UP,
  SIGN_OUT,
  LOAD_ACCESS_TOKEN,
  SET_PASSWORD,
  RESET_PASSWORD,
  START_SETTING_USER_PASSWORD,
  FINISH_SETTING_USER_PASSWORD,
} from './actionTypes';


export function signIn(data) {
  const request = api.post('/auth/token/', data);
  return {
    type: SIGN_IN,
    payload: request,
  };
}

export function signInWithFacebook(data) {
  const request = api.post('/auth/facebook/', { auth_token: data.accessToken });
  return {
    type: SIGN_IN_WITH_FACEBOOK,
    payload: request,
  };
}

export function signUp(data) {
  const request = api.post('/users/sign_up/', data);
  return {
    type: SIGN_UP,
    payload: request,
  };
}

export function signOut() {
  return {
    type: SIGN_OUT,
    payload: null,
  };
}

export function loadAccessToken() {
  const token = localStorage.getItem('AccessToken');
  return {
    type: LOAD_ACCESS_TOKEN,
    payload: token,
  };
}

export function setPassword(data) {
  const request = api.post('/auth/set_password/', data);
  return {
    type: SET_PASSWORD,
    payload: request,
  };
}

export function resetPassword(data) {
  const request = api.post('/auth/reset_password/', data);
  return {
    type: RESET_PASSWORD,
    payload: request,
  };
}

export function startSettingUserPassword() {
  return {
    type: START_SETTING_USER_PASSWORD,
  };
}

export function finishSettingUserPassword() {
  return {
    type: FINISH_SETTING_USER_PASSWORD,
  };
}
