import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';

import { useFormik } from 'formik';
import { Row, Col, Container } from 'react-grid-system';

import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { ArtistPostEditor } from './../../containers';

import { Post as PostComponent, ArtistPostReply } from './../../components';
import { Order } from './../Utils/DataUtil';
import { DateTime } from './../Utils/DateTimeUtil';

import * as artistActions from './../../actions/artist';

import { strings } from './../../assets/values';
import { getStyle } from './style';


const ArtistPost = (props) => {
  const [editing, setEditing] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [commentsExpanded, setCommentsExpanded] = useState(!!props.commentsExpanded);

  const onSubmit = (data) => {
    const artistId = props.post.artist.id || props.post.artist;
    return props.createArtistPostReply(artistId, { ...data, artist_post: props.post.id })
      .then(() => props.readArtistPost(artistId, props.post.id));
  };

  const onDeletePost = () => {
    props.deleteArtistPost(props.post.id)
      .then(() => setConfirmDelete(false))
      .then(() => props.readArtistPosts(props.post.artist.id));
  };

  const onDeletePostReply = () => {
    const artistId = props.post.artist.id || props.post.artist;
    props.readArtistPost(artistId, props.post.id);
  };

  const handleCreatePostLike = () => {
    const artistId = props.post.artist.id || props.post.artist;
    return props.createArtistPostLike(artistId, props.post.id);
  };

  const handleDeletePostLike = () => {
    const artistId = props.post.artist.id || props.post.artist;
    return props.deleteArtistPostLike(artistId, props.post.id, props.post.user_like);
  };

  const onLikeButtonClick = () => {
    if (!props.user) {
      return toastr.error('로그인이 필요합니다', '로그인 후 게시물을 추천할 수 있습니다');
    }
    if (props.post.is_user_liked) {
      return handleDeletePostLike();
    }
    return handleCreatePostLike();
  };

  const getInitialValues = (post) => {
    let scheduleDate = null;
    if (post.schedule) {
      scheduleDate = new Date(DateTime.dateISO(post.schedule.date));
    }
    let isSecret = false;
    if (post.minimum_reward) {
      isSecret = true;
    }
    let postTags = '';
    if (post.tags) {
      postTags = post.tags.reduce((prev, curr) => `${prev}, ${curr.name}`, '');
      postTags = postTags.slice(2);
    }
    return {
      ...post, post_tags: postTags, isSecret: isSecret, schedule_date: scheduleDate
    };
  };

  const openConfirmDelete = () => {
    setConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setConfirmDelete(false);
  };

  const openEditingForm = () => {
    setEditing(true);
  };

  const closeEditingForm = () => {
    setEditing(false);
  };

  const handleExpansionChange = (event, expanded) => {
    setCommentsExpanded(expanded);
  };

  const formik = useFormik({
    initialValues: {
      body: '',
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const style = getStyle();
  const deleteActions = [
    <Button key="cancel" color="primary" onClick={(event) => { event.preventDefault(); closeConfirmDelete(); }}>
      {strings.cancel}
    </Button>,
    <Button key="remove" colro="primary" onClick={(event) => { event.preventDefault(); onDeletePost(); }}>
      {strings.remove}
    </Button>,
  ];
  // const openIcon = <HardwareKeyboardArrowUp viewBox="0 0 30 30"/>;
  // const closeIcon = <HardwareKeyboardArrowDown viewBox="0 0 30 30"/>;
  return (
    <div style={style.post.wrapper}>
      <Card>
        {props.editingPermission &&
          <CardActions>
            <Button
              type="button"
              variant="outlined"
              size="small"
              onClick={(event) => { event.preventDefault(); openConfirmDelete(); }}
              disabled={editing}
            >
              {strings.remove}
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="small"
              onClick={(event) => { event.preventDefault(); openEditingForm(); }}
              disabled={editing}
            >
              {strings.edit}
            </Button>
          </CardActions>
        }
        <CardContent>
          {editing ?
            <ArtistPostEditor
              onDismiss={closeEditingForm}
              initialValues={getInitialValues(props.post)}
              match={props.match}
              editing
            /> :
            <PostComponent
              {...props.post}
              body={props.post.body}
              url={`${strings.appUrl}/${props.post.artist.url_artist}/${strings.routes.artistPosts}/${props.post.id}`}
              style={style.post.content}
            />
          }
          <div style={{ display: 'inline-block', width: '50%', textAlign: 'left' }}>
            <Button
              variant="text"
              color="primary"
              onClick={onLikeButtonClick}
            >
              {props.post.is_user_liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              <span style={{ marginLeft: '0.5rem' }}>{props.post.like_count}</span>
            </Button>
          </div>
          {props.post.is_readable && (
            <div>
              <Divider style={style.post.reply.divider}/>
              <Accordion
                style={style.post.reply}
                expanded={commentsExpanded}
                onChange={handleExpansionChange}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    댓글 {`(${props.post.reply_count})`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                    <Container>
                      {props.post.replies.sort(Order.ID_ASCENDING).map(reply =>
                        <ArtistPostReply
                          key={reply.id}
                          style={style.post.replyComponent}
                          deletePermission={props.user && props.user.id === reply.user.id}
                          onDeletePostReply={onDeletePostReply}
                          {...reply}
                        />)}
                      {props.replyPermission &&
                        <Row>
                          <Col sm={2} md={2} lg={2} xl={2}>
                            <div style={style.post.replyNickname}>
                              {props.user.nickname}
                            </div>
                          </Col>
                          <Col sm={8} md={8} lg={8} xl={8}>
                            <TextField
                              fullWidth
                              value={formik.values.body}
                              onChange={formik.handleChange}
                              name="body"
                              label={strings.postReply}
                              margin="normal"
                            />
                          </Col>
                          <Col sm={2} md={2} lg={2} xl={2}>
                            <Button
                              color="primary"
                              variant="outlined"
                              style={style.post.replySubmit}
                              type="submit"
                              disabled={!formik.dirty || formik.isSubmitting}
                            >
                              {strings.confirm}
                            </Button>
                          </Col>
                        </Row>
                      }
                    </Container>
                    {!props.replyPermission &&
                      <div style={{ marginTop: 15 }}>
                        <Link href={strings.routes.signIn} to={strings.routes.signIn}>
                          <Button variant="outlined" size="small">로그인 후 댓글을 남길 수 있습니다</Button>
                        </Link>
                      </div>
                    }
                  </form>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          {!!props.actions &&
            <div>
              <Divider style={style.post.reply.divider}/>
              <div style={{ marginTop: 15 }}>
                {props.actions}
              </div>
            </div>
          }
        </CardContent>
      </Card>
      <div>
        <Dialog open={confirmDelete} >
          <div style={{ width: '240px', height: '30px', padding: '40px' }}>
            {strings.wantDelete}
          </div>
          <DialogActions>
            {deleteActions}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

ArtistPost.propTypes = {
  user: PropTypes.object,
  post: PropTypes.object.isRequired,
  comments: PropTypes.array,
  commentsExpanded: PropTypes.bool,
  replyPermission: PropTypes.bool,
  editingPermission: PropTypes.bool,
  createArtistPostReply: PropTypes.func.isRequired,
  readArtistPost: PropTypes.func.isRequired,
  readArtistPosts: PropTypes.func.isRequired,
  deleteArtistPost: PropTypes.func.isRequired,
  createArtistPostLike: PropTypes.func.isRequired,
  deleteArtistPostLike: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  match: PropTypes.object,
};

ArtistPost.defaultProps = {
  type: 'artist',
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistPost);
