/* eslint-disable react/no-danger */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import stripTags from 'striptags';
import HtmlEntities from 'html-entities';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextEditor from './../../containers/Editor/TextEditor';

import { Permission } from './../Utils/PermissonUtil';

import * as artistActions from './../../actions/artist';

import { strings } from './../../assets/values';
import { getStyle } from './style';

const entities = new HtmlEntities.AllHtmlEntities();

class ArtistIntroduction extends React.Component {
  static fetchData({ store, params }) {
    return store.dispatch(artistActions.getArtistByUrl(params.url))
      .then((response) => { if (response.action.payload.ok) store.dispatch(artistActions.finishInitialize()); });
  }

  constructor(props) {
    super(props);
    this.state = {
      subtitle: this.introShortener(this.props.artist.description),
    };
  }

  introShortener = raw => `${entities.decode(stripTags(raw)).substring(0, 100)}...`;

  handleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  handleUpdate = () => {
    const data = { description: this.props.content };
    this.props.updateArtist(this.props.artist.id, data);
    this.handleEdit();
  };

  handleExpandChange = (expanded) => {
    if (expanded === true) {
      this.setState({ subtitle: null });
    } else {
      this.setState({ subtitle: this.introShortener(this.props.artist.description) });
    }
  };

  render() {
    const isArtist = Permission.checkArtist(this.props.artist, this.props.user);
    const style = getStyle();
    return (
      <div style={{ marginBottom: 14 }}>
        {this.state.editing ?
          <Card style={{ padding: 18 }}>
            <TextEditor initialContent={this.props.artist.description}/>
            <CardActions style={style.intro.actions}>
              <Button
                style={style.intro.actions.cancel}
                onClick={(event) => { event.preventDefault(); this.handleEdit(); }}
                color="primary"
              >
                {strings.cancel}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={style.intro.actions.confirm}
                onClick={(event) => { event.preventDefault(); this.handleUpdate(); }}
              >
                {strings.confirm}
              </Button>
              <div style={{ clear: 'both' }}/>
            </CardActions>
          </Card> :
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CardHeader
                title={strings.artistIntroduction}
                subheader={this.state.subtitle}
              />
            </AccordionSummary>
            <AccordionDetails>
              <CardContent style={style.introduction.extendedContent}>
                {isArtist ?
                  <Button
                    variant="contained"
                    style={{ ...style.muiRaisedButton(0), marginBottom: 30 }}
                    onClick={(event) => { event.preventDefault(); this.handleEdit(); }}
                    fullWidth
                  >
                    {strings.edit}
                  </Button> : null
                }
                <div style={{ wordWrap: 'break-word' }} className="artist-text-content" dangerouslySetInnerHTML={{ __html: this.props.artist.description }}/>
                {!isArtist ?
                  <Link
                    href={`/${this.props.artist.url_artist}/rewards`}
                    to={`/${this.props.artist.url_artist}/rewards`}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={style.muiRaisedButton(30)}
                      fullWidth
                    >
                      {strings.createSupport}
                    </Button>
                  </Link>
                  : null
                }
              </CardContent>
            </AccordionDetails>
          </Accordion>
        }
      </div>
    );
  }
}

ArtistIntroduction.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  content: PropTypes.string,
  updateArtist: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    artist: state.artist.data[state.artist.active],
    user: state.user.data,
    content: state.editor.content,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistIntroduction);
