import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import { colors, strings, styles } from './../../assets/values';

import * as artistActions from './../../actions/supports';


const style = {
  title: {
    fontWeight: styles.fontWeight.Medium,
    fontSize: styles.fontSize.xl,
    lineHeight: 1.0,
    color: colors.textBlack,
    marginBottom: 30,
    textAlign: 'center',
  },
  category: {
    fontWeight: styles.fontWeight.Medium,
    fontSize: styles.fontSize.xl,
    color: colors.textBlack,
    paddingTop: 30,
    marginBottom: 30,
    marginLeft: 20,
    textAlign: 'left',
  },
  card: {
    width: 600,
    maxWidth: '100%',
    marginTop: -1,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
    paddingBottom: 20,
    container: {
      paddingBottom: 0,
    },
    title: {
      fontWeight: styles.fontWeight.Medium,
    },
  },
  innerCard: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    boxShadow: 'none',
    margin: 4,
    paddingBottom: 0,
    lineHeight: 1.7,
  },
};

class SupportFAQ extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.readQuestions();
  }

  render() {
    return (
      <div>
        <div style={style.title}>{strings.faq}</div>
        <div style={style.card}>
          <div style={style.category}>{strings.artist}</div>
          {this.props.artistQuestions.map(question =>
            <Accordion key={question.title}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {`Q. ${question.title}`}
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{`A. ${question.body}`}</Typography>
              </AccordionDetails>
            </Accordion>)}
        </div>
        <div style={style.card}>
          <div style={style.category}>{strings.fan}</div>
          {this.props.fanQuestions.map(question =>
            <Accordion key={question.title}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {`Q. ${question.title}`}
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{`A. ${question.body}`}</Typography>
              </AccordionDetails>
            </Accordion>)}
        </div>
        <div style={style.card}>
          <div style={style.category}>{strings.common}</div>
          {this.props.commonQuestions.map(question =>
            <Accordion key={question.title}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {`Q. ${question.title}`}
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{`A. ${question.body}`}</Typography>
              </AccordionDetails>
            </Accordion>)}
        </div>
      </div>
    );
  }
}

SupportFAQ.propTypes = {
  questions: PropTypes.array,
  artistQuestions: PropTypes.array,
  fanQuestions: PropTypes.array,
  commonQuestions: PropTypes.array,
  readQuestions: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    questions: state.support.questions,
    artistQuestions: state.support.questions.filter(item => item.category === strings.artist),
    fanQuestions: state.support.questions.filter(item => item.category === strings.fan),
    commonQuestions: state.support.questions.filter(item => item.category === strings.common),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportFAQ);
