export const termsOfUse = [
  {
    title: '제1조 목적',
    subtitle: '이 약관은 주식회사 노페땅(이하 "회사")가 운영하는 인터넷사이트 삼천원(이하 "사이트")에서 제공하는 인터넷 관련 서비스(이하 "서비스")를 이용함에 있어 회사와 이용자의 권리와 의무, 책임사항 및 회원의 서비스 이용절차에 관한 사항을 규정함을 목적으로 합니다.',
  },
  {
    title: '제2조 용어의 정의',
    subtitle: '이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 본 약관에 명시되지 않은 용어는 관련 법령 이 정하는 바에 따르며, 그 외에는 일반적인 상거래관행에 의합니다.',
    descriptions: [
      {
        title: '회사',
        body: '주식회사 노페땅을 말합니다.',
      },
      {
        title: '사이트',
        body: '회사가 운영하는 사이트로 3000won.com을 말합니다.',
      },
      {
        title: '이용자',
        body: '사이트에 접속하여 이 약관에 따라 제공하는 서비스를 받는 회원 및 비회원을 말합니다.',
      },
      {
        title: '회원',
        body: '회사에 개인정보를 제공하여 회원등록을 한 자로서 이 약관에 따라 회사와 서비스이용계약을 체결한 자를 말합니다.',
      },
      {
        title: 'ID',
        body: '회원의 식별과 서비스 이용을 위하여 회원이 선정하고 회사가 승인한 이메일을 말합니다.',
      },
      {
        title: '비밀번호',
        body: '회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가 설정하여 회사에 등록한 영문 또는 숫자의 조합을 말합니다.',
      },
    ],
  },
  {
    title: '제3조 약관의 명시와 개정',
    contents: [
      { body: '회사는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자 등록번호, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가 알 수 있도록 사이트의 초기 서비스 화면에 게시합니다. 다만, 약관의 구체적 내용은 이용자가 연결화면을 통하여 볼 수 있도록 합니다.' },
      { body: '회사는 약관의 규제 등에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보 보호 등에 관한 법률, 전자상거래 등에서의 소비자 보호에 관한 법률, 유사수신행위의 규제에 관한 법률 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.' },
      { body: '회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 사이트의 초기화면에 최소 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관의 내용을 변경하는 경우에는 적용일자 30일 이전부터 공지합니다.' },
      { body: '제3항에 따라 공지된 적용일자 이후에 회원이 회사의 서비스를 계속 이용하는 경우에는 개정된 약관에 동의하는 것으로 봅니다. 개정된 약관에 동의하지 아니하는 회원은 언제든지 자유롭게 서비스 이용계약을 해지할 수 있습니다. 유료서비스를 이용하는 회원이 약관의 개정에 동의하지 아니하여 해지할 경우 회사가 정한 환불정책에 따릅니다.' },
      { body: '이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 전자거래소비자보호지침 및 관계법령 또는 일반상 관례에 따릅니다.' },
    ],
  },
  {
    title: '제4조 이용계약의 성립',
    contents: [
      { body: '이용계약은 이용자의 약관동의와 이용신청에 대한 회사의 승낙으로 성립합니다.' },
      { body: '이용계약의 성립시기는 회사가 가입완료 표시를 신청절차 상에 표시한 시점으로 합니다.' },
    ],
  },
  {
    title: '제5조 이용신청 및 제한',
    contents: [
      { body: '이용자는 회사가 정한 가입 양식에 따라 회원정보를 정확히 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.' },
      {
        body: '회사는 다음 각호에 해당하는 경우에 대해서는 그 신청에 대한 제한 사유가 해소될 때까지 승낙을 유보 또는 일부 서비스 이용을 제한할 수 있습니다.',
        contents: [
          { body: '내용이 허위인 것으로 판명 되거나, 그러하다고 의심할 만한 합리적인 사유가 발생할 경우' },
          { body: '기타 회사가 필요하다고 인정되는 경우' },
        ],
      },
      {
        body: '회사는 다음에 해당하는 경우에는 이용신청을 승낙하지 아니할 수 있습니다.',
        contents: [
          { body: '이미 가입된 회원과 이메일 주소가 동일한 경우' },
          { body: '다른 사람의 명의를 사용하여 신청하였을 때' },
          { body: '회원가입 신청서의 내용을 허위로 기재하였을 때' },
          { body: '사회의 안녕질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때' },
          { body: '기타 회사가 정한 이용신청 요건이 미비되었을 때' },
        ],
      },
      {
        body: '회사는 다음 각 호에 해당하는 경우 그 사유가 해소될 때까지 이용계약 성립을 유보할 수 있습니다.',
        contents: [
          { body: '서비스 관련 제반 용량이 부족한 경우' },
          { body: '기술상 장애 사유가 있는 경우' },
        ],
      },
    ],
  },
  {
    title: '제6조 이용자 ID부여 및 변경',
    contents: [
      { body: '이용자 ID는 이용자가 가지고 있는 이메일 주소입니다.' },
      { body: '서비스 이용자 ID 및 비밀번호의 관리책임은 회원에게 있습니다. 이를 소홀히 관리하여 발생하는 서비스 이용상의 손해 또는 제3자에 의한 부정이용 등에 대한 책임은 회원에게 있으며 회사는 그에 대한 책임을 일절 지지 않습니다.' },
    ],
  },
  {
    title: '제7조 계약사항의 변경',
    subtitle: '회원은 마이페이지를 통해 언제든지 자신의 정보를 열람하고, 수정할 수 있습니다. 회원은 이용 신청 시 기재한 사항에 변경이 있는 경우, 온라인으로 개인정보를 수정하거나 전자우편 기타방법으로 회사에 대하여 그 변경사항을 알려야 합니다. 이를 알리지 않아 발생하는 불이익에 대한 책임은 회원에게 있습니다.',
  },
  {
    title: '제8조 이용자의 정보 보안',
    contents: [
      { body: '회사 서비스 가입절차를 완료하는 순간부터 회원은 입력한 정보의 비밀을 유지할 책임이 있으며 ID와 비밀번호를 사용하여 발생하는 모든 결과에 대한 책임도 회원에게 있습니다.' },
      { body: 'ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있으며, ID 나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 회사에 신고하여야 합니다. 신고하지 않음으로 인해 발생하 는 모든 책임은 회원에게 있습니다.' },
      { body: '이용자는 회사 서비스의 사용 종료 시마다 정확히 로그아웃(Log-out)해야 하며, 로그아웃 하지 아니하여 제3자가 회원에 관한 정보를 도용하는 등의 결과로 인해 발생하는 손해 및 손실에 대하여 회사는 책임을 부담하지 아니합니다.' },
    ],
  },
  {
    title: '제9조 서비스 이용',
    contents: [
      { body: '회사는 회원의 이용신청을 승낙한 때부터 서비스를 개시합니다. 단 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시합니다.' },
      { body: '회사의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 사이트에 공시하거나 회원에게 이를 통지합니다.' },
      { body: '서비스의 이용은 연중무휴, 1일 24시간을 원칙으로 합니다. 다만, 회사의 업무상 또는 기술상의 이유로 서비스가 일시 중지 될 수 있습니다. 이러한 경우 회사는 사전 또는 사후에 이를 공지합니다.' },
      { body: '회원 가입한 후라도 일부 서비스 이용시 서비스 제공자의 요구에 따라 특정회원에게만 서비스를 제공할 수 있습니다.' },
      { body: '회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전에 공개합니다.' },
    ],
  },
  {
    title: '제10조 서비스의 중지 및 중지에 대한 공지',
    contents: [
      { body: '이용자는 회사 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태, 정전, 회사 관리범위 외의 서비스 설비 장애 및 기타 불가항력에 의하여 보관되지 못하였 거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실에 대해 회사는 관련 책임을 부담하지 않습니다.' },
      { body: '회사가 정상적인 서비스 제공의 어려움으로 인하여 일시적으로 서비스를 중지하여야 할 경우에 는 서비스 중지 1주일 전에 고지 후 서비스를 중지할 수 있으며, 이 기간 동안 회원이 고지내용을 인지하지 못한 것에 대하여 회사는 책임을 부담하지 아니합니다. 상당한 이유가 있을 경우 위 사전 고지기간은 감축되거나 생략될 수 있습니다. 또한 위 서비스 중지에 의하여 본 서비스에 보관 되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 보관되지 못하였거나 삭제된 경우, 전송 되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 대하여도 회사는 책임을 부담하지 않습니다.' },
      { body: '회사의 사정으로 서비스를 영구적으로 중단하여야 할 경우에 제 2 항을 준용합니다. 다만 이 경우 사전 고지기간은 1개월로 합니다.' },
      { body: '천재지변, 국가비상사태 등 회사가 정상적인 서비스 제공이 불가능할 경우 일시적으로 서비스를 제한, 정지시킬 수 있으며 사전 또는 사후 회원에게 중지사유 및 기간을 공지합니다.' },
      { body: '회사는 사전 공지 후 서비스를 일시적으로 수정, 변경 및 중단할 수 있으며, 이에 대하여 회원 또는 제 3자에 어떠한 책임도 부담하지 않습니다.' },
      { body: '회사는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고 없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 회사가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.' },
      { body: '회사가 통제할 수 없는 사유로 인한 서비스 중단의 경우(시스템관리자의 고의, 과실 없는 디스크 장애, 시스템다운 등)에 사전통지가 불가능 하며 타인 (PC통신회사, 기간통신사업자 등)의 고의, 과실로 인한 시스템 중단 등의 경우에는 통지하지 않습니다.' },
      { body: '회사는 회원이 이 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지하거나 본인의 동의 없이 이용계약을 해지할 수 있습니다. 이 경우 회사는 회원의 접속을 금지할 수 있으며, 회원이 게시한 내용의 전부 또는 일부를 임의로 삭제 할 수 있습니다.' },
      { body: '장기간 휴면 회원인 경우, 안내 메일 또는 공지사항 발표 후 1주일간의 통지기간을 거쳐 서비스 사용을 중지할 수 있습니다.' },
    ],
  },
  {
    title: '제11조 서비스 이용 제한',
    contents: [
      { body: '이용자가 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가 있다고 의심할 만한 합리적 인 사유가 발생할 경우 회사는 이용자의 본 서비스 사용을 일부 또는 전부 이용 정지할 수 있으며, 이로 인해 발생하는 불이익에 대해 회사는 책임을 부담하지 아니합니다.' },
      { body: '회사는 이용자가 본 약관 제16조 이용자의 의무 등 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지할 수 있습니다. 이 경우 회사는 이용자의 접속을 금지할 수 있습니다.' },
    ],
  },
  {
    title: '제12조 정보제공 및 광고의 게재',
    contents: [
      { body: '회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보 및 광고에 대해서는 전자우편 이나 서신우편, SMS(핸드폰 문자메시지), DM(Direct Mail), 메신저 등의 방법으로 회원에게 제공 할 수 있으며, 만약 원치 않는 정보를 수신한 경우 회원은 이를 수신거부 할 수 있습니다.' },
      { body: '회사는 서비스의 운용과 관련하여 서비스화면, 홈페이지, 전자우편 등에 광고 등을 게재할 수 있으며, 회사는 서비스를 이용하고자 하는 회원이 이 광고게재에 대하여 동의하는 것으로 간주합니다.' },
      { body: '회사는 서비스 상에 게재되어 있거나 서비스를 통한 광고주와의 판촉활동에 회원이 참여하거나 교신 또는 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해 책임을 지지 않습니다.' },
    ],
  },
  {
    title: '제13조 게시물의 관리 운영',
    contents: [
      {
        body: '회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있으며, 회사는 회원이 게시하거나 등록하는 서비스의 내용물이 다음 각 항에 해당한다고 판단되는 경우에 사전통지 없이 삭제할 수 있고, 이에 대하여 회사는 어떤 책임도 지지 않습니다.',
        contents: [
          { body: '본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우' },
          { body: '다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우' },
          { body: '공공질서 및 미풍양속에 위반되는 내용인 경우' },
          { body: '범죄적 행위에 결부된다고 인정되는 내용인 경우' },
          { body: '서비스 성격에 부합하지 않는 정보의 경우' },
          { body: '제3자의 저작권 등 기타 권리를 침해하는 내용인 경우' },
          { body: '기타 관계 법령에 위배되는 경우' },
        ],
      },
      { body: '회사는 게시된 내용이 일정기간 이상 경과되어, 게시물로써의 효력을 상실하여 그 존치 목적이 불분명한 경우 공지사항 발표 후 1주일간의 통지기간을 거쳐 해당 게시물을 삭제할 수 있습니다.' },
      { body: '회원의 게시물이 타인의 저작권을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 회원이 부담하여야 합니다.' },
      { body: '회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.' },
    ],
  },
  {
    title: '제14조 회사의 소유권',
    contents: [
      { body: '회사가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스 명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는 회사에게 있습니다.' },
      { body: '회원은 회사가 명시적으로 승인한 경우를 젱회하고는 제 1항 소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스, 담보권설정행위, 상업적 이용행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.' },
    ],
  },
  {
    title: '제15조 회사의 의무',
    contents: [
      { body: '회사는 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 지속적 안정적으로 서비스를 제공하기 위하여 노력할 의무가 있습니다.' },
      { body: '회사는 이용자의 개인 신상 정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 다만, 관계법령에 의해 국가기관의 요구나 전기통신관련법령 및 정보통신윤리위원회의 요청 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 아니합니다.' },
      { body: '회사는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 멸실된 때에는 부득이한 사유가 없는 한 지체없이 이를 수리 또는 복구합니다.' },
      { body: '회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 회사의 고의나 중과실에 기해 발생한 경우에 한하여 책임을 부담하며, 그 책임의 범위는 통상손해에 한합니다.' },
      { body: '회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 신속히 처리하여야 합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 통보하여야 합니다.' },
      { body: '회사는 관련법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위 하여 노력합니다. 회원의 개인정보보호에 관해서는 관련법령 및 제 17조에 제시된 내용을 지킵니다.' },
    ],
  },
  {
    title: '제16조 이용자의 의무',
    contents: [
      { body: '회원은 서비스 이용을 위해 가입할 경우 현재의 사실과 일치하는 완전한 정보 (이하 가입정보) 를 제공해야 하며, 가입정보가 변경된 경우 즉시 갱신해야 합니다.' },
      { body: '회원은 본인의 아이디와 비밀번호 관리를 위해 제 8 조 이용자의 정보보안 사항을 준수하여야 합니다.' },
      {
        body: '회원은 서비스를 이용 시, 각 호에 해당하는 행위를 해서는 안됩니다.',
        contents: [
          { body: '타인의 ID, 비밀번호 도용 및 타인으로 가장하는 행위' },
          { body: '타인과의 관계를 허위로 명시하는 행위' },
          { body: '타인을 비방할 목적으로 사실 또는 허위의 사실을 적시하여 명예를 훼손하는 행위' },
          { body: '자기 또는 타인에게 재산상의 이익을 주거나 타인의 손해를 가할 목적으로 허위의 정보를 유통시키는 행위' },
          { body: '수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글을 타인에게 전달하는 행위' },
          { body: '회사의 사전 승낙 없이 서비스를 이용한 영리 행위' },
          { body: '타인의 정보통신서비스 이용명의를 도용하여 사용하는 행위<' },
          { body: '불필요하거나 승인되지 않은 광고, 판촉물을 게재하거나, 저속 또는 음란한 데이터, 텍스트, 소프트웨어, 음악, 사진, 그래픽, 비디오 메시지 등을 게재하거나 메시지 또는 전자우편으로 보내는 행위' },
          { body: '권리(지적재산권을 포함한 모든 권리)가 없는 컨텐츠를 게재하거나 메시지 또는 전자우편으로 보내는 행위' },
          { body: '컴퓨터 소프트웨어, 하드웨어, 전기통신 장비를 파괴, 방해 또는 기능을 제한하기 위한 소프트웨어 바이러스를 게재하거나 메시지 또는 전자우편으로 보내는 행위' },
          { body: '다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시, 게재, 전자우편으로 보내는 행위 등 다른 사용자의 개인정보를 수집 또는 저장하는 행위' },
          { body: '재물을 걸고 도박하거나 사행행위를 하는 행위' },
          { body: '윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위' },
          { body: '기타 불법적이거나 부당한 행위' },
        ],
      },
    ],
  },
  {
    title: '제17조 개인정보보호정책',
    contents: [
      { body: '회사는 이용 신청 시 회원이 제공하는 정보를 통하여 회원에 관한 정보를 수집하며, 회원의 개인 정보는 본 이용계약의 이행과 본 이용계약상의 서비스제공을 위한 목적으로 이용합니다.' },
      {
        body: '회사는 서비스 제공과 관련하여 취득한 회원의 정보를 본인의 승낙 없이 제 3자에게 누설 또는 배포할 수 없으며 상업적 목적으로 사용할 수 없습니다. 다만, 다음의 각 호의 경우에는 그러하지 아니합니다.',
        contens: [
          { body: '관계 법령에 의하여 수사상의 목적으로 관계기관으로부터 요구가 있는 경우' },
          { body: '정보통신윤리위원회의 요청이 있는 경우' },
          { body: '기타 관계법령에서 정한 절차에 따른 요청이 있는 경우' },
        ],
      },
    ],
  },
  {
    title: '제18조 회원에 대한 통지',
    contents: [
      { body: '회사가 회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.' },
      { body: '회사는 불특정 다수 회원에 대한 통지의 경우, 1주일 이상 사이트에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지합니다.' },
    ],
  },
  {
    title: '제19조 개별 서비스에 대한 약관 및 이용조건',
    subtitle: '회사는 제공하는 서비스의 구체적 내용에 따라 개별 서비스에 대한 약관 및 이용조건을 각 개별서비스마다 별도로 정하여 회원의 동의를 얻을 수 있습니다. 이 경우 개별 서비스에 대한 이용약관 등 이 본 약관에 우선합니다.',
  },
  {
    title: '제20조 페이지 생성과 수정 및 취소',
    contents: [
      { body: '회원은 회사가 정한 양식과 절차에 따라 문화예술 생산자 페이지를 생성하고, 회사가 승인한 경우에 한하여 사이트에 페이지를 게시 또는 등록하여 다른 회원으로부터 정기적으로 후원금을 모금할 수 있습니다.' },
      { body: '페이지 등록 시기는 회원이 약관에 동의하고, 회사가 회원이 신청한 내용을 승인하여 사이트에 게시한 시점으로 합니다.' },
      {
        body: '회사는 회원이 공개를 신청한 페이지에 대해 다음과 같은 내용을 검토한 후, 회원이 회사에 제공한 이메일을 통해 승인여부를 통지 합니다.',
        contents: [
          { body: '회사의 페이지 생성 규정과 절차를 준수하였는가' },
          { body: '회사가 제공한 신청 항목을 빠짐없이 기재하였는가' },
          { body: '회사가 요규한 관련 파일(이미지, 동영상, 음성 파일 등)을 규격에 맞게 제공하였는가' },
          { body: '관계법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해하려는 목적이 있지는 않은가' },
          { body: '범죄적 행위, 국익 또는 사회적 공익을 저해하려는 목적이 있지는 않은가' },
          { body: '타인의 명예를 손상시키거나 불이익을 주려는 목적이 있지는 않는가' },
          { body: '기타 회사가 정한 페이지 생성 조건에 부합하는가' },
        ],
      },
      { body: '회원이 생성을 신청한 내용에 허위, 기재누락, 오기가 있어서는 안되며, 회원이 이를 준 수하지 않아 발생한 불이익에 대해서는 회원이 모든 책임을 부담해야 합니다.' },
      {
        body: '회원이 작성 및 제공한 내용 및 파일에 대한 모든 권리 및 책임은 해당 회원에게 있으며, 회사는 회원이 등록한 페이지의 내용이 다음 각 항에 해당한다고 판단되는 경우 사전통지 없이 삭제할 수 있고 이에 대하여 회사는 어떠한 책임도 지지 않습니다.',
        contents: [
          { body: '회원이 제공한 회원정보 또는 페이지 정보가 허위임이 판명된 경우' },
          { body: '제3자의 재산권과 기타 권리를 침해하는 내용인 경우' },
          { body: '회원이 회사 사이트와 게시판에 음란물을 게재하거나 음란사이트를 링크하는 경우' },
          { body: '회원이 페이지 정보를 불법적으로 변경 또는 조작한 경우' },
          { body: '기타 관계법령에 위반된다고 판단되는 경우' },
        ],
      },
      { body: '회원은 페이지 공개에 따라 발생한 회원과 다른 회원, 회원과 이용자, 회원과 제 3자 간의 약속, 리워드, 거래, 서비스 제공, 계약, 보증에 관하여 이행의 의무가 있으며, 이를 이행 하지 않아 발생하는 불이익에 대한 책임은 회원에게 있습니다.' },
      { body: '위 4항의 이유로 발생한 분쟁과 관련하여, 회원은 회사와 회사의 자회사, 관계사 그리고 에이전시 및 그 임원 및 직원에게 책임 및 손해 배상을 요구할 수 없습니다.' },
      { body: '공개 승인된 이후 페이지의 일부 항목은 더이상 변경하거나 취소하실 수 없습니다. 변경이 금지된 사항을 회원이 임의로 수정, 변경하거나 삭제한 경우, 요금을 지불한 회원이 손해배상 청구 등 법적 대응을 할 수 있으며, 이로 인한 불이익에 대하여 회사는 책임을 지지 않습니다' },
      { body: '공개 승인된 페이지는 커뮤니티의 투명한 운영과 사후 참조를 고려하여 삭제가 불가능합니다.' },
    ],
  },
  {
    title: '제21조 모금액 전달',
    contents: [
      { body: '회사는 모금이 진행된 문화예술 생산자에 한해 매월 1일로부터 15일 이내에 이메일 명세서를 통해 회원에게 전월 수수료 내역을 고지합니다.' },
      { body: '회원은 페이지 생성 시 후원자에게 제공될 리워드를 사이트에 게시하고, 모금액을 수령한 이후 게시한 내용에 따라 이행할 의무가 있습니다. 이를 이행하지 않거나, 고의로 게시한 내용과 다르게 혹은 일부만 이행하는 경우 회사는 회원에게 지급한 구독액 일체를 회수할 수 있습니다.' },
      { body: '회원은 페이지 및 리워드에 관련한 다른 회원의 문의사항에 성실하게 답변 및 응대할 의무가 있습니다.' },
      { body: '회사는 이메일 명세서 제공과 관련하여 회원과 미리 약정한 이메일로 이메일 명세서 확인요청을 고지합니다. 회원은 이 고지일로부터 7일 이내에 전자우편을 통해 문의 사항과 이의사항을 회사에 알려야 하며, 이를 이행하지 않아 발생하는 불이익에 대하여 회사는 책임을 지지 않습니다.' },
      { body: '회사는 전월 1일부터 말일까지 결제 성공 및 정산 완료된 금액을 1일부터 15일 이내에 회원이 지정한 은행계좌로 지급합니다.' },
      { body: '모집된 모금액의 지급과 관련하여 회사는 회원의 신분증 사본, 통장사본 등 신분을 확인할 수 있는 자료를 요청할 수 있으며, 회원 본인 명의의 은행계좌임이 확인되었을 경우에만 지정한 계좌로 구독 금액을 송금합니다.' },
    ],
  },
  {
    title: '제22조 제3자의 지적재산권 및 저작권 공지',
    contents: [
      { body: '회사와 회원은 제 3자의 지적재산을 존중하며, 회사는 언제든지 제 3자의 지적 재산을 침해하는 회원과의 거래를 해지할 수 있습니다.' },
      { body: '회사는 회원이 제출하고, 등록한 컨텐츠가 제 3자의 저작권을 침해했다고 통지 받는 경우, 저작 권법 및 관련법령에 따라 해당 자료를 사이트에서 제거할 수 있습니다.' },
      {
        body: '자신의 저작권이 침해되었다고 믿는 제 3자의 경우, 다음 정보를 포함하는 서면 통지를 작성하여 회사에 제공하여야 합니다.',
        contents: [
          { body: '저작권 소유자 또는 법적 대리인의 전자 서명 혹은 실질 서명' },
          { body: '침해를 주장하는 컨텐츠' },
          { body: '침해를 주장하는 컨텐츠가 게시 혹은 사용된 사이트 위치에 대한 상세한 기술' },
          { body: '저작권 소유자 또는 그 법적 대리인의 주소, 전화번호 및 이메일 주소' },
          { body: '침해를 주장하는 서면 통지상의 모든 정보가 사실과 부합하며, 위증으로 판명될 경우 법적 책임을 지겠다는 저작권 소유자 또는 법적 대리인의 진술' },
        ],
      },
      {
        body: '회원은 자신의 컨텐츠가 실수 또는 오인으로 인해 삭제 혹은 접근불가상태가 되었다고 믿는 경우, 다음 정보를 포함하는 서면 통지를 작성하여 회사에 제공하여야 합니다.',
        contents: [
          { body: '회원의 전자서명 혹은 실질 서명' },
          { body: '삭제 혹은 접근불가상태가 된 컨텐츠가 게시되어 있던 사이트 위치에 대한 상세한 기술' },
          { body: '실수 또는 오인의 결과로 자신의 컨텐츠가 삭제 혹은 접근불가상태가 되었다고 믿는 진술과 이와 같은 진술 이 위증으로 판명될 경우 법적 책임을 지겠다는 서약' },
          { body: '회원의 성명, 주소, 전화번호 및 이메일 주소' },
        ],
      },
      { body: '회사는 본 조항과 관련된 제 3자 또는 회원의 통지가 위에서 기술한 요구사항에 부합하지 않을 경우, 해당 통지는 유효하지 않은 것으로 간주할 수 있으며, 이에 대해 응하거나 회답해야 할 책 임이 없습니다.' },
    ],
  },
  {
    title: '제23조 수수료',
    contents: [
      { body: '회원의 사이트 가입은 무료이며, 특정 서비스 이용 시에만 수수료가 부과됩니다.' },
      { body: '서비스 수수료 정책은 회사 서비스에서 정의한 규칙에 따르며, 회원은 서비스 이용에 대한 수수료를 부담해야 합니다.' },
      { body: '서비스 수수료 정책은 회사의 사정에 따라 변경될 수 있으며, 회사는 사전에 사이트를 통해 변경내용을 고지합니다.' },
      { body: '회원은 회사의 서비스 수수료 외에 결제수단 (신용카드, 핸드폰, 계좌이체 등)이용에 따른 수수료를 부담해야 합니다.' },
      { body: '회사의 서비스 이용에 따라 회원에게 부과되는 혹은 의무가 있는 모든 세금을 성실하게 신고하고 납부할 의무와 책임이 있습니다.' },
    ],
  },
  {
    title: '제24조 취소 및 환불',
    contents: [
      { body: '취소 및 환불 규정은 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령을 준수합니다.' },
      { body: '아티스트에 대한 정기후원은 취소하지 않는 한 매월 지정된 결제일에 자동 연장됩니다.' },
      { body: '결제 취소 및 환불은 결제일로부터 3일 내의 기간에만 가능합니다.' },
      { body: '제3항에서 정한 기간 이후 취소 또는 환불 사유가 발생한 경우, 회사는 해당 페이지 담당자와 회원 간의 합의에 따라 취소 또는 환불 처리를 해드립니다.' },
      { body: '개별 서비스의 성격에 따라 회사는 별도 약관 및 이용조건에 따른 취소 및 환불 규정을 정할 수 있으며, 이 경우 개별 약관 및 이용조건 상의 취소 및 환불 규정이 우선 적용됩니다.' },
      { body: '기타 본 약관 및 사이트의 이용안내에 규정되지 않은 취소 및 환불에 대한 사항에 대해서는 소비자 피해보상규정에서 정한 바에 따릅니다.' },
    ],
  },
  {
    title: '제25조 회원탈퇴 및 자격상실',
    contents: [
      { body: '회원은 회사에 언제든지 회원탈퇴를 요청할 수 있으며, 회사는 회원탈퇴에 관한 규정에 따라 이를 처리합니다.' },
      {
        body: '회사는 회원이 다음 각 호의 사유에 해당하는 경우, 사전통지 없이 회원자격을 제한 및 정지시킬 수 있습니다.',
        contents: [
          { body: '가입신청 시에 허위 내용을 등록한 경우' },
          { body: '사이트를 이용하여 결제한 대금, 기타 회사 이용에 관련하여 회원이 부담하는 채무를 길일에 이행하지 않는 경우' },
          { body: '다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우' },
          { body: '회사를 이용하여 법령과 이 약관이 금지하거나 공공질서 미풍양속에 반하는 행위를 하는 경우' },
        ],
      },
      { body: '회사가 회원자격을 제한, 정지시킨 후 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실시킬 수 있습니다.' },
      { body: '회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.' },
    ],
  },
  {
    title: '제26조 양도금지',
    subtitle: '회원은 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도 및 증여할 수 없으며, 이를 담 보로 제공할 수 없습니다.',
  },
  {
    title: '제27조 손해배상',
    subtitle: '회사는 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 회사가 고의로 행한 범죄행위를 제외하고 이에 대하여 책임을 부담하지 아니합니다.',
  },
  {
    title: '제28조 면책조항',
    contents: [
      { body: '회사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 에는 서비스 제공에 대한 책임이 면제됩니다.' },
      { body: '회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.' },
      { body: '회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책 임이 면제됩니다.' },
      { body: '회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주 소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.' },
      { body: '회사는 회원이나 제3자에 의해 표출된 어떠한 의견이나 정보에 대해 확신이나 대표할 의무가 없 으며 의견을 승인하거나 반대하거나 수정하지 않습니다. 회사는 어떠한 경우라도 회원이 서비스 에 담긴 정보에 의존해 얻은 이득이나 입은 손해에 대해 책임이 없습니다.' },
      { body: '회사는 회원간 또는 회원과 제3자간에 서비스를 매개로 하여 물품거래 혹은 금전적 거래 등과 관 련하여 어떠한 책임도 부담하지 아니하고, 회원이 서비스의 이용과 관련하여 기대하는 이익에 관 하여 책임을 부담하지 않습니다.' },
      { body: '회사는 이용자 또는 기타 유관기관이 서비스에 게재한 정보에 대해 정확성, 신뢰도에 대하여 보 장하지 않습니다. 따라서 회사는 이용자가 위 내용을 이용함으로 인해 입게 된 모든 종류의 손실 이나 손해에 대하여 책임을 부담하지 아니합니다.' },
      { body: '회사는 이용자가 서비스 이용과 관련하여 가입자에게 발생한 손해 중 가입자의 고의, 과실에 의 한 손해 및 타 이용자로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.' },
    ],
  },
  {
    title: '제29조 분쟁해결',
    contents: [
      { body: '회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위해서 피해보상처리 기구를 설치, 운영합니다.' },
      { body: '회사는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보합니다.' },
    ],
  },
  {
    title: '제30조 준거법 및 관할법원',
    contents: [
      { body: '이 약관의 해석 및 회원간의 분쟁에 대하여는 대한민국의 법을 적용합니다.' },
      { body: '서비스 이용 중 발생한 회원과 회사간의 소송은 민사소송법에 의한 관할법원에 제소합니다.' },
    ],
  },
  {
    title: '부칙',
    subtitle: '이 약관은 2016년 6월 9일부터 적용합니다.',
  },
];

export const privacyPolicy = [
  {
    title: '제1조 총칙',
    contents: [
      { body: '주식회사 노페땅(이하 "회사")은 회원의 개인정보보호를 매우 중요시하며, 「정보통신망 이용촉진 및 정보보호」에 관한 법률을 준수하고 있습니다. 회사는 개인정보보호방침을 통하여 회원이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 회사가 개인정보보호를 위해 어떠한 조치를 취하고 있는지 알려드립니다.' },
      { body: '본 개인정보취급방침은 정부의 법령 및 지침의 변경, 또는 보다 나은 서비스의 제공을 위하여 그 내용이 변경될 수 있으며, 회사는 개인정보취급방침을 개정하는 경우 홈페이지 상에 공지할 것입니다.' },
    ],
  },
  {
    title: '제2조 수집하는 개인정보의 항목 및 수집방법',
    subtitle: '회사는 회원가입, 상담, 서비스 제공 등을 위해 다음과 같이 개인정보를 수집하고 있습니다.',
    contents: [
      {
        body: '회원가입 시 수집하는 개인정보',
        contents: [
          { body: '성명 또는 닉네임' },
          { body: '이메일' },
          { body: '비밀번호' },
        ],
      },
      {
        body: '서비스 이용과정 및 처리과정에서 수집될 수 있는 정보',
        contents: [
          { body: '서비스 이용기록' },
          { body: '접속 로그' },
          { body: '쿠키' },
          { body: '접속 IP정보' },
          { body: '지불정보 및 기록' },
          { body: '기타 연락처 및 주소' },
        ],
      },
      {
        body: '개인정보 수집방법',
        contents: [
          { body: '홈페이지' },
          { body: '고객센터 및 1:1상담을 통한 전화 및 온라인 상담' },
          { body: '온라인 환불요청' },
        ],
      },
    ],
  },
  {
    title: '제3조 개인정보의 수집 및 이용목적',
    subtitle: '회사는 수집된 회원의 개인정보를 다음의 목적을 위해 사용하고 있습니다.',
    contents: [
      {
        body: '회원관리',
        contents: [
          { body: '회원제 서비스 이용에 따른 본인확인' },
          { body: '개인식별' },
          { body: '불량회원의 부정이용 방지와 비인가 사용방지' },
          { body: '가입의사 확인' },
          { body: '분쟁 조정을 위한 기록 보존' },
          { body: '불만처리 등 민원처리' },
          { body: '뉴스레터 등 고지사항 전달' },
        ],
      },
      {
        body: '서비스 제공에 관한 계약 및 서비스 제공에 따른 요금정산',
        contents: [
          { body: '컨텐츠 제공' },
          { body: '구독 및 요금결제' },
          { body: '금융거래 본인 인증 및 금융 서비스' },
          { body: '신규 서비스(컨첸츠) 개발 및 특화' },
          { body: '접속 빈도 파악' },
          { body: '회원의 서비스 이용에 따른 통계' },
        ],
      },
    ],
  },
  {
    title: '제4조 개인정보수집에 대한 동의',
    subtitle: '회사는 회원이 회사의 개인정보취급방침 또는 이용약관의 내용에 대해 「동의합니다」버튼 또는 「동의하지 않습니다」버튼을 클릭할 수 있는 절차를 마련하여, 「동의합니다」버튼을 클릭하면 개인정보수집에 대해 동의한 것으로 봅니다.',
  },
  {
    title: '제5조 개인정보의 보유와 이용기간 및 파기',
    subtitle: '회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 개인정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.',
    contents: [
      { body: '표시 및 광고에 관한 기록 : 6개월' },
      { body: '계약 또는 청약철회 등에 관한 기록 : 5년' },
      { body: '대금결제 및 재화 등의 공급에 관한 기록 : 5년' },
      { body: '소비자의 불만 또는 분쟁처리에 관한 기록 : 3년' },
      { body: '신용정보의 수집, 처리 및 이용 등에 관한 기록 : 3년' },
    ],
  },
  {
    subtitle: '회사는 원칙적으로 개인정보수집에 대한 동의철회, 데이터 삭제요청이 있거나 개인정보수집 및 이용목적이 달성된 후에는 해당정보를 지체 없이 삭제합니다.',
    contents: [
      { body: '삭제요청: 회원은 언제든지 수집된 개인정보에 대한 삭제 및 회원탈퇴를 요청할 수 있으며, 해당 요청은 아이디(가입 시 기재한 이메일) 등의 정보를 개인정보 삭제의 의사와 함께 기재하여 support@3000won.com로 이메일을 보냄으로써 가능합니다.' },
      { body: '삭제방법: 종이에 출력된 개인 정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.' },
    ],
  },
  {
    title: '제6조 링크 사이트',
    subtitle: '회사는 회원에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를 클릭하여 타 사이트의 페이지로 옮겨 갈 경우 해당 사이트의 개인정보보호정책은 회사와 무관하므로 새로 방문한 사이트의 정책을 검토하시기 바랍니다.',
  },
  {
    title: '제7조 개인정보의 목적 외 사용 및 제3자에 대한 제공 및 공유',
    contents: [
      {
        body: '회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.',
        contents: [
          { body: '이용자들이 사전에 동의한 경유' },
          { body: '법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우' },
        ],
      },
      {
        body: '다만, 보다 나은 서비스의 제공을 위하여 회원의 개인정보를 협력업체 등과 공유할 필요가 있는 경우에는 제공받는 자, 주된 사업, 제공 목적 및 제공할 정보의 내용 등을 상세히 회원에게 개별 고지하여 동의를 구하도록 하겠습니다. 아래의 경우에는 관련 법령의 규정에 의하여 회원의 동의 없이 개인정보를 제공할 수도 있사오니 이점 양지해 주시기 바랍니다',
        contents: [
          { body: '통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우' },
          { body: '관계법령의 규정에 의거하거나, 수사목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우' },
        ],
      },
    ],
  },
  {
    title: '제8조 수집한 개인정보의 위탁',
    subtitle: '회사는 회원의 동의 없이 회원 정보를 외부업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁업무내용에 대해 회원에게 상세히 알리고, 동의를 얻도록 하겠습니다.',
  },
  {
    title: '제9조 회원 및 법정대리인의 권리와 그 행사방법',
    contents: [
      { body: '회원 및 법정 대리인은 언제든지 등록되어 있는 자신 또는 대리자의 개인정보를 조회하거나 수정할 수 있으며, 회원탈퇴절차를 통해 가입해지를 요청할 수도 있습니다.' },
      { body: '회원 혹은 대리인의 개인정보 조회, 수정을 위해서는 홈페이지의 "마이페이지" 내의 "정보변경"을, 가입해지 (동의철회)를 성명, 아이디(가입 시 기재한 이메일) 등의 정보를 탈퇴의사와 함께 문의하기에 게시하거나, 동일한 내용을 기재하여 support@3000won.com로 이메일을 보내면 탈퇴하실 수 있습니다. 이 외에도, 회사의 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.' },
      { body: '회원이 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.' },
      { body: '회사는 회원이 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 회사가 수집하는 “개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.' },
    ],
  },
  {
    title: '제10조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항',
    contents: [
      { body: '회사는 회원들에게 보다 적절하고 유용한 서비스를 제공하기 위하여 회원의 정보를 수시로 저장하고 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는,  서버가 회원의 브라우저로 전송하는 아주 작은 텍스트 파일로서 회원의 컴퓨터에 저장됩니다.' },
      { body: '회원은 웹 브라우저의 개인정보보호 수준을 설정하여 쿠키의 사용여부를 선택하실 수 있습니다.' },
      { body: '회원은 사용하는 웹 브라우저의 개인정보보호 수준을 설정함으로써 모든 쿠키를 허용하거나, 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 쿠키의 저장을 거부할 경우 로그인이 필요한 일부 서비스의 이용에 제한이 생길 수 있음을 양지하시기 바랍니다.' },
    ],
  },
  {
    title: '제11조 개인정보의 관리적 보호 대책',
    subtitle: '회사는 회원들의 개인정보를 보호하기 위하여 다음과 같은 보호 대책을 시행하고 있습니다.',
    contents: [
      { body: '회원의 비밀번호는 암호화되어 저장 및 관리되고 있습니다. 비밀번호는 오직 회원 본인만이 알 수 있으며 개인정보를 확인 및 변경할 경우에도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서 회원의 비밀번호가 타인에게 알려지지 않도록 각별히 주의하시기 바랍니다.' },
      { body: '회사는 해킹이나 컴퓨터 바이러스에 의하여 회원들의 개인정보가 유출되거나 훼손되는 것을 막기 위하여 필요한 보안조치를 이용하고 있으며, 더욱 향상된 보안조치를 확보할 수 있도록 가능한 모든 기술적 방법을 구비하기 위하여 노력하고 있습니다.' },
      { body: '회사는 개인정보를 취급하는 직원을 최소한으로 제한하고 있으며, 관련 직원들에 대한 교육을 수시로 실시하여 본 방침의 이행 및 준수여부를 확인하고 있습니다.' },
    ],
  },
  {
    title: '제12조 개인정보관리책임자',
    subtitle: '회원의 개인정보를 보호하고 개인정보와 관련된 불만 등을 처리하기 위하여 회사는 개인정보관리책임자를 두고 있습니다. 회원의 개인정보와 관련한 문의사항은 아래의 개인정보관리책임자에게 연락하여 주시기 바랍니다. 기타 개인정보에 관한 신고나 상담이 필요한 경우에는 개인정보침해신고센터, 사이버경찰청 등으로 문의하실 수 있습니다.',
    contents: [
      { body: '개인정보 관리 책임자: 김민식, support@w3000won.com' },
    ],
  },
  {
    title: '제13조 개인정보취급방침의 개정과 그 공지',
    subtitle: '본 방침은 2016년 6월 9일부터 시행됩니다. 본 개인정보취급방침이 변경될 경우, 회사는 변경내용을 그 시행일 7일 전부터 홈페이지를 통해 공지할 예정입니다.',
    contents: [
      { body: '개인정보취급방침 시행일 : 2016년 6월 9일' },
    ],
  },
];

export const termsOfPayment = [
  {
    title: '제1조 목적',
    subtitle: '이 약관은 주식회사 케이지이니시스(이하 "회사"라 합니다)가 제공하는 전자지급결제대행서비스 및 결제대금예치서비스를 이용자가 이용함에 있어 회사와 이용자 사이의 전자금융거래에 관한 기본적인 사항을 정함을 목적으로 합니다.',
  },
  {
    title: '제2조 용어의 정의',
    subtitle: '이 약관에서 정하는 용어의 정의는 다음과 같습니다.',
    contents: [
      { body: '"전자금융거래"라 함은 회사가 전자적 장치를 통하여 전자지급결제대행서비스 및 결제대금예치서비스(이하 "전자금융거래 서비스"라고 합니다)를 제공하고, 이용자가 회사의 종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말합니다.' },
      { body: '"전자지급결제대행서비스"라 함은 전자적 방법으로 재화의 구입 또는 용역의 이용에 있어서 지급결제정보를 송신하거나 수신하는 것 또는 그 대가의 정산을 대행하거나 매개하는 서비스를 말합니다.' },
      { body: '"결제대금예치서비스"라 함은 이용자가 재화의 구입 또는 용역의 이용에 있어서 그 대가(이하 "결제대금"이라 한다)의 전부 또는 일부를 재화 또는 용역(이하 "재화 등"이라 합니다)을 공급받기 전에 미리 지급하는 경우, 회사가 이용자의 물품수령 또는 서비스 이용 확인 시점까지 결제대금을 예치하는 서비스를 말합니다.' },
      { body: '"이용자"라 함은 이 약관에 동의하고 회사가 제공하는 전자금융거래 서비스를 이용하는 자를 말합니다.' },
      { body: '"접근매체"라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 수단 또는 정보로서 전자식 카드 및 이에 준하는 전자적 정보(신용카드번호를 포함한다), "전자서명법"상의 인증서, 회사에 등록된 이용자번호, 이용자의 생체정보, 이상의 수단이나 정보를 사용하는데 필요한 비밀번호 등 전자금융거래법 제2조 제10호에서 정하고 있는 것을 말합니다.' },
      { body: '"거래지시"라 함은 이용자가 본 약관에 의하여 체결되는 전자금융거래계약에 따라 회사에 대하여 전자금융거래의 처리를 지시하는 것을 말합니다.' },
      { body: '"오류"라 함은 이용자의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 이용자의 거래지시에 따라 이행되지 아니한 경우를 말합니다.' },
    ],
  },
  {
    title: '제3조 약관의 명시 및 변경',
    contents: [
      { body: '회사는 이용자가 전자금융거래 서비스를 이용하기 전에 이 약관을 게시하고 이용자가 이 약관의 중요한 내용을 확인할 수 있도록 합니다.' },
      { body: '회사는 이용자의 요청이 있는 경우 전자문서의 전송방식에 의하여 본 약관의 사본을 이용자에게 교부합니다.' },
      { body: '회사가 약관을 변경하는 때에는 그 시행일 1월 전에 변경되는 약관을 회사가 제공하는 전자금융거래 서비스 이용 초기화면 및 회사의 홈페이지에 게시함으로써 이용자에게 공지합니다.' },
    ],
  },
  {
    title: '제4조 전자지급결제대행서비스의 종류',
    subtitle: '회사가 제공하는 전자지급결제대행서비스는 지급결제수단에 따라 다음과 같이 구별됩니다.',
    contents: [
      { body: '신용카드결제대행서비스: 이용자가 결제대금의 지급을 위하여 제공한 지급결제수단이 신용카드인 경우로서, 회사가 전자결제시스템을 통하여 신용카드 지불정보를 송,수신하고 결제대금의 정산을 대행하거나 매개하는 서비스를 말합니다.' },
      { body: '계좌이체대행서비스: 이용자가 결제대금을 회사의 전자결제시스템을 통하여 금융기관에 등록한 자신의 계좌에서 출금하여 원하는 계좌로 이체할 수 있는 실시간 송금 서비스를 말합니다.' },
      { body: '가상계좌서비스: 이용자가 결제대금을 현금으로 결제하고자 경우 회사의 전자결제시스템을 통하여 자동으로 이용자만의 고유한 일회용 계좌의 발급을 통하여 결제대금의 지급이 이루어지는 서비스를 말합니다.' },
      { body: '기타: 회사가 제공하는 서비스로서 지급결제수단의 종류에 따라 "휴대폰 결제대행서비스", "ARS결제대행서비스", "상품권결제대행서비스"등이 있습니다.' },
    ],
  },
  {
    title: '제5조 결제대금예치서비스의 내용',
    contents: [
      { body: '>이용자(이용자의 동의가 있는 경우에는 재화 등을 공급받을 자를 포함합니다. 이하 본조에서 같습니다)는 재화 등을 공급받은 사실을 재화 등을 공급받은 날부터 3영업일 이내에 회사에 통보하여야 합니다.' },
      { body: '회사는 이용자로부터 재화 등을 공급받은 사실을 통보받은 후 회사와 통신판매업자간 사이에서 정한 기일 내에 통신판매업자에게 결제대금을 지급합니다.' },
      { body: '회사는 이용자가 재화 등을 공급받은 날부터 3영업일이 지나도록 정당한 사유의 제시없이 그 공급받은 사실을 회사에 통보하지 아니하는 경우에는 이용자의 동의없이 통신판매업자에게 결제대금을 지급할 수 있습니다.' },
      { body: '회사는 통신판매업자에게 결제대금을 지급하기 전에 이용자에게 결제대금을 환급받을 사유가 발생한 경우에는 그 결제대금을 소비자에게 환급합니다.' },
      { body: '회사는 이용자와의 결제대금예치서비스 이용과 관련된 구체적인 권리,의무를 정하기 위하여 본 약관과는 별도로 결제대금예치서비스이용약관을 제정할 수 있습니다.' },
    ],
  },
  {
    title: '제6조 이용시간',
    contents: [
      { body: '회사는 이용자에게 연중무휴 1일 24시간 전자금융거래 서비스를 제공함을 원칙으로 합니다. 단, 금융기관 기타 결제수단 발행업자의 사정에 따라 달리 정할 수 있습니다.' },
      { body: '회사는 정보통신설비의 보수, 점검 기타 기술상의 필요나 금융기관 기타 결제수단 발행업자의 사정에 의하여 서비스 중단이 불가피한 경우, 서비스 중단 3일 전까지 게시가능한 전자적 수단을 통하여 서비스 중단 사실을 게시한 후 서비스를 일시 중단할 수 있습니다. 다만, 시스템 장애보국, 긴급한 프로그램 보수, 외부요인 등 불가피한 경우에는 사전 게시없이 서비스를 중단할 수 있습니다.' },
    ],
  },
  {
    title: '제7조 (접근매체의 선정과 사용 및 관리)',
    contents: [
      { body: '회사는 전자금융거래 서비스 제공 시 접근매체를 선정하여 이용자의 신원, 권한 및 거래지시의 내용 등을 확인할 수 있습니다.' },
      { body: '이용자는 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도 또는 담보 목적으로 제공할 수 없습니다.' },
      { body: '이용자는 자신의 접근매체를 제3자에게 누설 또는 노출하거나 방치하여서는 안되며, 접근매체의 도용이나 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.' },
      { body: '회사는 이용자로부터 접근매체의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 접근매체를 사용함으로 인하여 이용자에게 발생한 손해를 배상할 책임이 있습니다.' },
    ],
  },
  {
    title: '제8조 거래내용의 확인',
    contents: [
      { body: '회사는 이용자와 미리 약정한 전자적 방법을 통하여 이용자의 거래내용(이용자의 오류정정 요구사실 및 처리결과에 관한 사항을 포함합니다)을 확인할 수 있도록 하며, 이용자의 요청이 있는 경우에는 요청을 받은 날로부터 2주 이내에 모사전송 등의 방법으로 거래내용에 관한 서면을 교부합니다.' },
      { body: '회사가 이용자에게 제공하는 거래내용 중 거래계좌의 명칭 또는 번호, 거래의 종류 및 금액, 거래상대방을 나타내는 정보, 거래일자, 전자적 장치의 종류 및 전자적 장치를 식별할 수 있는 정보와 해당 전자금융거래와 관련한 전자적 장치의 접속기록은 5년간, 건당 거래금액이 1만원 이하인 소액 전자금융거래에 관한 기록, 전자지급수단 이용시 거래승인에 관한 기록, 이용자의 오류정정 요구사실 및 처리결과에 관한 사항은 1년간의 기간을 대상으로 한다.' },
      { body: '이용자가 제1항에서 정한 서면교부를 요청하고자 할 경우 다음의 주소 및 전화번호로 요청할 수 있습니다. 주소: 463-400 경기도 성남시 분당구 삼평동 670 유스페이스A동 5층 (주)케이지이니시스 이메일 주소: sm@inicis.com 전화번호: 1588-4954' },
    ],
  },
  {
    title: '제9조 오류의 정정 등',
    contents: [
      { body: '이용자는 전자금융거래 서비스를 이용함에 있어 오류가 있음을 안 때에는 회사에 대하여 그 정정을 요구할 수 있습니다.' },
      { body: '회사는 전항의 규정에 따른 오류의 정정요구를 받은 때에는 이를 즉시 조사하여 처리한 후 정정요구를 받은 날부터 2주 이내에 그 결과를 이용자에게 알려 드립니다.' },
    ],
  },
  {
    title: '제10조 회사의 책임',
    contents: [
      { body: '회사가 접근매체의 발급주체가 아닌 경우에는 접근매체의 위조나 변조로 발생한 사고로 인하여 이용자에게 발생한 손해에 대하여 배상책임이 없습니다.' },
      { body: '회사가 접근매체의 발급주체이거나 사용,관리주체인 경우에는 접근매체의 위조나 변조로 발생한 사고로 인하여 이용자에게 발생한 손해에 대하여 배상책임이 있습니다. 다만 이용자가 제6조 제2항에 위반하거나 제3자가 권한없이 이용자의 접근매체를 이용하여 전자금융거래를 할 수 있음을 알았거나 알 수 있었음에도 불구하고 이용자가 자신의 접근매체를 누설 또는 노출하거나 방치한 경우에는 그러하지 아니합니다.' },
      { body: '회사는 계약체결 또는 거래지시의 전자적 전송이나 처리과정에서 발생한 사고로 인하여 이용자에게 그 손해가 발생한 경우에는 그 손해를 배상할 책임이 있습니다. 다만 본조 제2항 단서에 해당하거나 법인("중소기업기본법" 제2조 제2항에 의한 소기업을 제외합니다)인 이용자에게 손해가 발생한 경우로서 회사가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 충분한 주의의무를 다한 경우에는 그러하지 아니합니다.' },
      { body: '회사는 이용자로부터의 거래지시가 있음에도 불구하고 천재지변, 회사의 귀책사유가 없는 정전, 화재, 통신장애 기타의 불가항력적인 사유로 처리 불가능하거나 지연된 경우로서 이용자에게 처리 불가능 또는 지연사유를 통지한 경우(금융기관 또는 결제수단 발행업체나 통신판매업자가 통지한 경우를 포함합니다)에는 이용자에 대하여 이로 인한 책임을 지지 아니합니다.' },
    ],
  },
  {
    title: '제11조 전자지급거래계약의 효력',
    contents: [
      { body: '회사는 이용자의 거래지시가 전자지급거래에 관한 경우 그 지급절차를 대행하며, 전자지급거래에 관한 거래지시의 내용을 전송하여 지급이 이루어지도록 합니다.' },
      { body: '회사는 이용자의 전자지급거래에 관한 거래지시에 따라 지급거래가 이루어지지 않은 경우 수령한 자금을 이용자에게 반환하여야 합니다.' },
    ],
  },
  {
    title: '제12조 거래지시의 철회',
    contents: [
      { body: '이용자는 전자지급거래에 관한 거래지시의 경우 지급의 효력이 발생하기 전까지 거래지시를 철회할 수 있습니다.' },
      { body: '전항의 지급의 효력이 발생 시점이란 (i) 전자자금이체의 경우에는 거래지시된 금액의 정보에 대하여 수취인의 계좌가 개설되어 있는 금융기관의 계좌이 원장에 입금기록이 끝난 때 (ii) 그 밖의 전자지급수단으로 지급하는 경우에는 거래지시된 금액의 정보가 수취인의 계좌가 개설되어 있는 금융기관의 전자적 장치에 입력이 끝난 때를 말합니다.' },
      { body: '이용자는 지급의 효력이 발생한 경우에는 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령상 청약의 철회의 방법 또는 본 약관 제5조에서 정한 바에 따라 결제대금을 반환받을 수 있습니다.' },
    ],
  },
  {
    title: '제13조 전자지급결제대행 서비스 이용 기록의 생성 및 보존',
    contents: [
      { body: '회사는 이용자가 전자금융거래의 내용을 추적, 검색하거나 그 내용에 오류가 발생한 경우에 이를 확인하거나 정정할 수 있는 기록을 생성하여 보존합니다.' },
      { body: '전항의 규정에 따라 회사가 보존하여야 하는 기록의 종류 및 보존방법은 제8조 제2항에서 정한 바에 따릅니다.' },
    ],
  },
  {
    title: '제14조 전자금융거래정보의 제공금지',
    subtitle: '회사는 전자금융거래 서비스를 제공함에 있어서 취득한 이용자의 인적사항, 이용자의 계좌, 접근매체 및 전자금융거래의 내용과 실적에 관한 정보 또는 자료를 이용자의 동의를 얻지 아니하고 제3자에게 제공,누설하거나 업무상 목적 외에 사용하지 아니합니다.',
  },
  {
    title: '제15조 분쟁처리 및 분쟁조정',
    contents: [
      { body: '이용자는 다음의 분쟁처리 책임자 및 담당자에 대하여 전자금융거래 서비스 이용과 관련한 의견 및 불만의 제기, 손해배상의 청구 등의 분쟁처리를 요구할 수 있습니다. 담당자: 신용관리팀 연락처(전화번호, FAX): 3430-5847, 3430-5889 E-mail : inirm@inicis.com' },
      { body: '용자가 회사에 대하여 분쟁처리를 신청한 경우에는 회사는 15일 이내에 이에 대한 조사 또는 처리 결과를 이용자에게 안내합니다.' },
      { body: '이용자는 "금융감독기구의 설치 등에 관한 법률" 제51조의 규정에 따른 금융감독원의 금융분쟁조정위원회나 "소비자보호법" 제31조 제1항의 규정에 따른 소비자보호원에 회사의 전자금융거래 서비스의 이용과 관련한 분쟁조정을 신청할 수 있습니다.' },
    ],
  },
  {
    title: '제16조 회사의 안정성 확보 의무',
    subtitle: '>회사는 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의 종류별로 전자적 전송이나 처리를 위한 인력, 시설, 전자적 장치 등의 정보기술부문 및 전자금융업무에 관하여 금융감독위원회가 정하는 기준을 준수합니다.',
  },
  {
    title: '제17조 약관외 준칙 및 관할',
    contents: [
      { body: '이 약관에서 정하지 아니한 사항에 대하여는 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률, 통신판매에 관한 법률, 여신전문금융업법 등 소비자보호 관련 법령에서 정한 바에 따릅니다.' },
      { body: '회사와 이용자간에 발생한 분쟁에 관한 관할은 민사소송법에서 정한 바에 따릅니다. 부칙 (2010년 4월 12일) 이 약관은 2012년 7월 31일부터 적용합니다.' },
    ],
  },
];

export const privacyOfPayment = [
  {
    subtitle: '개인정보 수집 및 이용동의는 개인정보의 수집 및 이용목적, 수집항목 및 수집방법 개인정보의 보유 및 이용기간으로 별도 구분하여 표시됩니다.',
  },
  {
    title: '개인정보의 수집 및 이용목적',
    contents: [
      {
        body: '전자금융거래서비스 제공에 관한 결제 정보 등 개인정보 수집',
        contents: [
          { body: '이용자가 구매한 재화나 용역의 대금 결제' },
          { body: '결제 과정 중 본인 식별, 인증, 실명확인 및 이용자가 결제한 거래의 내역을 요청하는 경우 응대 및 확인' },
          { body: '이용자가 결제한 거래의 취소 또는 환불, 상품 배송 등 전자상거래 관련 서비스 제공' },
          { body: '이용자가 결제한 대금의 청구 및 수납' },
          { body: '전자금융거래 및 통신과금 서비스 이용 불가능한 이용자(미성년자 등)와 불량, 불법 이용자의 부정 이용 방지' },
          { body: '서비스 제공 및 관련 업무 처리에 필요한 동의 또는 철회 등 의사확인' },
          { body: '회사가 제공하는 소비자보호법 메일 발송' },
          { body: '고객 불만 및 민원처리' },
        ],
      },
      {
        body: '신규 서비스 개발 및 마케팅광고에의 활용',
        contents: [
          { body: '이용 빈도 파악 및 마케팅 특성에 따른 서비스 제공 및 CRM 용도' },
          { body: '신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계' },
        ],
      },
    ],
  },
  {
    title: '수집항목 및 수집방법',
    contents: [
      {
        body: '수집항목',
        contents: [
          {
            body: '전자금융거래서비스 이행과 관련 수집 정보',
            contents: [
              { body: '이용자의 고유식별번호' },
              { body: '이용자의 신용카드 정보 또는 지불하고자 하는 금융기관 계좌 정보' },
              { body: '이용자의 휴대폰 또는 유선 전화 번호 및 가입 통신사' },
              { body: '이용자의 상품권 번호 및 상품권 회원 아이디, 비밀번호 등' },
              { body: '이용자의 결제하고자 하는 포인트 카드 정보' },
              { body: '이용자의 전자지갑 이용자번호 등 결제 정보' },
              { body: '이용자의 접속 IP' },
              { body: '이용자의 이메일' },
              { body: '이용자의 상품 또는 용역 거래 정보' },
            ],
          },
          {
            body: '회원가입과 관련한 수집 정보',
            contents: [
              { body: '성명, 생년월일, 성별, 아이디, 비밀번호, 연락처(메일주소, 휴대폰 번호), 가입인증정보' },
              { body: '만 14세 미만은 법정대리인 정보, 가입인증정보' },
              { body: '외국인의 경우 외국인등록번호' },
            ],
          },
          {
            body: '“서비스” 이용 또는 처리 과정에서 자동 혹은 수동으로 생성되어 수집되는 정보',
            contents: [
              { body: '이용자 IP Address, 쿠키, 서비스 접속 일시, 서비스 이용기록, 불량 혹은 비정상 이용기록, 결제기록' },
            ],
          },
        ],
      },
      {
        body: '수집방법',
        contents: [
          { body: '회사가 운영하는 웹사이트 혹은 회사가 제공하는 결제창에 이용자가 직접 입력' },
          { body: '회사가 제공하는 정보통신서비스의 이용 혹은 처리 과정에서 쿠키, 서비스 접속 log등이 자동으로 생성 및 수집되는 경우' },
        ],
      },
    ],
  },
  {
    title: '개인정보의 보유 및 이용기간',
    subtitle: '이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기 합니다. 단, 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령에 의하여 보존할 필요가 있는 경우 관련 법령에서 정한 일정한 기간 동안 개인정보를 보존합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.',
    contents: [
      { body: '건당 1만원 초과 전자금융거래에 관한 기록 - 보존이유: 전자금융거래법, 보존기간: 5년' },
      { body: '건당 1만원 이하 전자금융거래에 관한 기록 - 보존이유: 전자금융거래법, 보존기간: 1년' },
      { body: '계약 또는 청약 철회 등에 관한 기록 - 보존이유: 전자상거래 등에서의 소비자보호에 관한 법률, 보존기간: 5년' },
      { body: '대금결제 및 재화 등의 공급에 관한 기록 - 보존이유: 전자상거래 등에서의 소비자보호에 관한 법률, 보존기간: 5년' },
      { body: '소비자의 불만 또는 분쟁 처리에 관한 기록 - 보존이유: 전자상거래 등에서의 소비자보호에 관한 법률, 보존기간: 3년' },
      { body: '본인확인에 관한 기록 - 보존이유: 정보통신 이용촉진 및 정보보호 등에 관한 법률, 보존기간: 6개월' },
    ],
  },
  {
    title: '개인정보 제공 및 위탁안내 (개인정보 제공)',
    subtitle: '회사는 이용자의 개인정보를 본 개인정보취급방침에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이 동 범위를 초과하여 이용하거나 이용자의 개인 정보를 제3자에게 제공하지 않습니다. 다만, 관련 법령에 의하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 등에 개인정보를 제공하여야 하는 경우는 예외로 합니다. 회사의 서비스 이행을 위하여 개인정보를 제3자에게 제공하고 있는 경우는 다음과 같습니다.',
    contents: [
      {
        body: '신용카드 결제',
        contents: [
          { body: '제공받는 자 : 국민, 비씨, 롯데, 삼성, NH농협, 현대, 외환, 신한, 하나SK 4개 시중은행:신한, SC제일, 씨티, 하나 8개 특수은행 농협, 기업, 국민, 저축, 수협, 신협, 우체국, 새마을금고 7개 지방은행 대구, 부산, 경남, 광주, 전북, 조흥, 제주 1개 전업카드사:우리 8개 VAN사:(주)코밴, KIS정보통신, NICE정보통신, 브이피(주), 한국신용카드결제(주), 퍼스트데이터코리아, (주)케이에스넷, 스타VAN' },
          { body: '제공정보 : 거래정보' },
          { body: '보유 및 이용기간 : 건당 1만원 초과 : 5년, 건당 1만원 이하 : 1년' },
        ],
      },
      {
        body: '계좌이체 결제',
        contents: [
          { body: '제공받는 자 : 금융결제원 경남/ 광주/국민/기업/농협/대구/부산/산업/새마을금고/ 수협/신한/신협/외환/우리/우체국/전북/제주/하나/씨티/SC제일은행 동양/미래에셋/삼성/신한/한투/한화증권' },
          { body: '제공정보 : 거래정보' },
          { body: '보유 및 이용기간 : 건당 1만원 초과 : 5년, 건당 1만원 이하 : 1년' },
        ],
      },
      {
        body: '가상계좌 결제',
        contents: [
          { body: '제공받는 자 : 국민/농협/우리/신한/하나/기업/우체국/외환/부산/경남/ 대구/수협/씨티 /삼성증권' },
          { body: '제공정보 : 거래정보' },
          { body: '보유 및 이용기간 : 건당 1만원 초과 : 5년, 건당 1만원 이하 : 1년' },
        ],
      },
      {
        body: '휴대폰 결제',
        contents: [
          { body: '제공받는 자 : (주)SKT, (주)KT, (주)LGU+, (주)모빌리언스, (주)다날, (주)인포허브, 갤럭시아커뮤니케이션즈(주)' },
          { body: '제공정보 : 거래정보(모빌리언스, 다날 IP 포함), 휴대폰 번호, 고유식별정보' },
          { body: '보유 및 이용기간 : 건당 1만원 초과 : 5년, 건당 1만원 이하 : 1년' },
        ],
      },
      {
        body: '유선전화 결제',
        contents: [
          { body: '제공받는 자 : (주)KT, (주)인포허브' },
          { body: '제공정보 : 거래정보, 유선전화번호, 고유식별정보' },
          { body: '보유 및 이용기간 : 건당 1만원 초과 : 5년, 건당 1만원 이하 : 1년' },
        ],
      },
      {
        body: '상품권 결제',
        contents: [
          { body: '제공받는 자 : 한국문화진흥, 한국도서보급, 해피머니아이엔씨, 아이앤플레이' },
          { body: '제공정보 : 거래정보' },
          { body: '보유 및 이용기간 : 건당 1만원 초과 : 5년, 건당 1만원 이하 : 1년' },
        ],
      },
      {
        body: 'M마일리지',
        contents: [
          { body: '제공받는 자 : (주)아이엠아이' },
          { body: '제공정보 : 거래정보' },
          { body: '보유 및 이용기간 : 건당 1만원 초과 : 5년, 건당 1만원 이하 : 1년' },
        ],
      },
      {
        body: '포인트 결제',
        contents: [
          { body: '제공받는 자 : (주)SK마케팅앤컴퍼니, GS넥스테이션주식회사' },
          { body: '제공정보 : 거래정보' },
          { body: '보유 및 이용기간 : 건당 1만원 초과 : 5년, 건당 1만원 이하 : 1년' },
        ],
      },
      {
        body: '전자지갑 결제',
        contents: [
          { body: '제공받는 자 : 금융결제원, (주)SK플래닛, 옐로페이, 하나은행' },
          { body: '제공정보 : 거래정보, 휴대폰 번호' },
          { body: '보유 및 이용기간 : 건당 1만원 초과 : 5년, 건당 1만원 이하 : 1년' },
        ],
      },
      {
        body: '현금영수증 발행',
        contents: [
          { body: '제공받는 자 : 국세청' },
          { body: '제공정보 : 거래정보, 고유식별정보, 휴대폰번호, 카드번호' },
          { body: '보유 및 이용기간 : 건당 1만원 초과 : 5년, 건당 1만원 이하 : 1년' },
        ],
      },
      {
        body: '본인 확인 인증',
        contents: [
          { body: '제공받는 자 : 나이스신용평가정보, 한국신용평가정보(주), 한국신용정보, SKT, KT, LGU+, (주)코밴, KIS정보통신, NICE정보통신, 브이피(주), 한국신용카드결제(주), 퍼스트 데이터 코리아, (주)케이에스넷 국민/비씨/롯데/삼성/NH농협/현대/외환/신한 경남/광주/국민/기업/농협/대구/부산/산업/새마을금고/ 수협/신한/신협/외환/우리/우체국/전북/제주/하나/씨티/SC제일' },
          { body: '제공정보 : 고유식별정보, 본인확인정보' },
          { body: '보유 및 이용기간 : 건당 1만원 초과 : 5년, 건당 1만원 이하 : 1년' },
        ],
      },
    ],
  },
  {
    title: '개인정보 제공 및 위탁안내 (개인정보 위탁)',
    subtitle: '회사는 이용자의 동의 없이 이용자의 개인정보를 외부 업체에 위탁하지 않습니다. 하단의 업체는 이용자에게 사전 통지 또는 동의를 얻어 위탁 업무를 대행하고 있습니다.',
    contents: [
      {
        body: '배송정보 안내서비스 제공',
        contents: [
          { body: '제공받는 자 : (주)굿스플로' },
          { body: '제공 정보 : 거래 정보' },
        ],
      },
      {
        body: '고객상담 업무지원 위탁 수행',
        contents: [
          { body: '제공받는 자 : (주)씨베이스' },
          { body: '제공 정보 : 거래 정보' },
        ],
      },
    ],
  },
];

export const identificationOfPayment = [
  {
    title: '수집항목',
    contents: [
      { body: '고유식별정보: 주민등록표에 기재된 성명 및 주민등록번호' },
      { body: '다만, 재외국민의 경우에는 여권에 기재된 성명 및 여권번호(여권이 발급되지 아니한 재외국민은 『재외국민등록법』에 의한 등록부에 기재된 성명 및 등록번호)' },
    ],
  },
  {
    title: '고유식별정보의 수집 및 이용목적',
    subtitle: '당사는 다음과 같은 목적 하에 “서비스”와 관련한 고유식별정보를 수집합니다.',
    contents: [
      { body: '서비스 제공 계약의 성립, 유지, 종료를 위한 본인 식별 및 실명확인, 각종 회원관리' },
      { body: '서비스 제공 과정 중 본인 식별, 인증, 실명확인 및 회원에 대한 각종 안내/고지' },
      { body: '대금 환불, 상품배송 등 전자상거래 관련 서비스 제공' },
      { body: '서비스 제공 및 관련 업무처리에 필요한 동의 또는 철회 등 의사확인' },
    ],
  },
  {
    title: '고유식별정보의 보유 및 이용기간',
    contents: [
      { body: '건당 거래 금액 1만원 초과의 경우: 5년간' },
      { body: '건당 거래 금액 1만원 이하의 경우: 1년간' },
    ],
  },
  {
    title: '',
    subtitle: '고유식별정보의 수집 및 이용에 관련된 다른 사항은 개인정보 취급방침의 내용을 준용합니다.',
    contents: [
      { body: '이용자는 고유식별정보의 수집 및 이용에 동의하지 않더라도 회사의 서비스 이용에 불이익이 없음을 알려드립니다.' },
      { body: '단, 해당 고유식별번호가 반드시 필요한 서비스에 한하여 이용이 제한될 수 있습니다' },
    ],
  },
];

export const termsOfBilling = [
  {
    contents: [
      { body: '귀하가 신청하신 신용카드 정기출금 결제는 결제대행사 ㈜케이지이니시스에서 대행합니다.' },
      { body: '따라서, 귀하의 신용카드 결제내역에는 정기출금과 관련한 이용가맹점이 ㈜케이지이니시스로 표기되오니 이점 착오 없으시기 바랍니다.' },
      { body: '>또한 ㈜케이지이니시스는 정기출금 결제대행만을 수행하므로, 정기출금 결제신청 해지 등과 관련한 모든 업무는 해당 인터넷 상점을 통해 직접 요청하셔야 합니다.' },
      { body: '㈜케이지이니시스는 귀하의 본 신청과 관련한 거래내역 e-mail로 통보 드리며, 별도 조회를 www.inicis.com에서 이용하실 수 있도록 하고 있습니다. ㈜케이지이니시스는 이러한 별도 서비스 제공을 위해 필요한 최소정보만(성명, e-mail)을 해당 인터넷 상점으로부터 수령하여 보관하게 됩니다.' },
    ],
  },
];
