import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { Visible, Row, Col } from 'react-grid-system';

import Button from '@material-ui/core/Button';

import * as artistActions from '../../actions/artist';
import { PropsData, Order } from '../Utils/DataUtil';
import { colors, presets, strings, styles } from '../../assets/values';

import bannerDesktopSrc from './../../assets/images/banner-desktop.jpg';
import bannerMobileSrc from './../../assets/images/banner-mobile.jpg';
import lookAroundSrc from './../../assets/images/home/look-around.png';
import beTogetherSrc from './../../assets/images/home/be-together.png';
import beHappySrc from './../../assets/images/home/be-happy.png';
import lookAroundEgSrc from './../../assets/images/home/look-around-iphone.png';
import beTogetherEgSrc from './../../assets/images/home/be-together-iphone.png';
import beHappyEgSrc from './../../assets/images/home/be-happy-iphone.png';
import beArtistSrc from './../../assets/images/home/be-artist.png';

import history from './../../history';


const values = presets.filterValues;

const style = {
  section: {
    gray: {
      paddingTop: 32,
      wordBreak: 'keep-all',
    },
    white: {
      paddingTop: 32,
      backgroundColor: '#fff',
      wordBreak: 'keep-all',
    },
    black: {
      paddingTop: 32,
      backgroundColor: colors.primaryBlack,
      wordBreak: 'keep-all',
    },
  },
  headline: {
    fontWeight: 350,
    lineHeight: 1.75,
    wordBreak: 'keep-all',
  },
};
const paths = strings.routes;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.startInitialize();
    this.props.readArtists(null, null, values.artistsOrdering.supporterDescending)
      .then(() => this.props.finishInitialize());
  }

  handleRoute = (artist) => {
    history.push(`/${artist.url_artist}`);
  };

  handleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    return (
      <div>
        <Visible lg xl>
          <div
            style={{
              backgroundImage: `url(${bannerDesktopSrc})`,
              height: 400,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          />
        </Visible>
        <Visible xs sm md>
          <div
            style={{
              backgroundImage: `url(${bannerMobileSrc})`,
              height: 180,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          />
        </Visible>
        <div style={style.section.gray}>
          <Row style={{ maxWidth: 768, margin: '0 auto', padding: 16 }}>
            <Col md={4}>
              <div style={{ padding: 25 }}>
                <img src={lookAroundSrc} alt="둘러보기"/>
              </div>
            </Col>
            <Col md={4}>
              <div style={{ padding: 25 }}>
                <img src={beTogetherSrc} alt="함께하기"/>
              </div>
            </Col>
            <Col md={4}>
              <div style={{ padding: 25 }}>
                <img src={beHappySrc} alt="행복하기"/>
              </div>
            </Col>
          </Row>
          <Row style={{ maxWidth: 768, margin: '0 auto', padding: 16 }}>
            <Col md={8} offset={{ md: 2 }}>
              <h2 style={{ textAlign: 'center', fontWeight: 350, lineHeight: 1.75 }}>
                삼천원은 아티스트와 팬이 직접적으로 창작물을 교류하고, 소통하는 공간입니다.
              </h2>
            </Col>
          </Row>
        </div>
        <div style={style.section.white}>
          <Row style={{ maxWidth: 1024, margin: '0 auto', paddingTop: 16 }}>
            <Col md={4} offset={{ md: 2 }}>
              <h2 style={{ ...style.headline, color: colors.primaryPink }}>
                둘러보기
              </h2>
              <p style={{ fontWeight: 350 }}>
                다양한 아티스트를 둘러보고 취향에 딱 맞는 작품을 찾아보세요
              </p>
              <div style={{ marginBottom: 12 }}>
                <Link to={paths.artists}>
                  <Button color="primary">아티스트 둘러보기</Button>
                </Link>
              </div>
              <div style={{ marginBottom: 12 }}>
                <Link to={paths.posts}>
                  <Button color="primary">포스트 읽어보기</Button>
                </Link>
              </div>
            </Col>
            <Col md={4}>
              <img src={lookAroundEgSrc} alt="둘러보기"/>
            </Col>
          </Row>
        </div>
        <div style={style.section.gray}>
          <Row style={{ maxWidth: 1024, margin: '0 auto', paddingTop: 16 }}>
            <Col md={4} offset={{ md: 2 }}>
              <img src={beTogetherEgSrc} alt="함께하기"/>
            </Col>
            <Col md={4}>
              <h2 style={{ ...style.headline, textAlign: 'right', color: colors.primaryOrange }}>
                함께하기
              </h2>
              <p style={{ fontWeight: 350, textAlign: 'right' }}>
                아티스트와 함께하고 지속가능한 문화예술을 만들어가요
              </p>
              <p style={{ fontWeight: 350, textAlign: 'right', fontSize: styles.fontSize.md }}>
                아티스트의 프로젝트, 정기후원, 작품제작에 월 정기결제로 함께하고 지속가능한 문화예술 생태계를
                만들어갈 수 있어요
              </p>
            </Col>
          </Row>
        </div>
        <div style={style.section.white}>
          <Row style={{ maxWidth: 1024, margin: '0 auto', paddingTop: 16 }}>
            <Col md={4} offset={{ md: 2 }}>
              <h2 style={{ ...style.headline, color: colors.primaryTeal }}>
                행복하기
              </h2>
              <p style={{ fontWeight: 350 }}>
                리워드를 받아보고 아티스트와 함께 행복해져요
              </p>
              <p style={{ fontWeight: 350, fontSize: styles.fontSize.md }}>
                아티스트의 마음이 담긴 편지, 리워드, 작품을 받아보고 문화예술을 함께 즐겨요
              </p>
            </Col>
            <Col md={4}>
              <div style={{ padding: 0, margin: 0 }}>
                <img src={beHappyEgSrc} alt="행복하기"/>
              </div>
            </Col>
          </Row>
        </div>
        <div style={style.section.black}>
          <Row style={{ maxWidth: 1024, margin: '0 auto', paddingTop: 16 }}>
            <Col md={6} offset={{ md: 3 }} style={{ textAlign: 'center', paddingBottom: 60 }}>
              <h2 style={{ ...style.headline, color: colors.white, }}>
                누가 아티스트로 등록할 수 있나요?
              </h2>
              <p style={{ fontWeight: 350, color: colors.textWhite, lineHeight: 1.5 }}>
                인디밴드, 버스커, 싱어송라이터, DJ, 문화공간, 보드게임 디자이너,
                웹툰 작가, 일러스트레이터, 게임 제작팀, 디자이너,
                포토그래퍼 등 개인과 단체를 막론하고 문화예술의 생산자로써
                소비자에게 가치를 전달하는 모두 삼천원에 아티스트로 등록할 수 있습니다.
              </p>
              <div
                style={{
                  marginTop: 60,
                  backgroundImage: `url(${beArtistSrc})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  height: 400,
                }}
              >
                <Link to={paths.artistsStart}>
                  <Button variant="contained" color="primary" style={{ position: 'relative', top: 315 }}>
                    아티스트로 등록하기
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

Index.propTypes = {
  artists: PropTypes.array,
  mainpageSchedules: PropTypes.array,
  readArtists: PropTypes.func.isRequired,
  startInitialize: PropTypes.func.isRequired,
  finishInitialize: PropTypes.func.isRequired,
  initializing: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    artists: PropsData.fromState(state.artist.data, Order.DESCENDING),
    mainpageSchedules: state.artist.mainpageSchedules,
    initializing: state.artist.initializing,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
