import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Person from '@material-ui/icons/Person';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

class MyDashboardSupporter extends React.Component {
  render() {
    return (
      <TableRow key={this.props.id}>
        <TableCell>
          {this.props.nickname}
        </TableCell>
        <TableCell>
          {this.props.email}
        </TableCell>
        <TableCell>
          {this.props.reward.title}
        </TableCell>
        <TableCell>
          {this.props.payment_amount}
        </TableCell>
        <TableCell>
          {this.props.user_sum}
        </TableCell>
        <TableCell>
          {this.props.user_count}
        </TableCell>
        <TableCell>
          <IconButton onClick={this.props.onClickSupporter}>
            <Person/>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

MyDashboardSupporter.propTypes = {
  id: PropTypes.number,
  nickname: PropTypes.string,
  email: PropTypes.string,
  reward: PropTypes.object,
  payment_amount: PropTypes.number,
  user_sum: PropTypes.number,
  user_count: PropTypes.number,
  supporter: PropTypes.object,
  onClickSupporter: PropTypes.func,
};

export default MyDashboardSupporter;
