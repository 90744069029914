import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { useFormik } from 'formik';

import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { RowWithLabel } from './../../components';

import * as userActions from './../../actions/user';

import * as Validator from './../Utils/Validator';

import { presets, strings } from './../../assets/values';
import { getStyle } from './style';

import history from './../../history';

const validate = (values) => {
  const errors = {};
  const nameError = Validator.validateName(values.name);
  const urlArtistError = Validator.validateUrlArtist(values.url_artist);
  const contactPhoneError = Validator.validateContactPhone(values.contact_phone);
  const contactEmailError = Validator.validateContactEmail(values.contact_email);
  const accountNumberError = Validator.validateAccountNumber(values.account_number);
  const accountHolderError = Validator.validateAccountHolder(values.account_holder);
  if (values.is_business_registered === 'true') {
    const businessRegistrationNumberError = Validator.validateBusinessRegistrationNumber(values.business_registration_number);
    const businessRegistrationNameError = Validator.validateBusinessRegistrationName(values.business_registration_name);
    if (businessRegistrationNumberError) errors.business_registration_number = businessRegistrationNumberError;
    if (businessRegistrationNameError) errors.business_registration_name = businessRegistrationNameError;
  } else {
    const residentRegistrationNumberError = Validator.validateResidentRegistrationNumber(values.resident_registration_number);
    if (residentRegistrationNumberError) errors.resident_registration_number = residentRegistrationNumberError;
  }
  if (nameError) errors.name = nameError;
  if (urlArtistError) errors.url_artist = urlArtistError;
  if (contactPhoneError) errors.contact_phone = contactPhoneError;
  if (contactEmailError) errors.contact_email = contactEmailError;
  if (accountNumberError) errors.account_number = accountNumberError;
  if (accountHolderError) errors.account_holder = accountHolderError;
  return errors;
};

const DashboardProfile = (props) => {
  const onSubmit = (values) => {
    const payload = {
      artist: {
        name: values.name,
        post_style: values.post_style,
        contact_phone: values.contact_phone,
        contact_email: values.contact_email,
        account_bank: values.account_bank,
        account_holder: values.account_holder,
        account_number: values.account_number,
        is_secret_post_noticeable: values.is_secret_post_noticeable,
        secondary_post_enabled: values.secondary_post_enabled,
        secondary_post_label: values.secondary_post_label,
        secondary_post_style: values.secondary_post_style,
        business_registration_number: values.business_registration_number,
        business_registration_name: values.business_registration_name,
        resident_registration_number: values.resident_registration_number,
        is_business_registered: values.is_business_registered === 'true',
        is_approved: values.is_approved === 'true',
      },
    };
    props.updateUser(payload).then((response) => {
      if (response.action.payload.ok) {
        toastr.success(strings.success, strings.edittingArtistProfileSuccess);
        props.finishEditingUserSettingForm();
      }
      if (!response.action.payload.ok) toastr.error(strings.fail);
    });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      post_style: 'feed',
      secondary_post_enabled: false,
      is_secret_post_noticeable: false,
      secondary_post_label: '둘째 포스트',
      secondary_post_style: 'board',
      url_artist: '',
      contact_phone: '',
      contact_email: '',
      account_bank: '',
      account_holder: '',
      account_number: '',
      is_business_registered: 'false',
      is_approved: 'false',
      business_registration_number: '',
      business_registration_name: '',
      resident_registration_number: '',
    },
    onSubmit: values => onSubmit(values),
    validate: values => validate(values),
    validateOnChange: false,
  });

  useEffect(() => {
    props.readUser().then((response) => {
      if (!response.action.payload.ok) {
        history.push('/');
      }
    });
  }, []);

  useEffect(() => {
    if (props.user) {
      const artist = props.user.artist[0];
      formik.setValues({
        ...artist,
        is_business_registered: artist.is_business_registered ? 'true' : 'false',
        is_approved: artist.is_approved ? 'true' : 'false',
      });
    }
  }, [props.user]);

  const handleToggle = () => {
    const artist = props.user.artist[0];
    formik.setValues({
      ...artist,
      is_business_registered: artist.is_business_registered ? 'true' : 'false',
      is_approved: artist.is_approved ? 'true' : 'false',
    });
    if (props.editing) props.finishEditingUserSettingForm();
    else props.startEditingUserSettingForm();
  };

  const style = getStyle();
  const values = presets.formValues;
  const toggleRowStyle = style.rowWithLabel(40, 0);
  const defaultRowStyle = style.rowWithLabel(25);

  if (!props.user) {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <Card style={{ padding: 16 }}>
      <Button
        variant="outlined"
        color="primary"
        href={`${strings.appUrl}/${props.user.artist[0].url_artist}`}
        style={style.muiRaisedButton(20)}
      >
        {strings.artistLink}
      </Button>
      <RowWithLabel style={toggleRowStyle} label={strings.editing}>
        <Switch onChange={handleToggle} checked={props.editing} color="primary"/>
      </RowWithLabel>
      <form onSubmit={formik.handleSubmit}>
        <RowWithLabel style={defaultRowStyle} label={strings.artistName}>
          <TextField
            name="name"
            value={formik.values.name}
            disabled={!props.editing}
            onChange={formik.handleChange}
            error={formik.errors.name}
          />
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.artistUrl}>
          <TextField
            name="url_artist"
            value={formik.values.url_artist}
            disabled
            error={formik.errors.url_artist}
          />
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={`메인 ${strings.postStyle.label}`}>
          <RadioGroup
            style={{ flexDirection: 'row' }}
            value={formik.values.post_style}
            onChange={formik.handleChange}
            error={formik.errors.post_style}
            name="post_style"
          >
            <FormControlLabel
              control={<Radio color="primary"/>}
              disabled={!props.editing}
              value={values.postStyle.feed}
              label={strings.postStyle.feed}
            />
            <FormControlLabel
              control={<Radio color="primary"/>}
              disabled={!props.editing}
              value={values.postStyle.board}
              label={strings.postStyle.board}
            />
          </RadioGroup>
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label="시크릿 포스트 노출">
          <FormControl component="fieldset" variant="standard">
            <Switch
              onChange={formik.handleChange}
              checked={formik.values.is_secret_post_noticeable}
              disabled={!props.editing}
              color="primary"
              name="is_secret_post_noticeable"
            />
            <FormHelperText>후원자만이 읽을 수 있는 시크릿 포스트의 제목을 포스트 목록에 노출합니다</FormHelperText>
          </FormControl>
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label="두번째 포스트 활성화">
          <FormControl component="fieldset" variant="standard">
            <Switch
              onChange={formik.handleChange}
              checked={formik.values.secondary_post_enabled}
              disabled={!props.editing}
              color="primary"
              name="secondary_post_enabled"
            />
            <FormHelperText>메인 포스트 메뉴와 독립적으로 사용할 수 있는 두번째 포스트 메뉴를 활성화합니다</FormHelperText>
          </FormControl>
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label="두번째 포스트 이름">
          <TextField
            name="secondary_post_label"
            onChange={formik.handleChange}
            disabled={!props.editing}
            value={formik.values.secondary_post_label}
            error={formik.errors.secondary_post_label}
            helperText="두번째 포스트 메뉴의 이름으로 쓰입니다"
          />
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={`두번째 ${strings.postStyle.label}`}>
          <RadioGroup
            style={{ flexDirection: 'row' }}
            value={formik.values.secondary_post_style}
            onChange={formik.handleChange}
            error={formik.errors.secondary_post_style}
            name="secondary_post_style"
          >
            <FormControlLabel
              control={<Radio color="primary"/>}
              disabled={!props.editing}
              value={values.postStyle.feed}
              label={strings.postStyle.feed}
            />
            <FormControlLabel
              control={<Radio color="primary"/>}
              disabled={!props.editing}
              value={values.postStyle.board}
              label={strings.postStyle.board}
            />
          </RadioGroup>
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.pagePublicAccess.label}>
          <RadioGroup
            style={{ flexDirection: 'row' }}
            value={formik.values.is_approved}
            onChange={formik.handleChange}
            error={formik.errors.is_approved}
            name="is_approved"
          >
            <FormControlLabel
              control={<Radio color="primary"/>}
              disabled={!props.editing}
              value={`${values.pagePublicAccess.approved}`}
              label={strings.pagePublicAccess.approved}
            />
            <FormControlLabel
              control={<Radio color="primary"/>}
              disabled={!props.editing}
              value={`${values.pagePublicAccess.pending}`}
              label={strings.pagePublicAccess.pending}
            />
          </RadioGroup>
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.contactPhone}>
          <TextField
            name="contact_phone"
            value={formik.values.contact_phone}
            onChange={formik.handleChange}
            disabled={!props.editing}
            error={formik.errors.contact_phone}
          />
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.contactEmail}>
          <TextField
            name="contact_email"
            value={formik.values.contact_email}
            error={formik.errors.contact_email}
            onChange={formik.handleChange}
            disabled={!props.editing}
          />
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.accountBank}>
          <Select
            name="account_bank"
            value={formik.values.account_bank}
            error={formik.errors.account_bank}
            onChange={formik.handleChange}
            disabled={!props.editing}
          >
            {presets.banks.map(bank => <MenuItem key={bank.code} value={bank.code}>{bank.name}</MenuItem>)}
          </Select>
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.accountNumber}>
          <TextField
            name="account_number"
            value={formik.values.account_number}
            error={formik.errors.account_number}
            onChange={formik.handleChange}
            disabled={!props.editing}
          />
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.accountHolder}>
          <TextField
            name="account_holder"
            value={formik.values.account_holder}
            error={formik.errors.account_holder}
            onChange={formik.handleChange}
            disabled={!props.editing}
          />
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.businessRegistration.label}>
          <RadioGroup
            value={formik.values.is_business_registered}
            error={formik.errors.is_business_registered}
            onChange={formik.handleChange}
            name="is_business_registered"
          >
            <FormControlLabel
              control={<Radio color="primary"/>}
              disabled={!props.editing}
              value={`${values.businessRegistration.registered}`}
              label={strings.businessRegistration.registered}
              color="primary"
            />
            <FormControlLabel
              control={<Radio color="primary"/>}
              disabled={!props.editing}
              value={`${values.businessRegistration.freelancer}`}
              label={strings.businessRegistration.freelancer}
              color="primary"
            />
          </RadioGroup>
        </RowWithLabel>
        { formik.values.is_business_registered === true || formik.values.is_business_registered === 'true' ? // eslint-disable-line
          <div>
            <RowWithLabel style={defaultRowStyle} label={strings.businessRegistrationName}>
              <TextField
                name="business_registration_name"
                value={formik.values.business_registration_name}
                error={formik.errors.business_registration_name}
                onChange={formik.handleChange}
                disabled={!props.editing}
                margin="normal"
              />
            </RowWithLabel>
            <RowWithLabel style={defaultRowStyle} label={strings.businessRegistrationNumber}>
              <TextField
                name="business_registration_number"
                value={formik.values.business_registration_number}
                error={formik.errors.business_registration_number}
                onChange={formik.handleChange}
                disabled={!props.editing}
                margin="normal"
              />
              <CardContent style={{ lineHeight: 1.5, padding: 0 }}>
                <div>
                  <Typography variant="body2" style={{ marginBottom: 16 }}>사업자등록이 된 아티스트에게는 전체 모집금액 중 수수료(결제대행사 수수료 3.5%(VAT별도) 및 플랫폼수수료 5%(VAT별도))를 제한 금액이 정산됩니다. 수수료에 해당하는 부분의 세금계산서를 발행해 드립니다.</Typography>
                  <Typography variant="body2" style={{ marginBottom: 16 }}>삼천원 내에서 발생한 매출에 대해서 부가세 신고의무가 발생합니다.</Typography>
                  <Typography variant="body2">사업자등록번호를 입력하신 후, 이메일 support@3000won.com으로 사업자등록증 사본을 보내주세요.</Typography>
                </div>
              </CardContent>
            </RowWithLabel>
          </div> :
          <RowWithLabel style={defaultRowStyle} label={strings.residentRegistrationNumber}>
            <TextField
              name="resident_registration_number"
              type="password"
              value={formik.values.resident_registration_number}
              error={formik.errors.resident_registration_number}
              onChange={formik.handleChange}
              disabled={!props.editing}
            />
            <CardContent style={{ lineHeight: 1.5, padding: 0 }}>
              <div>
                <Typography variant="body2" style={{ marginBottom: 16 }}>전체 모집금액 중에서 부가가치세(10%) 및 결제대행사 수수료 3.5%(VAT별도), 플랫폼수수료 5%를 제외한 금액이 사업소득으로 구분됩니다. 해당 사업소득에서 소득세(3.3%)가 원천징수되며, 사업소득세 신고를 위해 소득자의 주민등록번호가 필요합니다.</Typography>
                <Typography variant="body2" style={{ marginBottom: 16 }}>삼천원에서 발생한 소득에 대해서 종합소득세 신고의무가 발생합니다.</Typography>
                <Typography variant="body2" style={{ marginBottom: 16 }}>주민등록번호는 오직 사업소득세 신고를 위해서만 수집되며, 다른 그 어떠한 용도로도 사용되지 않습니다.</Typography>
                <ul>
                  <li><Typography variant="caption">수집목적 : 개인사업자 사업소득신고 (소득세법 제145조 및 제164조)</Typography></li>
                  <li><Typography variant="caption">수집항목 : 주민등록번호</Typography></li>
                  <li><Typography variant="caption">이용기간 : 플랫폼 삼천원의 회원 탈퇴 시까지 보관 후 파기</Typography></li>
                </ul>
                <Typography variant="body2">정산정보를 입력함으로써 고유식별정보(주민등록번호) 수집 및 이용에 동의하게됩니다.</Typography>
              </div>
            </CardContent>
          </RowWithLabel>
        }
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            disabled={!props.editing || formik.isSubmitting}
            type="submit"
          >
            {strings.submit}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

DashboardProfile.propTypes = {
  user: PropTypes.object,
  editing: PropTypes.bool,
  fields: PropTypes.object,
  readUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  startEditingUserSettingForm: PropTypes.func.isRequired,
  finishEditingUserSettingForm: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    editing: state.user.editingSetting,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...userActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardProfile);
