import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class CheckoutTable extends React.Component {
  render() {
    if (!this.props.checkout.is_business_registered) {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>전체 결제액</TableCell>
              <TableCell>부가가치세</TableCell>
              <TableCell>결제사 수수료 (+ VAT)</TableCell>
              <TableCell>플랫폼 수수료</TableCell>
              <TableCell>소득세</TableCell>
              <TableCell>최종 정산액</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {this.props.checkout.price}
              </TableCell>
              <TableCell>
                {this.props.checkout.freelance_vat}
              </TableCell>
              <TableCell>
                {this.props.checkout.inicis_fee + this.props.checkout.inicis_vat}
              </TableCell>
              <TableCell>
                {this.props.checkout.freelance_fee}
              </TableCell>
              <TableCell>
                {this.props.checkout.income_tax}
              </TableCell>
              <TableCell>
                {this.props.checkout.total_checkout}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>전체 결제액</TableCell>
            <TableCell>결제사 수수료 (+ VAT)</TableCell>
            <TableCell>플랫폼 수수료 (+ VAT)</TableCell>
            <TableCell>최종 정산액</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              {this.props.checkout.price}
            </TableCell>
            <TableCell>
              {this.props.checkout.inicis_fee + this.props.checkout.inicis_vat}
            </TableCell>
            <TableCell>
              {this.props.checkout.business_fee + this.props.checkout.business_vat}
            </TableCell>
            <TableCell>
              {this.props.checkout.total_checkout}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

CheckoutTable.propTypes = {
  checkout: PropTypes.object,
};

export default CheckoutTable;
