import { styles, colors } from './../../assets/values';

export const getStyle = (stylesToOverride = {}) => Object.assign({
  navigateToTerms: {
    ...styles.muiComponents.checkbox,
    textDecoration: 'none',
    color: colors.textBlack,
    marginTop: 8,
    float: 'right',
    clear: 'both',
  },
  rowWithLabel: styles.rowWithLabel,
  muiRaisedButton: styles.muiComponents.raisedButton,
  muiCheckbox: {
    ...styles.muiComponents.checkbox,
    marginTop: 15,
  },
  muiTextField: styles.muiComponents.textField,
  muiSelectField: {
    width: 100,
  },
}, stylesToOverride);
