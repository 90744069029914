import { colors, styles } from './../../assets/values';


export const getStyle = (stylesToOverride = {}) => Object.assign({
  muiRaisedButton: styles.muiComponents.raisedButton,
  wrapper: {
    paddingTop: 32,
    width: 1000,
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  announcement: {
    wrapper: {
      paddingBottom: 15,
    },
    title: {
      fontWeight: styles.fontWeight.Medium,
      fontSize: styles.fontSize.xl,
      lineHeight: 1.0,
      color: colors.textBlack,
      marginBottom: 30,
      textAlign: 'center',
    },
    card: {
      paddingTop: '4%',
      paddingLeft: '7%',
      paddingRight: '7%',
    },
    content: {
      card: {
        padding: 16,
      },
      left: {
        width: '9%',
        minWidth: 44,
        float: 'left',
      },
      right: {
        width: '90%',
        maxWidth: `calc(100% - ${44}px)`,
        float: 'right',
      },
      thumbnail: {
        display: 'inline-block',
        width: 44,
        height: 44,
        color: colors.primaryPink,
      },
      title: {
        fontWeight: styles.fontWeight.Medium,
        fontSize: styles.fontSize.lg,
        color: colors.textBlack,
        display: 'inline-block',
        float: 'left',
        clear: 'left',
        paddingBottom: 4,
      },
      timestamp: {
        fontWeight: styles.fontWeight.Regular,
        fontSize: styles.fontSize.sm,
        color: colors.textBlack60,
        display: 'inline-block',
        float: 'left',
        clear: 'left',
      },
      footer: {
        textAlign: 'right',
        share: {
          width: 18,
          height: 18,
          padding: 0,
        },
      },
      body: {
        paddingTop: 8,
        paddingBottom: 16,
        clear: 'both',
      },
    },
  },
}, stylesToOverride);
