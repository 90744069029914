/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Container, Row, Col } from 'react-grid-system';

import Button from '@material-ui/core/Button';

import { colors, strings, styles } from './../../assets/values';
import { facebook, instagram } from './../../assets/images/brands';


const iconSize = 30;
const style = {
  footer: {
    width: '100%',
    height: styles.footerHeight,
    position: 'absolute',
    bottom: 0,
    containerWrapper: {
      color: styles.palette.alternateTextColor,
      backgroundColor: colors.footerBackground,
      fontFamily: styles.fontFamily,
    },
  },
  text: {
    representative: {
      float: 'left',
      height: iconSize,
      marginTop: (styles.footerHeight - iconSize) / 2,
      marginBottom: 0,
      marginLeft: 16,
      marginRight: 16,
      fontSize: styles.fontSize.xl,
    },
    company: {
      float: 'right',
      padding: '10px 16px',
      fontSize: styles.fontSize.xs,
      fontWeight: 200,
    },
  },
  link: {
    color: colors.textWhite,
    fontSize: styles.fontSize.md,
    textDecoration: 'none',
    icon: {
      float: 'right',
      height: iconSize,
      margin: (styles.footerHeight - iconSize) / 2,
      img: {
        height: iconSize,
      },
    },
  },
};
const paths = strings.routes;

class SupportLink extends React.Component {
  render() {
    return (
      <Link href={`${paths.support}/${this.props.to}`} to={`${paths.support}/${this.props.to}`} style={style.footer.link}>
        <Button
          style={{ color: colors.textWhite }}
          size="small"
          color="inherit"
        >
          {this.props.label}
        </Button>
      </Link>
    );
  }
}

SupportLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
};

class Footer extends React.Component {
  render() {
    return (
      <footer style={style.footer}>
        <div style={style.footer.containerWrapper}>
          <Container>
            <Row>
              <Col md={12}>
                <div style={style.text.representative}>{strings.representative}</div>
                <a style={style.link.icon} href={strings.officialFacebookUrl}>
                  <img style={style.link.icon.img} src={facebook} alt={strings.facebook}/>
                </a>
                <a style={style.link.icon} href={strings.officialInstagramUrl}>
                  <img style={style.link.icon.img} src={instagram} alt={strings.instagram}/>
                </a>
              </Col>
              <Col md={12}>
                <Link href={paths.announcements} to={paths.announcements} style={style.footer.link}>
                  <Button
                    style={{ color: colors.textWhite }}
                    size="small"
                    color="inherit"
                  >
                    {strings.announcement}
                  </Button>
                </Link>
                <SupportLink to={paths.supportFAQ} label={strings.faq}/>
                <a href={strings.kakaoChatUrl} style={style.footer.link} target="blank">
                  <Button
                    style={{ color: colors.textWhite }}
                    size="small"
                    color="inherit"
                  >
                    {strings.contacts}
                  </Button>
                </a>
                <SupportLink to={paths.supportTermsOfUse} label={strings.termsOfUse}/>
                <SupportLink
                  to={paths.supportPrivacyPolicy} label={strings.privacyPolicy}
                  // labelStyle={{ fontWeight: 500 }}
                />
                <SupportLink to={paths.supportTermsOfPayment} label={strings.termsOfPayment}/>
              </Col>
              <Col md={12}>
                <p style={style.text.company}>{strings.company}</p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
}

export default Footer;
