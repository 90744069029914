/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { ImageContainer } from './../../components';

import { Permission } from './../Utils/PermissonUtil';
import { numberWithCommas } from './../Utils/NumberUtil';

import * as artistActions from './../../actions/artist';
import * as paymentActions from './../../actions/payment';

import { strings, styles, colors } from '../../assets/values';
import { getStyle } from './style';

import history from './../../history';


class ArtistMerchandiseItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 1,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.readArtistMerchandise(this.props.artist.id);
  }

  handlePurchase = () => {
    const data = {
      amount: this.state.amount,
      merchandise: this.props.artist.merchandise[this.props.match.params.merchandiseId],
    };
    this.props.setPurchaseForm(data);
    history.push(`/${this.props.match.params.url}/${strings.routes.artistMerchandisePurchase}`);
  }

  handleEdit = () => {
    const merchandiseItem = this.props.artist.merchandise[this.props.match.params.merchandiseId];
    this.props.setArtistMerchandiseForm(merchandiseItem);
    history.push(`/${this.props.match.params.url}/${strings.routes.artistMerchandiseItemEditor}`);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const style = getStyle();
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.artist.merchandise) return progress;
    const merchandiseItem = this.props.artist.merchandise[this.props.match.params.merchandiseId];
    const currentQuantity = merchandiseItem.quantity - merchandiseItem.sold;
    return (
      <div style={style.wrapper}>
        <Row>
          <Col lg={3}/>
          <Col lg={7}>
            <div style={style.rightContainer}>
              <Card>
                {this.props.permission &&
                  <CardActions>
                    <Button
                      onClick={this.handleEdit}
                    >
                      {strings.edit}
                    </Button>
                  </CardActions>}
                <CardContent>
                  <div style={{ fontSize: styles.fontSize.xl, padding: 4 }}>
                    {merchandiseItem.name}
                  </div>
                  <div style={{ fontSize: styles.fontSize.lg, color: colors.textBlack80, padding: 4 }}>
                    {merchandiseItem.description_inline}
                  </div>
                </CardContent>
                <Row>
                  <Col md={6}>
                    <CardContent>
                      <ImageContainer
                        url={merchandiseItem.image} raw
                        width="100%" height={200}
                      />
                    </CardContent>
                  </Col>
                  <Col md={6}>
                    <CardContent
                      style={{ height: 200 }}
                    >
                      <div
                        style={{ fontSize: styles.fontSize.lg, color: colors.textBlack }}
                      >
                        {numberWithCommas(merchandiseItem.price)} 원
                      </div>
                      <div
                        style={{ fontSize: styles.fontSize.md, color: colors.textBlack80 }}
                      >
                        <div>배송비용 {numberWithCommas(merchandiseItem.shipping_price)} 원</div>
                        <div style={{ marginTop: 8 }}>판매수량 {merchandiseItem.quantity}개 ({currentQuantity}개 남음)</div>
                      </div>
                      <TextField
                        name="amount"
                        label="구매수량"
                        disabled={currentQuantity < 1}
                        value={this.state.amount}
                        onChange={this.handleChange}
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={currentQuantity < 1}
                        onClick={this.handlePurchase}
                        style={{ marginTop: 16, display: 'block', width: 'calc(100% - 32px)' }}
                      >
                        {currentQuantity > 0 ? strings.purchase : '매진되었습니다'}
                      </Button>
                    </CardContent>
                  </Col>
                </Row>
                <div
                  style={{ padding: 16 }}
                >
                  <div className="artist-text-content" dangerouslySetInnerHTML={{ __html: merchandiseItem.description }}/>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArtistMerchandiseItem.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  permission: PropTypes.bool.isRequired,
  readArtistMerchandise: PropTypes.func.isRequired,
  setArtistMerchandiseForm: PropTypes.func.isRequired,
  setPurchaseForm: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    artist: state.artist.data[state.artist.active],
    permission: Permission.checkArtist(state.artist.data[state.artist.active], state.user.data),
    merchandiseForm: state.artist.merchandiseForm,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...artistActions,
    ...paymentActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistMerchandiseItem);
