import { presets, strings } from './../../assets/values';

export const validateNumber = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (presets.regex.number.test(value)) {
    return strings.onlyNumber;
  }
  return null;
};

const rrnValidator = (rrn) => {
  if (rrn.length !== 13) return false;
  let sum = 0;
  for (let i = 0; i < rrn.length - 1; i += 1) {
    const c = rrn[i];
    sum += parseInt(c, 10) * ((i % 8) + 2);
  }
  if (!((11 - (sum % 11)) % 10 === parseInt(rrn[rrn.length - 1], 10))) return false;
  return true;
};

const brnValidator = (brn) => {
  console.log(brn);
  // FIXME: 예외가 있는 것 같다. 펑크마녀님 사업자등록번호가 통과되지 않음.
  // if (brn.length !== 10) return false;
  // const n = [1, 3, 7, 1, 3, 7, 1, 3, 5, 1];
  // let sum = 0;
  // for (let i = 0; i < brn.length - 1; i += 1) {
  //   const c = brn[i];
  //   sum += parseInt(c, 10) * n[i];
  // }
  // sum += Math.floor((brn[brn.length - 1] * 5) / 10);

  // if (!(10 - (sum % 10) === parseInt(brn[brn.length - 1], 10))) return false;
  return true;
};

export const validateAccountHolder = (value) => {
  if (!value) {
    return strings.invalidError;
  }
  return null;
};

export const validateAccountNumber = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (presets.regex.number.test(value)) {
    return strings.onlyNumber;
  }
  return null;
};

export const validateCardBirthDate = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (presets.regex.number.test(value)) {
    return strings.onlyNumber;
  } else if (value.length !== 6) {
    return strings.cardBirthDateLength;
  }
  return null;
};

export const validateCardNumber = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (presets.regex.number.test(value)) {
    return strings.onlyNumber;
  } else if (value.length > 16) {
    return strings.tooLong;
  }
  return null;
};

export const validateCardPassword = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (presets.regex.number.test(value)) {
    return strings.onlyNumber;
  } else if (value.length !== 2) {
    return strings.cardPasswordLength;
  }
  return null;
};

export const validateContact = (value) => {
  if (presets.regex.number.test(value)) {
    return strings.onlyNumber;
  }
  return null;
};

export const validateContactEmail = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (!presets.regex.email.test(value)) {
    return strings.onlyEmail;
  }
  return null;
};

export const validateContactPhone = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (presets.regex.number.test(value)) {
    return strings.onlyNumber;
  }
  return null;
};

export const validateDescriptionInline = (value) => {
  if (!value) {
    return strings.invalidError;
  }
  return null;
};

export const validateEmail = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (!presets.regex.email.test(value)) {
    return strings.onlyEmail;
  }
  return null;
};

export const validateGenreDatail = (value) => {
  if (!value) {
    return strings.invalidError;
  }
  return null;
};

export const validateName = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (value.length > 20) {
    return strings.tooLong;
  }
  return null;
};

export const validateNickName = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (value.length > 20) {
    return strings.tooLong;
  }
  return null;
};

export const validatePassword = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (value.length < 8) {
    return strings.tooShort;
  }
  return null;
};

export const validatePasswordCheck = (value, compareValue) => {
  if (!value) {
    return strings.invalidError;
  } else if (value !== compareValue) {
    return strings.passwordCheckError;
  }
  return null;
};

export const validateResidentRegistrationNumber = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (presets.regex.number.test(value)) {
    return strings.onlyNumber;
  } else if (!rrnValidator(value)) {
    return strings.rrnValidationError;
  }
  return null;
};

export const validateBusinessRegistrationNumber = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (presets.regex.number.test(value)) {
    return strings.onlyNumber;
  } else if (!brnValidator(value)) {
    return strings.brnValidationError;
  }
  return null;
};

export const validateBusinessRegistrationName = (value) => {
  if (!value) {
    return strings.invalidError;
  }
  return null;
};

export const validateTitle = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (value.length > 100) {
    return strings.tooLong;
  }
  return null;
};

export const validateUrlArtist = (value) => {
  if (!value) {
    return strings.invalidError;
  } else if (presets.regex.english.test(value)) {
    return strings.onlyEnglish;
  }
  return null;
};

export const validateContactAddress = (value) => {
  if (!value) {
    return strings.invalidError;
  }
  return null;
};

export const validateContactBody = (value) => {
  if (!value) {
    return strings.invalidError;
  }
  return null;
};
