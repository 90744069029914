import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { useFormik } from 'formik';

import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import * as artistActions from './../../actions/artist';

import { TextEditor } from './../../containers';
import { Permission } from './../Utils/PermissonUtil';

import { strings } from './../../assets/values';
import * as Validator from './../Utils/Validator';
import history from './../../history';


const validate = (values) => {
  const errors = {};
  const titleError = Validator.validateTitle(values.title);
  if (titleError) errors.title = titleError;
  return errors;
};

const CheerEditor = (props) => {
  const onSubmit = (data) => {
    const payload = {
      title: data.title,
      body: props.content,
      artist: props.artistId,
      is_secret: data.is_secret,
    };
    let submitAction;
    let redirectUrl;
    if (props.editing && props.artistId) {
      submitAction = props.updateArtistCheer(payload, props.initialValues.id);
      redirectUrl = `/${props.match.params.url}/${strings.routes.artistCheers}/${props.initialValues.id}/`;
    } else if (props.editing && !props.artistId) {
      submitAction = props.updateCheerPost(payload, props.initialValues.id);
      redirectUrl = `${strings.routes.cheers}/${props.initialValues.id}/`;
    } else if (props.artistId) {
      submitAction = props.createArtistCheer(payload, props.user.nickname);
    } else if (!props.artistId) {
      submitAction = props.createCheerPost(payload, props.user.nickname);
    }
    return submitAction.then((response) => {
      if (response.action.payload.ok) {
        toastr.success(strings.success, strings.cheerSuccess);
        props.onDismiss();
        if (redirectUrl) history.push(redirectUrl);
        if (props.onDismiss) props.onDismiss();
      } else {
        toastr.error(strings.fail, strings.postFail);
      }
    });
  };

  const onCancel = () => {
    props.onDismiss();
  };

  const formik = useFormik({
    initialValues: {
      title: props.initialValues.title,
      is_secret: props.initialValues.is_secret,
    },
    onSubmit: values => onSubmit(values),
    validate: values => validate(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <CardContent>
        <TextField
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          label="제목"
          margin="normal"
          fullWidth
        />
        {props.artistId &&
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.is_secret}
              onChange={formik.handleChange}
              name="is_secret"
              disabled={
                !Permission.checkSupporter({ id: props.artistId }, props.user) ||
                !Permission.checkArtist({ id: props.artistId }, props.user)
              }
            />
          }
          label="후원자 커뮤니티에 올리기"
        />
        }
      </CardContent>
      <CardContent>
        <TextEditor initialContent={props.initialValues.body}/>
      </CardContent>
      <CardActions>
        <Button
          type="button"
          variant="outlined"
          size="large"
          onClick={(event) => { event.preventDefault(); onCancel(); }}
        >
          {strings.cancel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          disabled={formik.isSubmitting}
        >
          {strings.confirm}
        </Button>
      </CardActions>
    </form>
  );
};

CheerEditor.propTypes = {
  user: PropTypes.object,
  artistId: PropTypes.number,
  content: PropTypes.string,
  initialValues: PropTypes.object,
  editing: PropTypes.bool,
  finishInitialize: PropTypes.func.isRequired,
  createArtistCheer: PropTypes.func.isRequired,
  readArtistCheers: PropTypes.func.isRequired,
  readArtistCheer: PropTypes.func.isRequired,
  readCheerPost: PropTypes.func.isRequired,
  updateArtistCheer: PropTypes.func.isRequired,
  createCheerPost: PropTypes.func.isRequired,
  updateCheerPost: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  match: PropTypes.object.isRequired,
};

CheerEditor.defaultProps = {
  artistId: null,
  editing: false,
  onDismiss: null,
  initialValues: {
    body: '', title: '', post_tags: '', is_secret: false
  },
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    artistId: state.artist.active,
    content: state.editor.content,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CheerEditor);
