import {
  SET_ACTIVE_NAVIGATOR_PATH,
  SET_ACTIVE_TAB_PATH,
  OPEN_NAVIGATOR_DRAWER, CLOSE_NAVIGATOR_DRAWER, OPEN_NAVIGATOR_POPOVER, CLOSE_NAVIGATOR_POPOVER,
  SET_NAVIGATOR_POPOVER_ANCHOR,
} from './actionTypes';


export function setActiveNavigatorPath(data) {
  return {
    type: SET_ACTIVE_NAVIGATOR_PATH,
    payload: data,
  };
}

export function setActiveTabPath(data) {
  return {
    type: SET_ACTIVE_TAB_PATH,
    payload: data,
  };
}

export function openNavigatorDrawer() {
  return {
    type: OPEN_NAVIGATOR_DRAWER,
  };
}

export function closeNavigatorDrawer() {
  return {
    type: CLOSE_NAVIGATOR_DRAWER,
  };
}

export function openNavigatorPopover() {
  return {
    type: OPEN_NAVIGATOR_POPOVER,
  };
}

export function closeNavigatorPopover() {
  return {
    type: CLOSE_NAVIGATOR_POPOVER,
  };
}

export function setNavigatorPopoverAnchor(element) {
  return {
    type: SET_NAVIGATOR_POPOVER_ANCHOR,
    payload: element,
  };
}
