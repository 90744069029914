import { colors, styles } from './../../assets/values';

export const getStyle = (stylesToOverride = {}) => Object.assign({
  card: {
    paddingLeft: 'auto',
    paddingRight: 'auto',
    paddingTop: 30,
    paddingBottom: 30,
    title: {
      fontWeight: styles.fontWeight.Medium,
      fontSize: styles.fontSize.xl,
      lineHeight: 1.0,
      color: colors.textBlack,
      marginBottom: 20,
      textAlign: 'center',
    },
    subtitle: {
      textAlign: 'center',
      color: colors.textBlack80,
    },
    inner: {
      width: 600,
      maxWidth: '100%',
      marginTop: 30,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 16,
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  content: {
    lineHeight: 1.7,
  },
  dt: {
    fontWeight: styles.fontWeight.Bold,
  },
  contacts: {
    address: {
      width: 300,
      maxWidth: '100%',
      marginTop: -8,
      float: 'left',
    },
    submit: {
      float: 'right',
      marginTop: 8,
      marginRight: 0,
    },
    body: {
      clear: 'both',
      marginTop: -16,
      textarea: {
        minHeight: 120,
      },
    },
  },
}, stylesToOverride);
