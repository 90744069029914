import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import history from './../../history';

class Placeholder extends React.Component {
  render() {
    return (
      <Card>
        <CardHeader title={history.location}/>
        <CardContent>Implement this page</CardContent>
        {this.props.children}
      </Card>
    );
  }
}

export default Placeholder;
