import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import WebIcon from '@material-ui/icons/Web';

import { ContentWrapper } from './../../components';

import { colors, strings, styles } from './../../assets/values';


const containerWidth = 900;
const style = {
  card: {
    paddingLeft: `${(60 / containerWidth) * 100}%`,
    paddingRight: `${(60 / containerWidth) * 100}%`,
    paddingTop: 30,
    paddingBottom: 30,
    container: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    title: {
      fontWeight: styles.fontWeight.Medium,
      fontSize: styles.fontSize.xl,
      lineHeight: 1.0,
      color: colors.textBlack,
      marginBottom: 20,
      textAlign: 'center',
    },
    subtitle: {
      textAlign: 'center',
      color: colors.textBlack80,
    },
    construction: {
      width: 160,
      height: 160,
      padding: 0,
      wrapper: {
        width: 160,
        marginTop: 30,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      icon: {
        width: 160,
        height: 160,
      },
    },
  },
};

class UnderConstruction extends React.Component {
  render() {
    const Construction = props => (
      <div style={style.card.container}>
        <div style={style.card.title}>{props.title}</div>
        <div style={style.card.subtitle}>{strings.underConstruction}</div>
        <div style={style.card.construction.wrapper}>
          <IconButton style={style.card.construction}><WebIcon/></IconButton>
        </div>
      </div>
    );
    return (
      <div>
        { this.props.standAlone ?
          <ContentWrapper cardStyle={style.card}>
            <Construction title={this.props.title}/>
          </ContentWrapper>
          : <Construction title={this.props.title}/>
        }
      </div>
    );
  }
}

UnderConstruction.propTypes = {
  title: PropTypes.string.isRequired,
  standAlone: PropTypes.bool,
};

UnderConstruction.defaultProps = {
  standAlone: false,
};

export default UnderConstruction;
