import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import { strings } from './../../assets/values';


class DashboardContent extends React.Component {
  render() {
    return (
      <Card style={this.props.style}>
        <CardHeader title={this.props.title} style={this.props.titleStyle}/>
        {this.props.children}
      </Card>
    );
  }
}

DashboardContent.defaultProps = {
  title: strings.defaultTitle,
};

DashboardContent.propTypes = {
  style: PropTypes.object,
  titleStyle: PropTypes.object,
  title: PropTypes.string,
};

export default DashboardContent;
