/* Application */
export const appName = '삼천원';
export const appDomain = '3000won.com';
export const appDescription = '삼천원은 아티스트와 팬이 직접적으로 창작물을 교류하고, 소통하는 공간입니다.';
export const appUrl = 'https://3000won.com';
export const facebookAppId = '1073659386010459';
export const officialFacebookUrl = 'https://www.facebook.com/threethousandswon';
export const officialInstagramUrl = 'https://www.instagram.com/art3000won';
export const officialTwitterUrl = 'https://twitter.com/art3000won';
export const kakaoChatUrl = 'http://pf.kakao.com/_xnCxnNT/chat';
export const logoFull = '3000BI_int%1Droshort.jpg';
export const s3 = {
  domain: 'https://s3-ap-northeast-1.amazonaws.com',
  bucket: 'threethousandwon-tokyo/media',
};


/* Artists */
export const region = '지역';
export const category = '카테고리';
export const filter = '필터';
export const emptySearchResult = '검색 결과가 없습니다';
export const searchArtists = '아티스트 찾아보기';
export const artistsOrdering = {
  supporterDescending: '후원하는 사람 많은 순',
  createdDescending: '최신 등록 순',
  createdAscending: '오래된 순',
  latestPost: '최신 포스트 순',
};

/* Artist */
export const edit = '수정하기';
export const change = '변경하기';
export const confirm = '확인';
export const cancel = '취소';
export const previous = '이전';
export const next = '다음';
export const editArtistProfile = '아티스트 프로필 수정';
export const coverImage = '커버 이미지';
export const profileImage = '프로필 이미지';
export const artistIntro = '소개';
export const artistIntroduction = '소개';
export const artistPosts = '포스트';
export const artistPostsSecondary = '둘째 포스트';
export const artistPostTags = '태그';
export const artistSchedules = '일정';
export const artistCheers = '커뮤니티';
export const artistRewards = '리워드';
export const artistFunding = '후원';
export const artistMerchandise = '상품';
export const limitPeople = '인원 제한';
export const uploadProfileImage = '프로필 이미지 업로드';
export const createSupport = '후원하기';
export const encourageSupport = '후원하고 더 많은 포스트를 읽어보세요!';
export const encourageSupportButton1 = '후원하고';
export const encourageSupportButton2 = '더 많은 포스트를 읽어보세요!';
export const encourageSupportButtonForCommunity = '커뮤니티에 글을 써보세요!';
export const readAllPosts = '모든 포스트 보기';
export const nowSupporting = '후원하는 중';
export const publicPosts = '일반포스트';
export const secretPosts = '시크릿포스트';
export const isSecretPosts = '후원자를 위한 컨텐츠. 후원하는 사람만이 볼 수 있습니다.';
export const isTimeLimited = '기존 후원자를 위한 컨텐츠. 이 포스트가 작성된 이후에 후원하기 시작한 사람은 이 글을 볼 수 없습니다.';
export const postReply = '댓글';
export const postOpenReply = '댓글 보기';
export const postCloseReply = '댓글 숨기기';
export const writePost = '포스트 작성하기';
export const writePostInfo = '포스트를 작성하면 후원하는 사람과 팔로우하는 사람들에게 메일로 알려줍니다';
export const title = '제목';
export const close = '닫기';
export const submitCheer = '응원 메세지 보내기';
export const writeCheer = '응원 메세지 작성하기';
export const shareArtist = '아티스트 알리기';
export const shareWithFacebook = '페이스북으로 공유하기';
export const shareWithTwitter = '트위터로 공유하기';
export const postSuccess = '포스트를 등록했습니다';
export const postFail = '포스트를 등록하지 못했습니다';
export const cheerSuccess = '포스트를 등록했습니다';
export const artistName = '아티스트 이름';
export const month = '월';
export const won = '원';
export const noLimit = '제한없음';
export const supportLimit = '명 제한';
export const supportCount = '명이 후원하고 있습니다';
export const supportCountText = '명';
export const soldOut = '마감됨!';
export const selectedReward = '선택한 리워드';
export const selectOtherReward = '다른 리워드 선택';
export const paymentMethod = '결제수단';
export const cardNumber = '카드번호';
export const cardCompany = '카드사';
export const cardNotExist = '등록된 카드가 없습니다';
export const addCard = '카드등록';
export const paymentAmount = '결제금액';
export const paymentAmountPerMonth = '월 결제금액';
export const pay = '결제하기';
export const usageOfInformation = '입력하신 정보는 오직 아티스트의 리워드 전달을 위해서만 활용됩니다.';
export const paymentDay = '매월 결제일';
export const perMonth = '매월';
export const day = '일';
export const support = {
  create: {
    success: '결제 성공',
    message: '아티스트를 후원해주셔서 감사합니다!',
  },
  update: {
    success: '변경 예약 성공',
    message: '다음 결제일부터 변경된 리워드가 적용됩니다.',
  },
};
export const supporterCount = (current, max) => {
  let message = '';
  if (max <= 0) message = `${current}${supportCount}`;
  else if (max > current) message = `${max}${supportLimit}, ${current}${supportCount}`;
  else message = `${max}${supportLimit}, ${soldOut}`;
  return message;
};

export const notFound = '찾으시는 페이지가 존재하지 않습니다';
export const forbidden = '페이지에 접근할 권한이 없습니다';

/* Auth */
export const or = '또는';
export const signIn = '로그인';
export const signUp = '회원가입';
export const signOut = '로그아웃';
export const signUpVerb = '가입하기';
export const email = '이메일';
export const password = '비밀번호';
export const passwordCheck = `${password} 확인`;
export const passwordCheckError = '비밀번호가 같지 않습니다';
export const agreeToTerms = '이용약관에 동의합니다';
export const agreeToPrivacyPolicy = '개인정보 수집/이용에 동의합니다';
export const navigateToTerms = '이용약관 전문보기';
export const navigateToPaymentTerms = '결제약관 전문보기';
export const navigateToPrivacyPolicy = '개인정보처리방침 전문보기';
export const signInFail = '아이디 또는 비밀번호가 잘못되었습니다';
export const signInSuccess = '성공적으로 로그인 하였습니다';
export const signUpSuccess = '회원가입이 완료되었습니다';
export const signOutSuccess = '성공적으로 로그아웃 되었습니다.';
export const signInRequired = '로그인이 필요합니다';

export const twitter = '트위터';
export const facebook = '페이스북';
export const instagram = '인스타그램';
export const signInWithTwitter = `${twitter}로 ${signIn}`;
export const signInWithFacebook = `${facebook}으로 ${signIn}`;
export const signUpWithTwitter = `${twitter}로 ${signUpVerb}`;
export const signUpWithFacebook = `${facebook}으로 ${signUpVerb}`;

export const forgotPassword = '비밀번호를 잊어버리셨나요?';
export const resetPassword = '임시 비밀번호 발급';
export const sendTempPassword = '임시 비밀번호가 이메일로 전송되었습니다';
export const notValidEmail = '이메일 주소가 존재하지 않습니다';
export const goToSignIn = '로그인으로 돌아가기';

/* Artist New */
export const recruit = 'recruit';
export const artistGuideline = '아티스트 가이드라인';
export const artistGuidelineLink = 'https://drive.google.com/open?id=0B_3_DZzX6AM6bkdmOHlZeW0yYTA';
export const intro3000 = '삼천원 소개';
export const intro3000Link = 'https://drive.google.com/open?id=0B6VtA4h4Se3dRXAyMGtiV0ZlUUk';
export const rewardPrice = '리워드 가격';
export const typeNumber = '숫자만 입력해주세요';
export const artistsStart = {
  title: '아티스트 등록하기',
  body: [
    {
      title: '누가 등록 할 수 있나요',
      body: '문화예술의 생산자라면 누구라도 가능합니다. 인디밴드, 버스커, 싱어송라이터, 국악 악단, 일렉 프로듀서, DJ, 문화공간, 보드게임 디자이너, 라노벨 작가, 웹툰 작가, 일러스트레이터, 게임 제작팀, 배우, 디자이너, 동인게임제작팀, 수공예 제작자, 포토그래퍼, BJ, 콜라보 프로젝트 등 개인과 단체를 막론하고 문화예술의 생산자로써 소비자에게 가치를 전달하는 모두 등록신청이 가능합니다.',
    },
    {
      title: '무엇을 할 수 있나요',
      body: '플랫폼 삼천원은 문화예술 생산자의 안정적인 수입원을 마련합니다. 문화예술 생산자는 삼천원 플랫폼을 통해 자신에 대해 소개하고, 영상/사진/이야기를 게시할 수 있습니다. 소비자는 월단위 정기결제를 통해 이러한 컨텐츠들을 구독합니다. 이러한 정기결제는 대부분이 생산자에게 전달되어 안정적이고 지속적인 수입원이 됩니다.',
    },
    {
      title: '어떻게 등록하나요',
      body: '삼천원에 아티스트로 등록하는 과정은 아주 간단하며, 별도의 심사과정은 없습니다.',
      how: [
        {
          title: '회원가입',
          body: '아티스트로 등록하기 위해서는 먼저 플랫폼 삼천원에 회원가입을 해주셔야합니다. 회원가입시 어떠한 개인정보도 요구하지 않습니다.',
        },
        {
          title: '페이지 생성',
          body: '아래 "아티스트 등록하기"버튼을 눌러서 연락처, 사용할 페이지 주소 등 간단한 정보를 입력합니다.',
        },
        {
          title: '페이지 공개',
          body: '아티스트 페이지를 꾸미신 후, 대시보드에서 정산정보를 입력한 다음 페이지를 공개상태로 전환합니다.',
        },
      ],
    },
  ],
};

/* Artist New Form */
export const add = '추가하기';
export const artistRewardAmount = '리워드 가격';
export const artistRewardBody = '리워드 내용';
export const artistRewardPeopleLimit = '리워드 인원 인원제한';
export const no = 'No';
export const remove = '삭제하기';
export const contactWithoutHyphen = '-없이 입력해주세요';
export const genre = '세부장르';
export const simpleDescription = '한줄 소개';
export const emailError = '이메일을 입력해주세요!';
export const phoneNumberTooShort = '너무 짧아요!';
export const extraInformation = 'SNS, 블로그';
export const extraInformationHint = 'SNS나 블로그 등의 개인 페이지 주소를 적어주세요';
export const artistUrlHint = '30자 이내의 알파벳으로 적어주세요.';

/* Editor */
export const imageUploadInfo = '이미지는 5MB까지 업로드하실 수 있으며, 1MB를 초과하는 이미지는 자동으로 압축됩니다.';
export const imageSizeLimit = '이미지는 용량 5MB 이하로 올려주세요';

/* Delete */
export const wantDelete = '삭제하시겠습니까?';

/* Merchandise */
export const merchandise = '상품';
export const merchandisePrice = '가격';
export const purchase = '구매하기';
export const createMerchandise = '상품 추가하기';
export const shippingPrice = '배송비용';
export const merchandiseQuantity = '수량';
export const merchandiseInformation = '상품정보';
export const buyerName = '구매자 이름';
export const buyerInformation = '구매자 정보';
export const buyerContact = '연락처';
export const buyerEmail = '이메일';
export const buyerMemo = '요청사항';
export const purchaseHistory = '구매 내역';
export const orderDate = '구매일';
export const noMerchandise = '판매중인 상품이 없습니다';
export const purchaseAmount = '갯수';

/* Payment */
export const buyerNameHint = '예매하시는 분 성함을 입력해주세요';
export const buyerEmailHint = '이메일을 입력해주세요';
export const buyerContactHint = '휴대전화 번호를 입력해주세요';
export const cardNumberHint = '― 없이 입력해주세요';
export const cardExpirationYear = '유효기간 년';
export const cardExpirationYearHint = 'YY';
export const cardExpirationMonth = '유효기간 월';
export const cardExpirationMonthHint = 'MM';
export const cardPassword = '비밀번호 앞 두자리';
export const cardPasswordHint = '••';
export const birthDate = '생년월일';
export const birthDateHint = 'YYMMDD';
export const agreeToPaymentTerms = '결제약관에 동의합니다';
export const fail = '실패';
export const success = '성공';
export const addCardFail = '카드등록에 실패했습니다';
export const createSupportFail = '리워드 결제에 실패했습니다';
export const updateSupportFail = '리워드 변경에 실패했습니다';
export const gsPointUse = 'GS&포인트 사용';
export const gsPointUseHint = 'GS&포인트는 각 아티스트의 첫 결제에서 결제금액의 절반까지 사용할수 있습니다';
export const gsWebId = '웹 아이디';
export const gsWebPassword = '비밀번호';
export const searchGSPoint = '포인트 조회';
export const gsRestPoint = '잔여포인트';
export const gsUsingPoint = '사용할 포인트';
export const paymentInformation = '삼천원은 보안통신을 사용하여 서버와의 연결을 안전하게 암호화하고 있습니다. 또한 카드정보는 결제대행사에서 관리하고 있으며, 서버에 보관되지 않습니다.';
export const paymentSupport = '결제관련 문의는 삼천원 고객지원팀(support@3000won.com)으로 연락해주세요!';

/* MyPage */
export const submit = '확인';
export const myPage = '내 정보';
export const myProfile = '계정 정보';
export const myArtists = '후원하는 아티스트';
export const myPayments = '결제 내역';
export const myPurchases = '구매 내역';
export const changeReward = '리워드 변경';
export const cancelSupport = '중단하기';
export const supportCanceled = '다음 결제일부터 중단됩니다';
export const confirmCancelSupport = '정말 후원하기를 중단하시겠습니까? 다음 결제일부터 결제가 중단됩니다.';
export const paymentFailedRecently = '최근 결제가 실패했습니다. 등록된 카드를 확인해주세요.';
export const emptyMyArtists = '아직 후원하고 있는 아티스트가 없습니다. 어떤 아티스트가 있는지 둘러보세요!';

/* Additional User Information */
export const notAssigned = '미입력';
export const changePassword = '비밀번호 변경';
export const oldPassword = '현재 비밀번호';
export const newPassword = '새 비밀번호';
export const newPasswordCheck = '새 비밀번호 확인';
export const editing = '수정하기';
export const editingInfo = '정보 수정하기';
export const name = '이름';
export const nickname = '닉네임';
export const contact = '연락처';
export const searchAddress = '주소 검색';
export const address = '주소';
export const addressExtra = '나머지 주소';
export const postcode = '우편번호';
export const gender = {
  label: '성별',
  male: '남성',
  female: '여성',
  others: '기타',
};
export const accessibility = {
  label: '개인정보 공개',
  inPublic: '공개',
  inPrivate: '비공개',
  inform: '공개를 선택할 경우, 후원하는 아티스트에게 리워드 전달을 위한 연락처와 주소 정보를 제공합니다.',
};
export const passwordChanged = '비밀번호가 변경되었습니다';


/* Dashboard */
export const dashboard = '대시보드';
export const dashboardCheckout = '정산 내역';
export const dashboardOverview = '개요';
export const dashboardProfile = '아티스트 정보';
export const dashboardSupporters = '후원하는 사람들';
export const dashboardPurchases = '상품판매 내역';
export const checkoutInformation = '정산정보';
export const pageViewsAnalytics = '방문자 통계';
export const pageViewsOf30Days = '30일간 방문자수';
export const userGenderRatio = '방문자 성비';
export const lastCheckout = '지난 정산정보';
export const download = '다운로드';
export const downloadExel = '엑셀파일로 다운로드';
export const balanceInformation = '정산 정보';
export const balancePayment = '금액';
export const supporters = '후원하는 사람';
export const supporterInformation = '후원하는 사람 정보';
export const sumOfPayment = '누적 금액';
export const sumOfMonth = '누적 개월';
export const detail = '세부정보';
export const viewDetail = '자세히 보기';
export const supportersScope = {
  active: '후원하는 중',
  all: '중단된 사람도 보기',
  inactive: '중단된 사람만 보기',
};
export const supportersOrdering = {
  countAscending: '누적기간 짧은 순',
  countDescending: '누적기간 많은 순',
  createdAtAscending: '오래된 순',
  createdAtDescending: '신규생성 순',
  sumDescending: '누적금액 많은 순',
};

/* Additional Setting Information */
export const artistLink = '내 아티스트 페이지로 바로가기';
export const artistUrl = '페이지 주소';
export const pagePublicAccess = {
  label: '페이지 공개상태',
  approved: '공개중',
  pending: '비공개',
};
export const postStyle = {
  label: '포스트 스타일',
  feed: '피드형',
  board: '게시판형',
};
export const contactPhone = '휴대전화 연락처';
export const contactEmail = '이메일 연락처';
export const accountBank = '은행';
export const accountNumber = '계좌번호';
export const accountHolder = '계좌주';
export const residentRegistrationNumber = '주민등록번호';
export const businessRegistrationNumber = '사업자등록번호';
export const rrnValidationError = '주민등록번호를 확인해주세요';
export const brnValidationError = '사업자등록번호를 확인해주세요';
export const businessRegistrationName = '상호';
export const informationForCheckout = '정산을 위한 정보 입력';
export const informationForCheckoutForm = 'https://goo.gl/forms/qeGTWwvM367fywNC3';
export const businessRegistration = {
  label: '사업자등록 여부',
  registered: '사업자',
  freelancer: '프리랜서',
};
export const edittingArtistProfileSuccess = '아티스트 정보를 수정했습니다';

/* Supports */
export const supports = '고객 지원';
export const contactsIntro = '궁금하신 점이나 건의하실 점을 아래에 자유롭게 적어서 보내주세요!';
export const contactAddress = '연락처';
export const contactAddressHelper = '(휴대전화 번호 or 이메일 주소)';
export const contactBody = '문의사항';
export const send = '보내기';
export const underConstruction = '현재 개발중인 페이지입니다 빠른 시일 내에 서비스하도록 하겠습니다. 감사합니다.';
export const artist = '아티스트';
export const fan = '후원하는 사람';
export const common = '일반';
export const follower = '팔로워';
export const nowFollowing = '팔로우중';
export const follow = '팔로우하기';

export const indexIntroductionTitle = '문화예술 직접소비 플랫폼 삼천원';
export const indexIntroductionSubtitle = '삼천원은 지속가능한 덕질, 지속가능한 문화예술을 위한 정기 크라우드펀딩 플랫폼입니다.';
export const learnMore = '더 알아보기';

/* Validation Errors */
export const invalidError = '입력 해 주세요';
export const onlyNumber = '숫자만 입력 해 주세요';
export const onlyEmail = '이메일을 입력 해 주세요';
export const onlyEnglish = '영어만 입력 해 주세요';
export const tooLong = '너무 깁니다';
export const tooShort = '너무 짧습니다';
export const cardPasswordLength = '두자리를 입력 해 주세요';
export const cardBirthDateLength = '여섯자리를 입력 해 주세요';

/* Header */
export const header = {
  main: '메인페이지',
  about: '소개',
  posts: '읽어보기',
  community: '커뮤니티',
  artists: '둘러보기',
  writePost: '포스트 쓰기',
  myArtistPage: '내 페이지',
  artistsStart: '등록하기',
  search: searchArtists,
  signIn: signIn,
  signUp: signUp,
  signOut: signOut,
  myPage: myPage,
  dashboard: dashboard,
};

/* Footer */
export const representative = '삼천원';
export const termsOfUse = '이용약관';
export const privacyPolicy = '개인정보처리방침';
export const termsOfPayment = '전자금융거래 이용약관';
export const privacyOfPayment = '개인정보 제공 및 위탁안내';
export const identificationOfPayment = '고유식별정보 수집 및 이용안내';
export const termsOfBilling = '정기과금 이용약관';
export const notice = '공지사항';
export const announcement = '공지사항';
export const faq = '도움말';
export const contacts = '카카오톡 채널로 문의하기';
export const gspoint = 'GS&POINT 안내';
export const company = '주식회사 노페땅 | 대표 김민식 | 서울시 성동구 왕십리로2길 20, 1층 | 사업자등록 674-87-00398 | 통신판매업 2016-서울성동-00443 | support@3000won.com | ⓒ Nofetan Inc.';

/* Dummy */
export const dummy = {
  imageUrl: 'https://placehold.it/500x500?text=dummy',
};

/* Route */
export const routes = {
  app: '/',
  about: '/about',
  posts: '/posts',
  cheers: '/community',
  artists: '/artists',
  artistsStart: '/artists_start',
  artistsNew: '/artists_new',
  artistsSearch: '/artists_search',
  my: '/my',
  myProfile: 'profile',
  myArtists: 'artists',
  myPayments: 'payments',
  myPurchases: 'purchases',
  dashboard: '/dashboard',
  dashboardCheckout: 'checkout',
  dashboardOverview: 'overview',
  dashboardProfile: 'profile',
  dashboardSupporters: 'supporters',
  dashboardPurchases: 'purchases',
  support: '/support',
  supportTermsOfUse: 'terms_of_use',
  supportPrivacyPolicy: 'privacy_policy',
  supportTermsOfPayment: 'terms_of_payment',
  supportNotice: 'notice',
  supportFAQ: 'faq',
  supportGSPoint: 'gspoint',
  signIn: '/sign_in',
  signUp: '/sign_up',
  signOut: '/sign_out',
  resetPassword: '/reset_password',
  artistIntro: 'intro',
  artistPosts: 'posts',
  artistPostsSecondary: 'posts_secondary',
  artistPostsNew: 'posts_new',
  artistSchedules: 'schedules',
  artistCheers: 'cheers',
  artistRewards: 'rewards',
  artistMerchandise: 'merchandise',
  artistMerchandiseItemEditor: 'merchandise/edit',
  artistMerchandisePurchase: 'merchandise/purchase',
  artistPurchase: 'purchase',
  artistPurchaseSuccess: 'purchase_success',
  announcements: '/announcements',
  invitation: '/invitation',
};

/* etc */
export const defaultTitle = '기본타이틀';
