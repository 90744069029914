import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { strings, terms } from './../../assets/values';

import { getStyle } from './style';

class SupportTermsOfPayment extends React.Component {
  render() {
    const style = getStyle();
    const sectionsOfTerms = [
      {
        num: 1,
        title: strings.termsOfPayment,
        contents: terms.termsOfPayment,
      },
      {
        num: 2,
        title: strings.privacyOfPayment,
        contents: terms.privacyOfPayment,
      },
      {
        num: 3,
        title: strings.identificationOfPayment,
        contents: terms.identificationOfPayment,
      },
      {
        num: 4,
        title: strings.termsOfBilling,
        contents: terms.termsOfBilling,
      },
    ];
    return (
      <Card>
        <CardContent>
          {sectionsOfTerms.map(section =>
            <section key={section.num}>
              <div style={style.card.title}>{section.title}</div>
              <ul style={style.content}>
                {section.contents.map(term =>
                  <li key={term.title || 0}>
                    {term.title && <h3>{term.title}</h3>}
                    {term.subtitle && <p>{term.subtitle}</p>}
                    {term.contents &&
                      <ol>
                        {term.contents.map(content =>
                          <li key={content.body}>
                            {content.body}
                            {content.contents &&
                              <ol>
                                {content.contents.map(subterm =>
                                  <li key={subterm.body}>
                                    {subterm.body}
                                    {content.contents &&
                                      <ol>
                                        {content.contents.map(subsubterm =>
                                          <li key={subsubterm.body}>{subsubterm.body}</li>)}
                                      </ol>
                                    }
                                  </li>)}
                              </ol>
                            }
                          </li>)}
                      </ol>
                    }
                  </li>)}
              </ul>
            </section>)}
        </CardContent>
      </Card>
    );
  }
}

export default SupportTermsOfPayment;
