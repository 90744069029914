import {
  READ_PAYMENT_METHOD, CREATE_PAYMENT_METHOD, SEARCH_GS_POINT, INITIALIZE_GS_POINT, SET_GS_POINT,
  READ_SUPPORTS, CREATE_SUPPORT,
  CREATE_PURCHASE, READ_PURCHASES, UPDATE_PURCHASE, READ_PURCHASES_ARTIST,
  SUPPORT_ADD_UPDATE,
  SUPPORT_CANCEL_SUPPORT,
  READ_TRANSACTIONS,
  READ_SUPPORTS_ARTIST,
  SET_SUPPORT_FORM, SET_PURCHASE_FORM,
  START_EDITING_PAYMENT_METHOD_FORM,
  FINISH_EDITING_PAYMENT_METHOD_FORM,
  READ_MERCHANDISE, PURCHASE_MERCHANDISE,
} from './../actions/actionTypes';
import { fulfilled } from './helper';

const INITIAL_PAYMENT_STATE = {
  registeredCard: [],
  editing: false,
  transactions: {},
  supports: [],
  supportForm: {},
  supporters: {},
  gs_point: {},
  merchandise: {},
  purchases: {},
  purchaseForm: {},
  purchasesArtist: {},
};

export default function (state = INITIAL_PAYMENT_STATE, action) {
  switch (action.type) {
    case fulfilled(READ_PAYMENT_METHOD):
      return {
        ...state,
        registeredCard: action.payload.data,
      };
    case fulfilled(CREATE_PAYMENT_METHOD):
      if (action.payload.ok) {
        return {
          ...state,
          registeredCard: action.payload.data,
        };
      }
      return state;
    case fulfilled(SEARCH_GS_POINT):
      if (action.payload.ok) {
        return {
          ...state,
          gs_point: action.payload.data,
        };
      }
      return state;
    case INITIALIZE_GS_POINT:
      return {
        ...state,
        gs_point: {},
      };
    case SET_GS_POINT: {
      return {
        ...state,
        supportForm: { ...state.supportForm, ...action.payload },
      };
    }
    case fulfilled(READ_PURCHASES):
      return {
        ...state,
        purchases: action.payload.data,
      };
    case fulfilled(CREATE_PURCHASE):
      if (action.payload.ok) {
        return {
          ...state,
          purchaseForm: {
            ...state.purchaseForm,
            success: true,
          },
        };
      }
      return state;
    case fulfilled(UPDATE_PURCHASE):
      if (action.payload.ok) {
        return {
          ...state,
          purchaseForm: action.payload.data,
        };
      }
      return state;
    case fulfilled(READ_PURCHASES_ARTIST): {
      return {
        ...state,
        purchasesArtist: action.payload.data,
      };
    }
    case fulfilled(READ_SUPPORTS):
      return {
        ...state,
        supports: action.payload.data,
      };
    case fulfilled(CREATE_SUPPORT):
      if (action.payload.ok) {
        return {
          ...state,
          supportForm: action.payload.data,
        };
      }
      return state;
    case fulfilled(SUPPORT_ADD_UPDATE):
      return {
        ...state,
        supportForm: { ...action.payload.data, updating: true },
      };
    case SUPPORT_CANCEL_SUPPORT: // TODO : IMPLEMENT THIS
      return state;
    case fulfilled(READ_TRANSACTIONS):
      if (action.payload.data.results.length > 0) {
        return {
          ...state,
          transactions: action.payload.data,
        };
      }
      return state;
    case SET_SUPPORT_FORM: {
      return {
        ...state,
        supportForm: action.payload,
      };
    }
    case SET_PURCHASE_FORM: {
      return {
        ...state,
        purchaseForm: action.payload,
      };
    }
    case fulfilled(READ_SUPPORTS_ARTIST): {
      return {
        ...state,
        supporters: action.payload.data,
      };
    }
    case START_EDITING_PAYMENT_METHOD_FORM:
      return {
        ...state,
        editing: true,
      };
    case FINISH_EDITING_PAYMENT_METHOD_FORM:
      return {
        ...state,
        editing: false,
      };
    case fulfilled(READ_MERCHANDISE): {
      return {
        ...state,
        merchandise: {
          ...state.merchandise,
          [action.payload.data.id]: action.payload.data,
        },
      };
    }
    case fulfilled(PURCHASE_MERCHANDISE): {
      return {
        ...state,
        purchase: action.payload.data,
      };
    }
    default:
      return state;
  }
}
