import React from 'react';
import PropTypes from 'prop-types';

import { Visible } from 'react-grid-system';
import Card from '@material-ui/core/Card';

import { ImageContainer } from './../../components';

import { colors, styles } from './../../assets/values';


const style = {
  mobileCard: {
    width: '100%',
    height: 295,
    marginBottom: -1,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 25,
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'center',
  },
  card: {
    width: 295,
    maxWidth: '100%',
    height: 295,
    marginBottom: 5,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 25,
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'center',
    name: {
      marginTop: 10,
      fontWeight: styles.fontWeight.Medium,
      fontSize: styles.fontSize.lg,
    },
    tags: {
      marginTop: 10,
      tag: {
        fontSize: styles.fontSize.sm,
      },
    },
    description_inline: {
      marginTop: 10,
      fontSize: styles.fontSize.md,
      wordWrap: 'break-word',
      whiteSpace: 'pre-line',
    },
    supporters_count: {
      marginTop: 10,
      fontWeight: styles.fontWeight.Regular,
      fontSize: styles.fontSize.sm,
      bottom: 40,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    newpost: {
      padding: 8,
      display: 'flex',
      flexWrap: 'wrap',
      position: 'absolute',
      backgroundColor: colors.primaryRed,
      fontSize: styles.fontSize.md,
      color: colors.white,
      right: 0,
      bottom: 0,
    },
  },
};

class SlideCard extends React.Component {
  render() {
    return (
      <div>
        <Visible lg>
          <Card style={style.card} onClick={this.props.onClickCard}>
            <div>
              <ImageContainer url={this.props.imageUrl} width={250} height={250}/>
            </div>
          </Card>
        </Visible>
        <Visible xs sm md>
          <Card style={style.mobileCard} onClick={this.props.onClickCard}>
            <div>
              <ImageContainer url={this.props.imageUrl} width={250} height={250}/>
            </div>
          </Card>
        </Visible>
      </div>
    );
  }
}

SlideCard.propTypes = {
  onClickCard: PropTypes.func,
  imageUrl: PropTypes.string,
};

export default SlideCard;
