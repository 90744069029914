import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { useFormik } from 'formik';

import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ContentWrapper, FacebookSignIn } from './../../components';

import * as authActions from './../../actions/auth';

import logoSrc from './../../assets/images/logo.png';
import { strings, styles, colors } from './../../assets/values';
import * as Validator from './../Utils/Validator';
import { getStyle } from './style';

import history from './../../history';


const paths = strings.routes;

const validate = (values) => {
  const errors = {};
  const emailError = Validator.validateEmail(values.email);
  const nicknameError = Validator.validateNickName(values.nickname);
  const passwordError = Validator.validatePassword(values.password);
  const passwordCheckError = Validator.validatePasswordCheck(values.password_check, values.password);
  if (emailError) errors.email = emailError;
  if (nicknameError) errors.nickname = nicknameError;
  if (passwordError) errors.password = passwordError;
  if (passwordCheckError) errors.password_check = passwordCheckError;
  return errors;
};

const SignUp = (props) => {
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);

  useEffect(() => {
    if (props.accessToken) {
      history.goBack();
    }
  }, [props.accessToken]);

  const onSubmit = (data) => {
    if (!terms || !privacy) {
      return toastr.error(strings.fail, '모든 약관에 동의해주세요');
    }
    return props.signUp(data)
      .then((response) => {
        if (response.action.payload.ok) {
          toastr.success(strings.success, strings.signUpSuccess);
          history.push(strings.routes.signIn);
        } else toastr.error(strings.fail);
      });
  };

  const facebookResponse = (data) => {
    props.signInWithFacebook(data);
    history.goBack();
  };

  const handleCheck = (name, event) => {
    if (name === 'terms') {
      setTerms(event.target.checked);
    }
    if (name === 'privacy') {
      setPrivacy(event.target.checked);
    }
  };

  const style = getStyle();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      nickname: '',
      password_check: '',
    },
    onSubmit: values => onSubmit(values),
    validate: values => validate(values)
  });

  return (
    <ContentWrapper title={strings.signUp} width={420}>
      <Card style={style.card}>
        <img style={style.card.logo} src={logoSrc} alt={strings.appName}/>
        <FacebookSignIn appId={strings.facebookAppId} callback={facebookResponse} label={strings.signUpWithFacebook} type="button"/>
        <div style={style.card.hr.wrapper}>
          <span style={style.card.hr.text}>{strings.or}</span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            name="email"
            label={strings.email}
            onChange={formik.handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="nickname"
            label={strings.nickname}
            onChange={formik.handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="password"
            label={strings.password}
            type="password"
            onChange={formik.handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="password_check"
            label={strings.passwordCheck}
            type="password"
            onChange={formik.handleChange}
            fullWidth
            margin="normal"
          />
          <div style={{ marginTop: 30 }}>
            <FormControlLabel
              control={
                <Checkbox
                  style={style.checkbox}
                  color="primary"
                  checked={terms}
                  onChange={event => handleCheck('terms', event)}
                />
              }
              label={strings.agreeToTerms}
            />
            <Link
              href={`${paths.support}/${paths.supportTermsOfUse}`}
              to={`${paths.support}/${paths.supportTermsOfUse}`}
              target="_blank"
              style={style.navigateToTerms}
            >
              {strings.navigateToTerms}
            </Link>
            <div style={{ clear: 'both' }}/>
            <FormControlLabel
              control={
                <Checkbox
                  style={style.checkbox}
                  color="primary"
                  checked={privacy}
                  onChange={event => handleCheck('privacy', event)}
                />
              }
              label={strings.agreeToPrivacyPolicy}
            />
            <table
              style={{
                width: '100%',
                fontSize: styles.fontSize.sm,
                color: colors.textBlack80,
                padding: 2,
                borderSpacing: 2
              }}
            >
              <tbody>
                <tr>
                  <th>이용목적</th><td>본인확인, 공지사항 전달 및 부정이용 방지</td>
                </tr>
                <tr>
                  <th>수집항목</th><td>성명 또는 닉네임, 이메일</td>
                </tr>
                <tr>
                  <th>보유기간</th><td>회원탈퇴시 까지</td>
                </tr>
              </tbody>
            </table>
            <Link to={`${paths.support}/${paths.supportPrivacyPolicy}`} target="_blank" style={style.navigateToTerms}>
              {strings.navigateToPrivacyPolicy}
            </Link>
            <div style={{ clear: 'both' }}/>
          </div>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: 30 }}
            type="submit"
          >
            {strings.signUpVerb}
          </Button>
        </form>
      </Card>
    </ContentWrapper>
  );
};

SignUp.propTypes = {
  accessToken: PropTypes.string,
  signUp: PropTypes.func.isRequired,
  signInWithFacebook: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    accessToken: state.auth.accessToken,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...authActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
