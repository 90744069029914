import * as Sentry from '@sentry/react';

import {
  INITIALIZE_ARTIST_STATE,
  READ_ARTISTS, READ_MORE_ARTISTS, CREATE_ARTIST,
  GET_ARTIST_BY_URL,
  READ_ARTIST, UPDATE_ARTIST, SET_ARTISTS_ORDERING,
  READ_ARTIST_POST_TAGS,
  READ_ARTIST_POSTS, READ_MORE_ARTIST_POSTS, CREATE_ARTIST_POST, READ_ARTIST_POST, UPDATE_ARTIST_POST,
  READ_POSTS, READ_MORE_POSTS,
  READ_ARTIST_SCHEDULES, CREATE_ARTIST_SCHEDULE,
  READ_ARTIST_POST_REPLIES, CREATE_ARTIST_POST_REPLY,
  READ_ARTIST_CHEERS, CREATE_ARTIST_CHEER, READ_ARTIST_CHEER, UPDATE_ARTIST_CHEER, DELETE_ARTIST_CHEER,
  READ_CHEER_POSTS, DELETE_CHEER_POST, READ_NOTICED_CHEER_POSTS,
  READ_ARTIST_CHEER_REPLIES, CREATE_ARTIST_CHEER_REPLY,
  CREATE_CHEER_POST_LIKE, DELETE_CHEER_POST_LIKE,
  CREATE_ARTIST_CHEER_LIKE, DELETE_ARTIST_CHEER_LIKE,
  CREATE_ARTIST_REWARD, READ_ARTIST_REWARDS, UPDATE_ARTIST_REWARD, DELETE_ARTIST_REWARD,
  CREATE_ARTIST_MERCHANDISE, READ_ARTIST_MERCHANDISE, UPDATE_ARTIST_MERCHANDISE,
  READ_ARTIST_MERCHANDISE_DETAIL, SET_ARTIST_MERCHANDISE_FORM,
  UPLOAD_ARTIST_PROFILE_IMAGE, UPLOAD_ARTIST_COVER_IMAGE, UPLOAD_ARTIST_MERCHANDISE_IMAGE,
  READ_ARTISTS_RECENTLY_UPDATED, READ_ARTIST_RECENT_SCHEDULES,
  START_INITIALIZE, FINISH_INITIALIZE,
  START_CREATE_REWARD, FINISH_CREATE_REWARD, CREATE_CHEER_POST, READ_CHEER_POST, UPDATE_CHEER_POST,
  CREATE_ARTIST_POST_LIKE, DELETE_ARTIST_POST_LIKE, CLEAR_ARTIST_POSTS,
} from '../actions/actionTypes';
import { strings, presets } from './../assets/values';
import { pending, fulfilled } from './helper';

const INITIAL_ARTIST_STATE = {
  data: {},
  url2id: {},
  profileImage: {},
  coverImage: {},
  merchandiseFrom: {},
  initializing: false,
  fetching: false,
  creatingReward: false,
  searchField: '',
  responseStatus: 0,
  merchandiseImageUrl: '',
  ordering: presets.filterValues.artistsOrdering.supporterDescending,
  cheers: {},
  noticedCheers: {},
};

export default function (state = INITIAL_ARTIST_STATE, action) {
  const newData = {};
  switch (action.type) {
    case INITIALIZE_ARTIST_STATE:
      return INITIAL_ARTIST_STATE;
    case fulfilled(READ_ARTISTS):
      action.payload.data.results.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        data: newData,
        _nextArtists: action.payload.data.next ? action.payload.data.next.split('?')[1] : null,
        searchField: action.meta.search,
      };
    case fulfilled(READ_MORE_ARTISTS):
      action.payload.data.results.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        data: {
          ...state.data,
          ...newData,
        },
        _nextArtists: action.payload.data.next ? action.payload.data.next.split('?')[1] : null,
      };
    case SET_ARTISTS_ORDERING:
      return {
        ...state,
        ordering: action.payload,
      };
    case fulfilled(CREATE_ARTIST):
      return state;
    case fulfilled(GET_ARTIST_BY_URL):
      return {
        ...state,
        active: action.payload.data.id,
        data: {
          ...state.data,
          [action.payload.data.id]: action.payload.data,
        },
        url2id: {
          ...state.url2id,
          [action.meta.url]: action.payload.data,
        },
        responseStatus: action.payload.status,
      };
    case fulfilled(READ_ARTIST):
      return {
        ...state,
        active: action.payload.data ? action.payload.data.id : action.meta.artistId,
        data: {
          ...state.data,
          [action.payload.data.id]: action.payload.data,
        },
      };
    case fulfilled(UPDATE_ARTIST):
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.data.id]: {
            ...state.data[action.payload.data.id],
            ...action.payload.data,
          },
        },
      };
    case fulfilled(READ_ARTIST_POST_TAGS):
      if (!action.payload.ok) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artistId]: {
            ...state.data[action.meta.artistId],
            post_tags: action.payload.data,
          },
        },
        _nextPosts: action.payload.data.next ? action.payload.data.next.split('?')[1] : null,
        _postsCount: action.payload.data.count ?? 0,
      };
    case CLEAR_ARTIST_POSTS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artistId]: {
            ...state.data[action.meta.artistId],
            posts: undefined,
          },
        },
      };
    case fulfilled(READ_ARTIST_POSTS):
      if (!action.payload.ok) {
        return state;
      }
      action.payload.data.results.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artistId]: {
            ...state.data[action.meta.artistId],
            posts: newData,
          },
        },
        _nextPosts: action.payload.data.next ? action.payload.data.next.split('?')[1] : null,
        _postsCount: action.payload.data.count ?? 0,
      };
    case fulfilled(READ_POSTS):
      if (!action.payload.ok) {
        return state;
      }
      action.payload.data.results.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        posts: newData,
        _nextPosts: action.payload.data.next ? action.payload.data.next.split('?')[1] : null,
        _postsCount: action.payload.data.count ?? 0,
      };
    case fulfilled(READ_MORE_POSTS):
      action.payload.data.results.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        posts: {
          ...state.posts,
          ...newData,
        },
        _nextPosts: action.payload.data.next ? action.payload.data.next.split('?')[1] : null,
        _postsCount: action.payload.data.count ?? 0,
      };
    case fulfilled(READ_MORE_ARTIST_POSTS):
      action.payload.data.results.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artistId]: {
            ...state.data[action.meta.artistId],
            posts: {
              ...state.data[action.meta.artistId].posts,
              ...newData,
            },
          },
        },
        _nextPosts: action.payload.data.next ? action.payload.data.next.split('?')[1] : null,
        _postsCount: action.payload.data.count ?? 0,
      };
    case fulfilled(CREATE_ARTIST_POST): {
      if (!action.payload.ok) {
        Sentry.captureMessage(JSON.stringify(action.payload.data), 'error');
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.data.artist.id]: {
            ...state.data[action.payload.data.artist.id],
            posts: {
              ...state.data[action.payload.data.artist.id].posts,
              [action.payload.data.id]: action.payload.data,
            },
          },
        },
      };
    }
    case fulfilled(READ_ARTIST_POST): {
      let newState = {
        ...state,
        posts: {
          ...state.posts,
          [action.payload.data.id]: action.payload.data,
        }
      };
      if (state.data[action.meta.artistId]) {
        newState = {
          ...newState,
          data: {
            ...state.data,
            [action.meta.artistId]: {
              ...state.data[action.meta.artistId],
              active: action.payload.data ? action.payload.data.id : action.meta.postId,
              posts: {
                ...state.data[action.meta.artistId].posts,
                [action.payload.data.id]: action.payload.data,
              },
              singlePost: action.payload.data,
            },
          },
        };
      }
      return newState;
    }
    case fulfilled(UPDATE_ARTIST_POST):
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.data.artist.id]: {
            ...state.data[action.payload.data.artist.id],
            posts: {
              ...state.data[action.payload.data.artist.id].posts,
              [action.payload.data.id]: action.payload.data,
            },
          },
        },
      };
    case fulfilled(READ_ARTIST_SCHEDULES):
      action.payload.data.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artistId]: {
            ...state.data[action.meta.artistId],
            schedules: newData,
          },
        },
      };
    case CREATE_ARTIST_SCHEDULE: // TODO : IMPLEMENT THIS
      return state;
    case fulfilled(READ_ARTIST_POST_REPLIES): // TODO : IMPLEMENT THIS
      action.payload.data.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artistId]: {
            ...state.data[action.meta.artistId],
            comments: {
              ...state.data[action.meta.artistId].comments,
              newData,
            },
          },
        },
      };
    case fulfilled(CREATE_ARTIST_POST_REPLY): {
      return state;
    }
    case fulfilled(READ_ARTIST_CHEERS):
      action.payload.data.results.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artistId]: {
            ...state.data[action.meta.artistId],
            cheer_posts: newData,
            cheer_posts_count: action.payload.data.count,
          },
        },
      };
    // case fulfilled(READ_MORE_ARTIST_CHEERS):
    //   action.payload.data.results.map(datum => (newData[datum.id] = datum));
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       [action.meta.artistId]: {
    //         ...state.data[action.meta.artistId],
    //         cheer_posts: {
    //           ...state.data[action.meta.artistId].cheer_posts,
    //           ...newData,
    //         },
    //       },
    //     },
    //     _nextCheers: action.payload.data.next ? action.payload.data.next.split('?')[1] : null,
    //   };
    case fulfilled(READ_CHEER_POSTS): {
      action.payload.data.results.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        cheers: newData,
        cheersCount: action.payload.data.count,
      };
    }
    case fulfilled(READ_NOTICED_CHEER_POSTS): {
      action.payload.data.results.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        noticedCheers: newData,
      };
    }
    case fulfilled(CREATE_CHEER_POST): {
      return {
        ...state,
        cheers: {
          ...state.cheers,
          [action.payload.data.id]: action.payload.data,
        }
      };
    }
    case fulfilled(READ_CHEER_POST): {
      return {
        ...state,
        cheers: {
          ...state.cheers,
          [action.payload.data.id]: action.payload.data,
        }
      };
    }
    case fulfilled(UPDATE_CHEER_POST): {
      return {
        ...state,
        cheers: {
          ...state.cheers,
          [action.payload.data.id]: action.payload.data,
        }
      };
    }
    case fulfilled(DELETE_CHEER_POST): {
      const { cheers } = state;
      delete cheers[action.meta.id];
      return {
        ...state,
        cheers
      };
    }
    // case fulfilled(READ_MORE_CHEER_POSTS):
    //   action.payload.data.results.map(datum => (newData[datum.id] = datum));
    //   return {
    //     ...state,
    //     cheers: {
    //       ...state.cheerPosts,
    //       ...newData,
    //     },
    //     _nextCheers: action.payload.data.next ? action.payload.data.next.split('?')[1] : null,
    //   };
    case fulfilled(CREATE_ARTIST_CHEER): {
      const artistId = action.payload.data.artist;
      return {
        ...state,
        data: {
          ...state.data,
          [artistId]: {
            ...state.data[artistId],
            cheer_posts: {
              ...state.data[artistId].cheer_posts,
              [action.payload.data.id]: { ...action.payload.data, user: action.meta.user, reply_count: 0 },
            },
          },
        },
      };
    }
    case pending(READ_ARTIST_CHEER):
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artistId]: {
            ...state.data[action.meta.artistId],
            singleCheer: null,
          },
        },
      };
    case fulfilled(READ_ARTIST_CHEER):
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artistId]: {
            ...state.data[action.meta.artistId],
            active: action.payload.data ? action.payload.data.id : action.meta.cheerId,
            cheer_posts: {
              ...state.data[action.meta.artistId].cheer_posts,
              [action.payload.data.id]: action.payload.data,
            },
            singleCheer: action.payload.data,
          },
        },
      };
    case pending(UPDATE_ARTIST_CHEER): {
      return {
        ...state,
        fetching: true,
      };
    }
    case fulfilled(UPDATE_ARTIST_CHEER): {
      const artistId = action.payload.data.artist;
      return {
        ...state,
        data: {
          ...state.data,
          [artistId]: {
            ...state.data[artistId],
            cheer_posts: {
              ...state.data[artistId].cheer_posts,
              [action.payload.data.id]: {
                ...state.data[artistId].cheer_posts[action.payload.data.id],
                body: action.payload.data.body,
              },
            },
            singleCheer: {
              ...state.data[artistId].singleCheer,
              body: action.payload.data.body,
            },
          },
        },
        fetching: false,
      };
    }
    case fulfilled(DELETE_ARTIST_CHEER):
      return state;
    case fulfilled(READ_ARTIST_CHEER_REPLIES):
      action.payload.data.map(datum => (newData[datum.id] = datum));
      return { state };
    case fulfilled(CREATE_ARTIST_CHEER_REPLY): {
      const { artistId } = action.meta;
      const cheerId = action.payload.data.cheer_post;
      const newReplies = state.data[artistId].cheer_posts[cheerId].replies;
      newReplies.push(action.payload.data);
      return {
        ...state,
        data: {
          ...state.data,
          [artistId]: {
            ...state.data[artistId],
            cheer_posts: {
              ...state.data[artistId].cheer_posts,
              [cheerId]: {
                ...state.data[artistId].cheer_posts[cheerId],
                replies: newReplies,
              },
            },
            singleCheer: {
              ...state.data[artistId].singleCheer,
              replies: newReplies,
            }
          },
        },
      };
    }
    case fulfilled(CREATE_ARTIST_CHEER_LIKE):
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artist]: {
            ...state.data[action.meta.artist],
            singleCheer: {
              ...state.data[action.meta.artist].singleCheer,
              like_count: state.data[action.meta.artist].singleCheer.like_count + 1,
              is_user_liked: true,
            }
          }
        }
      };
    case fulfilled(DELETE_ARTIST_CHEER_LIKE):
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artist]: {
            ...state.data[action.meta.artist],
            singleCheer: {
              ...state.data[action.meta.artist].singleCheer,
              like_count: state.data[action.meta.artist].singleCheer.like_count - 1,
              is_user_liked: false,
            }
          }
        }
      };
    case fulfilled(CREATE_CHEER_POST_LIKE): {
      return {
        ...state,
        cheers: {
          ...state.cheers,
          [action.payload.data.cheer]: {
            ...state.cheers[action.payload.data.cheer],
            like_count: state.cheers[action.payload.data.cheer].like_count + 1,
            is_user_liked: true,
          }
        }
      };
    }
    case fulfilled(DELETE_CHEER_POST_LIKE): {
      return {
        ...state,
        cheers: {
          ...state.cheers,
          [action.payload.data.cheer]: {
            ...state.cheers[action.payload.data.cheer],
            like_count: state.cheers[action.payload.data.cheer].like_count - 1,
            is_user_liked: false,
          }
        }
      };
    }
    case fulfilled(CREATE_ARTIST_POST_LIKE): {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artist]: {
            ...state.data[action.meta.artist],
            posts: {
              ...state.data[action.meta.artist].posts,
              [action.payload.data.artist_post]: {
                ...state.data[action.meta.artist].posts[action.payload.data.artist_post],
                like_count: state.data[action.meta.artist].posts[action.payload.data.artist_post].like_count + 1,
                is_user_liked: true,
              },
            },
          },
        },
      };
    }
    case fulfilled(DELETE_ARTIST_POST_LIKE): {
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artist]: {
            ...state.data[action.meta.artist],
            posts: {
              ...state.data[action.meta.artist].posts,
              [action.meta.artistPost]: {
                ...state.data[action.meta.artist].posts[action.meta.artistPost],
                like_count: state.data[action.meta.artist].posts[action.meta.artistPost].like_count - 1,
                is_user_liked: false,
              },
            },
          },
        },
      };
    }
    case fulfilled(CREATE_ARTIST_REWARD):
      return state;
    case fulfilled(READ_ARTIST_REWARDS):
      action.payload.data.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artistId]: {
            ...state.data[action.meta.artistId],
            rewards: newData,
          },
        },
      };
    case fulfilled(UPDATE_ARTIST_REWARD):
      return state;
    case fulfilled(DELETE_ARTIST_REWARD):
      return state;
    case fulfilled(CREATE_ARTIST_MERCHANDISE):
      return state;
    case fulfilled(READ_ARTIST_MERCHANDISE):
      action.payload.data.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.artistId]: {
            ...state.data[action.meta.artistId],
            merchandise: newData,
          },
        },
      };
    case fulfilled(READ_ARTIST_MERCHANDISE_DETAIL): {
      const artistId = action.payload.data.artist;
      action.payload.data.map(datum => (newData[datum.id] = datum));
      return {
        ...state,
        data: {
          ...state.data,
          [artistId]: {
            ...state.data[artistId],
            merchandise: {
              ...state.data[artistId].merchandise,
              [action.payload.data.id]: action.payload.data,
            },
          },
        },
      };
    }
    case fulfilled(UPDATE_ARTIST_MERCHANDISE):
      return state;
    case SET_ARTIST_MERCHANDISE_FORM: {
      return {
        ...state,
        merchandiseForm: action.payload,
      };
    }
    case fulfilled(UPLOAD_ARTIST_PROFILE_IMAGE):
      return {
        ...state,
        profileImage: {
          name: action.payload.data.hashed_name,
          url: `${strings.s3.domain}/${strings.s3.bucket}/${action.payload.data.hashed_name}`,
        },
      };
    case fulfilled(UPLOAD_ARTIST_COVER_IMAGE):
      return {
        ...state,
        coverImage: {
          name: action.payload.data.hashed_name,
          url: `${strings.s3.domain}/${strings.s3.bucket}/${action.payload.data.hashed_name}`,
        },
      };
    case fulfilled(UPLOAD_ARTIST_MERCHANDISE_IMAGE):
      return {
        ...state,
        merchandiseImageUrl: `${strings.s3.domain}/${strings.s3.bucket}/${action.payload.data.hashed_name}`,
      };
    case fulfilled(READ_ARTISTS_RECENTLY_UPDATED):
      return {
        ...state,
        mainpage: action.payload.data,
      };
    case fulfilled(READ_ARTIST_RECENT_SCHEDULES):
      return {
        ...state,
        mainpageSchedules: action.payload.data,
      };
    case START_INITIALIZE:
      return {
        ...state,
        initializing: true,
      };
    case FINISH_INITIALIZE:
      return {
        ...state,
        initializing: false,
      };
    case START_CREATE_REWARD:
      return {
        ...state,
        creatingReward: true,
      };
    case FINISH_CREATE_REWARD:
      return {
        ...state,
        creatingReward: false,
      };
    default:
      return state;
  }
}
