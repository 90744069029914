import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';

class MenuItemWithLink extends React.Component {
  render() {
    return (
      <Link style={{ color: 'inherit' }} href={this.props.path} to={this.props.path} onClick={this.props.onClick}>
        <MenuItem value={this.props.value} style={this.props.style}>
          {this.props.children}
        </MenuItem>
      </Link>
    );
  }
}

MenuItemWithLink.propTypes = {
  path: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

MenuItemWithLink.defaultProps = {
  style: null,
  onClick: () => {},
};

export default MenuItemWithLink;
