/* artist */
export const INITIALIZE_ARTIST_STATE = 'actions/artist/initializeArtistState';
export const READ_ARTISTS = 'actions/artist/readArtists';
export const READ_MORE_ARTISTS = 'actions/artist/readMoreArtists';
export const SET_ARTISTS_ORDERING = 'actions/artist/setArtistsOrdering';
export const CREATE_ARTIST = 'actions/artist/createArtist';
export const GET_ARTIST_BY_URL = 'actions/artist/getArtistByUrl';
export const READ_ARTIST = 'actions/artist/readArtist';
export const UPDATE_ARTIST = 'actions/artist/updateArtist';
export const READ_ARTIST_POST_TAGS = 'actions/artist/readArtistTags';
export const CLEAR_ARTIST_POSTS = 'actions/artist/clearArtistPosts';
export const READ_ARTIST_POSTS = 'actions/artist/readArtistPosts';
export const READ_MORE_ARTIST_POSTS = 'actions/artist/readMoreArtistPost';
export const READ_POSTS = 'actions/artist/readPosts';
export const READ_MORE_POSTS = 'actions/artist/readMorePosts';
export const CREATE_ARTIST_POST = 'actions/artist/createArtistPost';
export const READ_ARTIST_POST = 'actions/artist/readArtistPost';
export const UPDATE_ARTIST_POST = 'actions/artist/updateArtistPost';
export const DELETE_ARTIST_POST = 'actions/artist/deleteArtistPost';
export const READ_ARTIST_SCHEDULES = 'actions/artist/readArtistSchedules'; // TODO : API FIX REQUIRED
export const CREATE_ARTIST_SCHEDULE = 'actions/artist/createArtistSchedule'; // TODO : API FIX REQUIRED
export const READ_ARTIST_POST_REPLIES = 'actions/artist/readArtistPostReplies';
export const CREATE_ARTIST_POST_REPLY = 'actions/artist/createArtistPostReply';
export const DELETE_ARTIST_POST_REPLY = 'actions/artist/deleteArtistPostReply';
export const READ_ARTIST_CHEERS = 'actions/artist/readArtistCheers';
export const READ_MORE_ARTIST_CHEERS = 'actions/artist/readMoreArtistCheers';
export const READ_NOTICED_CHEER_POSTS = 'actions/artist/readNoticedCheerPosts';
export const READ_CHEER_POSTS = 'actions/artist/readCheerPosts';
export const READ_CHEER_POST = 'actions/artist/readCheerPost';
export const CREATE_CHEER_POST = 'actions/artist/readCheerPost';
export const UPDATE_CHEER_POST = 'actions/artists/updateCheerPost';
export const READ_MORE_CHEER_POSTS = 'actions/artist/readMoreCheerPosts';
export const CREATE_ARTIST_CHEER = 'actions/artist/createArtistCheer';
export const READ_ARTIST_CHEER = 'actions/artist/readArtistCheer';
export const UPDATE_ARTIST_CHEER = 'actions/artist/updateArtistCheer';
export const DELETE_ARTIST_CHEER = 'actions/artist/deleteArtistCheer';
export const DELETE_CHEER_POST = 'actions/artist/deleteCheerPost';
export const READ_ARTIST_CHEER_REPLIES = 'actions/artist/createArtistCheerReplies';
export const CREATE_ARTIST_CHEER_REPLY = 'actions/artist/createArtistCheerReply';
export const DELETE_ARTIST_CHEER_REPLY = 'actions/artist/deleteArtistCheerReply';
export const CREATE_CHEER_REPLY = 'actions/artist/createCheerReply';
export const DELETE_CHEER_REPLY = 'actions/artist/deleteCheerReply';
export const CREATE_ARTIST_REWARD = 'actions/artist/createArtistReward';
export const READ_ARTIST_REWARDS = 'actions/artist/readArtistRewards';
export const UPDATE_ARTIST_REWARD = 'actions/artist/updateArtistReward';
export const DELETE_ARTIST_REWARD = 'actions/artist/deleteArtistReward';
export const READ_ARTIST_MERCHANDISE = 'actions/artist/readArtistMerchandise';
export const CREATE_ARTIST_MERCHANDISE = 'actions/artist/createArtistMerchandise';
export const UPDATE_ARTIST_MERCHANDISE = 'actions/artist/updateArtistMerchandise';
export const SET_ARTIST_MERCHANDISE_FORM = 'actions/artist/setArtistMerchandiseForm';
export const READ_ARTIST_MERCHANDISE_DETAIL = 'actions/artist/readArtistMerchandiseDetail';
export const CREATE_ARTIST_FOLLOW = 'actions/artist/createArtistFollow';
export const DELETE_ARTIST_FOLLOW = 'actions/artist/deleteArtistFollow';
export const UPLOAD_ARTIST_COVER_IMAGE = 'actions/artist/uploadArtistCoverImage';
export const UPLOAD_ARTIST_PROFILE_IMAGE = 'actions/artist/uploadArtistProfileImage';
export const UPLOAD_ARTIST_MERCHANDISE_IMAGE = 'actions/artist/uploadArtistMerchandiseImage';
export const READ_ARTISTS_RECENTLY_UPDATED = 'actions/artist/readArtistsRecentlyUpdated';
export const READ_ARTIST_RECENT_SCHEDULES = 'actions/artist/readArtistRecentSchedules';
export const START_INITIALIZE = 'actions/artist/startInitialize';
export const FINISH_INITIALIZE = 'actions/artist/finishInitialize';
export const START_CREATE_REWARD = 'actions/artist/startCreateReward';
export const FINISH_CREATE_REWARD = 'actions/artist/finishCreateReward';
export const CREATE_CHEER_POST_LIKE = 'actions/artist/createCheerPostLike';
export const DELETE_CHEER_POST_LIKE = 'actions/artist/deleteCheerPostLike';
export const CREATE_ARTIST_CHEER_LIKE = 'actions/artist/createArtistCheerLike';
export const DELETE_ARTIST_CHEER_LIKE = 'actions/artist/deleteArtistCheerLike';
export const CREATE_ARTIST_POST_LIKE = 'actions/artist/createArtistPostLike';
export const DELETE_ARTIST_POST_LIKE = 'actions/artist/deleteArtistPostLike';

/* auth */
export const SIGN_IN = 'actions/auth/signIn';
export const SIGN_IN_WITH_FACEBOOK = 'actions/auth/signInWithFacebook';
export const SIGN_OUT = 'actions/auth/signOut';
export const LOAD_ACCESS_TOKEN = 'actions/auth/loadAccessToken';
export const SET_PASSWORD = 'actions/auth/setPassword';
export const RESET_PASSWORD = 'actions/auth/resetPassword';
export const START_SETTING_USER_PASSWORD = 'actions/auth/startSettingUserPassword';
export const FINISH_SETTING_USER_PASSWORD = 'actions/auth/finishSettingUserPassword';

/* editor */
export const UPLOAD_IMAGE_WITH_EDITOR = 'actions/editor/uploadImageWithEditor';
export const UPDATE_EDITOR_CONTENT = 'actions/editor/updateEditorContent';
export const START_UPLOADING_IMAGE = 'actions/editor/startUploadingImage';
export const FINISH_UPLOADING_IMAGE = 'actions/editor/finishUploadingImage';

/* navigator */
export const SET_ACTIVE_NAVIGATOR_PATH = 'actions/navigator/setActiveNavigatorPath';
export const SET_ACTIVE_TAB_PATH = 'actions/navigator/setActiveTabPath';
export const OPEN_NAVIGATOR_DRAWER = 'actions/navigator/openNavigatorDrawer';
export const CLOSE_NAVIGATOR_DRAWER = 'actions/navigator/closeNavigatorDrawer';
export const OPEN_NAVIGATOR_POPOVER = 'actions/navigator/openNavigatorPopover';
export const CLOSE_NAVIGATOR_POPOVER = 'actions/navigator/closeNavigatorPopover';
export const SET_NAVIGATOR_POPOVER_ANCHOR = 'actions/navigator/setNavigatorPopoverAnchor';

/* payment */
export const READ_PAYMENT_METHOD = 'actions/payment/readPaymentMethod';
export const CREATE_PAYMENT_METHOD = 'actions/payment/createPaymentMethod';
export const READ_SUPPORTS = 'actions/payment/readSupports';
export const CREATE_SUPPORT = 'actions/payment/createSupport';
export const SUPPORT_ADD_UPDATE = 'actions/payment/supportAddUpdate';
export const SUPPORT_CANCEL_SUPPORT = 'actions/payment/supportCancelSupport';
export const READ_TRANSACTIONS = 'actions/payment/readTransactions';
export const SET_SUPPORT_FORM = 'actions/purchase/setSupportForm';
export const START_EDITING_PAYMENT_METHOD_FORM = 'actions/payment/startEditingPaymentMethodForm';
export const FINISH_EDITING_PAYMENT_METHOD_FORM = 'actions/payment/finishEditingPaymentMethodForm';
export const READ_SUPPORTS_ARTIST = 'actions/payment/readSupportsArtist';
export const SEARCH_GS_POINT = 'actions/payment/searchGSPoint';
export const INITIALIZE_GS_POINT = 'actions/payment/initializeGSPoint';
export const SET_GS_POINT = 'actions/payment/setGSPoint';
export const READ_MERCHANDISE = 'actions/payment/readMerchandise';
export const PURCHASE_MERCHANDISE = 'actions/payment/purchaseMerchandise';
export const CREATE_PURCHASE = 'actions/payment/createPurchase';
export const READ_PURCHASES = 'actions/payment/readPurchases';
export const UPDATE_PURCHASE = 'actions/payment/updatePurchase';
export const READ_PURCHASES_ARTIST = 'actions/payment/readPurchasesArtist';
export const SET_PURCHASE_FORM = 'actions/payment/setPurchaseForm';

/* user */
export const SIGN_UP = 'actions/auth/signUp'; // Implemented in auth.js
export const READ_USER = 'actions/user/readUser';
export const RESET_USER = 'actions/user/resetUser';
export const UPDATE_USER = 'actions/user/updateUser';
export const START_EDITING_USER_PROFILE_FORM = 'actions/user/startEditingUserProfileForm';
export const FINISH_EDITING_USER_PROFILE_FORM = 'actions/user/finishEditingUserProfileForm';
export const START_EDITING_USER_SETTING_FORM = 'actions/user/startEditingUserSettingForm';
export const FINISH_EDITING_USER_SETTING_FORM = 'actions/user/finishEditingUserSettingForm';

/* supports */
export const READ_QUESTIONS = 'actions/supports/readQuestions';
export const CREATE_CONTACT = 'actions/supports/createContact';
export const READ_ANNOUNCEMENTS = 'actions/supports/readAnnouncements';
export const READ_MORE_ANNOUNCEMENTS = 'actions/supports/readMoreAnnouncements';

/* dashboard */
export const READ_PAGE_VIEWS = 'actions/dashboard/readPageViews';
export const READ_PAGE_VIEWS_USER_GENDER = 'actions/dashboard/readPageViewsUserGender';
export const READ_CHECKOUT = 'actions/dashboard/readCheckout';
