import { api } from './api';
import {
  UPLOAD_IMAGE_WITH_EDITOR,
  UPDATE_EDITOR_CONTENT,
  START_UPLOADING_IMAGE,
  FINISH_UPLOADING_IMAGE,
} from './actionTypes';


export function uploadImage(data) {
  const formData = new FormData();
  formData.append('file', data);
  const request = api.post('/images/upload/', formData, { headers: { 'Content-Type': 'multipart/form-data; boundary=frontier' } });
  return {
    type: UPLOAD_IMAGE_WITH_EDITOR,
    payload: request,
  };
}

export function updateEditorContent(data) {
  return {
    type: UPDATE_EDITOR_CONTENT,
    payload: data,
  };
}

export function startUploadingImage() {
  return {
    type: START_UPLOADING_IMAGE,
  };
}

export function finishUploadingImage() {
  return {
    type: FINISH_UPLOADING_IMAGE,
  };
}
