import React from 'react';

import { getStyle } from './style';

class SupportGSPoint extends React.Component {
  render() {
    const style = getStyle();
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={style.card.title}>GS&POINT 사용안내</div>
        <img
          src="https://s3-ap-northeast-1.amazonaws.com/threethousandwon-tokyo/gs_poster.jpg" alt="GS&POINT 사용안내"
          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
        />
        <p>
          GS&POINT를 각 아티스트와 함께하는 첫 결제에서 사용할 수 있습니다.<br/><br/>
          첫 결제시 결제금액의 최대 50%까지 사용할 수 있으며,<br/>이후 정기결제부터는 정상 금액이 결제됩니다.<br/><br/>
          포인트의 사용을 위해서 GS&POINT 웹 아이디와 비밀번호가 필요합니다.<br/><br/>
          GS&POINT에 대한 자세한 사항은 GS&POINT 홈페이지에서 확인하실 수 있습니다.
        </p>
        <p><a style={{ fontSize: '1.1em' }} href="http://www.gsnpoint.com" rel="noopener noreferrer" target="_blank"> GS&POINT 홈페이지 바로가기 </a></p>
      </div>
    );
  }
}

export default SupportGSPoint;
