import React from 'react';
import * as Sentry from '@sentry/react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        Object.keys(info).forEach((key) => {
          scope.setExtra(key, info[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center', paddingTop: 30 }}>
          <div style={{ fontSize: 120 }}>X_X</div>
          <div style={{ fontSize: 18 }}>뭔가 잘못됐습니다</div>
          <div style={{ marginTop: 30 }}>
            <Button
              onClick={() => window.location.reload()}
              variant="outlined"
            >
              새로고침 해보기
            </Button>
          </div>
          <div style={{ marginTop: 30 }}>
            <Typography>
              support@3000won.com
            </Typography>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
