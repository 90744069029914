import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { Announcement as AnnouncementComponent } from './../../components';

import { PropsData, Order } from './../Utils/DataUtil';

import { strings } from './../../assets/values';

import * as supportsActions from './../../actions/supports';

import { getStyle } from './style';

class Announcements extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.readAnnouncements();
  }

  onReadMoreAnnouncements = () => {
    this.props.readMoreAnnouncements(this.props._next);
  };

  render() {
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.announcements) return (progress);
    const style = getStyle();
    const readMoreStyle = style.muiRaisedButton(10);
    return (
      <div style={style.wrapper}>
        <div style={style.announcement.title}>{strings.announcement}</div>
        <div>
          {this.props.announcements.map(post =>
            <div style={style.announcement.wrapper} key={post.title}>
              <Card style={style.announcement.card}>
                <AnnouncementComponent
                  {...post}
                  style={style.announcement.content}
                />
              </Card>
            </div>)}
          {this.props._next &&
            <div>
              <Button
                fullWidth
                style={readMoreStyle}
                variant="outlined"
                color="primary"
                onClick={(event) => { event.preventDefault(); this.onReadMoreAnnouncements(); }}
              >
                더 보기
              </Button>
            </div>
          }
        </div>
      </div>
    );
  }
}

Announcements.propTypes = {
  announcements: PropTypes.array,
  readAnnouncements: PropTypes.func.isRequired,
  readMoreAnnouncements: PropTypes.func.isRequired,
  _next: PropTypes.string,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const sortedAnnouncements = PropsData.fromState(state.support.announcements, Order.DESCENDING);
  return {
    announcements: sortedAnnouncements,
    _next: state.support.announcements._nextPosts,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...supportsActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
