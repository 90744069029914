import React from 'react';
import { Link } from 'react-router-dom';

import { Visible } from 'react-grid-system';

import bannerDesktopSrc from './../../assets/images/banner-desktop.jpg';
import bannerMobileSrc from './../../assets/images/banner-mobile.jpg';


class Banner extends React.Component {
  render() {
    return (
      <Link to="/about">
        <Visible lg xl>
          <div
            style={{
            backgroundImage: `url(${bannerDesktopSrc})`,
            height: 250,
            width: '100%',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            borderRadius: 4,
            marginBottom: 16,
          }}
          />
        </Visible>
        <Visible xs sm md>
          <div
            style={{
            backgroundImage: `url(${bannerMobileSrc})`,
            height: 180,
            width: '100%',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            marginBottom: 16,
          }}
          />
        </Visible>
      </Link>
    );
  }
}

export default Banner;
