import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { useFormik } from 'formik';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import * as artistActions from './../../actions/artist';
import * as userActions from './../../actions/user';

import { strings } from './../../assets/values';
import * as Validator from './../Utils/Validator';
import { getStyle } from './style';

import history from './../../history';

const validate = (values) => {
  const errors = {};
  const nameError = Validator.validateName(values.name);
  const contactEmailError = Validator.validateContactEmail(values.contact_email);
  const contactPhoneError = Validator.validateContactPhone(values.contact_phone);
  const urlArtistError = Validator.validateUrlArtist(values.url_artist);
  if (nameError) errors.name = nameError;
  if (contactEmailError) errors.contact_email = contactEmailError;
  if (contactPhoneError) errors.contact_phone = contactPhoneError;
  if (urlArtistError) errors.url_artist = urlArtistError;
  return errors;
};

const ArtistsNew = (props) => {
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    props.readUser().then((response) => {
      if (!response.action.payload.ok) history.push(strings.routes.signIn);
    });
  }, []);

  const onSubmit = (data) => {
    setProgress(true);
    props.createArtist(data)
      .then((response) => {
        if (!response.action.payload.ok) {
          toastr.error(strings.fail);
          setProgress(false);
        } else {
          props.readUser().then(history.push(`/${data.url_artist}`));
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      contact_email: '',
      contact_phone: '',
      url_artist: '',
    },
    validate: values => validate(values),
    onSubmit: values => onSubmit(values),
    validateOnChange: false,
  });
  const style = getStyle();
  return (
    <div style={style.artistsNew.container}>
      <Card style={style.artistsNew.card}>
        <CardHeader title="아티스트 등록하기"/>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            name="name"
            label={strings.artistName}
            helperText="페이지의 제목으로 쓰입니다"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
            fullWidth
            margin="normal"
          />
          <TextField
            name="contact_phone"
            label={strings.contact}
            value={formik.values.contact_phone}
            onChange={formik.handleChange}
            helperText={strings.contactWithoutHyphen}
            error={formik.errors.contact_phone}
            fullWidth
            margin="normal"
          />
          <TextField
            name="contact_email"
            label={strings.contactEmail}
            value={formik.values.contact_email}
            onChange={formik.handleChange}
            error={formik.errors.contact_email}
            fullWidth
            margin="normal"
          />
          <TextField
            name="url_artist"
            label="페이지 ID (페이지의 주소로 쓰이게 됩니다)"
            value={formik.values.url_artist}
            onChange={formik.handleChange}
            fullWidth
            helperText={strings.artistUrlHint}
            error={formik.errors.url_artist}
            margin="normal"
          />
          {progress && (
            <div style={{ textAlign: 'center', width: '100%' }}>
              <CircularProgress size={80} />
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            style={{ marginTop: 30 }}
            disabled={!formik.dirty || progress}
          >
            등록하기
          </Button>
        </form>
      </Card>
    </div>
  );
};

ArtistsNew.propTypes = {
  fields: PropTypes.object,
  createArtist: PropTypes.func.isRequired,
  readUser: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions, ...userActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistsNew);
