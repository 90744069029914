import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { useFormik } from 'formik';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { ContentWrapper } from './../../components';

import * as authActions from './../../actions/auth';

import { strings } from './../../assets/values';

import * as Validator from './../Utils/Validator';

import { getStyle } from './style';


const validate = (values) => {
  const errors = {};
  const emailError = Validator.validateEmail(values.email);
  if (emailError) errors.email = emailError;
  return errors;
};

const ResetPassword = (props) => {
  const onSubmit = (data) => {
    props.resetPassword(data)
      .then((response) => {
        if (!response.action.payload.ok) toastr.error(strings.fail, strings.notValidEmail);
        else toastr.success(strings.sendTempPassword);
      });
  };

  const style = getStyle();
  const submitStyle = style.muiRaisedButton(35);
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: values => onSubmit(values),
    validate: values => validate(values),
  });

  return (
    <ContentWrapper title={strings.resetPassword} width={420} cardStyle={style.card}>
      <div>
        비밀번호를 분실하신 경우, 가입하실 때 사용하신 이메일 주소로 임시 비밀번호를 보내드립니다.
      </div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          name="email"
          label={strings.email}
          value={formik.values.email}
          onChange={formik.handleChange}
          fullWidth
        />
        <Button
          variant="contained"
          style={submitStyle}
          color="primary"
          type="submit"
          fullWidth
        >
          {strings.resetPassword}
        </Button>
      </form>
      <Link href="/sign_in" to="/sign_in">
        <Button
          color="primary"
          style={style.flatButton}
          type="button"
        >
          {strings.goToSignIn}
        </Button>
      </Link>
    </ContentWrapper>
  );
};

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    accessToken: state.auth.accessToken,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...authActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
