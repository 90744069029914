import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import CardPayment from './../Payment/Card';
import { PageNumberPaginator } from './../../components';

import * as userActions from './../../actions/user';
import * as paymentActions from './../../actions/payment';

import { strings, presets, colors } from './../../assets/values';
import { getStyle } from './style';

import history from './../../history';


class MyPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }
  UNSAFE_componentWillMount() {
    this.props.readUser().then((response) => {
      if (!response.action.payload.ok) history.push('/');
      else {
        this.props.readPaymentMethod();
        this.props.readTransactions(this.state.currentPage);
      }
    });
  }

  handlePaymentEdit = () => {
    this.props.startEditingPaymentMethodForm();
  };

  handleTransactionsRead = (page) => {
    this.props.readTransactions(page);
    this.setState({ currentPage: page });
  };

  render() {
    const style = getStyle();
    const getRegisteredCard = (array) => {
      if (array.length) {
        const choice = presets.cards.filter(card => card.code === array[0].billing_card_code);
        return (
          <TableRow>
            <TableCell>
              {array[0].billing_card_number}****
            </TableCell>
            <TableCell>
              {choice[0].name}
            </TableCell>
          </TableRow>
        );
      }
      return (
        <TableRow>
          <TableCell>
            {strings.cardNotExist}
          </TableCell>
          <TableCell/>
        </TableRow>
      );
    };
    return (
      <div>
        <Card>
          <CardHeader title="결제수단"/>
          {this.props.editing ?
            <CardContent>
              <CardPayment/>
            </CardContent> :
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>카드번호</TableCell>
                    <TableCell>카드사</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getRegisteredCard(this.props.registeredCard)}
                </TableBody>
              </Table>
              <CardActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.handlePaymentEdit}
                >
                  카드등록
                </Button>
              </CardActions>
            </CardContent>
          }
        </Card>
        <Card style={style.innerCard}>
          <CardHeader title="결제내역"/>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>아티스트</TableCell>
                  <TableCell>선택한 리워드</TableCell>
                  <TableCell>지불한 가격</TableCell>
                  <TableCell>지불 방법</TableCell>
                  <TableCell>날짜</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.transactions.results && this.props.transactions.results.map(transaction =>
                  <TableRow key={transaction.id || 0}>
                    <TableCell>
                      {transaction.support.artist.name}
                    </TableCell>
                    <TableCell>
                      {transaction.support.reward.amount}
                    </TableCell>
                    <TableCell>
                      {transaction.price}
                    </TableCell>
                    <TableCell>
                      {transaction.payment_method.inicispayment}
                    </TableCell>
                    <TableCell>
                      {transaction.support.payment_day}
                    </TableCell>
                  </TableRow>)}
              </TableBody>
            </Table>
            <PageNumberPaginator
              currentPage={this.state.currentPage}
              count={this.props.transactions.count}
              onPageClick={this.handleTransactionsRead}
            />
          </CardContent>
        </Card>
        <CardContent style={{ color: colors.textBlack80 }}>
          <Typography>
            문제가 있으신가요?
            <a href={strings.kakaoChatUrl} target="blank" style={{ color: colors.primaryPink }}>
              {' 카카오톡 채널로 문의'}
            </a>
            해주시면 도와드리겠습니다 :)
          </Typography>
        </CardContent>
      </div>
    );
  }
}

MyPayments.propTypes = {
  editing: PropTypes.bool,
  registeredCard: PropTypes.arrayOf(PropTypes.object),
  transactions: PropTypes.object,
  readUser: PropTypes.func.isRequired,
  readPaymentMethod: PropTypes.func.isRequired,
  readTransactions: PropTypes.func.isRequired,
  startEditingPaymentMethodForm: PropTypes.func.isRequired,
  finishEditingPaymentMethodForm: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    editing: state.payment.editing,
    transactions: state.payment.transactions,
    registeredCard: state.payment.registeredCard,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...userActions,
    ...paymentActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPayments);
