import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { useFormik } from 'formik';

import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core//TextField';
import Button from '@material-ui/core/Button';

import { ContentWrapper } from './../../components';

import * as userActions from './../../actions/user';

import logoSrc from './../../assets/images/logo.png';
import { strings, styles } from './../../assets/values';
import * as Validator from './../Utils/Validator';
import { getStyle } from './style';

import history from './../../history';

const validate = (values) => {
  const errors = {};
  const emailError = Validator.validateEmail(values.email);
  const nicknameError = Validator.validateNickName(values.nickname);
  if (emailError) errors.email = emailError;
  if (nicknameError) errors.nickname = nicknameError;
  return errors;
};

const SignUpDetail = (props) => {
  useEffect(() => {
    if (!props.accessToken) history.goBack();
  }, []);

  const onSubmit = data => props.updateUser(data)
    .then((response) => {
      if (response.action.payload.ok) {
        toastr.success(strings.success);
        history.goBack();
      } else toastr.error(strings.fail);
    });

  const style = getStyle();
  const formik = useFormik({
    initialValues: {
      email: '',
      nickname: '',
    },
    onSubmit: values => onSubmit(values),
    validate: values => validate(values),
  });

  return (
    <ContentWrapper title={strings.signUp} width={420}>
      <Card style={style.card}>
        <img style={style.card.logo} src={logoSrc} alt={strings.appName}/>
        <p style={{ fontSize: styles.fontSize.md }}>원할한 이용을 위해, 아래 회원정보를 입력해주세요!</p>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            name="email"
            label={strings.email}
            value={formik.values.email}
            onChange={formik.handleChange}
            fullWidth
          />
          <TextField
            name="nickname"
            label={`${strings.name}/${strings.nickname}`}
            value={formik.values.nickname}
            onChange={formik.handleChange}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: 12 }}
            type="submit"
            disabled={formik.isSubmitting}
          >
            {strings.submit}
          </Button>
        </form>
      </Card>
    </ContentWrapper>
  );
};

SignUpDetail.propTypes = {
  accessToken: PropTypes.string,
  updateUser: PropTypes.func.isRequired,
  loadAccessToken: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    accessToken: state.auth.accessToken,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpDetail);
