import React from 'react';

import NavigationChevronLeft from '@material-ui/icons/ChevronLeft';

import { styles } from './../../assets/values';

const style = {
  arrow: {
    color: styles.palette.primary1Color,
    marginRight: 24,
  },
};

class PrevArrow extends React.Component {
  render() {
    return (
      <div {...this.props}>
        <NavigationChevronLeft style={style.arrow}/>
      </div>
    );
  }
}

export default PrevArrow;
