import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { DashboardContent, CheckoutTable, MonthNavigator } from './../../components';

import { DateTime } from './../Utils/DateTimeUtil';
import { strings } from './../../assets/values';

import * as userActions from './../../actions/user';
import * as dashboardActions from './../../actions/dashboard';

import history from './../../history';


const styles = {
  DashboardCard: {
    marginTop: -1,
  },
  DashboardContent: {
    marginBottom: 64,
  },
};

const last = DateTime.lastYearMonth();

class DashboardCheckout extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.readUser()
      .then((response) => {
        if (!response.action.payload.ok) history.push('/');
        else this.props.readCheckout(last.year, last.month);
      });
  }

  handleMonth = (year, month) => {
    this.props.readCheckout(year, month);
  };

  render() {
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.checkout) return progress;
    return (
      <div>
        <DashboardContent title={strings.checkoutInformation} style={styles.DashboardContent}>
          <MonthNavigator
            onChange={this.handleMonth} style={{ marginTop: -1 }}
            year={last.year} month={last.month}
          />
          <div style={{ padding: 8 }}>
            <CheckoutTable checkout={this.props.checkout}/>
          </div>
        </DashboardContent>
      </div>
    );
  }
}

DashboardCheckout.propTypes = {
  user: PropTypes.object,
  checkout: PropTypes.object,
  readUser: PropTypes.func.isRequired,
  readCheckout: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    checkout: state.dashboard.checkout,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...userActions,
    ...dashboardActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCheckout);
