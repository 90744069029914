import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Container, Row, Col } from 'react-grid-system';

import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { MyProfile, MyArtists, MyPurchases, MyPayments } from './../../containers';
// import { SelectableList } from './../../components';

import * as navigatorActions from './../../actions/navigator';

import { strings } from './../../assets/values';

import history from './../../history';

const paths = strings.routes;

class My extends React.Component {
  navigate = (path) => {
    history.push(`${paths.my}/${path}`);
  };

  render() {
    const getPath = (path = '') => `${this.props.match.path}/${path}`;
    return (
      <Container style={{ paddingTop: 30, paddingBottom: 60 }}>
        <Row>
          <Col md={3}>
            <Card>
              <List>
                {/* defaultValue={history.location.pathname}> */}
                <ListItem
                  button
                  value={`${paths.my}/${paths.myProfile}`}
                  onClick={() => this.navigate(paths.myProfile)}
                >
                  {strings.myProfile}
                </ListItem>
                <ListItem
                  button
                  value={`${paths.my}/${paths.myArtists}`}
                  onClick={() => this.navigate(paths.myArtists)}
                >
                  {strings.myArtists}
                </ListItem>
                <ListItem
                  button
                  value={`${paths.my}/${paths.myPurchases}`}
                  onClick={() => this.navigate(paths.myPurchases)}
                >
                  {strings.myPurchases}
                </ListItem>
                <ListItem
                  button
                  value={`${paths.my}/${paths.myPayments}`}
                  onClick={() => this.navigate(paths.myPayments)}
                >
                  {strings.myPayments}
                </ListItem>
              </List>
            </Card>
          </Col>
          <Col md={9}>
            <Route exact path={getPath()} component={MyProfile}/>
            <Route path={getPath(paths.myProfile)} component={MyProfile}/>
            <Route path={getPath(paths.myArtists)} component={MyArtists}/>
            <Route path={getPath(paths.myPurchases)} component={MyPurchases}/>
            <Route path={getPath(paths.myPayments)} component={MyPayments}/>
          </Col>
        </Row>
      </Container>
    );
  }
}

My.propTypes = {
  user: PropTypes.object,
  tab: PropTypes.string,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...navigatorActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(My);
