import React from 'react';
import PropTypes from 'prop-types';

import { Visible } from 'react-grid-system';

import { colors, styles } from './../../assets/values';


const titleTextHeight = 50;
const titleTextMargin = 20;
const getStyle = props => ({
  title: {
    width: props.width,
    height: titleTextHeight - titleTextMargin,
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: titleTextMargin,
    lineHeight: 1.0,
    color: colors.textBlack,
    fontSize: styles.fontSize.xl,
    fontWeight: styles.fontWeight.Medium,
    wrapper: {
      paddingTop: props.height - (props.title.length ? titleTextHeight : 0),
    },
  },
  card: {
    boxShadow: 0,
    width: props.width,
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 30,
    paddingBottom: 30,
  },
});

class ContentWrapper extends React.Component {
  render() {
    const style = getStyle(this.props);
    return (
      <div>
        <Visible lg>
          <div style={style.title.wrapper}>
            { this.props.title.length ?
              <div style={style.title}>{this.props.title}</div> : null
            }
          </div>
        </Visible>
        <div style={{ ...style.card, ...this.props.cardStyle }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

ContentWrapper.propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  cardStyle: PropTypes.object,
};

ContentWrapper.defaultProps = {
  title: '',
  width: 900,
  height: 90,
};

export default ContentWrapper;
