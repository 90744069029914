import { ActionType } from 'redux-promise-middleware';
import { api } from './../actions/api';


export const setAuth = (token) => {
  if (token) {
    api.setHeader('Authorization', `Token ${token}`);
    localStorage.setItem('AccessToken', token);
  } else {
    api.setHeader('Authorization', null);
    localStorage.removeItem('AccessToken');
  }
};

export const fulfilled = actionType => `${actionType}_${ActionType.Fulfilled}`;
export const pending = actionType => `${actionType}_${ActionType.pending}`;
