import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Row, Col } from 'react-grid-system';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { HomeArtistPosts, Community } from './../../containers';

import { strings } from './../../assets/values';

import history from './../../history';


const paths = strings.routes;
const tabPaths = [
  paths.posts,
  paths.cheers,
];

export default class Home extends React.Component {
  handleTabChange = (event, value) => {
    const path = tabPaths[parseInt(value, 10)];
    history.push(path);
  };

  render() {
    const getActivePath = (path) => {
      if (path.length > 2) return tabPaths.indexOf(path);
      return 0;
    };

    return (
      <div>
        <Row>
          <Col lg={8} sm={12} offset={{ lg: 2 }}>
            <div style={{ paddingTop: 16, width: '100%' }}>
              <Paper>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={getActivePath(history.location.pathname)}
                  onChange={this.handleTabChange}
                >
                  <Tab
                    label={strings.artistPosts}
                  />
                  <Tab
                    label={strings.artistCheers}
                  />
                </Tabs>
              </Paper>
            </div>
          </Col>
        </Row>
        <Switch>
          <Route exact path="/" component={HomeArtistPosts}/>
          <Route path={paths.posts} component={HomeArtistPosts}/>
          <Route path={paths.cheers} component={Community}/>
        </Switch>
      </div>
    );
  }
}
