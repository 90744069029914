import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import { strings } from './../../assets/values';
import { getStyle } from './style';

import history from './../../history';


const paths = strings.routes;

class ArtistsStart extends React.Component {
  render() {
    const style = getStyle();
    const ArtistsStartCard = props => (
      <Card style={style.artistsStart.card} key={props.strings.title}>
        <CardHeader
          style={style.artistsStart.card.title}
          title={props.strings.title}
        />
        <CardContent style={style.artistsStart.card.text}>
          <div>{props.strings.body}</div>
          <ol>
            {props.strings.how && props.strings.how.map(whyString =>
              <li key={whyString.title}>
                <h4>{whyString.title}</h4>
                <p>{whyString.body}</p>
              </li>)}
          </ol>
        </CardContent>
      </Card>
    );
    return (
      <div style={style.artistsStart.container}>
        { strings.artistsStart.body.map((stringResource, index) => (
          <ArtistsStartCard key={index} strings={stringResource}/>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => { event.preventDefault(); history.push(paths.artistsNew); }}
          fullWidth
        >
          {strings.artistsStart.title}
        </Button>
      </div>
    );
  }
}

export default ArtistsStart;
