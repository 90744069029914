import { colors, styles } from './../../assets/values';


export const getStyle = (stylesToOverride = {}) => Object.assign({
  // Styles in common
  rowWithLabel: styles.rowWithLabel,
  muiTextField: styles.muiComponents.textField,
  muiRaisedButton: styles.muiComponents.raisedButton,
  // Styles used in each component
  artists: {
    wrapper: {
      paddingTop: 16,
      paddingBottom: 48,
      width: 1000,
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    card: {
      header: {
        fontSize: styles.fontSize.lg,
      },
      body: {
        paddingBottom: 0,
      },
    },
    cardWrapper: {
      paddingBottom: 8,
    },
    filters: {
      row: {
        paddingBottom: 16,
      },
      header: {
        padding: 0,
        paddingBottom: 8,
      },
      headerTitle: {
        fontSize: styles.fontSize.lg,
      },
    },
    checkbox: {
      label: {
        marginTop: -2,
      },
    },
    cardList: {
      clear: 'both',
    },
    empty: {
      paddingTop: 30,
      paddingBottom: 30,
      textAlign: 'center',
      fontSize: styles.fontSize.lg,
      fontWeight: 500,
    },
    readMore: {
      marginTop: 16,
      textAlign: 'center',
    },
    searchHeader: {
      width: 780,
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 30,
      fontSize: styles.fontSize.lg,
      fontWeight: 500,
    },
  },
  artist: {
    header: {
      padding: 0,
      margin: 0,
    },
    body: {
      width: '100%',
      paddingBottom: 48,
    },
    tabWrapper: {
      width: 1200,
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    tabBody: {
      maxWidth: '100%',
    },
    tabs: {
      float: 'right',
      tab: {
        fontSize: styles.fontSize.md,
      },
    },
  },
  wrapper: {
    paddingTop: 16,
    width: 1200,
    maxWidth: '100%',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  leftContainer: {
    paddingBottom: 8,
  },
  rightContainer: {
    maxWidth: '100%',
    paddingBottom: 4,
  },
  info: {
    coverContainer: {
      width: '100%',
      textAlign: 'center',
      padding: 0,
      margin: 0,
    },
    name: {
      position: 'absolute',
      top: 220,
      width: '100%',
      height: 65,
      fontSize: 65,
      fontWeight: styles.fontWeight.DemiLight,
      lineHeight: 1.0,
      color: colors.textWhite,
    },
    editChip: {
      zIndex: '10',
      position: 'absolute',
      top: 320,
      left: '47%',
    },
    infoContainer: {
      paddingTop: 20,
      width: 1200,
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    tags: {
      marginTop: 8,
    },
    share: {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginLeft: -12,
    },
    description: {
      marginTop: 8,
      fontSize: styles.fontSize.md,
    },
    support: {
      fontSize: styles.fontSize.md,
      color: colors.textBlack,
      verticalAlign: 'text-bottom',
      marginTop: 24,
      marginRight: 16,
      display: 'inline-block',
      label: {
        paddingLeft: 4,
        paddingRight: 6,
      },
      icon: {
        margin: -4,
        height: 20,
        width: 20,
        color: colors.primaryPink,
      },
      count: {
        color: colors.primaryPink,
      },
    },
    follow: {
      fontSize: styles.fontSize.md,
      color: colors.textBlack,
      verticalAlign: 'text-bottom',
      marginTop: 24,
      display: 'inline-block',
      label: {
        paddingLeft: 4,
        paddingRight: 6,
      },
      icon: {
        margin: -4,
        height: 20,
        width: 20,
        color: colors.primaryOrange,
      },
      count: {
        color: colors.primaryOrange,
      },
      button: {
        borderRadius: 40,
        height: 25,
        backgroundColor: colors.primaryOrange,
        verticalAlign: 'middle',
      },
    },
  },
  intro: {
    actions: {
      padding: 0,
      paddingTop: 16,
      cancel: {
        marginRight: 0,
        float: 'left',
        width: 100,
      },
      confirm: {
        marginRight: 0,
        float: 'right',
        width: `calc(100% - ${100 + 16}px)`,
      },
    },
  },
  introduction: {
    titleStyle: {
      fontSize: 20,
      color: colors.textBlack,
    },
    subtitleStyle: {
      padding: 12,
      lineHeight: 1.5,
    },
    card: {
      paddingTop: 10,
      paddingLeft: '7%',
      paddingRight: '7%',
      minHeight: 96,
    },
    profile: {
      width: 44,
      height: 44,
      border: 5,
      backgroundColor: colors.black,
      color: colors.textBlack,
      float: 'left',
    },
    introBox: {
      float: 'left',
      paddingLeft: 20,
      clear: 'both',
    },
    introHeader: {
      fontSize: 20,
      color: colors.textBlack40,
      float: 'left',
    },
    introContent: {
      paddingTop: 10,
      fontSize: styles.fontSize.md,
      color: colors.textBlack80,
      clear: 'both',
      float: 'left',
    },
    DropDownMenu: {
      float: 'left',
    },
    extendedContent: {
      padding: 0,
    },
  },
  posts: {
    list: {
      margin: 0,
    },
    tags: {
      container: {
        marginTop: 8,
      },
      title: {
        padding: 4,
        fontSize: styles.fontSize.md,
        fontWeight: styles.fontWeight.Bold,
      },
      chip: {
        marginTop: 4,
        marginRight: 4,
      },
      label: {
        lineHeight: 2,
        paddingLeft: 8,
        paddingRight: 8,
      },
      name: {
        fontSize: styles.fontSize.sm,
      },
    },
    schedules: {
      container: {
        marginTop: 8,
      },
      title: {
        padding: 4,
        fontSize: styles.fontSize.md,
        fontWeight: styles.fontWeight.Bold,
      },
    },
  },
  post: {
    wrapper: {
      paddingBottom: 15,
    },
    card: {
      paddingTop: '4%',
      paddingLeft: '7%',
      paddingRight: '7%',
    },
    content: {
      card: {
        padding: 16,
      },
      left: {
        width: '13%',
        minWidth: 64,
        float: 'left',
      },
      right: {
        paddingLeft: 10,
        float: 'left',
      },
      thumbnail: {
        display: 'inline-block',
        width: 44,
        height: 44,
        color: colors.primaryPink,
      },
      title: {
        fontWeight: styles.fontWeight.Medium,
        fontSize: styles.fontSize.lg,
        color: colors.textBlack,
        display: 'inline-block',
        float: 'left',
        clear: 'left',
        paddingBottom: 4,
      },
      artistName: {
        fontWeight: styles.fontWeight.Medium,
        fontSize: styles.fontSize.md,
        color: colors.textBlack,
        display: 'inline-block',
        float: 'left',
        clear: 'left',
        paddingBottom: 4,
      },
      userName: {
        fontWeight: styles.fontWeight.Medium,
        fontSize: styles.fontSize.sm,
        color: colors.textBlack60,
        display: 'inline-block',
        float: 'left',
        clear: 'left',
        paddingBottom: 4,
      },
      timestamp: {
        fontWeight: styles.fontWeight.Regular,
        fontSize: styles.fontSize.sm,
        color: colors.textBlack60,
        display: 'inline-block',
        float: 'right',
        clear: 'right',
      },
      schedule: {
        fontSize: styles.fontSize.md,
        fontWeight: styles.fontWeight.Bold,
        clear: 'left',
      },
      secret: {
        fontSize: styles.fontSize.sm,
        marginTop: 16,
        padding: 8,
        background: colors.hashtagBackground,
        clear: 'both',
      },
      body: {
        paddingTop: 8,
        paddingBottom: 16,
        clear: 'both',
      },
      tags: {
        textAlign: 'left',
      },
      tagIcon: {
        verticalAlign: 'middle',
        height: 18,
        width: 18,
        margin: -2,
        color: colors.textBlack,
      },
      tag: {
        display: 'inline-block',
        verticalAlign: 'middle',
        paddingLeft: 4,
        fontSize: styles.fontSize.sm,
        color: colors.textBlack,
      },
    },
    reply: {
      marginTop: 1,
      border: 'none',
      boxShadow: 'none',
      divider: {
        marginTop: 16,
        backgroundColor: colors.textBlack20,
      },
    },
    replyHeader: {
      paddingLeft: 0,
      paddingTop: 14,
      textStyle: {
        padding: 0,
      },
      button: {
        right: 'initial',
        padding: 'initial',
        width: 24,
        height: 24,
      },
      title: {
        fontSize: 13,
      },
    },
    replyComponent: {
      wrapper: {
        paddingTop: 5,
        width: 580,
        maxWidth: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      leftContainer: {
        width: 150,
        maxWidth: '100%',
        marginRight: 'auto',
        paddingBottom: 6,
      },
      rightContainer: {
        width: 430,
        maxWidth: '100%',
        paddingBottom: 6,
      },
      timestamp: {
        fontWeight: styles.fontWeight.Regular,
        fontSize: styles.fontSize.xs,
        color: colors.textBlack60,
        display: 'inline-block',
        paddingLeft: 10,
      },
      deleteButton: {
        fontWeight: styles.fontWeight.Regular,
        fontSize: styles.fontSize.xs,
        color: colors.textBlack60,
        backgroundColor: colors.transparent,
        display: 'inline-block',
        paddingLeft: 10,
      },
    },
    replyNickname: {
      marginTop: 40,
      marginRight: 'auto',
    },
    replySubmit: {
      marginTop: 25,
      marginLeft: 'auto',
    },
  },
  newPost: {
    card: {
      borderRadius: 0,
      margin: 15,
    },
    form: {
      marginTop: 0,
      paddingTop: 0,
    },
  },
  purchase: {
    container: {
      padding: 8,
    },
    card: {
      padding: 8,
    },
  },
  cheers: {
    card: {
      padding: 16,
      paddingBottom: 8,
    },
    username: {
      fontWeight: styles.fontWeight.Medium,
      fontSize: styles.fontSize.md,
      color: colors.textBlack,
      float: 'left',
    },
    submit: {
      float: 'right',
      marginTop: -16,
      marginRight: -16,
    },
    body: {
      fontWeight: styles.fontWeight.Regular,
      fontSize: styles.fontSize.md,
      color: colors.textBlack80,
      clear: 'both',
    },
    hr: {
      marginTop: 16,
      height: 1,
      border: 0,
      backgroundColor: colors.primaryRed,
    },
  },
  artistsStart: {
    container: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 768,
      paddingTop: 50,
      paddingBottom: 100,
    },
    card: {
      padding: 16,
      marginBottom: 20,
      title: {
        padding: 0,
        fontSize: styles.fontSize.lg,
        fontWeight: styles.fontWeight.Medium,
      },
      text: {
        margin: 0,
        padding: 0,
        paddingTop: 20,
        lineHeight: 1.7,
      },
    },
    header: {
      marginBottom: 30,
      title: {
        float: 'left',
        marginTop: 16,
        fontWeight: styles.fontWeight.Medium,
        fontSize: styles.fontSize.lg,
        color: colors.textBlack80,
      },
      buttonOnRight: Object.assign({
        float: 'right',
        marginLeft: 16,
      }, styles.muiComponents.raisedButton(0)),
    },
  },
  artistsNew: {
    container: {
      paddingBottom: 100,
      paddingTop: 100,
    },
    card: {
      maxWidth: 500,
      padding: 40,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    tableItem: {
      paddingLeft: 10,
      paddingRight: 10,
      textAlign: 'center',
    },
    tableBar: {
      width: '100%',
    },
    textCenter: {
      textAlign: 'center',
    },
    numberWidth: {
      paddingLeft: 10,
      paddingRight: 10,
      width: '10%',
      textAlign: 'center',
    },
    buttonWidth: {
      paddingLeft: 10,
      paddingRight: 10,
      width: '20%',
      textAlign: 'center',
    },
  },
  navigateToTerms: {
    textDecoration: 'none',
    color: colors.textBlack,
    marginTop: 8,
    float: 'right',
    clear: 'right',
  },
}, stylesToOverride);
