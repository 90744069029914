/**
 * Reference : https://github.com/keppelen/react-facebook-login
 */
import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import { strings } from './../../assets/values';


class FacebookSignIn extends React.Component {
  componentDidMount() {
    const {
      appId,
      xfbml,
      cookie,
      version,
      autoLoad,
      language
    } = this.props;
    let fbRoot = document.getElementById('fb-root');

    if (!fbRoot) {
      fbRoot = document.createElement('div');
      fbRoot.id = 'fb-root';

      document.body.appendChild(fbRoot);
    }

    window.fbAsyncInit = () => {
      window.FB.init({
        version: `v${version}`,
        appId,
        xfbml,
        cookie,
      });

      if (autoLoad || window.location.search.includes('facebookdirect')) {
        window.FB.getLoginStatus(this.checkLoginState);
      }
    };
    // Load the SDK asynchronously
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = `//connect.facebook.net/${language}/all.js`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  responseApi = (authResponse) => {
    window.FB.api('/me', { fields: this.props.fields }, (me) => {
      Object.assign(me, authResponse);
      this.props.callback(me);
    });
  };

  checkLoginState = (response) => {
    if (response.authResponse) {
      this.responseApi(response.authResponse);
    } else if (this.props.callback) {
      this.props.callback({ status: response.status });
    }
  };

  auth = () => {
    const { scope, appId } = this.props;
    let isMobile = false;

    try {
      isMobile = ((window.navigator && window.navigator.standalone) || navigator.userAgent.match('CriOS') || navigator.userAgent.match('mobile'));
    } catch (ex) {
      // continue regardless of error
    }

    if (isMobile) {
      window.location.href = `https://www.facebook.com/dialog/oauth?client_id=${appId}&redirect_uri=${window.location.href}&state=facebookdirect&${scope}`;
    } else {
      window.FB.login(this.checkLoginState, { scope });
    }
  };

  render() {
    return (
      <Button variant="outlined" color="primary" type="button" onClick={this.auth} fullWidth>
        {this.props.label}
      </Button>
    );
  }
}

FacebookSignIn.propTypes = {
  callback: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  // children: PropTypes.element.isRequired,
  xfbml: PropTypes.bool,
  cookie: PropTypes.bool,
  scope: PropTypes.string,
  autoLoad: PropTypes.bool,
  fields: PropTypes.string,
  version: PropTypes.string,
  language: PropTypes.string,
  label: PropTypes.string,
};

FacebookSignIn.defaultProps = {
  scope: 'public_profile,email',
  xfbml: false,
  cookie: false,
  fields: 'name,email',
  version: '2.3',
  language: 'ko_KR',
  label: strings.signInWithFacebook,
};

export default FacebookSignIn;
