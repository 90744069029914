import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';

import CardContent from '@material-ui/core/CardContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ArtistCard } from './../../components';

import { PropsData, Order, Tags } from './../Utils/DataUtil';

import * as artistActions from './../../actions/artist';

import { strings, presets } from './../../assets/values';
import { getStyle } from './style';


const values = presets.filterValues;

const order = {
  '-created_at': Order.ID_DESCENDING,
  created_at: Order.ID_ASCENDING,
  '-supporter_count': Order.SUPPORTER_DESCENDING,
  '-latest_post': Order.LATEST_POST_DESCENDING,
};
// const orderString = {
//   '-created_at': strings.artistsOrdering.createdDescending,
//   created_at: strings.artistsOrdering.createdAscending,
//   '-supporter_count': strings.artistsOrdering.supporterDescending,
//   '-latest_post': strings.artistsOrdering.latestPost,
// };

class Artists extends React.Component {
  static childContextTypes = {
    gutterWidth: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      order: order[this.props.ordering],
      progress: true,
    };
  }

  getChildContext = () => ({
    gutterWidth: 16,
  });

  UNSAFE_componentWillMount() {
    this.props.readArtists(null, null, this.props.ordering)
      .then(() => this.setState({ progress: false }));
  }

  onReadMoreArtists = () => {
    this.props.readMoreArtists(this.props._next);
  };

  onUpdateFilter = (event) => {
    const newFilters = { ...this.state.filters, [event.target.value]: event.target.checked };
    this.setState({ filters: newFilters });
    this.props.readArtists(Tags.filterFromObject(newFilters), null, this.props.ordering)
      .then(() => this.setState({ progress: false }));
  };

  onUpdateOrdering = (event) => {
    this.props.setArtistsOrdering(event.target.value);
    this.setState({ progress: true, order: order[event.target.value] });
    this.props.readArtists(Tags.filterFromObject(this.state.filters), null, event.target.value)
      .then(() => this.setState({ progress: false }));
  };

  render() {
    const style = getStyle();
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    return (
      <div style={style.artists.wrapper}>
        <div style={style.artists.cardWrapper}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
              {strings.filter}
            </AccordionSummary>
            <AccordionDetails>
              <CardContent style={style.artists.card.body}>
                <Row style={style.artists.filters.row}>
                  {presets.categories.map(category =>
                    <Col xs={6} sm={6} md={4} lg={2} key={category}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!this.state.filters[category]}
                            value={category}
                            onChange={this.onUpdateFilter}
                            style={style.artists.checkbox}
                          />
                        }
                        label={category}
                      />
                    </Col>)}
                </Row>
              </CardContent>
            </AccordionDetails>
          </Accordion>
          <div style={{ paddingTop: 10 }}>
            <FormControl>
              <Select
                style={{ marginBottom: 16 }}
                value={this.props.ordering}
                onChange={this.onUpdateOrdering}
              >
                <MenuItem value={values.artistsOrdering.supporterDescending}>
                  {strings.artistsOrdering.supporterDescending}
                </MenuItem>
                <MenuItem value={values.artistsOrdering.latestPost}>
                  {strings.artistsOrdering.latestPost}
                </MenuItem>
                <MenuItem value={values.artistsOrdering.createdDescending}>
                  {strings.artistsOrdering.createdDescending}
                </MenuItem>
                <MenuItem value={values.artistsOrdering.createdAscending}>
                  {strings.artistsOrdering.createdAscending}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {this.state.progress && progress}
        {!this.state.progress && this.props.artists.length &&
          <div style={style.artists.cardList}>
            <Row>
              {this.props.artists.sort(this.state.order).map(artist =>
                <Col lg={3} key={artist.id}>
                  <div style={style.artists.cardWrapper}>
                    <ArtistCard artist={artist}/>
                  </div>
                </Col>)}
            </Row>
          </div>
        }
        {this.props._next &&
          <div style={style.artists.readMore}>
            <Button
              onClick={(event) => { event.preventDefault(); this.onReadMoreArtists(); }}
              color="primary"
              variant="outlined"
            >
              더 보기
            </Button>
          </div>
        }
        <div style={{ clear: 'both' }}/>
      </div>
    );
  }
}

Artists.propTypes = {
  artists: PropTypes.array,
  readArtists: PropTypes.func.isRequired,
  ordering: PropTypes.string.isRequired,
  setArtistsOrdering: PropTypes.func.isRequired,
  readMoreArtists: PropTypes.func.isRequired,
  _next: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    artists: PropsData.fromState(state.artist.data, Order.DESCENDING),
    ordering: state.artist.ordering,
    _next: state.artist._nextArtists,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Artists);
