import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { useFormik } from 'formik';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import SetPassword from './SetPassword';

import { RowWithLabel } from './../../components';

import * as userActions from './../../actions/user';
import * as authActions from './../../actions/auth';

import * as Validator from './../Utils/Validator';

import { presets, strings } from './../../assets/values';
import { getStyle } from './style';

import history from './../../history';

const validate = (values) => {
  const errors = {};
  const emailError = Validator.validateContactEmail(values.email);
  const nicknameError = Validator.validateNickName(values.nickname);
  const contactError = Validator.validateContact(values.contact);
  if (emailError) errors.email = emailError;
  if (nicknameError) errors.nickname = nicknameError;
  if (contactError) errors.contact = contactError;
  return errors;
};

const MyProfile = (props) => {
  useEffect(() => {
    props.readUser().then((response) => {
      if (!response.action.payload.ok) history.push('/');
    });
  }, []);

  const onSubmit = (data) => {
    props.updateUser({
      ...data,
      profile_accessible: data.profile_accessible === 'public',
    }).then(() => {
      props.finishEditingUserProfileForm();
    });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      nickname: '',
      contact: '',
      address: [{ address: '' }],
      gender: '',
      profile_accessible: false,
    },
    onSubmit: values => onSubmit(values),
    validate: values => validate(values),
  });

  const handleToggle = () => {
    formik.setValues({
      email: props.user.email,
      nickname: props.user.nickname,
      contact: props.user.contact,
      address: props.user.address.length > 0 ? props.user.address : [{ address: '' }],
      gender: props.user.gender,
      profile_accessible: props.user.profile_accessible ? 'public' : 'private',
    });
    if (props.editing) props.finishEditingUserProfileForm();
    else props.startEditingUserProfileForm();
  };

  const style = getStyle();
  const values = presets.formValues;
  const toggleRowStyle = style.rowWithLabel(30, 0);

  useEffect(() => {
    if (props.user) {
      formik.setValues({
        email: props.user.email,
        nickname: props.user.nickname,
        contact: props.user.contact,
        address: props.user.address.length > 0 ? props.user.address : [{ address: '' }],
        gender: props.user.gender,
        profile_accessible: props.user.profile_accessible ? 'public' : 'private',
      });
    }
  }, [props.user]);

  if (!props.user) {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <Card style={style.innerCard}>
      <CardContent>
        <Button
          variant="outlined"
          onClick={props.startSettingUserPassword}
          type="button"
          disabled={props.settingPassword}
        >
          {strings.changePassword}
        </Button>
        <RowWithLabel style={toggleRowStyle} label={strings.editingInfo} inline>
          <Switch
            onChange={handleToggle}
            checked={props.editing}
            color="primary"
          />
        </RowWithLabel>
        <form onSubmit={formik.handleSubmit}>
          <RowWithLabel label={strings.email}>
            <TextField
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              disabled={!props.editing}
            />
          </RowWithLabel>
          <RowWithLabel label={`${strings.name}/${strings.nickname}`}>
            <TextField
              name="nickname"
              value={formik.values.nickname}
              onChange={formik.handleChange}
              disabled={!props.editing}
            />
          </RowWithLabel>
          <RowWithLabel label={strings.contact}>
            <TextField
              name="contact"
              value={formik.values.contact}
              onChange={formik.handleChange}
              disabled={!props.editing}
              helperText={strings.notAssigned}
            />
          </RowWithLabel>
          <RowWithLabel label={strings.address}>
            <TextField
              name="address[0].address"
              value={formik.values.address[0].address}
              onChange={formik.handleChange}
              disabled={!props.editing}
              helperText={strings.notAssigned}
            />
          </RowWithLabel>
          <RowWithLabel label={strings.gender.label}>
            <RadioGroup
              value={formik.values.gender}
              onChange={formik.handleChange}
              name="gender"
            >
              <FormControlLabel
                control={<Radio color="primary"/>}
                disabled={!props.editing}
                value={values.gender.female}
                label={strings.gender.female}
              />
              <FormControlLabel
                control={<Radio color="primary"/>}
                disabled={!props.editing}
                value={values.gender.male}
                label={strings.gender.male}
              />
              <FormControlLabel
                control={<Radio color="primary"/>}
                disabled={!props.editing}
                value={values.gender.others}
                label={strings.gender.others}
              />
            </RadioGroup>
          </RowWithLabel>
          <RowWithLabel label={strings.accessibility.label}>
            <RadioGroup
              value={formik.values.profile_accessible}
              onChange={formik.handleChange}
              name="profile_accessible"
            >
              <FormControlLabel
                control={<Radio color="primary"/>}
                disabled={!props.editing}
                value="public"
                label={strings.accessibility.inPublic}
              />
              <FormControlLabel
                control={<Radio color="primary"/>}
                disabled={!props.editing}
                value="private"
                label={strings.accessibility.inPrivate}
              />
            </RadioGroup>
            <div style={style.editInform}>{strings.accessibility.inform}</div>
          </RowWithLabel>
          <Button
            variant="contained"
            color="primary"
            disabled={!props.editing || (!formik.dirty || formik.isSubmitting)}
            type="submit"
          >
            수정하기
          </Button>
        </form>
      </CardContent>
      <Dialog
        open={props.settingPassword}
        onClose={props.finishSettingUserPassword}
      >
        <CardContent>
          <SetPassword/>
        </CardContent>
      </Dialog>
    </Card>
  );
};

MyProfile.propTypes = {
  user: PropTypes.object,
  editing: PropTypes.bool,
  settingPassword: PropTypes.bool,
  readUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  startEditingUserProfileForm: PropTypes.func.isRequired,
  finishEditingUserProfileForm: PropTypes.func.isRequired,
  startSettingUserPassword: PropTypes.func.isRequired,
  finishSettingUserPassword: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    editing: state.user.editingProfile,
    settingPassword: state.auth.settingPassword,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActions, ...authActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
