import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { Container, Col, Row } from 'react-grid-system';

import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { DashboardOverview, DashboardCheckout, DashboardProfile, DashboardSupporters, DashboardPurchases } from './../../containers';

// import { SelectableList } from './../../components';

import { strings } from './../../assets/values';

import history from './../../history';

const paths = strings.routes;


class Dashboard extends React.Component {
  navigate = (path) => {
    history.push(`${paths.dashboard}/${path}`);
  };

  render() {
    const getPath = (path = '') => `${this.props.match.path}/${path}`;
    return (
      <Container style={{ paddingTop: 30, paddingBottom: 60 }}>
        <Row>
          <Col md={3}>
            <Card>
              <List>
                {/* defaultValue={history.location.pathname}> */}
                <ListItem
                  button
                  value={`${paths.dashboard}/${paths.dashboardOverview}`}
                  onClick={() => this.navigate(paths.dashboardOverview)}
                >
                  {strings.dashboardOverview}
                </ListItem>
                <ListItem
                  button
                  value={`${paths.dashboard}/${paths.dashboardProfile}`}
                  onClick={() => this.navigate(paths.dashboardProfile)}
                >
                  {strings.dashboardProfile}
                </ListItem>
                <ListItem
                  button
                  value={`${paths.dashboard}/${paths.dashboardSupporters}`}
                  onClick={() => this.navigate(paths.dashboardSupporters)}
                >
                  {strings.dashboardSupporters}
                </ListItem>
                <ListItem
                  button
                  value={`${paths.dashboard}/${paths.dashboardPurchases}`}
                  onClick={() => this.navigate(paths.dashboardPurchases)}
                >
                  {strings.dashboardPurchases}
                </ListItem>
                <ListItem
                  button
                  value={`${paths.dashboard}/${paths.dashboardCheckout}`}
                  onClick={() => this.navigate(paths.dashboardCheckout)}
                >
                  {strings.dashboardCheckout}
                </ListItem>
              </List>
            </Card>
          </Col>
          <Col md={9}>
            <Route exact path={getPath()} component={DashboardOverview}/>
            <Route path={getPath(paths.dashboardOverview)} component={DashboardOverview}/>
            <Route path={getPath(paths.dashboardCheckout)} component={DashboardCheckout}/>
            <Route path={getPath(paths.dashboardProfile)} component={DashboardProfile}/>
            <Route path={getPath(paths.dashboardSupporters)} component={DashboardSupporters}/>
            <Route path={getPath(paths.dashboardPurchases)} component={DashboardPurchases}/>
          </Col>
        </Row>
      </Container>
    );
  }
}

Dashboard.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
  };
}

export default connect(mapStateToProps, null)(Dashboard);
