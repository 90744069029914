import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import { RowWithLabel, PostcodeDialog } from './../../components';
import { numberWithCommas } from './../Utils/NumberUtil';

import { strings, presets, colors } from './../../assets/values';

import * as paymentActions from './../../actions/payment';
import * as userActions from './../../actions/user';

import { getStyle } from './style';
import comodoLogoSrc from './../../assets/images/comodo_cert.png';

import history from './../../history';

const paths = strings.routes;

class ArtistMerchadisePurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkAgreement: false,
      address: '',
      progress: false,
      daumAddress: null,
      postcode: null,
      stepIndex: 0,
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.user) history.push(strings.routes.signIn);
  }

  onSubmit = () => {
    this.setState({ progress: true });
    const data = {
      buyer_name: this.state.buyer_name,
      buyer_contact: this.state.buyer_contact,
      buyer_email: this.state.buyer_email,
      address: {
        address: `${this.state.daumAddress}, ${this.state.address}`,
        addressHead: this.state.daumAddress,
        addressExtra: this.state.address,
        postcode: this.state.postcode,
      },
      card_number: this.state.card_number,
      expired_month: this.state.expired_month,
      expired_year: this.state.expired_year,
      card_password: this.state.card_password,
      card_birth_date: this.state.card_birth_date,
      merchandise: this.props.purchaseForm.merchandise.id,
      amount: this.props.purchaseForm.amount,
      memo: this.state.memo,
    };
    this.props.createPurchase(data)
      .then((response) => {
        this.setState({ progress: false });
        if (!response.action.payload.ok) toastr.error(strings.fail, response.action.payload.data.detail);
        else this.handleNext();
      });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCardChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleNext = () => {
    const stepIndex = this.state.stepIndex + 1;
    this.setState({ stepIndex });
  };

  handlePrevious = () => {
    if (this.state.stepIndex === 0) {
      history.push(`/${this.props.match.params.url}/${strings.routes.artistMerchandise}/${this.props.purchaseForm.merchandise.id}`);
    }
    const stepIndex = this.state.stepIndex - 1;
    this.setState({ stepIndex });
  };

  render() {
    const style = getStyle();
    const defaultRowStyle = style.rowWithLabel(25);
    const PurchaseInfo = () =>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{strings.merchandise}</TableCell>
              <TableCell>{strings.merchandisePrice}</TableCell>
              <TableCell>{strings.shippingPrice}</TableCell>
              <TableCell>{strings.merchandiseQuantity}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {this.props.purchaseForm.merchandise.name} - {this.props.purchaseForm.merchandise.description_inline}
              </TableCell>
              <TableCell>{numberWithCommas(this.props.purchaseForm.merchandise.price)} 원</TableCell>
              <TableCell>{numberWithCommas(this.props.purchaseForm.merchandise.shipping_price)} 원</TableCell>
              <TableCell>{this.props.purchaseForm.amount} 개</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div style={{ textAlign: 'right' }}>
          총 {numberWithCommas(Number((this.props.purchaseForm.merchandise.price * this.props.purchaseForm.amount) + this.props.purchaseForm.merchandise.shipping_price))}원
        </div>
      </CardContent>;
    const steps = [
      <div style={style.purchase.card}>
        <CardHeader
          title={strings.merchandiseInformation}
        />
        <PurchaseInfo/>
        <CardHeader
          title={strings.buyerInformation}
        />
        <CardContent>
          <RowWithLabel label={strings.buyerName}>
            <TextField
              name="buyer_name"
              helperText="구매하시는 분의 성함을 적어주세요"
              value={this.state.buyer_name} onChange={this.handleChange}
            />
          </RowWithLabel>
          <PostcodeDialog onComplete={(address, postcode) => this.setState({ daumAddress: address, postcode })}/>
          <RowWithLabel label={strings.addressExtra}>
            <TextField
              name="address"
              helperText="상세주소를 적어주세요"
              value={this.state.address} onChange={this.handleChange} fullWidth
            />
          </RowWithLabel>
          <RowWithLabel label={strings.buyerContact}>
            <TextField
              name="buyer_contact"
              helperText="휴대전화 번호를 적어주세요"
              value={this.state.buyer_contact} onChange={this.handleChange}
            />
          </RowWithLabel>
          <RowWithLabel label={strings.buyerEmail}>
            <TextField
              name="buyer_email"
              helperText="이메일 주소를 적어주세요"
              value={this.state.buyer_email} onChange={this.handleChange}
            />
          </RowWithLabel>
          <RowWithLabel label={strings.buyerMemo}>
            <TextField
              name="memo"
              helperText="판매자에게 전달할 요청사항을 적어주세요"
              value={this.state.memo} onChange={this.handleChange} fullWidth
            />
          </RowWithLabel>
        </CardContent>
        <CardActions style={{ textAlign: 'right' }}>
          <Button
            onClick={this.handlePrevious}
            style={{ marginRight: 12 }}
          >
            {strings.previous}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!(this.state.address && this.state.buyer_name && this.state.buyer_contact)}
            onClick={(event) => { event.preventDefault(); this.handleNext(); }}
          >
            {strings.next}
          </Button>
        </CardActions>
      </div>,
      <div style={style.purchase.card}>
        <CardHeader
          title={strings.paymentMethod}
        />
        <CardContent>
          <RowWithLabel style={defaultRowStyle} label={strings.cardNumber}>
            <TextField
              name="card_number" helperText={strings.cardNumberHint}
              onChange={this.handleChange}
            />
          </RowWithLabel>
          <RowWithLabel style={defaultRowStyle} label={strings.cardExpirationMonth}>
            <Select
              name="expired_month"
              value={this.state.expired_month}
              onChange={(event, key, value) => this.handleCardChange('expired_month', value)}
            >
              {presets.months.map(item =>
                <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </Select>
          </RowWithLabel>
          <RowWithLabel style={defaultRowStyle} label={strings.cardExpirationYear}>
            <Select
              name="expired_year"
              value={this.state.expired_year}
              onChange={(event, key, value) => this.handleCardChange('expired_year', value)}
            >
              {presets.years.map(item =>
                <MenuItem key={item} value={item}>{`20${item}`}</MenuItem>)}
            </Select>
          </RowWithLabel>
          <RowWithLabel style={defaultRowStyle} label={strings.cardPassword}>
            <TextField
              name="card_password"
              type="password" helperText={strings.cardPasswordHint} style={{ width: '4em' }}
              onChange={this.handleChange}
            />
            <span style={{ display: 'inline-block', verticalAlign: 'top', paddingTop: '1.1em' }}>••</span>
          </RowWithLabel>
          <RowWithLabel style={defaultRowStyle} label={strings.birthDate}>
            <TextField
              name="card_birth_date"
              helperText={strings.birthDateHint}
              onChange={this.handleChange}
            />
          </RowWithLabel>
          <RowWithLabel style={defaultRowStyle} label={strings.agreeToPaymentTerms}>
            <Checkbox
              name="agreeToTerms"
              onClick={() => this.setState({ checkAgreement: !this.state.checkAgreement })}
            />
            <Link href={`${paths.support}/${paths.supportTermsOfPayment}`} to={`${paths.support}/${paths.supportTermsOfPayment}`} target="_blank" style={style.navigateToTerms}>
              {strings.navigateToPaymentTerms}
            </Link>
          </RowWithLabel>
        </CardContent>
        <CardContent style={{ color: colors.textBlack80, clear: 'both', lineHeight: 1.3 }}>
          {strings.paymentInformation}
          <img src={comodoLogoSrc} alt="comodo cert" style={{ display: 'block', width: 150, marginLeft: 'auto' }}/>
        </CardContent>
        <CardActions style={{ textAlign: 'right' }}>
          <Button
            onClick={this.handlePrevious}
          >
            {strings.previous}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="button"
            disabled={this.state.progress}
            onClick={(event) => { event.preventDefault(); this.onSubmit(); }}
          >
            {this.state.progress ? '처리중...' : strings.pay}
          </Button>
        </CardActions>
      </div>,
      <div style={style.purchase.card}>
        <PurchaseInfo/>
        <CardContent style={{ color: colors.textBlack80 }}>
          <Typography>
            문제가 있으신가요?
            <a href={strings.kakaoChatUrl} target="blank" style={{ color: colors.primaryPink }}>
              {' 카카오톡 채널로 문의'}
            </a>
            해주시면 도와드리겠습니다 :)
          </Typography>
        </CardContent>
      </div>,
    ];
    return (
      <div style={style.wrapper}>
        <Row>
          <Col lg={3}/>
          <Col lg={7}>
            <div style={style.rightContainer}>
              <Card style={style.purchase.container}>
                <Stepper activeStep={this.state.stepIndex}>
                  <Step completed={this.state.stepIndex > 0}>
                    <StepLabel>주문정보 확인</StepLabel>
                  </Step>
                  <Step completed={this.state.stepIndex > 1}>
                    <StepLabel>결제수단 선택</StepLabel>
                  </Step>
                  <Step completed={this.state.stepIndex > 2}>
                    <StepLabel>구매 완료</StepLabel>
                  </Step>
                </Stepper>
                {steps[this.state.stepIndex]}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArtistMerchadisePurchase.propTypes = {
  user: PropTypes.object,
  purchaseForm: PropTypes.object,
  createPurchase: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    purchaseForm: state.payment.purchaseForm,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...paymentActions,
    ...userActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistMerchadisePurchase);
