import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useFormik } from 'formik';

import TextField from '@material-ui/core/TextField';

import * as artistActions from './../../actions/artist';
import * as navigatorActions from './../../actions/navigator';

import { strings } from './../../assets/values';

import history from './../../history';


const ArtistsSearchForm = (props) => {
  const onSubmit = (values) => {
    props.readArtists(null, values.search)
      .then((response) => {
        if (response.action.payload.ok) {
          history.push(strings.routes.artistsSearch);
          props.closeNavigatorDrawer();
        }
      });
  };


  const formik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: values => onSubmit(values),
  });

  return (
    <form onSubmit={formik.handleSubmit} style={props.style}>
      <TextField
        fullWidth
        name="search"
        label={strings.searchArtists}
        value={formik.values.search}
        onChange={formik.handleChange}
        margin="dense"
      />
    </form>
  );
};

ArtistsSearchForm.propTypes = {
  style: PropTypes.object,
  readArtists: PropTypes.func.isRequired,
  closeNavigatorDrawer: PropTypes.func.isRequired,
};

ArtistsSearchForm.defaultProps = {
  style: { float: 'left', width: '10em' },
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...artistActions,
    ...navigatorActions,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(ArtistsSearchForm);
