import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { strings, terms } from './../../assets/values';

import { getStyle } from './style';

class SupportTermsOfUse extends React.Component {
  render() {
    const style = getStyle();
    return (
      <Card>
        <CardContent>
          <div style={style.card.title}>{strings.termsOfUse}</div>
          <ul style={style.content}>
            {terms.termsOfUse.map(term =>
              <li key={term.title}>
                <h3>{term.title}</h3>
                {term.subtitle && <p>{term.subtitle}</p>}
                {term.descriptions &&
                  <dl>
                    {term.descriptions.map(item =>
                      <span key={item.title}>
                        <dt style={style.dt}>{item.title}</dt>
                        <dd>{item.body}</dd>
                      </span>)}
                  </dl>
                }
                {term.contents &&
                  <ol>
                    {term.contents.map(content =>
                      <li key={content.body}>
                        {content.body}
                        {content.contents &&
                          <ol>
                            {content.contents.map(subterm => <li key={subterm.body}>{subterm.body}</li>)}
                          </ol>
                        }
                      </li>)}
                  </ol>
                }
              </li>)}
          </ul>
        </CardContent>
      </Card>
    );
  }
}

export default SupportTermsOfUse;
