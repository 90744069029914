import {
  UPLOAD_IMAGE_WITH_EDITOR, UPDATE_EDITOR_CONTENT,
  START_UPLOADING_IMAGE, FINISH_UPLOADING_IMAGE,
} from './../actions/actionTypes';
import { strings } from './../assets/values';
import { fulfilled } from './helper';

const INITIAL_EDITOR_STATE = { imageUrl: null, content: null, uploadingImage: false };

export default function (state = INITIAL_EDITOR_STATE, action) {
  switch (action.type) {
    case fulfilled(UPLOAD_IMAGE_WITH_EDITOR):
      return {
        ...state,
        imageUrl: `${strings.s3.domain}/${strings.s3.bucket}/${action.payload.data.hashed_name}`,
      };
    case UPDATE_EDITOR_CONTENT:
      return {
        ...state,
        content: action.payload,
      };
    case START_UPLOADING_IMAGE:
      return {
        ...state,
        uploadingImage: true,
      };
    case FINISH_UPLOADING_IMAGE:
      return {
        ...state,
        uploadingImage: false,
      };
    default:
      return state;
  }
}
