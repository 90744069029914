import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import * as artistActions from './../../actions/artist';

import { strings, styles } from './../../assets/values';

import { ArtistCard } from './../../components';

import { PropsData, Order } from './../Utils/DataUtil';

import { getStyle } from './style';

import history from './../../history';


class ArtistsSearch extends React.Component {
  onReadMoreArtists = () => {
    this.props.readMoreArtists(this.props._next);
  };

  handleRoute = (artist) => {
    history.push(`/${artist.url_artist}`);
  };

  render() {
    const style = getStyle();
    const readMoreStyle = styles.muiComponents.raisedButton(0);
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (this.props.initializing) return (progress);
    return (
      <div style={style.artists.wrapper}>
        <div style={style.artists.searchHeader}>
          {this.props.searchField} 검색 결과입니다
        </div>
        {this.props.artists.length > 0 ?
          <Row style={style.artists.cardList}>
            {this.props.artists.map(artist => (
              <Col key={artist.id} lg={4}>
                <div style={style.artists.cardWrapper}>
                  <ArtistCard key={artist.id} artist={artist} onClickCard={() => this.handleRoute(artist)}/>
                </div>
              </Col>))}
          </Row> :
          <div style={style.artists.empty}>
            {strings.emptySearchResult}
          </div>
        }
        {this.props._next &&
          <div style={style.artists.readMore}>
            <Button
              fullWidth
              style={readMoreStyle}
              onClick={(event) => { event.preventDefault(); this.onReadMoreArtists(); }}
            >
              더 보기
            </Button>
          </div>
        }
        <div style={{ clear: 'both' }}/>
      </div>
    );
  }
}

ArtistsSearch.propTypes = {
  searchField: PropTypes.string,
  artists: PropTypes.array,
  readArtists: PropTypes.func.isRequired,
  startInitialize: PropTypes.func.isRequired,
  finishInitialize: PropTypes.func.isRequired,
  initializing: PropTypes.bool,
  readMoreArtists: PropTypes.func.isRequired,
  _next: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    searchField: state.artist.searchField,
    artists: PropsData.fromState(state.artist.data, Order.DESCENDING),
    initializing: state.artist.initializing,
    _next: state.artist._nextArtists,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistsSearch);
