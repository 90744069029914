import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import { reducer as ToastrReducer } from 'react-redux-toastr';

import ArtistReducer from './artist';
import AuthReducer from './auth';
import DashboardReducer from './dashboard';
import EditorReducer from './editor';
import PaymentReducer from './payment';
import UserReducer from './user';
import NavigatorReducer from './navigator';
import SupportReducer from './support';

const RootReducer = combineReducers({
  artist: ArtistReducer,
  form: FormReducer,
  toastr: ToastrReducer,
  auth: AuthReducer,
  dashboard: DashboardReducer,
  editor: EditorReducer,
  payment: PaymentReducer,
  user: UserReducer,
  navigator: NavigatorReducer,
  support: SupportReducer,
});

export default RootReducer;
