import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { strings } from './../../assets/values';

class MyDashboardDialog extends React.Component {
  render() {
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{strings.name}</TableCell>
              <TableCell>{strings.email}</TableCell>
              <TableCell>{strings.selectedReward}</TableCell>
              <TableCell>{strings.paymentAmount}</TableCell>
              <TableCell>{strings.sumOfPayment}</TableCell>
              <TableCell>{strings.sumOfMonth}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {this.props.user.nickname}
              </TableCell>
              <TableCell>
                {this.props.user.email}
              </TableCell>
              <TableCell>
                {this.props.reward.title}
              </TableCell>
              <TableCell>
                {this.props.payment_amount}
              </TableCell>
              <TableCell>
                {this.props.user_sum}
              </TableCell>
              <TableCell>
                {this.props.user_count}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{strings.contact}</TableCell>
              <TableCell>{strings.address}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {this.props.user.accessible_contact}
              </TableCell>
              <TableCell>
                {this.props.user.accessible_address[0] && this.props.user.accessible_address[0].address}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

MyDashboardDialog.propTypes = {
  user: PropTypes.object,
  reward: PropTypes.object,
  payment_amount: PropTypes.number,
  user_sum: PropTypes.number,
  user_count: PropTypes.number,
};

export default MyDashboardDialog;
