import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Col, Row } from 'react-grid-system';
import Card from '@material-ui/core/Card';

import { ArtistPostEditor } from './../../containers';

import * as artistActions from './../../actions/artist';

import { getStyle } from './style';


class ArtistPostsNew extends React.Component {
  UNSAFE_componentWillMount() {
    // When environment is not server or data is not fulfilld or route is not mached with state
    if (typeof window !== 'undefined' && (!this.props.artist || this.props.artist.url_artist !== this.props.match.params.url)) {
      this.props.startInitialize();
      this.props.getArtistByUrl(this.props.match.params.url)
        .then(() => this.props.finishInitialize());
    }
  }

  render() {
    const style = getStyle();
    return (
      <div style={style.wrapper}>
        <Row>
          <Col lg={3}/>
          <Col lg={7}>
            <div style={style.rightContainer}>
              <Card style={style.newPost.card && { paddingBottom: 10 }}>
                <ArtistPostEditor match={this.props.match}/>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArtistPostsNew.propTypes = {
  artist: PropTypes.object,
  getArtistByUrl: PropTypes.func.isRequired,
  startInitialize: PropTypes.func.isRequired,
  finishInitialize: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    artist: state.artist.data[state.artist.active],
    initializing: state.artist.initializing,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...artistActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistPostsNew);
