import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useFormik } from 'formik';
import { Row, Col } from 'react-grid-system';

import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PersonIcon from '@material-ui/icons/Person';

import { HashTag, ProfileImage, ImageContainer, SocialShare, RowWithLabel } from './../../components';

import { Permission } from './../Utils/PermissonUtil';

import * as artistActions from './../../actions/artist';
import * as userActions from './../../actions/user';

import { strings, presets } from './../../assets/values';
import { getStyle } from './style';

import history from './../../history';


const style = getStyle();

const ArtistInformation = (props) => {
  const [editing, setEditing] = useState(false);
  const [profileImage, setProfileImage] = useState(props.artist.profile_image);
  const [coverImage, setCoverImage] = useState(props.artist.cover_image);
  const coverImageInputRef = useRef(null);
  const profileImageInputRef = useRef(null);

  useEffect(() => {
    if (props.profileImage.name) {
      setProfileImage(props.profileImage);
    }
  }, [props.profileImage]);

  useEffect(() => {
    if (props.coverImage.name) {
      setCoverImage(props.coverImage);
    }
  }, [props.coverImage]);

  const onSubmit = (data) => {
    const update = {
      name: data.name,
      tags: [],
      description_inline: data.description_inline,
    };
    if (data.CATEGORY_MAIN) update.tags.push(data.CATEGORY_MAIN);
    if (data.REGION) update.tags.push(data.REGION);
    if (data.CATEGORY_SUB) update.tags.push(data.CATEGORY_SUB);
    if (profileImage.name) update.profile_image = profileImage.name;
    if (coverImage.name) update.cover_image = coverImage.name;
    props.onUpdate(update);
    setEditing(false);
  };

  const handleFollow = () => {
    if (!props.user) return history.push(strings.routes.signIn);
    const following = Permission.checkFollower(props.artist, props.user);
    let action;
    if (!following) {
      action = props.createArtistFollow(props.artist.id);
    } else {
      const follows = {};
      props.user.following_artists.map(follow => (follows[follow.artist] = follow.id));
      action = props.deleteArtistFollow(follows[props.artist.id]);
    }
    return action.then(() => props.readUser());
  };

  const startEditing = () => {
    setEditing(true);
  };

  const finishEditing = () => {
    setEditing(false);
  };

  const profileImageBrowserCallback = () => {
    profileImageInputRef.current.click();
  };

  const handleProfileImageUpload = (event) => {
    const node = event.target;
    props.uploadArtistProfileImage(node.files[0]);
    node.value = null;
  };

  const coverImageBrowserCallback = () => {
    coverImageInputRef.current.click();
  };

  const handleCoverImageUpload = (event) => {
    const node = event.target;
    props.uploadArtistCoverImage(node.files[0]);
    node.value = null;
  };

  const defaultValues = {};
  const { tags } = props.artist;
  tags.forEach(tag => (defaultValues[tag.tag_type.split('.')[1]] = tag.name));

  const formik = useFormik({
    initialValues: {
      name: props.artist.name || '',
      CATEGORY_MAIN: defaultValues.CATEGORY_MAIN || '',
      CATEGORY_SUB: defaultValues.CATEGORY_SUB || '',
      REGION: defaultValues.REGION || '',
      description_inline: props.artist.description_inline || '',
    },
    onSubmit: values => onSubmit(values),
  });

  return (
    <div>
      <div style={style.info.coverContainer}>
        <ImageContainer
          url={coverImage} width="100%" height={382}
          gradients
        />
        <div style={style.info.name}>{props.artist.name}</div>
        { Permission.checkArtist(props.artist, props.user) &&
          <Chip
            style={style.info.editChip}
            onClick={startEditing}
            label={strings.edit}
          />
        }
      </div>
      <div style={style.info.infoContainer}>
        <Row>
          <Col lg={3}>
            <div style={{ padding: 10, textAlign: 'center' }}>
              <ProfileImage
                url={profileImage}
                width={150} height={150}
              />
            </div>
          </Col>
          <Col lg={9}>
            <div style={{ padding: 10 }}>
              <div style={style.info.tags}>{props.artist.tags.map(tag => <HashTag key={tag.name} name={tag.name}/>)}</div>
              <div style={style.info.description}>{props.artist.description_inline}</div>
              <div style={style.info.support}>
                <PersonIcon style={style.info.support.icon}/>
                <span style={style.info.support.label}>{strings.supporters}</span>
                <span style={style.info.support.count}>
                  {props.artist.supports_count}명
                </span>
              </div>
              <div style={style.info.follow}>
                <PersonIcon style={style.info.follow.icon}/>
                <span style={style.info.follow.label}>{strings.follower}</span>
                <span style={style.info.follow.count}>
                  {props.artist.follows_count}명
                </span>
              </div>
              <div style={{ paddingTop: 15 }}>
                <SocialShare url={`${strings.appUrl}/${props.artist.url_artist}`} style={style.info.share}/>
                <Button
                  variant="contained"
                  type="button"
                  style={style.info.follow.button}
                  onClick={(event) => { event.preventDefault(); handleFollow(); }}
                >
                  {Permission.checkFollower(props.artist, props.user) ? strings.nowFollowing : strings.follow}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <input
        type="file"
        ref={profileImageInputRef}
        onChange={handleProfileImageUpload}
        style={{ display: 'none' }}
      />
      <input
        type="file"
        ref={coverImageInputRef}
        onChange={handleCoverImageUpload}
        style={{ display: 'none' }}
      />
      <Dialog
        // title={strings.editArtistProfile}
        open={editing}
        onClose={finishEditing}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <RowWithLabel label={strings.artistName}>
              <TextField
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </RowWithLabel>
            <RowWithLabel label={strings.profileImage}>
              <div style={{ verticalAlign: 'top' }}>
                <ImageContainer
                  url={profileImage.name} width={60} height={60}
                  style={{ verticalAlign: 'top' }}
                />
                <Button
                  variant="contained"
                  style={{ marginLeft: 8, verticalAlign: 'top' }}
                  onClick={(event) => { event.preventDefault(); profileImageBrowserCallback(); }}
                  type="button"
                >
                  {strings.change}
                </Button>
              </div>
            </RowWithLabel>
            <RowWithLabel label={strings.coverImage}>
              <div style={{ verticalAlign: 'top' }}>
                <ImageContainer
                  url={coverImage.name} width={180} height={60}
                  style={{ verticalAlign: 'top' }}
                />
                <Button
                  variant="contained"
                  style={{ marginLeft: 8, verticalAlign: 'top' }}
                  onClick={(event) => { event.preventDefault(); coverImageBrowserCallback(); }}
                  type="button"
                >
                  {strings.change}
                </Button>
              </div>
            </RowWithLabel>
            <RowWithLabel label={strings.category}>
              <Select
                name="CATEGORY_MAIN"
                value={formik.values.CATEGORY_MAIN}
                onChange={formik.handleChange}
              >
                { presets.categories.map(category => <MenuItem key={category} value={category}>{category}</MenuItem>) }
              </Select>
            </RowWithLabel>
            <RowWithLabel label={strings.region}>
              <Select
                name="REGION"
                value={formik.values.REGION}
                onChange={formik.handleChange}
              >
                { presets.regions.map(region => <MenuItem key={region} value={region}>{region}</MenuItem>) }
              </Select>
            </RowWithLabel>
            <RowWithLabel label={strings.genre}>
              <TextField
                name="CATEGORY_SUB"
                value={formik.values.CATEGORY_SUB}
                onChange={formik.handleChange}
              />
            </RowWithLabel>
            <RowWithLabel label={strings.simpleDescription}>
              <TextField
                name="description_inline"
                value={formik.values.description_inline}
                onChange={formik.handleChange}
                fullWidth
              />
            </RowWithLabel>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={(event) => { event.preventDefault(); finishEditing(); }}
              type="button"
            >
              {strings.cancel}
            </Button>,
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              {strings.confirm}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

ArtistInformation.propTypes = {
  initialize: PropTypes.func,
  onUpdate: PropTypes.func,
  artist: PropTypes.object,
  user: PropTypes.object,
  profileImage: PropTypes.object,
  coverImage: PropTypes.object,
  profile_image: PropTypes.string,
  uploadArtistProfileImage: PropTypes.func.isRequired,
  uploadArtistCoverImage: PropTypes.func.isRequired,
  createArtistFollow: PropTypes.func.isRequired,
  deleteArtistFollow: PropTypes.func.isRequired,
  readUser: PropTypes.func.isRequired,
};

ArtistInformation.defaultProps = {
  profile_image: strings.dummy.imageUrl,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    profileImage: state.artist.profileImage,
    coverImage: state.artist.coverImage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions, ...userActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistInformation);
