import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { PageNumberPaginator, DashboardPurchaseDialog, DashboardPurchase } from './../../components';

import * as userActions from './../../actions/user';
import * as paymentActions from './../../actions/payment';

import { strings, presets } from './../../assets/values';

import { getStyle } from './style';

import history from './../../history';

const values = presets.filterValues;

class DashboardPurchases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      scope: values.supportersScope.active,
      ordering: values.supportersOrdering.createdAtDescending,
      dialogOpen: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.readUser()
      .then((response) => {
        if (!response.action.payload.ok) history.push('/');
        else this.props.readPurchasesArtist(this.state.currentPage, this.state.scope, this.state.ordering);
      });
  }

  handlePurchasesRead = (page) => {
    this.props.readPurchasesArtist(page);
    this.setState({ currentPage: page });
  };

  handleDialog = (purchase) => {
    this.setState({ dialogOpen: !this.state.dialogOpen });
    if (purchase) {
      this.setState({ dialogPurchase: purchase });
    }
  };

  render() {
    const style = getStyle();
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.user) return (progress);
    return (
      <div>
        <Card style={style.innerCard}>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{strings.buyerName}</TableCell>
                  <TableCell>{strings.buyerContact}</TableCell>
                  <TableCell>{strings.merchandise}</TableCell>
                  <TableCell>{strings.purchaseAmount}</TableCell>
                  <TableCell>{strings.address}</TableCell>
                  <TableCell>{strings.orderDate}</TableCell>
                  <TableCell>{strings.viewDetail}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.purchasesArtist.results && this.props.purchasesArtist.results.map(purchase =>
                  <DashboardPurchase
                    key={purchase.id || 0} {...purchase}
                    onClickPurchase={() => this.handleDialog(purchase)}
                  />)}
              </TableBody>
            </Table>
            <PageNumberPaginator
              currentPage={this.state.currentPage}
              count={this.props.purchasesArtist.count}
              onPageClick={page => this.handlePurchasesRead(page, this.state.scope, this.state.ordering)}
            />
          </CardContent>
        </Card>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleDialog}
        >
          <DashboardPurchaseDialog {...this.state.dialogPurchase}/>
        </Dialog>
      </div>
    );
  }
}

DashboardPurchases.propTypes = {
  user: PropTypes.object,
  accessToken: PropTypes.string,
  purchasesArtist: PropTypes.object,
  readUser: PropTypes.func.isRequired,
  readPurchasesArtist: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    accessToken: state.auth.accessToken,
    purchasesArtist: state.payment.purchasesArtist,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...paymentActions, ...userActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPurchases);
