import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DashboardContent, CheckoutTable } from './../../components';

import * as userActions from './../../actions/user';
import * as dashboardActions from './../../actions/dashboard';

import { strings } from './../../assets/values';

import history from './../../history';


const styles = {
  DashboardCard: {
    marginTop: -1,
  },
  DashboardContent: {
    padding: 8,
    marginBottom: 12,
  },
};

class DashboardOverview extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.readUser()
      .then((response) => {
        if (!response.action.payload.ok) history.push('/');
        else {
          // this.props.readPageViews(this.props.user.artist[0].id);
          // this.props.readPageViewsUserGender(this.props.user.artist[0].id);
          this.props.readCheckout(2017, 3);
        }
      });
  }

  genderRatio = (data, flag) => {
    let female;
    if (!data.rows) return '*';
    for (let i = 0; i < data.rows.length; i += 1) {
      if (data.rows[i].dimensions[1] === 'female') {
        female = data.rows[i].metrics[0].values;
      }
    }
    const total = data.totals[0].values[0];
    if (flag) return Math.floor(((total - female) / total) * 100);
    return Math.floor((female / total) * 100);
  };

  render() {
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.user || !this.props.checkout) return progress;
    return (
      <div>
        {/* <DashboardContent title={strings.pageViewsAnalytics} style={styles.DashboardContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{strings.pageViewsOf30Days}</TableCell>
                <TableCell>{strings.userGenderRatio}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {`${this.props.analytics.pageViews.reports[0].data.totals[0].values[0]} 명`}
                </TableCell>
                <TableCell>
                  {strings.gender.female}: {this.genderRatio(this.props.analytics.userGender.reports[0].data)} %<br/>
                  {strings.gender.male}: {this.genderRatio(this.props.analytics.userGender.reports[0].data, true)} %
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DashboardContent> */}
        <DashboardContent title={strings.dashboardSupporters} style={styles.DashboardContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{strings.supporters}</TableCell>
                <TableCell>{strings.balancePayment}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {`${this.props.user.artist[0].supports_count} 명`}
                </TableCell>
                <TableCell>
                  {`${this.props.user.artist[0].supports_sum} 원`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DashboardContent>
        <DashboardContent title={strings.lastCheckout} style={styles.DashboardContent}>
          <div style={{ padding: 8 }}>
            <CheckoutTable checkout={this.props.checkout}/>
          </div>
        </DashboardContent>
      </div>
    );
  }
}

DashboardOverview.propTypes = {
  user: PropTypes.object,
  analytics: PropTypes.object,
  checkout: PropTypes.object,
  readUser: PropTypes.func.isRequired,
  readPageViews: PropTypes.func.isRequired,
  readPageViewsUserGender: PropTypes.func.isRequired,
  readCheckout: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    analytics: state.dashboard.analytics,
    checkout: state.dashboard.checkout,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...userActions,
    ...dashboardActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardOverview);
