import { applyMiddleware, compose, createStore } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';

import RootReducer from './../reducers';

const enhancer = compose(applyMiddleware(promiseMiddleware));

export default function configureStore(initialState) {
  const store = createStore(RootReducer, initialState, enhancer);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (module.hot) {
    module.hot.accept('./../reducers', () =>
      store.replaceReducer(require('./../reducers'))/*.default if you use Babel 6+ */) // eslint-disable-line
  }

  return store;
}
