import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useFormik } from 'formik';

import { Row, Col } from 'react-grid-system';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import * as artistActions from './../../actions/artist';

import { colors, strings, styles } from './../../assets/values';


const style = {
  card: {
    marginTop: 16,
    padding: 16,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.border,
    borderBottomWidth: 0.5,
    title: {
      fontWeight: styles.fontWeight.Medium,
      fontSize: styles.fontSize.xl,
      color: colors.textBlack,
      span: { fontSize: 53 },
    },
    subtitle: {
      paddingTop: 8,
      fontWeight: styles.fontWeight.Regular,
      fontSize: styles.fontSize.md,
      color: colors.textBlack60,
    },
    body: {
      paddingTop: 16,
      fontWeight: styles.fontWeight.Regular,
      fontSize: styles.fontSize.md,
      color: colors.textBlack80,
      lineHeight: 1.7,
      whiteSpace: 'pre-line',
    },
  },
  buttons: {
    textAlign: 'right',
  },
  raisedButton: {
    height: 25,
    minWidth: 65,
    borderRadius: 40,
    backgroundColor: colors.primaryPink,
  },
  reward: {
    textAlign: 'center',
    marginBottom: 45,
    marginTop: 45,
    color: colors.textBlack60,
  },
  textField: styles.muiComponents.textField(),
};


const ArtistReward = (props) => {
  const [editing, setEditing] = useState(props.editing);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const onSubmit = (data) => {
    if (!props.creating) {
      const newData = {
        ...data,
        id: props.id,
        artist: props.artist,
        title: `${data.amount}`
      };
      props.onUpdateReward(props.id, newData)
        .then(() => props.readArtistRewards(props.artist))
        .then(() => setEditing(false));
    } else {
      const newData = { ...data, artist: props.artist, title: `${data.amount}` };
      props.onUpdateReward(newData)
        .then(() => props.readArtistRewards(props.artist))
        .then(() => setEditing(false));
    }
    props.finishCreateReward();
  };

  const onDelete = (id) => {
    props.deleteArtistReward(id)
      .then(() => props.readArtistRewards(props.artist));
  };

  const openConfirmDelete = () => {
    setConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setConfirmDelete(false);
  };

  const startEditing = () => {
    // props.initialize({ id: props.id, amount: props.amount, body: props.body });
    setEditing(true);
  };

  const finishEditing = () => {
    // props.initialize({ id: props.id, amount: props.amount, body: props.body });
    setEditing(false);
    props.finishCreateReward();
  };

  const formik = useFormik({
    onSubmit: values => onSubmit(values),
    initialValues: {
      amount: props.amount,
      body: props.body,
      limit_people: props.limit_people,
    }
  });
  return (
    <div>
      { props.permission ?
        <div style={style.card}>
          { editing ?
            <form onSubmit={formik.handleSubmit}>
              <div>
                <TextField
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  style={style.textField}
                  name="amount"
                  label={`${strings.artistRewardAmount} (${strings.typeNumber})`}
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  value={formik.values.body}
                  onChange={formik.handleChange}
                  style={{ ...style.textField, width: '100%' }}
                  name="body"
                  label={strings.artistRewardBody}
                  multiline
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  value={formik.values.limit_people}
                  onChange={formik.handleChange}
                  style={style.textField}
                  name="limit_people"
                  type="number"
                  label={strings.limitPeople}
                  margin="normal"
                />
              </div>
              <div style={style.buttons}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                >
                  {strings.confirm}
                </Button>
                <Button
                  type="button"
                  onClick={(event) => { event.preventDefault(); finishEditing(); }}
                >
                  {strings.cancel}
                </Button>
              </div>
            </form> :
            <Row>
              <Col md={6}>
                <div style={style.card.title && style.reward}>
                  <div>
                    <span style={style.card.title.span}>{props.amount}{strings.won}</span>
                  </div>
                  <div>
                    <span style={style.card.subtitle}>{strings.supporterCount(props.supports_count, props.limit_people)}</span>
                  </div>
                  <div style={{ paddingTop: 25 }}>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={startEditing}
                    >
                      {strings.edit}
                    </Button>
                    {props.supports_count === 0 &&
                      <Button
                        type="button"
                        onClick={openConfirmDelete}
                      >
                        {strings.remove}
                      </Button>
                    }
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div style={style.card.body || style.reward}>{props.body}</div>
              </Col>
              <Dialog open={confirmDelete} >
                <div style={{ width: '240px', height: '30px', padding: '40px' }}>
                  {strings.wantDelete}
                </div>
                <DialogActions>
                  <Button color="default" onClick={closeConfirmDelete}>
                    {strings.cancel}
                  </Button>
                  <Button color="primary" onClick={(event) => { event.preventDefault(); onDelete(props.id); }}>
                    {strings.remove}
                  </Button>
                </DialogActions>
              </Dialog>
            </Row>
          }
        </div> :
        <div style={style.card}>
          <Row>
            <Col md={6}>
              <div style={style.card.title && style.reward}>
                <div>
                  <span style={style.card.title.span}>{props.amount}{strings.won}</span>
                </div>
                <div>
                  <span style={style.card.subtitle}>{strings.supporterCount(props.supports_count, props.limit_people)}</span>
                </div>
                <div style={{ paddingTop: 25 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    style={style.raisedButton}
                    disabled={props.limit_people > 0 && props.supports_count >= props.limit_people}
                    onClick={(event) => { event.preventDefault(); props.onClickReward(); }}
                  >
                    {strings.createSupport}
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div style={style.card.body || style.reward}>{props.body}</div>
            </Col>
          </Row>
        </div>
      }
    </div>
  );
};

ArtistReward.propTypes = {
  id: PropTypes.number,
  artist: PropTypes.number,
  amount: PropTypes.number,
  body: PropTypes.string,
  data: PropTypes.object.isRequired,
  limit_people: PropTypes.number,
  title: PropTypes.string,
  supports_count: PropTypes.number,
  onClickReward: PropTypes.func,
  permission: PropTypes.bool.isRequired,
  finishCreateReward: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  readArtistRewards: PropTypes.func.isRequired,
  onUpdateReward: PropTypes.func.isRequired,
  deleteArtistReward: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  creating: PropTypes.bool.isRequired,
};

ArtistReward.defaultProps = {
  id: 0,
  artist: 0,
  amount: 3000,
  body: '리워드 설명',
  limit_people: 0,
  title: '리워드 제목',
  supports_count: 0,
};

function mapStateToProps(state) {
  return {
    data: state.artist.data[state.artist.active],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...artistActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistReward);
