const checkArtist = (artist, user) => {
  if (!artist || !user) return false;
  else if (user.artist[0] && artist.id === user.artist[0].id) return true;
  else if (user.is_staff) return true;
  return false;
};

const checkSupporter = (artist, user) => {
  if (!user) return false;
  else if (user.artist[0] && artist.id === user.artist[0].id) return true;
  else if (user.supporting_artists.includes(artist.id)) return true;
  else if (user.is_staff) return true;
  return false;
};

const checkFollower = (artist, user) => {
  if (!user) return false;
  const followingArtists = user.following_artists.map(follow => follow.artist);
  if (user.artist[0] && artist.id === user.artist[0].id) return true;
  else if (followingArtists.includes(artist.id)) return true;
  else if (user.is_staff) return true;
  return false;
};

const checkUser = (user) => {
  if (!user) return false;
  return true;
};

const checkWriter = (writer, user) => {
  if (!user) return false;
  else if (writer.id === user.id) return true;
  else if (writer === user.nickname) return true; // for legacy
  return false;
};

export const Permission = {
  checkArtist,
  checkSupporter,
  checkFollower,
  checkUser,
  checkWriter
};
