import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';
import Button from '@material-ui/core/Button';
import { CheerPost } from './../../containers';

import * as artistActions from './../../actions/artist';
import { Permission } from './../Utils/PermissonUtil';
import { strings } from './../../assets/values';

import { getStyle } from './style';
import history from './../../history';


class ArtistSingleCheer extends React.Component {
  static fetchData({ store, params }) {
    return store.dispatch(artistActions.getArtistByUrl(params.url))
      .then((response) => {
        if (response.action.payload.ok) {
          store.dispatch(artistActions.finishInitialize());
          return store.dispatch(artistActions.readArtistCheer(response.action.payload.data.id, params.postNumber));
        }
        return Promise.resolve(null);
      });
  }

  UNSAFE_componentWillMount() {
    // When environment is not server or data is not fulfilld or route is not mached with state
    if (typeof window !== 'undefined' && (!this.props.cheer || this.props.cheer.id !== this.props.match.params.postNumber)) {
      this.props.readArtistCheer(this.props.artistId, this.props.match.params.postNumber);
    }
  }

  handleGoBack = () => {
    history.push(`/${this.props.artist.url_artist}/${strings.routes.artistCheers}/`);
  }

  render() {
    const style = getStyle();
    // TODO : 미스매칭 됬을 때 컴포넌트를 이용해서 잘못 된 접근이라고 알려줍시다.
    return (
      <div style={style.wrapper}>
        <Row >
          <Col lg={3}/>
          <Col lg={7}>
            <div style={style.rightContainer}>
              <div style={style.posts.list}>
                { this.props.cheer &&
                  <CheerPost
                    artist={this.props.artist}
                    post={this.props.cheer}
                    replyPermission={Permission.checkUser(this.props.user)}
                    editingPermission={Permission.checkWriter(this.props.cheer.user, this.props.user)}
                    commentsExpanded
                    actions={[
                      <Button
                        variant="outlined"
                        color="secondary"
                        key="goBack"
                        onClick={this.handleGoBack}
                      >
                        전체 글 보기
                      </Button>
                    ]}
                    type="cheer"
                    match={this.props.match}
                  />
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArtistSingleCheer.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  artistId: PropTypes.number,
  cheer: PropTypes.object,
  readArtistCheer: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    artist: state.artist.data[state.artist.active],
    artistId: state.artist.active,
    cheer: state.artist.data[state.artist.active].singleCheer,
    fetching: state.artist.fetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistSingleCheer);
