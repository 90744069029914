import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Col, Row } from 'react-grid-system';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

import { ArtistIntroduction, ArtistPost } from '..';
import { ArtistSchedule, EncourageSupport } from '../../components';

import { PropsData, Order } from '../Utils/DataUtil';
import { Permission } from '../Utils/PermissonUtil';

import * as artistActions from '../../actions/artist';

import { strings } from '../../assets/values';
import { getStyle } from './style';

import history from '../../history';

const paths = strings.routes;


class ArtistPostsFeed extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.clearArtistPosts(this.props.artist.id);
    this.props.readArtistPosts(this.props.artist.id, undefined, undefined, undefined, this.props.variant);
    this.props.readArtistPostTags(this.props.artist.id);
    this.props.readArtistSchedules(this.props.artist.id);
  }

  onReadPostsByTag = (tag) => {
    this.props.readArtistPosts(this.props.artist.id, tag.name, undefined, undefined, undefined, this.props.variant);
  };

  onReadMorePosts = () => {
    this.props.readMoreArtistPosts(this.props.artist.id, this.props._next);
  };

  writingPost = () => {
    history.push(`/${this.props.match.params.url}/${paths.artistPostsNew}`);
  };

  render() {
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.posts || !this.props.schedules || !this.props.artist.post_tags) return (progress);
    const style = getStyle();
    return (
      <div style={style.wrapper}>
        <Row>
          <Col lg={3}>
            <div style={style.leftContainer}>
              { Permission.checkArtist(this.props.artist, this.props.user) ?
                <Button
                  onClick={(event) => { event.preventDefault(); this.writingPost(); }}
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  {strings.writePost}
                </Button>
                 :
                <EncourageSupport
                  name={this.props.artist.name} url_artist={this.props.artist.url_artist}
                  supporting={Permission.checkSupporter(this.props.artist, this.props.user)}
                />
              }
              <Card style={style.posts.tags.container}>
                <CardContent>
                  <div style={style.posts.tags.title}>{strings.artistPostTags}</div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Chip
                      style={style.posts.tags.chip}
                      label={strings.readAllPosts}
                      onClick={() => this.props.readArtistPosts(this.props.artist.id, undefined, undefined, undefined, this.props.variant)}
                    />
                    {this.props.artist.post_tags.map(tag =>
                      <Chip
                        key={tag.id}
                        style={style.posts.tags.chip}
                        label={`#${tag.name}`}
                        onClick={() => this.onReadPostsByTag(tag)}
                      />)}
                  </div>
                </CardContent>
              </Card>
              {this.props.schedules.length > 0 &&
              <Card style={style.posts.schedules.container}>
                <CardContent>
                  <div style={style.posts.schedules.title}>{strings.artistSchedules}</div>
                  {this.props.schedules.sort(Order.SCHEDULE_DATE_ASCENDING).map(schedule =>
                    <ArtistSchedule key={schedule.id} title={schedule.title} date={schedule.date}/>)}
                </CardContent>
              </Card>
              }
            </div>
          </Col>
          <Col lg={8}>
            <div style={style.rightContainer}>
              <ArtistIntroduction/>
              {this.props.posts.map(post =>
                <ArtistPost
                  key={post.id} post={post}
                  replyPermission={this.props.user !== null}
                  editingPermission={Permission.checkArtist(this.props.artist, this.props.user)}
                />)}
              {this.props._next &&
                <div style={{ textAlign: 'center', paddingTop: 8 }}>
                  <Button
                    color="primary"
                    onClick={(event) => { event.preventDefault(); this.onReadMorePosts(); }}
                  >
                    더 보기
                  </Button>
                </div>
              }
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArtistPostsFeed.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  posts: PropTypes.array,
  schedules: PropTypes.array,
  clearArtistPosts: PropTypes.func.isRequired,
  readArtistPosts: PropTypes.func.isRequired,
  readMoreArtistPosts: PropTypes.func.isRequired,
  readArtistPostTags: PropTypes.func.isRequired,
  readArtistSchedules: PropTypes.func.isRequired,
  _next: PropTypes.string,
  match: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const sortedPosts = PropsData.fromState(state.artist.data[state.artist.active].posts, Order.DESCENDING);
  const sortedSchedules = PropsData.fromState(state.artist.data[state.artist.active].schedules, Order.DESCENDING);
  return {
    user: state.user.data,
    artist: state.artist.data[state.artist.active],
    posts: sortedPosts,
    schedules: sortedSchedules,
    _next: state.artist._nextPosts,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistPostsFeed);
