import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-grid-system';

import Button from '@material-ui/core/Button';

import history from './../../history';


class SimpleNavigator extends React.Component {
  render() {
    return (
      <Row style={{ marginBottom: '20px' }}>
        <Col md={2}>
          { this.props.back ?
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => history.goBack()}
            >
              Back
            </Button> : null
          }
        </Col>
        <Col md={2}/>
        <Col md={4}>
          <Button style={{ width: '100%' }} disabled>
            {this.props.title || this.props.path}
          </Button>
        </Col>
        <Col md={2}>
          { this.props.edit ?
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={() => history.push(this.props.editPath || `${this.props.path}/edit`)}
            >
              Edit
            </Button>
            : null
          }
        </Col>
        <Col md={2}>
          { this.props.write ?
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => history.push(this.props.writePath || `${this.props.path}/new`)}
            >
              Write
            </Button>
            : null
          }
        </Col>
      </Row>
    );
  }
}

SimpleNavigator.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string.isRequired,
  back: PropTypes.bool,
  edit: PropTypes.bool,
  editPath: PropTypes.string,
  write: PropTypes.bool,
  writePath: PropTypes.string,
};

SimpleNavigator.defaultProps = {
  back: false,
  edit: false,
  write: false,
};

export default SimpleNavigator;
