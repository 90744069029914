import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';

import Button from '@material-ui/core/Button';

import { ArtistIntroduction, ArtistPost } from './../../containers';
import { EncourageSupport } from './../../components';

import * as artistActions from './../../actions/artist';

import { Permission } from './../Utils/PermissonUtil';

import { strings } from './../../assets/values';

import { getStyle } from './style';
import history from '../../history';

const paths = strings.routes;


class ArtistSinglePost extends React.Component {
  static fetchData({ store, params }) {
    return store.dispatch(artistActions.getArtistByUrl(params.url))
      .then((response) => {
        if (response.action.payload.ok) {
          store.dispatch(artistActions.finishInitialize());
          return store.dispatch(artistActions.readArtistPost(response.action.payload.data.id, params.postNumber));
        }
        return Promise.resolve(null);
      });
  }

  UNSAFE_componentWillMount() {
    // When environment is not server or data is not fulfilld or route is not mached with state
    if (typeof window !== 'undefined' && (!this.props.post || this.props.post.id !== this.props.match.params.postNumber)) {
      this.props.readArtistPost(this.props.artistId, this.props.match.params.postNumber);
    }
  }

  writingPost = () => {
    history.push(`/${this.props.match.params.url}/${paths.artistPostsNew}`);
  };

  render() {
    const style = getStyle();
    // TODO : 미스매칭 됬을 때 컴포넌트를 이용해서 잘못 된 접근이라고 알려줍시다.
    return (
      <div style={style.wrapper}>
        <Row >
          <Col lg={3}>
            <div style={style.leftContainer}>
              { Permission.checkArtist(this.props.artist, this.props.user) ?
                <Button
                  variant="contained"
                  onClick={(event) => { event.preventDefault(); this.writingPost(); }}
                  color="primary"
                  fullWidth
                >
                  {strings.writePost}
                </Button> :
                <EncourageSupport
                  name={this.props.artist.name} url_artist={this.props.artist.url_artist}
                  supporting={Permission.checkSupporter(this.props.artist, this.props.user)}
                />
              }
            </div>
          </Col>
          <Col lg={7}>
            <div style={style.rightContainer}>
              <div style={style.posts.list}>
                <ArtistIntroduction/>
                { this.props.post &&
                  <ArtistPost
                    post={this.props.post}
                    replyPermission={Permission.checkSupporter(this.props.artist, this.props.user)}
                    editingPermission={Permission.checkArtist(this.props.artist, this.props.user)}
                  />
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArtistSinglePost.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  artistId: PropTypes.number,
  post: PropTypes.object,
  readArtistPost: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    artist: state.artist.data[state.artist.active],
    artistId: state.artist.active,
    post: state.artist.data[state.artist.active].singlePost,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistSinglePost);
