import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-grid-system';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import { deleteArtistCheerReply } from '../../actions/artist';
import { strings } from '../../assets/values';

import { DateTime } from '../../containers/Utils/DateTimeUtil';

class ArtistCheerPostReply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,
    };
  }

  onDeleteReply = () => {
    (() => {
      deleteArtistCheerReply(this.props.id);
      return Promise.resolve(null);
    })().then(() => this.setState({ confirmDelete: false }))
      .then(() => this.props.onDeleteCheerPostReply());
  };

  openConfirmDelete = () => {
    this.setState({ confirmDelete: true });
  };

  closeConfirmDelete = () => {
    this.setState({ confirmDelete: false });
  };

  render() {
    const deleteActions = [
      <Button key="cancel" color="primary" onClick={(event) => { event.preventDefault(); this.closeConfirmDelete(); }}>
        {strings.cancel}
      </Button>,
      <Button key="remove" color="primary" onClick={(event) => { event.preventDefault(); this.onDeleteReply(); }}>
        {strings.remove}
      </Button>,
    ];
    return (
      <Row>
        <Col md={2}>
          <Typography>{this.props.user.nickname}</Typography>
        </Col>
        <Col md={9}>
          <Typography>
            {this.props.body}
            <span style={this.props.style.timestamp}>{DateTime.dateFormat(this.props.created_at)}</span>
            <button style={this.props.style.deleteButton} onClick={(event) => { event.preventDefault(); this.openConfirmDelete(); }}>✕</button>
          </Typography>
        </Col>
        <div>
          <Dialog open={this.state.confirmDelete} >
            <div style={{ width: '240px', height: '30px', padding: '40px' }}>
              {strings.wantDelete}
            </div>
            <DialogActions>
              {deleteActions}
            </DialogActions>
          </Dialog>
        </div>
      </Row>
    );
  }
}

ArtistCheerPostReply.propTypes = {
  id: PropTypes.number,
  user: PropTypes.object.isRequired,
  body: PropTypes.string.isRequired,
  created_at: PropTypes.string,
  style: PropTypes.object,
  onDeleteCheerPostReply: PropTypes.func.isRequired,
};

export default ArtistCheerPostReply;
