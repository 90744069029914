import React from 'react';
import PropTypes from 'prop-types';

import { strings } from './../../assets/values';


class ProfileImage extends React.Component {
  parseUrl = (url) => {
    if (url.length === 0) {
      return `url(${strings.dummy.imageUrl})`;
    }
    if (this.props.raw) {
      return `url(${url})`;
    }
    if (this.props.gradients) {
      return `linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.6) 100%), url(${strings.s3.domain}/${strings.s3.bucket}/${this.props.url})`;
    }
    return `url(${strings.s3.domain}/${strings.s3.bucket}/${this.props.url})`;
  };

  render() {
    const styles = {
      crop: {
        display: 'inline-block',
        width: this.props.width,
        height: this.props.height,
        backgroundImage: this.parseUrl(this.props.url),
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        borderRadius: this.props.width / 2,
      },
    };
    return (
      <div style={{ ...styles.crop, ...this.props.style }}/>
    );
  }
}

ProfileImage.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
  url: PropTypes.string,
  raw: PropTypes.bool,
  gradients: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

ProfileImage.defaultProps = {
  width: 100,
  height: 100,
  url: strings.dummy.imageUrl,
  raw: false,
  gradients: false,
};

export default ProfileImage;
