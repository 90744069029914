import { styles } from './../../assets/values';

const muiTabs = styles.muiComponents.tabs(60);
export const getStyle = (stylesToOverride = {}) => Object.assign({
  card: {
    paddingBottom: 20,
  },
  body: {
    width: 600,
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  innerCard: {
    marginBottom: 60,
  },
  rowWithLabel: styles.rowWithLabel,
  tabs: muiTabs,
  tab: muiTabs.tab,
  inkBar: muiTabs.inkBar,
  muiRaisedButton: styles.muiComponents.raisedButton,
  muiCheckbox: styles.muiComponents.checkbox,
  muiTextField: styles.muiComponents.textField,
  muiRadioButtonGroup: {
    display: 'flex',
    marginTop: 14,
  },
  muiRadioButton: {
    width: 120,
  },
  editInform: {
    fontSize: styles.fontSize.sm,
    paddingTop: 8,
  },
}, stylesToOverride);
