// TODO : Implement attribute compare function for better flexibility
const ASCENDING = (a, b) => parseInt(a, 10) - parseInt(b, 10);
const DESCENDING = (a, b) => parseInt(b, 10) - parseInt(a, 10);
const ID_ASCENDING = (a, b) => parseInt(a.id, 10) - parseInt(b.id, 10);
const ID_DESCENDING = (a, b) => parseInt(b.id, 10) - parseInt(a.id, 10);
const SUPPORTER_DESCENDING = (a, b) => parseInt(b.supports_count, 10) - parseInt(a.supports_count, 10);
const LATEST_POST_DESCENDING = (a, b) => new Date(b.posts_updated_at).getTime() - new Date(a.posts_updated_at).getTime();
const SCHEDULE_DATE_ASCENDING = (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime();

export const Order = {
  ASCENDING,
  DESCENDING,
  ID_ASCENDING,
  ID_DESCENDING,
  SUPPORTER_DESCENDING,
  LATEST_POST_DESCENDING,
  SCHEDULE_DATE_ASCENDING
};


// TODO : Implement Method chaining for better readability
const fromState = (data, order) => {
  if (!data) return null;
  const sorted = [];
  Object.keys(data).sort(order).forEach(key => sorted.push(data[key]));
  return sorted;
};

export const PropsData = { fromState };


const filterFromObject = object => (
  Object.keys(object).filter(key => object[key])
);

export const Tags = { filterFromObject };
