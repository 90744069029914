import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Col, Row } from 'react-grid-system';

import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FavoriteIcon from '@material-ui/icons/Favorite';

import { ArtistIntroduction } from '..';
import { ArtistSchedule, EncourageSupport, PageNumberPaginator } from '../../components';

import { PropsData, Order } from '../Utils/DataUtil';
import { DateTime } from '../Utils/DateTimeUtil';
import { Permission } from '../Utils/PermissonUtil';

import * as artistActions from '../../actions/artist';

import { strings, colors } from './../../assets/values';
import { getStyle } from './style';

import history from '../../history';

const paths = strings.routes;


class ArtistPostsBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.clearArtistPosts(this.props.artist.id);
    this.props.readArtistPosts(this.props.artist.id, undefined, undefined, undefined, this.props.variant);
    this.props.readArtistPostTags(this.props.artist.id);
    this.props.readArtistSchedules(this.props.artist.id);
  }

  onReadPostsByTag = (tag) => {
    this.props.readArtistPosts(this.props.artist.id, tag.name, undefined, undefined, this.props.variant);
  };

  handlePageClick = (page = 1) => {
    this.props.readArtistPosts(this.props.artist.id, undefined, page, undefined, this.props.variant);
    this.setState({ currentPage: page });
  };

  handlePostClick = (id) => {
    history.push(`/${this.props.artist.url_artist}/${strings.routes.artistPosts}/${id}`);
  };

  writingPost = () => {
    history.push(`/${this.props.match.params.url}/${paths.artistPostsNew}`);
  };

  render() {
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.posts || !this.props.schedules || !this.props.artist.post_tags) return (progress);
    const style = getStyle();
    return (
      <div style={style.wrapper}>
        <Row>
          <Col lg={3}>
            <div style={style.leftContainer}>
              { Permission.checkArtist(this.props.artist, this.props.user) ?
                <Button
                  onClick={(event) => { event.preventDefault(); this.writingPost(); }}
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  {strings.writePost}
                </Button>
                 :
                <EncourageSupport
                  name={this.props.artist.name} url_artist={this.props.artist.url_artist}
                  supporting={Permission.checkSupporter(this.props.artist, this.props.user)}
                />
              }
              <Card style={style.posts.tags.container}>
                <CardContent>
                  <div style={style.posts.tags.title}>{strings.artistPostTags}</div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Chip
                      style={style.posts.tags.chip}
                      label={strings.readAllPosts}
                      onClick={() => this.props.readArtistPosts(this.props.artist.id, undefined, undefined, undefined, this.props.variant)}
                    />
                    {this.props.artist.post_tags.map(tag =>
                      <Chip
                        key={tag.id}
                        style={style.posts.tags.chip}
                        label={`#${tag.name}`}
                        onClick={() => this.onReadPostsByTag(tag)}
                      />)}
                  </div>
                </CardContent>
              </Card>
              {this.props.schedules.length > 0 &&
              <Card style={style.posts.schedules.container}>
                <CardContent>
                  <div style={style.posts.schedules.title}>{strings.artistSchedules}</div>
                  {this.props.schedules.sort(Order.SCHEDULE_DATE_ASCENDING).map(schedule =>
                    <ArtistSchedule key={schedule.id} title={schedule.title} date={schedule.date}/>)}
                </CardContent>
              </Card>
              }
            </div>
          </Col>
          <Col lg={8}>
            <div style={style.rightContainer}>
              <ArtistIntroduction/>
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>제목</TableCell>
                      <TableCell style={{ width: '2em' }}><FavoriteIcon fontSize="inherit" /></TableCell>
                      <TableCell style={{ width: '6em' }}>작성일</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.posts.map(post => (
                      <TableRow key={post.id} onClick={() => this.handlePostClick(post.id)} hover>
                        <TableCell>{post.title} <span style={{ color: colors.textBlack60 }}>({post.reply_count})</span></TableCell>
                        <TableCell numeric>{post.like_count}</TableCell>
                        <TableCell>{DateTime.dateFormat(post.created_at)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <PageNumberPaginator
                  currentPage={this.state.currentPage}
                  count={this.props.count}
                  onPageClick={this.handlePageClick}
                />
              </Paper>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArtistPostsBoard.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  posts: PropTypes.array,
  count: PropTypes.number,
  schedules: PropTypes.array,
  clearArtistPosts: PropTypes.func.isRequired,
  readArtistPosts: PropTypes.func.isRequired,
  readMoreArtistPosts: PropTypes.func.isRequired,
  readArtistPostTags: PropTypes.func.isRequired,
  readArtistSchedules: PropTypes.func.isRequired,
  _next: PropTypes.string,
  match: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const sortedPosts = PropsData.fromState(state.artist.data[state.artist.active].posts, Order.DESCENDING);
  const sortedSchedules = PropsData.fromState(state.artist.data[state.artist.active].schedules, Order.DESCENDING);
  return {
    user: state.user.data,
    artist: state.artist.data[state.artist.active],
    count: state.artist._postsCount,
    posts: sortedPosts,
    schedules: sortedSchedules,
    _next: state.artist._nextPosts,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistPostsBoard);
