import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { ImageContainer } from './../../components';

import { PropsData, Order } from './../Utils/DataUtil';
import { Permission } from './../Utils/PermissonUtil';
import { numberWithCommas } from './../Utils/NumberUtil';

import * as artistActions from './../../actions/artist';
import * as paymentActions from './../../actions/payment';

import { strings, styles, colors } from '../../assets/values';
import { getStyle } from './style';

import history from './../../history';


class ArtistMerchandise extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.readArtistMerchandise(this.props.artist.id);
  }

  handleCreatMerchandise = () => {
    this.props.setArtistMerchandiseForm({});
    history.push(`/${this.props.match.params.url}/${strings.routes.artistMerchandiseItemEditor}`);
  };

  render() {
    const style = getStyle();
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.merchandise) return (progress);
    return (
      <div style={style.wrapper}>
        <Row>
          <Col lg={3}/>
          <Col lg={8}>
            <div style={style.rightContainer}>
              <Card>
                <CardContent style={{ padding: 16 }}>
                  {this.props.merchandise.map(item =>
                    <Link
                      href={`/${this.props.match.params.url}/${strings.routes.artistMerchandise}/${item.id}`}
                      to={`/${this.props.match.params.url}/${strings.routes.artistMerchandise}/${item.id}`}
                      key={item.id}
                    >
                      <CardContent style={{ borderBottom: `1px solid ${colors.textBlack20}` }}>
                        <Row>
                          <Col sm={4} xs={6}>
                            <ImageContainer
                              url={item.image} width={120} height={120}
                              raw
                            />
                          </Col>
                          <Col sm={8} xs={6}>
                            <div style={{ fontSize: styles.fontSize.lg, color: colors.textBlack }}>
                              {item.name}
                            </div>
                            <div style={{ fontSize: styles.fontSize.md, color: colors.textBlack80 }}>
                              {item.description_inline}
                            </div>
                            <div style={{ fontSize: styles.fontSize.md, color: colors.textBlack, marginTop: 12 }}>
                              {numberWithCommas(item.price)} 원
                            </div>
                          </Col>
                        </Row>
                      </CardContent>
                    </Link>)}
                  {this.props.merchandise.length === 0 &&
                    <CardContent style={{ textAlign: 'center' }}>
                      {strings.noMerchandise}
                    </CardContent>
                  }
                </CardContent>
                {this.props.permission &&
                  <CardActions>
                    <Button variant="outlined" color="primary" onClick={this.handleCreatMerchandise}>
                      {strings.createMerchandise}
                    </Button>
                  </CardActions>}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArtistMerchandise.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  permission: PropTypes.bool.isRequired,
  readArtistMerchandise: PropTypes.func.isRequired,
  merchandise: PropTypes.array,
  setArtistMerchandiseForm: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    artist: state.artist.data[state.artist.active],
    permission: Permission.checkArtist(state.artist.data[state.artist.active], state.user.data),
    merchandise: PropsData.fromState(state.artist.data[state.artist.active].merchandise, Order.DESCENDING),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...artistActions,
    ...paymentActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistMerchandise);
