import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import ActionAccountCircle from '@material-ui/icons/AccountCircle';

import { ImageContainer } from './../../components';

import { strings, colors, styles } from './../../assets/values';


const style = {
  card: {
    width: '100%',
    maxWidth: '100%',
    height: 430,
    marginBottom: 5,
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: 'pointer',
    position: 'relative',
    textAlign: 'left',
    name: {
      fontWeight: styles.fontWeight.Medium,
      fontSize: styles.fontSize.md,
      color: colors.textBlack80,
      float: 'left',
      width: '85%',
    },
    supporters_count: {
      float: 'right',
      width: '15%',
      count_icon: {
        width: 23,
        height: 23,
        color: colors.primaryPink,
        marginLeft: 'auto',
        display: 'block',
      },
      count_text: {
        fontWeight: styles.fontWeight.Regular,
        fontSize: styles.fontSize.xs,
        textAlign: 'right',
        color: colors.primaryPink,
      },
    },
    tags: {
      marginTop: 8,
      tag: {
        display: 'inline-block',
        padding: 4,
        fontSize: styles.fontSize.sm,
        color: colors.textBlack80,
      },
    },
    description_inline: {
      marginTop: 14,
      maxHeight: '2em',
      fontSize: styles.fontSize.sm,
      color: colors.textBlack80,
      wordWrap: 'break-word',
      whiteSpace: 'pre-line',
    },
    divider: {
      marginTop: 14,
      width: 44,
      backgroundColor: colors.textBlack40,
      height: 2,
    },
  },
};

class ArtistCard extends React.Component {
  render() {
    return (
      <Link href={`/${this.props.artist.url_artist}`} to={`/${this.props.artist.url_artist}`}>
        <Card style={style.card}>
          <div>
            <ImageContainer url={this.props.artist.profile_image} height={248}/>
          </div>
          <div style={{ padding: 25 }}>
            <div>
              <div style={style.card.name}>
                {this.props.artist.name}
              </div>
              <div style={style.card.supporters_count}>
                <span>{<ActionAccountCircle style={style.card.supporters_count.count_icon}/>}</span>
                <div style={style.card.supporters_count.count_text}>
                  {this.props.artist.supports_count} {strings.supportCountText}
                </div>
              </div>
            </div>
            <div style={{ clear: 'both' }}/>
            <div style={style.card.description_inline}>
              {this.props.artist.description_inline}
            </div>
            <Divider style={style.card.divider}/>
            <div style={style.card.tags}>
              {typeof this.props.artist.tags === 'object' && this.props.artist.tags.map(tag => <span style={style.card.tags.tag} key={tag.name}>#{tag.name}</span>)}
            </div>
          </div>
          <div style={{ clear: 'both' }}/>
        </Card>
      </Link>
    );
  }
}

ArtistCard.propTypes = {
  artist: PropTypes.object.isRequired,
};

export default ArtistCard;
