import { api } from './api';
import {
  READ_PAGE_VIEWS,
  READ_PAGE_VIEWS_USER_GENDER,
  READ_CHECKOUT,
} from './actionTypes';


export function readPageViews(id) {
  const request = api.get(`/analytics/${id}/`);
  return {
    type: READ_PAGE_VIEWS,
    payload: request,
  };
}

export function readPageViewsUserGender(id) {
  const request = api.get(`/analytics/${id}/`, { dimensions: 'userGender' });
  return {
    type: READ_PAGE_VIEWS_USER_GENDER,
    payload: request,
  };
}

export function readCheckout(year, month) {
  const request = api.get('/transactions/checkout/', { year, month });
  return {
    type: READ_CHECKOUT,
    payload: request,
  };
}
