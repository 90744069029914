import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { PageNumberPaginator, MyDashboardDialog, MyDashboardSupporter } from './../../components';

import * as userActions from './../../actions/user';
import * as paymentActions from './../../actions/payment';
import { baseURL } from './../../actions/api';

import { strings, presets } from './../../assets/values';

import { getStyle } from './style';

import history from './../../history';
import { isMobile } from '../Utils/BrowerUtil';


const values = presets.filterValues;

class DashboardSupporters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      scope: values.supportersScope.active,
      ordering: values.supportersOrdering.createdAtDescending,
      dialogOpen: false,
      dialogSupporter: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.props.readUser()
      .then((response) => {
        if (!response.action.payload.ok) history.push('/');
        else this.props.readSupportsArtist(this.state.currentPage, this.state.scope, this.state.ordering);
      });
  }

  handleSupportersRead = (page, scope, ordering) => {
    this.props.readSupportsArtist(page, scope, ordering);
    this.setState({ currentPage: page });
  };

  handleScope = (event) => {
    const { value } = event.target;
    this.handleSupportersRead(1, value, this.state.ordering);
    this.setState({ scope: value });
  };


  handleOrdering = (event) => {
    const { value } = event.target;
    this.handleSupportersRead(1, this.state.scope, value);
    this.setState({ ordering: value });
  };

  handleDialog = (supporter) => {
    this.setState({ dialogOpen: !this.state.dialogOpen });
    if (supporter) {
      this.setState({ dialogSupporter: supporter });
    }
  };

  render() {
    const style = getStyle();
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.user) return (progress);
    const exportURL = `${baseURL}/supports/artist/?export=xlsx&scope=${this.state.scope}&ordering=${this.state.ordering}&auth=${this.props.accessToken}`;
    return (
      <div style={{ marginTop: 20 }}>
        <Card style={style.innerCard}>
          <CardHeader title={strings.supporters}/>
          <div style={{ padding: 8, paddingBottom: 32 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{strings.supporters}</TableCell>
                  <TableCell>{strings.balancePayment}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {`${this.props.user.artist[0].supports_count} 명`}
                  </TableCell>
                  <TableCell>
                    {`${this.props.user.artist[0].supports_sum} 원`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <CardActions>
            <Select
              value={this.state.scope}
              onChange={this.handleScope}
            >
              <MenuItem
                value={values.supportersScope.active}
              >
                {strings.supportersScope.active}
              </MenuItem>
              <MenuItem
                value={values.supportersScope.all}
              >
                {strings.supportersScope.all}
              </MenuItem>
              <MenuItem
                value={values.supportersScope.inactive}
              >
                {strings.supportersScope.inactive}
              </MenuItem>
            </Select>
            <Select
              value={this.state.ordering}
              onChange={this.handleOrdering}
            >
              <MenuItem
                value={values.supportersOrdering.createdAtDescending}
              >
                {strings.supportersOrdering.createdAtDescending}
              </MenuItem>
              <MenuItem
                value={values.supportersOrdering.sumDescending}
              >
                {strings.supportersOrdering.sumDescending}
              </MenuItem>
              <MenuItem
                value={values.supportersOrdering.countDescending}
              >
                {strings.supportersOrdering.countDescending}
              </MenuItem>
            </Select>
            <Button
              variant="outlined"
              href={exportURL}
              style={style.muiRaisedButton(0)}
            >
              {isMobile() ? strings.download : strings.downloadExel}
            </Button>
          </CardActions>
          <TableContainer component={Paper}>
            <Table padding="default" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{strings.name}</TableCell>
                  <TableCell>{strings.email}</TableCell>
                  <TableCell>{strings.selectedReward}</TableCell>
                  <TableCell>{strings.paymentAmount}</TableCell>
                  <TableCell>{strings.sumOfPayment}</TableCell>
                  <TableCell>{strings.sumOfMonth}</TableCell>
                  <TableCell>{strings.detail}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.supporters.results && this.props.supporters.results.map(supporter =>
                  <MyDashboardSupporter
                    key={supporter.id || 0} {...supporter} {...supporter.user}
                    onClickSupporter={() => this.handleDialog(supporter)}
                  />)}
              </TableBody>
            </Table>
            <PageNumberPaginator
              currentPage={this.state.currentPage}
              count={this.props.supporters.count}
              onPageClick={page => this.handleSupportersRead(page, this.state.scope, this.state.ordering)}
            />
          </TableContainer>
        </Card>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleDialog}
        >
          <MyDashboardDialog {...this.state.dialogSupporter}/>
        </Dialog>
      </div>
    );
  }
}

DashboardSupporters.propTypes = {
  user: PropTypes.object,
  accessToken: PropTypes.string,
  supporters: PropTypes.object,
  readUser: PropTypes.func.isRequired,
  readSupportsArtist: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    accessToken: state.auth.accessToken,
    supporters: state.payment.supporters,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...paymentActions, ...userActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSupporters);
