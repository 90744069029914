import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DaumPostcode from 'react-daum-postcode';

import { RowWithLabel } from './../../components';

import { strings, styles } from './../../assets/values';


class PostcodeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      initial: true,
      open: false,
    };
  }

  handlePostcode = (data) => {
    let fullAddress = data.address; // 최종 주소 변수
    let extraAddress = ''; // 조합형 주소 변수
    // 기본 주소가 도로명 타입일때 조합한다.
    if (data.addressType === 'R') {
      // 법정동명이 있을 경우 추가한다.
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      // 건물명이 있을 경우 추가한다.
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      // 조합형주소의 유무에 따라 양쪽에 괄호를 추가하여 최종 주소를 만든다.
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    this.setState({ address: fullAddress });
    this.props.onComplete(fullAddress, data.zonecode);
    this.handleClose();
  }

  handleInitialOpen = () => {
    if (this.state.initial) {
      this.setState({ open: true, initial: false });
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <RowWithLabel style={styles.rowWithLabel(25)} label={strings.address}>
          <TextField
            name="daumAddress"
            helperText={strings.searchAddress}
            value={this.state.address}
            onClick={this.handleOpen}
            onFocus={this.handleInitialOpen}
            fullWidth
          />
        </RowWithLabel>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          style={{
            paddingTop: '0 !important',
            marginTop: '-65px !important',
            bottom: '0 !important',
            overflow: 'scroll !important',
            height: 'auto !important',
          }}
        >
          <DaumPostcode onComplete={this.handlePostcode}/>
          <DialogActions>
            <Button
              color="primary"
              onClick={this.handleClose}
            >
              {strings.cancel}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

PostcodeDialog.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

export default PostcodeDialog;
