import { api } from './api';
import {
  READ_USER,
  RESET_USER,
  UPDATE_USER,
  START_EDITING_USER_PROFILE_FORM,
  FINISH_EDITING_USER_PROFILE_FORM,
  START_EDITING_USER_SETTING_FORM,
  FINISH_EDITING_USER_SETTING_FORM,
} from './actionTypes';


export function readUser() {
  const request = api.get('/users/me/');
  return {
    type: READ_USER,
    payload: request,
  };
}

export function resetUser() {
  return {
    type: RESET_USER,
    payload: null,
  };
}

export function updateUser(rowData) {
  const data = rowData;
  // delete un-writable fields to prevent server error
  delete data.is_staff;
  const request = api.patch('/users/me/', data);
  return {
    type: UPDATE_USER,
    payload: request,
  };
}

export function startEditingUserProfileForm() {
  return {
    type: START_EDITING_USER_PROFILE_FORM,
  };
}

export function finishEditingUserProfileForm() {
  return {
    type: FINISH_EDITING_USER_PROFILE_FORM,
  };
}

export function startEditingUserSettingForm() {
  return {
    type: START_EDITING_USER_SETTING_FORM,
  };
}

export function finishEditingUserSettingForm() {
  return {
    type: FINISH_EDITING_USER_SETTING_FORM,
  };
}
