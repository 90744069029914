import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { useFormik } from 'formik';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { ContentWrapper, FacebookSignIn } from './../../components';

import * as authActions from './../../actions/auth';
import logoSrc from './../../assets/images/logo.png';
import { strings, colors } from './../../assets/values';
import * as Validator from './../Utils/Validator';
import { getStyle } from './style';
import history from './../../history';


const validate = (values) => {
  const errors = {};
  const emailError = Validator.validateEmail(values.email);
  if (emailError) {
    errors.email = emailError;
  }
  return errors;
};


const SignIn = (props) => {
  useEffect(() => {
    if (props.accessToken) {
      history.goBack();
    }
  }, [props.accessToken]);

  const handleSignIn = (response) => {
    if (response.action.payload.ok) {
      toastr.success(strings.success, strings.signInSuccess);
      history.goBack();
    } else {
      toastr.error(strings.fail, strings.signInFail);
    }
  };

  const onSubmit = (data) => {
    props.signIn(data)
      .then(response => handleSignIn(response));
  };

  const facebookResponse = (data) => {
    props.signInWithFacebook(data)
      .then(response => handleSignIn(response));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
    validate: values => validate(values)
  });

  const style = getStyle();
  return (
    <ContentWrapper title={strings.signIn} width={420}>
      <Card style={style.card}>
        <img style={style.card.logo} src={logoSrc} alt={strings.appName}/>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            name="email"
            label={strings.email}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth
            margin="normal"
          />
          <TextField
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            label={strings.password}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 24, marginBottom: 12 }}
            type="submit"
            fullWidth
          >
            {strings.signIn}
          </Button>
        </form>
        <FacebookSignIn appId={strings.facebookAppId} callback={facebookResponse}/>
        <div style={style.card.hr.wrapper}>
          <span style={style.card.hr.text}>{strings.or}</span>
        </div>
        <Link to="/sign_up">
          <Button
            variant="outlined"
            type="button"
            fullWidth
          >
            {strings.signUp}
          </Button>
        </Link>
        <Link to="/reset_password">
          <Button
            style={style.flatButton}
            type="button"
          >
            {strings.forgotPassword}
          </Button>
        </Link>
        <Typography variant="caption">
          로그인에 문제가 있으신가요?
          <a href={strings.kakaoChatUrl} target="blank" style={{ color: colors.primaryPink }}>
            {' 카카오톡 채널로 문의'}
          </a>
          해주시면 도와드리겠습니다 :)
        </Typography>
      </Card>
    </ContentWrapper>
  );
};

SignIn.propTypes = {
  accessToken: PropTypes.string,
  signIn: PropTypes.func.isRequired,
  signInWithFacebook: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    accessToken: state.auth.accessToken,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...authActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
