import {
  SET_ACTIVE_NAVIGATOR_PATH,
  SET_ACTIVE_TAB_PATH,
  OPEN_NAVIGATOR_DRAWER, CLOSE_NAVIGATOR_DRAWER, OPEN_NAVIGATOR_POPOVER, CLOSE_NAVIGATOR_POPOVER,
  SET_NAVIGATOR_POPOVER_ANCHOR,
} from './../actions/actionTypes';


const INITIAL_NAVIGATOR_STATE = {
  path: null,
  tab: null,
  openDrawer: false,
  openPopover: false,
  popoverAnchor: null
};

export default function (state = INITIAL_NAVIGATOR_STATE, action) {
  switch (action.type) {
    case SET_ACTIVE_NAVIGATOR_PATH:
      return { ...state, path: action.payload };
    case SET_ACTIVE_TAB_PATH:
      return { ...state, tab: action.payload };
    case OPEN_NAVIGATOR_DRAWER:
      return { ...state, openDrawer: true };
    case CLOSE_NAVIGATOR_DRAWER:
      return { ...state, openDrawer: false };
    case OPEN_NAVIGATOR_POPOVER:
      return { ...state, openPopover: true };
    case CLOSE_NAVIGATOR_POPOVER:
      return { ...state, openPopover: false };
    case SET_NAVIGATOR_POPOVER_ANCHOR:
      return { ...state, popoverAnchor: action.payload };
    default:
      return state;
  }
}
