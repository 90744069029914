import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Row, Col } from 'react-grid-system';

import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import { HashTag, ImageContainer } from './../../components';

import { colors, styles, strings } from './../../assets/values';

const style = {
  card: {
    height: 'auto',
    minHeight: 200,
    marginBottom: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 21,
    cursor: 'pointer',
    position: 'relative',
    panel: {
      left: {
        textAlign: 'center',
      },
      right: {
        minHeight: 150,
      },
    },
    name: {
      color: colors.textBlack,
      fontWeight: styles.fontWeight.Medium,
      fontSize: styles.fontSize.lg,
    },
    tags: {
      marginTop: 10,
      display: 'flex',
      flexWrap: 'wrapper',
      tag: {
        fontSize: styles.fontSize.xs,
      },
    },
    description_inline: {
      marginTop: 10,
      fontSize: styles.fontSize.md,
      wordWrap: 'break-word',
      whiteSpace: 'pre-line',
    },
    supporters_count: {
      marginTop: 8,
      fontWeight: styles.fontWeight.Regular,
      fontSize: styles.fontSize.sm,
      textAlign: 'right',
    },
    warning: {
      background: colors.hashtagBackground,
      textAlign: 'right',
      fontSize: styles.fontSize.md,
      padding: 8,
    },
  },
  underlineDisabled: {
    borderColor: colors.textFieldUnderlineDisabled,
    cursor: 'normal',
  },
  input: {
    color: colors.textFieldColor,
    cursor: 'normal',
  },
  field: {
    width: 100,
  },
  muiRaisedButton: styles.muiComponents.raisedButton,
};

class MyArtistInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmCancel: false,
    };
  }

  onCancelSupport = () => {
    this.closeConfirmCancel();
    this.props.handleCancel();
  };

  openConfirmCancel = () => {
    this.setState({ confirmCancel: true });
  };

  closeConfirmCancel = () => {
    this.setState({ confirmCancel: false });
  };

  render() {
    return (
      <Card style={style.card}>
        <Row>
          <Col lg={4}>
            <div style={style.card.panel.left}>
              <Link href={`/${this.props.artist.url_artist}`} to={`/${this.props.artist.url_artist}`}>
                <ImageContainer url={this.props.artist.profile_image} width={140} height={140}/>
              </Link>
            </div>
          </Col>
          <Col lg={8}>
            <div style={style.card.panel.right}>
              <Link href={`/${this.props.artist.url_artist}`} to={`/${this.props.artist.url_artist}`}>
                <span style={style.card.name}>{this.props.artist.name}</span>
              </Link>
              <div style={style.card.tags}>
                {this.props.artist.tags.map(tag => <HashTag key={tag.name} name={tag.name}/>)}
              </div>
              <div style={style.card.description_inline}>
                {this.props.artist.description_inline}
              </div>
            </div>
          </Col>
          <div style={{ textAlign: 'right' }}>
            <Button disabled>
              {`${strings.paymentAmount} : ${this.props.payment_amount}`}
            </Button>
            <Button disabled>
              {`${strings.paymentDay} : ${this.props.payment_day}`}
            </Button>
            {(this.props.is_payment_active || this.props.is_support_changing) &&
              <div style={{ display: 'inline-block' }}>
                <Button
                  type="button" color="primary"
                  onClick={this.props.handleUpdate}
                >
                  {strings.changeReward}
                </Button>
                <Button
                  disabled={!this.props.is_payment_active}
                  type="button" color="primary"
                  onClick={this.openConfirmCancel}
                >
                  {strings.cancelSupport}
                </Button>
              </div>
            }
            {(!this.props.is_support_changing && !this.props.is_payment_active) &&
              <Button disabled>
                {strings.supportCanceled}
              </Button>
            }
          </div>
          {this.props.is_payment_failed &&
            <div style={style.card.warning}>
              {strings.paymentFailedRecently}
            </div>
          }
        </Row>
        <Dialog open={this.state.confirmCancel}>
          <DialogContent>
            {strings.confirmCancelSupport}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.closeConfirmCancel}>{strings.cancel}</Button>
            <Button color="primary" onClick={this.onCancelSupport}>{strings.cancelSupport}</Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }
}

MyArtistInformation.propTypes = {
  artist: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  reward: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  payment_amount: PropTypes.number.isRequired,
  payment_day: PropTypes.number.isRequired,
  is_support_changing: PropTypes.bool.isRequired,
  is_payment_active: PropTypes.bool.isRequired,
  is_payment_failed: PropTypes.bool.isRequired,
};

export default MyArtistInformation;
