import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Editor } from '@tinymce/tinymce-react';
import { api } from '../../actions/api';

import * as editorActions from './../../actions/editor';

import { styles, strings } from './../../assets/values';


class TextEditor extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.updateEditorContent(this.props.initialContent);
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.initialContent !== this.props.initialContent);
  }

  imageUploadHandler = async (blobInfo, success) => {
    const formData = new FormData();
    formData.append('file', blobInfo.blob());
    const response = await api.post('/images/upload/', formData, { headers: { 'Content-Type': 'multipart/form-data; boundary=frontier' } });
    const url = `${strings.s3.domain}/${strings.s3.bucket}/${response.data.hashed_name}`;
    success(url);
  }

  handleEditorChange = (event) => {
    this.props.updateEditorContent(event.target.getContent());
  };

  render() {
    return (
      <div>
        <input
          type="file"
          ref={(input) => { this.fileInput = input; }}
          onChange={this.handleImageUpload}
          style={{ display: 'none' }}
        />
        <p>{strings.imageUploadInfo}</p>
        <Editor
          initialValue={this.props.initialContent}
          apiKey="qzb6skpgks5hlp55cjq9y1ocfodsove76pbuf2sf90vvlz3j"
          init={{
            image_class_list: [
              { title: '이미지', value: 'tinymce-img-responsive' },
            ],
            content_style: 'p {margin: 0px; line-height: 1.7; font-size: 12pt;} img {max-width: 100%;}',
            file_browser_callback_types: this.props.file_browser_callback_types,
            font_formats: this.props.font_formats,
            fontsize_formats: this.props.fontsize_formats,
            height: this.props.height,
            autoresize_min_height: this.props.autoresize_min_height,
            autoresize_max_height: this.props.autoresize_max_height,
            autoresize_bottom_margin: this.props.autoresize_bottom_margin,
            language_url: this.props.language_url,
            language: this.props.language,
            media_live_embeds: this.props.media_live_embeds,
            menubar: this.props.menubar,
            plugins: this.props.plugins,
            toolbar: this.props.toolbar,
            statusbar: this.props.statusbar,
            images_upload_handler: this.imageUploadHandler,
          }}
          onChange={this.handleEditorChange}
        />
      </div>
    );
  }
}

TextEditor.propTypes = {
  file_browser_callback_types: PropTypes.string,
  font_formats: PropTypes.string,
  fontsize_formats: PropTypes.string,
  height: PropTypes.number,
  autoresize_min_height: PropTypes.number,
  autoresize_max_height: PropTypes.number,
  autoresize_bottom_margin: PropTypes.number,
  language_url: PropTypes.string,
  skin_url: PropTypes.string,
  language: PropTypes.string,
  media_live_embeds: PropTypes.bool,
  menubar: PropTypes.bool,
  plugins: PropTypes.string,
  toolbar: PropTypes.string,
  statusbar: PropTypes.bool,
  onChange: PropTypes.func,
  initialContent: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  uploadImage: PropTypes.func.isRequired,
  updateEditorContent: PropTypes.func.isRequired,
  startUploadingImage: PropTypes.func.isRequired,
  finishUploadingImage: PropTypes.func.isRequired,
};

TextEditor.defaultProps = {
  file_browser_callback_types: 'image',
  font_formats: `Default=${styles.fontFamily}`,
  fontsize_formats: '12pt 18pt',
  height: 200,
  autoresize_min_height: 200,
  autoresize_max_height: 600,
  autoresize_bottom_margin: 10,
  language: 'ko_KR',
  menubar: false,
  statusbar: false,
  plugins: 'autolink link image media lists print preview textcolor autoresize',
  toolbar: 'fontsizeselect bold forecolor | alignleft aligncenter alignright | link image media',
};

function mapStateToProps(state) {
  return {
    imageUrl: state.editor.imageUrl,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...editorActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);
