/* eslint-disable no-restricted-globals */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import JssProvider from 'react-jss/lib/JssProvider';
import { ThemeProvider, createGenerateClassName } from '@material-ui/core/styles';
import 'react-redux-toastr/src/styles/index.scss';

import configureStore from './store/ConfigureStore';
import ErrorBoundary from './components/Error/ErrorBoundary';
import { App } from './containers';

import muiTheme from './styles/muiTheme';
import './styles/index.scss';

import history from './history';


if (!String.prototype.includes) {
  String.prototype.includes = (search, start = 0) => {
    if (start + search.length > this.length) {
      return false;
    }

    return this.indexOf(search, start) !== -1;
  };
}

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value(searchElement, fromIndex) {
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      // 1. Let O be ? ToObject(this value).
      const o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      const len = o.length > 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      const n = fromIndex || 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }
        // c. Increase k by 1.
        k += 1;
      }

      // 8. Return false
      return false;
    },
  });
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://50a88efe9b8043c7b6c61dae31968d24@o232099.ingest.sentry.io/1484364',
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;
const store = configureStore(preloadedState);

const generateClassName = createGenerateClassName();

const render = (Component) => {
  const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
  renderMethod(
    <ErrorBoundary>
      <Provider store={store}>
        <JssProvider generateClassName={generateClassName}>
          <ThemeProvider theme={muiTheme}>
            <Router history={history}>
              <Component/>
            </Router>
          </ThemeProvider>
        </JssProvider>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
};

render(App);

// if (module.hot) {
//   module.hot.accept(App, () => {
//     render(App);
//   });
// }

// registerServiceWorker();
