import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';

import { useFormik } from 'formik';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import * as artistActions from './../../actions/artist';

import { PropsData, Order } from '../Utils/DataUtil';

import { TextEditor } from './../../containers';

import { strings, styles } from './../../assets/values';
import * as Validator from './../Utils/Validator';

import { getStyle } from './style';

import history from './../../history';


const validate = (values) => {
  const errors = {};
  const titleError = Validator.validateTitle(values.title);
  if (titleError) errors.title = titleError;
  return errors;
};

const ArtistPostEditor = (props) => {
  useEffect(() => {
    props.readArtistRewards(props.artistId)
      .then(() => props.finishInitialize());
  }, []);

  const onSubmit = (data) => {
    const newData = {
      title: data.title,
      body: props.content,
      artist: props.artistId,
      variant: data.variant,
      is_active: true,
      is_public: true,
    };
    if (data.post_tags) {
      newData.tags = data.post_tags.split(/\s*,\s*/);
    }
    if (data.schedule_date) {
      newData.schedule = { title: data.title, address: '[]', date: data.schedule_date };
    }
    if (data.isTimeLimited) {
      newData.minimum_reward = props.rewards[0].id;
      newData.is_time_limited = true;
    } else if (data.isSecret) {
      newData.minimum_reward = props.rewards[0].id;
    } else {
      newData.minimum_reward = null;
    }
    if (props.editing) {
      props.updateArtistPost(newData, props.initialValues.id)
        .then(() => props.readArtistPosts(props.artistId));
      props.onDismiss();
    } else {
      props.createArtistPost(newData)
        .then((response) => {
          if (response.action.payload.ok) {
            toastr.success(strings.success, strings.postSuccess);
            if (data.variant === 'secondary') {
              history.push(`/${props.match.params.url}/posts_secondary`);
            } else {
              history.push(`/${props.match.params.url}/posts`);
            }
          } else toastr.error(strings.fail, strings, strings.postFail);
        });
    }
  };

  const onCancel = () => {
    if (props.editing) {
      props.onDismiss();
    } else {
      history.push(`/${props.match.params.url}/posts`);
    }
  };

  const style = getStyle();
  const muiTextField = style.muiTextField();

  const formik = useFormik({
    initialValues: {
      title: props.initialValues.title || '',
      variant: props.initialValues.variant || 'primary',
      post_tags: props.initialValues.post_tags || '',
      isSecret: !!props.initialValues.is_secret || false,
      isTimeLimited: !!props.initialValues.is_time_limited || false,
    },
    onSubmit: values => onSubmit(values),
    validate: values => validate(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <CardContent style={style.newPost.form && { paddingTop: 15 }}>
        <TextField
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          label="제목"
          fullWidth
          size="medium"
          margin="normal"
        />
        <FormControl margin="normal" style={{ width: 120 }}>
          <InputLabel id="demo-simple-select-label">게시 위치</InputLabel>
          <Select
            name="variant"
            value={formik.values.variant}
            onChange={formik.handleChange}
            autoWidth
          >
            <MenuItem key="primary" value="primary">메인 포스트</MenuItem>
            {props.artist.secondary_post_enabled && (
              <MenuItem key="secondary" value="secondary">{props.artist.secondary_post_label}</MenuItem>
            )}
          </Select>
        </FormControl>
        {/* <Field
          name="schedule_date" helperText={strings.artistSchedules}
          component={DatePicker} container="inline" mode="landscape"
        /> */}
      </CardContent>
      <CardContent style={{ paddingTop: 0 }}>
        <TextEditor initialContent={props.initialValues.body}/>
        <TextField
          name="post_tags"
          value={formik.values.post_tags}
          onChange={formik.handleChange}
          style={{ ...muiTextField, fontSize: styles.fontSize.md }}
          helperText="쉼표, 로 구분해주세요 ex) 일상, 사진"
          label="태그"
          margin="normal"
        />
        <div>
          <FormControlLabel
            label={strings.isSecretPosts}
            control={
              <Checkbox
                name="isSecret"
                value={formik.values.isSecret}
                onChange={formik.handleChange}
                color="primary"
              />
            }
          />
        </div>
        <div>
          <FormControlLabel
            label={strings.isTimeLimited}
            control={
              <Checkbox
                name="isTimeLimited"
                value={formik.values.isTimeLimited}
                onChange={formik.handleChange}
                color="primary"
              />
            }
          />
        </div>
        <div style={{ paddingTop: 16 }}>{strings.writePostInfo}</div>
      </CardContent>
      <CardActions>
        <Button
          type="button"
          variant="contained"
          size="large"
          onClick={(event) => { event.preventDefault(); onCancel(); }}
        >
          {strings.cancel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          disabled={formik.isSubmitting}
        >
          {strings.confirm}
        </Button>
      </CardActions>
    </form>
  );
};

ArtistPostEditor.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  artistId: PropTypes.number,
  content: PropTypes.string,
  rewards: PropTypes.array,
  initialValues: PropTypes.object,
  editing: PropTypes.bool,
  finishInitialize: PropTypes.func.isRequired,
  createArtistPost: PropTypes.func.isRequired,
  readArtistPosts: PropTypes.func.isRequired,
  readArtistRewards: PropTypes.func.isRequired,
  updateArtistPost: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  match: PropTypes.object.isRequired,
};

ArtistPostEditor.defaultProps = {
  editing: false,
  onDismiss: null,
  initialValues: { body: '', title: '', post_tags: '' },
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    artistId: state.artist.active,
    artist: state.artist.data[state.artist.active],
    content: state.editor.content,
    rewards: PropsData.fromState(state.artist.data[state.artist.active].rewards, Order.ASCENDING),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistPostEditor);
