// import addHours from 'date-fns/add_hours';
import format from 'date-fns/format';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import koLocale from 'date-fns/locale/ko';
import getMonth from 'date-fns/get_month';
import getYear from 'date-fns/get_year';
import getDay from 'date-fns/get_day';

import { presets } from './../../assets/values';


// const setTimezone = time => addHours(time, presets.dateTime.timezoneOffset);
const dateISO = rawDateTime => format(rawDateTime);
const dateFormat = rawDateTime => format(rawDateTime, presets.dateTime.format.full);
const dateVerboseFormat = rawDateTime => format(rawDateTime, presets.dateTime.format.fullVerbose);

const timeFromNow = rawDateTime => distanceInWordsToNow(rawDateTime, { locale: koLocale });

const lastYearMonth = () => {
  const now = Date.now();
  let month = getMonth(now);
  let year = getYear(now);

  if (month === 0) {
    year -= 1;
    month = 12;
  }

  return { year, month };
};

export const DateTime = {
  dateISO,
  dateFormat,
  dateVerboseFormat,
  getMonth,
  getDay,
  timeFromNow,
  lastYearMonth
};
