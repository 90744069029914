export const months = [
  '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
];

export const years = [
  '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
];

export const regions = [
  '광주', '대구', '대전', '부산', '서울', '울산', '인천', '강원',
  '경기', '경남', '경북', '전남', '전북', '제주', '충남', '충북',
];

export const categories = [
  '문학', '미술', '게임', '공예', '디자인', '사진', '건축', '음악',
  '무용', '연극', '영화·비디오', '방송', '만화', '출판', '기타',
];

export const httpStatusCodes = [
  '100', '101', '200', '201', '202', '204', '206', '207', '300', '301', '302', '303', '304', '305', '307', '400', '401',
  '402', '403', '404', '405', '406', '408', '409', '410', '411', '412', '413', '414', '415', '416', '417', '418', '422',
  '423', '424', '425', '426', '429', '431', '444', '450', '451', '500', '502', '503', '506', '507', '508', '509', '599',
];

export const cards = [
  { name: '하나카드', code: '01' },
  { name: '롯데카드', code: '03' },
  { name: '현대카드', code: '04' },
  { name: '국민카드', code: '06' },
  { name: '비씨카드', code: '11' },
  { name: '삼성카드', code: '12' },
  { name: '신한카드', code: '14' },
  { name: '한미카드', code: '15' },
  { name: 'NH카드', code: '16' },
  { name: '하나SK카드', code: '17' },
  { name: '해외비자', code: '21' },
  { name: '해외마스터', code: '22' },
  { name: 'JCB', code: '23' },
  { name: '해외아멕스', code: '24' },
  { name: '해외다이너스', code: '25' },
  { name: '카드', code: null },
];

export const banks = [
  { name: '기업은행', code: '03' },
  { name: '국민은행', code: '04' },
  { name: '외환은행', code: '05' },
  { name: '수협', code: '07' },
  { name: '농협', code: '11' },
  { name: '우리은행', code: '20' },
  { name: '스탠다드차타드', code: '23' },
  { name: '씨티은행', code: '27' },
  { name: '대구은행', code: '31' },
  { name: '부산은행', code: '32' },
  { name: '광주은행', code: '34' },
  { name: '제주은행', code: '35' },
  { name: '전북은행', code: '37' },
  { name: '경남은행', code: '39' },
  { name: '새마을금고', code: '45' },
  { name: '신협', code: '48' },
  { name: '우체국', code: '71' },
  { name: '하나은행', code: '81' },
  { name: '신한은행', code: '88' },
  { name: '케이뱅크', code: '89' },
  { name: '카카오뱅크', code: '90' },
];

export const formValues = {
  gender: {
    male: '0',
    female: '1',
    others: '2',
  },
  accessibility: {
    inPublic: true,
    inPrivate: false,
  },
  businessRegistration: {
    registered: true,
    freelancer: false,
  },
  pagePublicAccess: {
    approved: true,
    pending: false,
  },
  postStyle: {
    feed: 'feed',
    board: 'board',
  }
};

export const filterValues = {
  artistsOrdering: {
    supporterDescending: '-supporter_count',
    createdDescending: '-created_at',
    createdAscending: 'created_at',
    latestPost: '-latest_post',
  },
  supportersScope: {
    active: 'active',
    all: 'all',
    inactive: 'inactive',
  },
  supportersOrdering: {
    createdAtAscending: 'created_at',
    createdAtDescending: '-created_at',
    countAscending: 'user_count',
    countDescending: '-user_count',
    sumDescending: '-user_sum',
  },
};

export const regex = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Za-z]{2,4}$/i,
  number: /[^0-9]/,
  english: /[^A-Za-z]/,
};

export const dateTime = {
  timezone: 'Asia/Seoul',
  timezoneOffset: 9,
  format: {
    full: 'YYYY-MM-DD',
    fullVerbose: 'YYYY년 MM월 DD일',
    year: 'YYYY',
    month: 'MM',
    day: 'DD',
  },
};
