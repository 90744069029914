import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

class FlatButtonWithLink extends React.Component {
  render() {
    return (
      <Link href={this.props.path} to={this.props.path}>
        <Button
          color={this.props.active ? 'primary' : 'default'}
          style={this.props.style}
        >
          {this.props.label}
        </Button>
      </Link>
    );
  }
}

FlatButtonWithLink.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
  active: PropTypes.bool,
  style: PropTypes.object,
};

FlatButtonWithLink.defaultProps = {
  active: false,
  style: null,
};

export default FlatButtonWithLink;
