import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-grid-system';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import NavigationChevronRight from '@material-ui/icons/ChevronRight';
import NavigationChevronLeft from '@material-ui/icons/ChevronLeft';

const style = {
  textAlign: 'center',
};

class MonthNavigator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: this.props.year,
      month: this.props.month,
    };
  }

  monthUp = () => {
    let nextState = { year: this.state.year, month: this.state.month + 1 };
    if (this.state.month === 12) {
      nextState = { year: this.state.year + 1, month: 1 };
    }
    this.setState(nextState);
    this.props.onChange(nextState.year, nextState.month);
  };

  monthDown = () => {
    let nextState = { year: this.state.year, month: this.state.month - 1 };
    if (this.state.month === 1) {
      nextState = { year: this.state.year - 1, month: 12 };
    }
    this.setState(nextState);
    this.props.onChange(nextState.year, nextState.month);
  };

  render() {
    return (
      <Card style={{ ...this.props.style, ...style }}>
        <Row>
          <Col md={2}>
            <Button icon={<NavigationChevronLeft/>} color="primary" onClick={this.monthDown}/>
          </Col>
          <Col md={8}>
            <div style={{ paddingTop: 8 }}>{`${this.state.year} / ${this.state.month}`}</div>
          </Col>
          <Col md={2}>
            <Button icon={<NavigationChevronRight/>} color="primary" onClick={this.monthUp}/>
          </Col>
        </Row>
      </Card>
    );
  }
}

MonthNavigator.defaultProps = {
  year: 2017,
  month: 1,
  style: style,
};

MonthNavigator.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default MonthNavigator;
