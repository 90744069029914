import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';

import { Row, Col } from 'react-grid-system';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { PageNumberPaginator } from './../../components';

import * as artistActions from './../../actions/artist';

import { CheerEditor } from './../../containers';

import { PropsData, Order } from './../Utils/DataUtil';
import { Permission } from './../Utils/PermissonUtil';
import { DateTime } from './../Utils/DateTimeUtil';
import history from './../../history';

import { getStyle } from './style';
import { strings, colors } from './../../assets/values';

class ArtistCheers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      openEditor: false,
      tabIndex: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.readArtistCheers(this.props.artist.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tabIndex !== this.state.tabIndex) {
      this.handleCheersRead();
    }
  }

  handleCheersRead = (page = 1) => {
    this.props.readArtistCheers(this.props.artist.id, page, this.state.tabIndex === 1 ? true : null);
    this.setState({ currentPage: page });
  };

  handleCheerClick = (id) => {
    history.push(`/${this.props.artist.url_artist}/${strings.routes.artistCheers}/${id}`);
  };

  handleOpenEditor = () => {
    if (Permission.checkUser(this.props.user)) {
      this.setState({ openEditor: true });
    } else {
      toastr.error(strings.signInRequired);
    }
  };

  handleCloseEditor = () => {
    this.setState({ openEditor: false });
  };

  handleTabChange = (event, value) => {
    this.setState({ tabIndex: value, openEditor: false });
  };


  render() {
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.cheer_posts) return (progress);
    const style = getStyle();
    return (
      <div style={style.wrapper}>
        <Row>
          <Col lg={3}/>
          <Col lg={8}>
            <div style={style.rightContainer}>
              {this.state.openEditor ?
                <Card style={{ marginBottom: 32 }}>
                  <CheerEditor
                    match={this.props.match}
                    onDismiss={this.handleCloseEditor}
                  />
                </Card> :
                <Paper>
                  <Tabs
                    indicatorColor="secondary"
                    value={this.state.tabIndex}
                    onChange={this.handleTabChange}
                  >
                    <Tab
                      label="전체"
                    />
                    <Tab
                      label="후원자 커뮤니티"
                    />
                  </Tabs>
                  <CardActions>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={this.handleOpenEditor}
                      style={{ marginLeft: 'auto' }}
                    >
                      글 쓰기
                    </Button>
                  </CardActions>
                  {!Permission.checkSupporter(this.props.artist, this.props.user) && this.state.tabIndex === 1 &&
                    <CardContent>
                      <Typography variant="subheading" align="center">
                        {this.props.artist.name}님과 함께하는 사람에게만 공개되는 커뮤니티입니다.
                      </Typography>
                    </CardContent>
                  }
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>제목</TableCell>
                        <TableCell style={{ width: '2em' }}>추천</TableCell>
                        <TableCell style={{ width: '8em' }}>글쓴이</TableCell>
                        <TableCell style={{ width: '6em' }}>날짜</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.cheer_posts.map(cheer => (
                        <TableRow key={cheer.id} onClick={() => this.handleCheerClick(cheer.id)} hover>
                          <TableCell>{cheer.title} <span style={{ color: colors.textBlack60 }}>({cheer.reply_count})</span></TableCell>
                          <TableCell numeric>{cheer.like_count}</TableCell>
                          <TableCell>{cheer.user.nickname}</TableCell>
                          <TableCell>{DateTime.dateFormat(cheer.created_at)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <PageNumberPaginator
                    currentPage={this.state.currentPage}
                    count={this.props.count}
                    onPageClick={this.handleCheersRead}
                  />
                </Paper>
              }
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArtistCheers.propTypes = {
  cheer_posts: PropTypes.array,
  count: PropTypes.number,
  user: PropTypes.object,
  artist: PropTypes.object,
  createArtistCheer: PropTypes.func.isRequired,
  readArtistCheers: PropTypes.func.isRequired,
  readMoreArtistCheers: PropTypes.func.isRequired,
  finishInitialize: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    cheer_posts: PropsData.fromState(state.artist.data[state.artist.active].cheer_posts, Order.DESCENDING),
    count: state.artist.data.cheer_posts_count,
    user: state.user.data,
    artist: state.artist.data[state.artist.active],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistCheers);
