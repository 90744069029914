import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { bindActionCreators } from 'redux';
import { useFormik } from 'formik';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { RowWithLabel } from './../../components';

import * as authActions from './../../actions/auth';
import * as userActions from './../../actions/user';

import { strings } from './../../assets/values';

import * as Validator from './../Utils/Validator';

import { getStyle } from './style';


const validate = (values) => {
  const errors = {};
  const newPasswordError = Validator.validatePassword(values.new_password);
  const passwordCheckError = Validator.validatePasswordCheck(values.password_check, values.new_password);
  if (newPasswordError) errors.new_password = newPasswordError;
  if (passwordCheckError) errors.password_check = passwordCheckError;
  return errors;
};

const SetPassword = (props) => {
  const onSubmit = (data) => {
    props.setPassword(data).then((response) => {
      if (response.action.payload.ok) {
        toastr.success(strings.success, strings.passwordChanged);
        props.finishSettingUserPassword();
      } else {
        toastr.error(strings.fail);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      password_check: '',
    },
    onSubmit: values => onSubmit(values),
    validate: values => validate(values),
  });
  const style = getStyle();
  const defaultRowStyle = style.rowWithLabel(25);
  const defaultTextField = style.muiTextField(300);
  return (
    <form onSubmit={formik.handleSubmit}>
      <RowWithLabel style={defaultRowStyle} label={strings.oldPassword}>
        <TextField
          name="old_password"
          value={formik.values.old_password}
          onChange={formik.handleChange}
          style={defaultTextField}
          inputStyle={defaultTextField.input}
          underlineShow={false}
          type="password"
        />
      </RowWithLabel>
      <RowWithLabel style={defaultRowStyle} label={strings.newPassword}>
        <TextField
          name="new_password"
          value={formik.values.new_password}
          onChange={formik.handleChange}
          style={defaultTextField}
          inputStyle={defaultTextField.input}
          underlineShow={false}
          type="password"
        />
      </RowWithLabel>
      <RowWithLabel style={defaultRowStyle} label={strings.newPasswordCheck}>
        <TextField
          name="password_check"
          value={formik.values.password_check}
          onChange={formik.handleChange}
          style={defaultTextField}
          inputStyle={defaultTextField.input}
          underlineShow={false}
          type="password"
        />
      </RowWithLabel>
      <div style={{ marginTop: 8 }}>
        <Button onClick={props.finishSettingUserPassword}>
          {strings.cancel}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!formik.dirty || formik.isSubmitting}>
          {strings.confirm}
        </Button>
      </div>
    </form>
  );
};

SetPassword.propTypes = {
  settingPassword: PropTypes.bool,
  setPassword: PropTypes.func.isRequired,
  finishSettingUserPassword: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    settingPassword: state.user.settingPassword,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...authActions, ...userActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
