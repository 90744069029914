import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Banner, PageNumberPaginator } from './../../components';
import { CheerEditor } from './../../containers';

import { DateTime } from './../Utils/DateTimeUtil';
import { PropsData, Order } from './../Utils/DataUtil';
import { Permission } from './../Utils/PermissonUtil';

import * as artistActions from './../../actions/artist';

import { strings, colors } from './../../assets/values';

import history from './../../history';

class Community extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      showEditor: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initializeArtistState();
  }

  componentDidMount() {
    this.props.readPublicCheerPosts();
    this.props.readNoticedCheerPosts();
  }

  handleCheersRead = (page) => {
    this.props.readPublicCheerPosts(page);
    this.setState({ currentPage: page });
  };

  handleCheerClick = (cheer) => {
    history.push(`${strings.routes.cheers}/${cheer.id}`);
  }

  handleShowEditor = () => {
    this.setState({ showEditor: true });
  }

  handleHideEditor = () => {
    this.setState({ showEditor: false });
  }

  render() {
    const progress = (<CircularProgress size={80} style={{ display: 'block', margin: '30px auto' }}/>);
    if (!this.props.cheers || !this.props.noticedCheers) return (progress);
    return (
      <Row>
        <Col lg={8} sm={12} offset={{ lg: 2 }}>
          <div style={{ paddingTop: 16, paddingBottom: 16, width: '100%' }}>
            <Banner/>
            {this.state.showEditor &&
            <Card>
              <CheerEditor
                onDismiss={this.handleHideEditor}
              />
            </Card>
            }
            {!this.state.showEditor &&
            <div>
              {Permission.checkUser(this.props.user) &&
              <div
                style={{
                  textAlign: 'right',
                  marginBottom: 16,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={this.handleShowEditor}
                >
                  글쓰기
                </Button>
              </div>
              }
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>제목</TableCell>
                      <TableCell style={{ width: '2em' }}>추천</TableCell>
                      <TableCell style={{ width: '4em' }}>글쓴이</TableCell>
                      <TableCell style={{ width: '6em' }}>날짜</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.noticedCheers.map(cheer => (
                      <TableRow key={cheer.id} onClick={() => this.handleCheerClick(cheer)} hover>
                        <TableCell style={{ fontWeight: 550 }}>[공지] {cheer.title} <span style={{ color: colors.textBlack60 }}>({cheer.reply_count})</span></TableCell>
                        <TableCell numeric style={{ fontWeight: 550 }}>{cheer.like_count}</TableCell>
                        <TableCell style={{ fontWeight: 550 }}><bold>{cheer.user.nickname}</bold></TableCell>
                        <TableCell style={{ fontWeight: 550 }}>{DateTime.dateFormat(cheer.created_at)}</TableCell>
                      </TableRow>
                    ))}
                    {this.props.cheers.map(cheer => (
                      <TableRow key={cheer.id} onClick={() => this.handleCheerClick(cheer)} hover>
                        <TableCell>{cheer.title} <span style={{ color: colors.textBlack60 }}>({cheer.reply_count})</span></TableCell>
                        <TableCell numeric>{cheer.like_count}</TableCell>
                        <TableCell>{cheer.user.nickname}</TableCell>
                        <TableCell>{DateTime.dateFormat(cheer.created_at)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <PageNumberPaginator
                  currentPage={this.state.currentPage}
                  count={this.props.count}
                  onPageClick={this.handleCheersRead}
                />
              </Paper>
            </div>
            }
          </div>
        </Col>
      </Row>
    );
  }
}

Community.propTypes = {
  user: PropTypes.object,
  cheers: PropTypes.array,
  noticedCheers: PropTypes.array,
  readPublicCheerPosts: PropTypes.func.isRequired,
  readNoticedCheerPosts: PropTypes.func.isRequired,
  initializeArtistState: PropTypes.func.isRequired,
  count: PropTypes.number,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const sortedCheers = PropsData.fromState(state.artist.cheers, Order.DESCENDING);
  const sortedNoticedCheers = PropsData.fromState(state.artist.noticedCheers, Order.DESCENDING);
  return {
    user: state.user.data,
    cheers: sortedCheers,
    noticedCheers: sortedNoticedCheers,
    count: state.artist.cheersCount,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Community);
