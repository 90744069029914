import * as colors from './colors';

export const footerHeight = 80;
export const fontFamily = 'Noto Sans KR, sans-serif';
export const fontWeight = {
  Thin: 100,
  Light: 200,
  DemiLight: 300,
  Regular: 400,
  Medium: 500,
  Bold: 700,
  Black: 900,
};
export const fontSize = {
  xs: 10,
  sm: 12,
  md: 14,
  lg: 18,
  xl: 28,
  muiRaisedButton: 14,
};
export const palette = {
  primary1Color: colors.primaryPink,
  primary2Color: colors.primaryBlack,
  primary3Color: colors.toggleTrackOff,
  accent1Color: colors.primaryBlue,
  accent2Color: colors.toggleThumbOff,
  accent3Color: colors.textBlack40,
  textColor: colors.textBlack,
  secondaryTextColor: colors.textBlack40,
  alternateTextColor: colors.white,
  canvasColor: colors.white,
  borderColor: colors.border,
  disabledColor: colors.disabled,
  pickerHeaderColor: colors.unused,
  clockCircleColor: colors.unused,
  shadowColor: colors.black,
};
export const header = {
  color: {
    background: colors.headerBackground,
    textCommon: colors.textBlack,
    textActive: colors.textRed,
  },
  spacing: {
    height: 50,
    gutterWidth: 30,
    drawerWidth: 304,
    logoVerticalPadding: 16,
  },
  typography: {
    titleWeight: 400,
  },
};

export const rowWithLabel = (top, labelTop = 16) => ({
  marginTop: top,
  minHeight: 48,
  label: {
    color: colors.textBlack80,
    marginTop: labelTop,
  },
});

export const muiComponents = {
  checkbox: {
    fontWeight: fontWeight.Regular,
    fontSize: fontSize.sm,
    iconStyle: {
      marginTop: 3,
      marginRight: 8,
      height: 16,
      width: 16,
    },
    labelStyle: {
      marginTop: 1,
    },
  },
  raisedButton: top => ({
    marginTop: top,
    height: 30,
  }),
  textField: width => ({
    width: width || 256,
    input: {
      color: colors.textFieldColor,
      cursor: 'normal',
    },
  }),
  tabs: height => ({
    width: 500,
    maxWidth: '100%',
    height: 36,
    paddingTop: height - 36,
    marginLeft: 'auto',
    marginRight: 'auto',
    tab: {
      height: 36,
    },
    inkBar: tabCount => ({
      height: 1,
      marginLeft: `${20 / tabCount}%`,
      width: `${60 / tabCount}%`,
    }),
  }),
  artistTabs: {
    width: 300,
    maxWidth: '100%',
    float: 'right',
    height: 44,
    marginLeft: 'auto',
    marginRight: 'auto',
    tab: {
      height: 44,
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: fontSize.md,
    },
    inkBar: tabCount => ({
      height: 3,
      width: `${100 / tabCount}%`,
    }),
  },
};
