import { create } from 'apisauce';

const DEBUG = false; // process.env.NODE_ENV === 'development';

let BASE_URL;
if (DEBUG) {
  BASE_URL = 'http://dev-3000won-api-django.eba-8beqw3p5.ap-northeast-2.elasticbeanstalk.com/api/v1';
} else {
  BASE_URL = 'https://api.3000won.com/api/v1';
}

export const api = create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

export const baseURL = BASE_URL;
