import React from 'react';
import PropTypes from 'prop-types';

import NavigationChevronRight from '@material-ui/icons/ChevronRight';
import NavigationChevronLeft from '@material-ui/icons/ChevronLeft';
import NavigationFirstPage from '@material-ui/icons/FirstPage';
import NavigationLastPage from '@material-ui/icons/LastPage';
import IconButton from '@material-ui/core/IconButton';

import { styles } from './../../assets/values';

class PageNumberPaginator extends React.Component {
  render() {
    const lastPage = Math.ceil(this.props.count / 10);
    return (
      <div style={{ textAlign: 'center', paddingTop: 10 }}>
        <IconButton
          onClick={() => this.props.onPageClick(1)}
        >
          <NavigationFirstPage/>
        </IconButton>
        <IconButton
          disabled={this.props.currentPage === 1}
          onClick={() => this.props.onPageClick(this.props.currentPage - 1)}
        >
          <NavigationChevronLeft/>
        </IconButton>
        <span style={{ fontSize: styles.fontSize.md }}>{this.props.currentPage} / {lastPage}</span>
        <IconButton
          disabled={this.props.currentPage >= lastPage}
          onClick={() => this.props.onPageClick(this.props.currentPage + 1)}
        >
          <NavigationChevronRight/>
        </IconButton>
        <IconButton
          onClick={() => this.props.onPageClick(lastPage)}
        >
          <NavigationLastPage/>
        </IconButton>
      </div>
    );
  }
}

PageNumberPaginator.propTypes = {
  count: PropTypes.number,
  currentPage: PropTypes.number,
  onPageClick: PropTypes.func.isRequired,
};

PageNumberPaginator.defaultProps = {
  count: 1,
  currentPage: 1,
};

export default PageNumberPaginator;
