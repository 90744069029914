import { colors, styles } from './../../assets/values';

export const getStyle = () => ({
  muiRaisedButton: styles.muiComponents.raisedButton,
  flatButton: {
    textAlign: 'center',
    width: '100%',
    marginTop: 10,
  },
  checkbox: {
    ...styles.muiComponents.checkbox,
    float: 'left',
  },
  navigateToTerms: {
    ...styles.muiComponents.checkbox,
    display: 'block',
    textDecoration: 'none',
    color: colors.textBlack,
    textAlign: 'right',
  },
  card: {
    marginTop: 50,
    marginBottom: 50,
    padding: 50,
    logo: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: 30,
      width: 180,
      maxWidth: '100%',
    },
    hr: {
      wrapper: {
        width: '100%',
        height: 10,
        borderBottom: `1px solid ${colors.textBlack40}`,
        textAlign: 'center',
        marginTop: 30,
        marginBottom: 30,
      },
      text: {
        width: 60,
        fontSize: styles.fontSize.md,
        backgroundColor: colors.white,
        padding: '0 20px',
      },
    },
  },
});
