import React from 'react';
import PropTypes from 'prop-types';
import { Inspector } from 'react-inspector';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import { strings } from './../../assets/values';

class CardImageInspector extends React.Component {
  render() {
    return (
      <Card style={{ marginBottom: '20px' }}>
        <CardHeader title={this.props.title}/>
        <CardMedia>
          <img src={this.props.url} alt={this.props.title}/>
        </CardMedia>
        <CardContent style={this.props.textStyle || {}}>
          <Inspector data={this.props.data} expandLevel={0} sortObjectKeys/>
        </CardContent>
      </Card>
    );
  }
}

CardImageInspector.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  data: PropTypes.object.isRequired,
  textStyle: PropTypes.object,
};

CardImageInspector.defaultProps = {
  url: strings.dummy.imageUrl,
};

export default CardImageInspector;
