import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardHeader, CardContent } from '@material-ui/core';

import { styles } from './../../assets/values';

const style = {
  card: {
    textAlign: 'center',
    body: {
      padding: 8,
      fontWeight: styles.fontWeight.Regular,
      fontSize: styles.fontSize.md,
    },
  },
};

class DashboardCard extends React.Component {
  render() {
    return (
      <Card style={{ ...style.card, ...this.props.cardStyle }}>
        <CardHeader title={this.props.title}/>
        <CardContent style={style.card.body}>
          {this.props.body}
        </CardContent>
      </Card>
    );
  }
}

DashboardCard.defaultProps = {
  cardStyle: style,
};

DashboardCard.propTypes = {
  cardStyle: PropTypes.object,
  title: PropTypes.string,
  body: PropTypes.any,
};

export default DashboardCard;
