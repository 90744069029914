/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import ActionAccountCircle from '@material-ui/icons/AccountCircle';

import { DateTime } from '../../containers/Utils/DateTimeUtil';


const bodyStyle = {
  paddingTop: 12,
  wordWrap: 'break-word',
};

class Announcement extends React.Component {
  render() {
    return (
      <div>
        <div style={this.props.style.left}>
          <ActionAccountCircle style={this.props.style.thumbnail}/>
        </div>
        <div style={this.props.style.right}>
          <div style={this.props.style.title}>{this.props.title}</div>
          <div style={this.props.style.timestamp}>{DateTime.dateFormat(this.props.created_at)}</div>
        </div>
        <div style={this.props.style.body}>
          <div style={bodyStyle} className="artist-text-content" dangerouslySetInnerHTML={{ __html: this.props.contents }}/>
        </div>
      </div>
    );
  }
}

Announcement.propTypes = {
  contents: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  created_at: PropTypes.string,
  style: PropTypes.object,
};

export default Announcement;
