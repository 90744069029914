import { fade } from '@material-ui/core/styles/colorManipulator';

/* grayscale */
export const black = '#000000';
export const white = '#ffffff';
export const transparent = 'rgba(0, 0, 0, 0)';

/* primary colors */
export const primaryBlack = 'rgb(58, 54, 53)';
export const primaryRed = 'rgb(220, 85, 75)';
export const primaryPink = primaryRed;
export const primaryOrange = 'rgb(249,174,0)';
export const primaryTeal = 'rgb(95, 180, 169)';
export const primaryBlue = '#00aaff'; // Temporary color. Noticeable color for the distinction

/* text */
export const textRed = primaryRed;
export const textRed80 = fade(primaryRed, 0.8);
export const textPink = primaryPink;
export const textBlack = primaryBlack;
export const textBlack80 = fade(primaryBlack, 0.8);
export const textBlack60 = fade(primaryBlack, 0.6);
export const textBlack40 = fade(primaryBlack, 0.4);
export const textBlack20 = fade(primaryBlack, 0.2);
export const textWhite = white;
export const textWhite90 = fade(white, 0.9);
export const textWhite80 = fade(white, 0.8);
export const textWhite60 = fade(white, 0.6);
export const textWhite40 = fade(white, 0.4);

/* border */
export const border = fade(primaryBlack, 0.6);

/* hashtag */
export const hashtagBackground = fade(primaryRed, 0.2);

/* header and footer */
export const headerBackground = white;
export const footerBackground = fade(primaryBlack, 0.7);

/* textfield */
export const textFieldColor = textBlack;

/* toggle */
export const toggleThumbOff = '#f5f5f5';
export const toggleTrackOff = '#bdbdbd';

/* etc */
export const disabled = textBlack40;
export const unused = '#00ffcc'; // Unused color. Noticeable color for checking exceptional case
