import { api } from './api';
import {
  READ_PAYMENT_METHOD, CREATE_PAYMENT_METHOD, SEARCH_GS_POINT, INITIALIZE_GS_POINT, SET_GS_POINT,
  READ_SUPPORTS, CREATE_SUPPORT,
  READ_PURCHASES, CREATE_PURCHASE,
  SUPPORT_ADD_UPDATE,
  SUPPORT_CANCEL_SUPPORT,
  READ_TRANSACTIONS,
  READ_SUPPORTS_ARTIST, READ_PURCHASES_ARTIST,
  SET_SUPPORT_FORM, SET_PURCHASE_FORM,
  START_EDITING_PAYMENT_METHOD_FORM,
  FINISH_EDITING_PAYMENT_METHOD_FORM,
  READ_MERCHANDISE, PURCHASE_MERCHANDISE,
} from './actionTypes';


export function readPaymentMethod() {
  const request = api.get('/payment_methods/');
  return {
    type: READ_PAYMENT_METHOD,
    payload: request,
  };
}

export function createPaymentMethod(data) {
  const request = api.post('/payment_methods/', data);
  return {
    type: CREATE_PAYMENT_METHOD,
    payload: request,
  };
}

export function searchGSPoint(data) {
  const request = api.post('/payment_methods/search_gs_point/', data);
  return {
    type: SEARCH_GS_POINT,
    payload: request,
  };
}

export function initializeGSPoint() {
  return {
    type: INITIALIZE_GS_POINT,
  };
}

export function setGSPoint(data) {
  return {
    type: SET_GS_POINT,
    payload: data,
  };
}

export function readSupports() {
  const request = api.get('/supports/');
  return {
    type: READ_SUPPORTS,
    payload: request,
  };
}

export function createSupport(data) {
  const request = api.post('/supports/', data);
  return {
    type: CREATE_SUPPORT,
    payload: request,
  };
}

export function supportAddUpdate(data) {
  const request = api.post('/supports/add_update/', data);
  return {
    type: SUPPORT_ADD_UPDATE,
    payload: request,
  };
}

export function supportCancelSupport(data) {
  const request = api.post('/supports/cancel_support/', data);
  return {
    type: SUPPORT_CANCEL_SUPPORT,
    payload: request,
  };
}

export function readTransactions(page) {
  const request = api.get('/transactions/', { page });
  return {
    type: READ_TRANSACTIONS,
    payload: request,
  };
}

export function readSupportsArtist(page, scope, ordering) {
  const request = api.get('/supports/artist/', { page, scope, ordering });
  return {
    type: READ_SUPPORTS_ARTIST,
    payload: request,
  };
}

export function readPurchasesArtist(page) {
  const request = api.get('/purchases/artist/', { page });
  return {
    type: READ_PURCHASES_ARTIST,
    payload: request,
  };
}

export function setSupportForm(data) {
  return {
    type: SET_SUPPORT_FORM,
    payload: data,
  };
}

export function setPurchaseForm(data) {
  return {
    type: SET_PURCHASE_FORM,
    payload: data,
  };
}

export function startEditingPaymentMethodForm() {
  return {
    type: START_EDITING_PAYMENT_METHOD_FORM,
  };
}

export function finishEditingPaymentMethodForm() {
  return {
    type: FINISH_EDITING_PAYMENT_METHOD_FORM,
  };
}

export function createPurchase(data) {
  const request = api.post('/purchases/', data);
  return {
    type: CREATE_PURCHASE,
    payload: request,
  };
}

export function readPurchases(page) {
  const request = api.get('/purchases/', { page });
  return {
    type: READ_PURCHASES,
    payload: request,
  };
}

export function readMerchandise(id) {
  const request = api.get(`/merchandise/${id}/`);
  return {
    type: READ_MERCHANDISE,
    payload: request,
  };
}

export function purchaseMerchandise(id, data) {
  const request = api.post(`/merchandise/${id}/purchase/`, data);
  return {
    type: PURCHASE_MERCHANDISE,
    payload: request,
  };
}
