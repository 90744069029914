import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';

import ReduxToastr from 'react-redux-toastr';

import Header from './Header';
import Footer from './Footer';

import {
  Artists, Artist, ArtistsStart, ArtistsNew, ArtistsSearch,
  SignIn, SignUp, SignUpDetail, ResetPassword, My, Dashboard,
  Support, Index, Announcements, Invitation,
  HomeArtistPosts, Community, CommunitySinglePost,
} from './../../containers';

import { strings, styles } from './../../assets/values';


const paths = strings.routes;
const style = {
  container: {
    fontFamily: styles.fontFamily,
  },
};

class App extends React.Component {
  static propTypes = {
    serverSideScreenClass: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  };

  static defaultProps = {
    serverSideScreenClass: 'xl',
  }

  static childContextTypes = {
    serverSideScreenClass: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  };

  getChildContext = () => ({
    serverSideScreenClass: this.props.serverSideScreenClass,
  });

  componentDidMount() {
    const jssStyles = document.getElementById('jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{strings.appName}</title>
          <meta property="og:type" content="website"/>
          <meta property="og:title" content={strings.appName}/>
          <meta property="og:description" content={strings.appDescription}/>
          <meta property="og:image" content={`${strings.s3.domain}/${strings.s3.bucket}/${strings.logoFull}`}/>
          <meta name="description" content={strings.appDescription}/>
        </Helmet>
        <ReduxToastr/>
        <Header/>
        <div style={style.container}>
          <Switch>
            <Route exact path="/" component={HomeArtistPosts}/>
            <Route path={paths.posts} component={HomeArtistPosts}/>
            <Route path={`${paths.cheers}/:postNumber`} component={CommunitySinglePost}/>
            <Route path={paths.cheers} component={Community}/>
            <Route path={paths.announcements} component={Announcements}/>
            <Route path={paths.about} component={Index}/>
            <Route path={paths.artists} component={Artists}/>
            <Route path={paths.artistsStart} component={ArtistsStart}/>
            <Route path={paths.artistsNew} component={ArtistsNew}/>
            <Route path={paths.artistsSearch} component={ArtistsSearch}/>
            <Route path={paths.my} component={My}/>
            <Route path={paths.dashboard} component={Dashboard}/>
            <Route path={paths.signIn} component={SignIn}/>
            <Route path={`${paths.signUp}/detail`} component={SignUpDetail}/>
            <Route path={paths.signUp} component={SignUp}/>
            <Route path={paths.support} component={Support}/>
            <Route path={paths.resetPassword} component={ResetPassword}/>
            <Route path={paths.invitation} component={Invitation}/>
            <Route path="/:url" component={Artist}/>
          </Switch>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default App;
