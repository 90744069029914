import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as artistActions from './../../actions/artist';

import { colors, styles } from './../../assets/values';

import history from './../../history';


const style = {
  tag: {
    display: 'inline',
    marginRight: 8,
    padding: 4,
    textDecoration: 'none',
    backgroundColor: 'transparent',
    color: colors.textBlack80,
    fontSize: styles.fontSize.md,
    cursor: 'pointer',
  },
};


class HashTag extends React.Component {
  handleTouchTap = () => {
    if (this.props.activate) {
      this.props.startInitialize();
      this.props.readArtists([this.props.name], null, this.props.ordering)
        .then(() => history.push('/artists'));
    }
  }; // 태그가 눌리면 그 태그로 필터링 해서 데이터를 받아오고 아티스트 리스트 페이지로 넘깁니다.

  render() {
    return (
      <button style={{ ...style.tag, ...this.props.style }} onClick={(event) => { event.preventDefault(); this.handleTouchTap(); }}>
        {`#${this.props.name}`}
      </button>
    );
  }
}

HashTag.propTypes = {
  activate: PropTypes.bool,
  name: PropTypes.string.isRequired,
  ordering: PropTypes.string.isRequired,
  initializing: PropTypes.bool,
  startInitialize: PropTypes.func.isRequired,
  readArtists: PropTypes.func.isRequired,
  style: PropTypes.object,
};

HashTag.defaultProps = {
  activate: true,
};

function mapStateToProps(state) {
  return {
    initializing: state.artist.initializing,
    ordering: state.artist.ordering,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...artistActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HashTag);
