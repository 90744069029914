import React from 'react';
import PropTypes from 'prop-types';

import { DateTime } from './../../containers/Utils/DateTimeUtil';

import { colors, styles } from './../../assets/values';


const style = {
  card: {
    borderBottom: `1px solid ${colors.textBlack20}`,
    margin: 4,
    padding: 8,
  },
  date: {
    color: colors.textBlack,
    fontSize: styles.fontSize.md,
  },
  title: {
    textAlign: 'right',
    color: colors.textBlack80,
    fontSize: styles.fontSize.md,
  },
};


class ArtistSchedule extends React.Component {
  render() {
    return (
      <div style={style.card}>
        <div style={style.date}>{DateTime.dateVerboseFormat(this.props.date)}</div>
        <div style={style.title}>{this.props.title}</div>
      </div>
    );
  }
}

ArtistSchedule.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ArtistSchedule;
