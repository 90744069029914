import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { bindActionCreators } from 'redux';
import { useFormik } from 'formik';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { RowWithLabel } from './../../components';

import * as paymentActions from './../../actions/payment';

import { strings, presets } from './../../assets/values';
import comodoLogoSrc from './../../assets/images/comodo_cert.png';

import * as Validator from './../Utils/Validator';

import { getStyle } from './style';

const paths = strings.routes;

const validate = (values) => {
  const errors = {};
  const cardNumberError = Validator.validateCardNumber(values.card_number);
  const cardPasswordError = Validator.validateCardPassword(values.card_password);
  const cardBirthDateError = Validator.validateCardBirthDate(values.card_birth_date);
  if (cardNumberError) errors.card_number = cardNumberError;
  if (cardPasswordError) errors.card_password = cardPasswordError;
  if (cardBirthDateError) errors.card_birth_date = cardBirthDateError;
  return errors;
};

const CardPayment = (props) => {
  const [processing, setProcessing] = useState(false);
  const [checkAgreement, setCheckAgreement] = useState(false);

  const onSubmit = (data) => {
    setProcessing(true);
    props.createPaymentMethod(data)
      .then((response) => {
        setProcessing(false);
        if (response.action.payload.ok) {
          if (props.stepper) props.handleNext();
          else props.finishEditingPaymentMethodForm();
        } else {
          const errorMessage = response.action.payload.data.detail || strings.addCardFail;
          toastr.error(strings.fail, errorMessage);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      card_number: '',
      expired_month: '',
      expired_year: '',
      card_password: '',
      card_birth_date: '',
    },
    onSubmit: values => onSubmit(values),
    validate: values => validate(values),
  });

  const style = getStyle();
  const defaultRowStyle = style.rowWithLabel(25);
  // const defaultTextFieldStyle = style.muiTextField(150);
  return (
    <form onSubmit={formik.handleSubmit}>
      <CardContent>
        <RowWithLabel style={defaultRowStyle} label={strings.cardNumber}>
          <TextField
            name="card_number"
            value={formik.values.card_number}
            onChange={formik.handleChange}
            helperText={strings.cardNumberHint}
            margin="normal"
          />
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.cardExpirationMonth}>
          <FormControl>
            <InputLabel>MM</InputLabel>
            <Select
              name="expired_month"
              value={formik.values.expired_month}
              onChange={formik.handleChange}
              margin="dense"
            >
              {presets.months.map(item =>
                <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </Select>
          </FormControl>
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.cardExpirationYear}>
          <FormControl>
            <InputLabel>YYYY</InputLabel>
            <Select
              name="expired_year"
              value={formik.values.expired_year}
              onChange={formik.handleChange}
              margin="dense"
            >
              {presets.years.map(item =>
                <MenuItem key={item} value={item}>{`20${item}`}</MenuItem>)}
            </Select>
          </FormControl>
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.cardPassword}>
          <TextField
            name="card_password"
            value={formik.values.card_password}
            onChange={formik.handleChange}
            type="password"
            helperText={strings.cardPasswordHint}
            margin="normal"
          />
          <span style={{ display: 'inline-block', verticalAlign: 'top', paddingTop: '1.1em' }}>••</span>
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.birthDate}>
          <TextField
            name="card_birth_date"
            value={formik.values.card_birth_date}
            onChange={formik.handleChange}
            helperText={strings.birthDateHint}
            margin="normal"
          />
        </RowWithLabel>
        <RowWithLabel style={defaultRowStyle} label={strings.agreeToPaymentTerms}>
          <Checkbox
            name="agreeToTerms"
            onClick={() => setCheckAgreement(!checkAgreement)}
            margin="dense"
            color="primary"
          />
          <Link href={`${paths.support}/${paths.supportTermsOfPayment}`} to={`${paths.support}/${paths.supportTermsOfPayment}`} target="_blank" style={style.navigateToTerms}>
            {strings.navigateToPaymentTerms}
          </Link>
        </RowWithLabel>
      </CardContent>
      <CardContent style={{ clear: 'both' }}>
        <Typography>{strings.paymentInformation}</Typography>
        <img src={comodoLogoSrc} alt="comodo cert" style={{ display: 'block', width: 150, marginLeft: 'auto' }}/>
      </CardContent>
      <CardActions style={{ textAlign: 'right' }}>
        <Button
          onClick={props.stepper ? props.handlePrevious : props.finishEditingPaymentMethodForm}
        >
          {props.stepper ? strings.previous : strings.cancel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!checkAgreement || processing}
        >
          {processing ? '처리중...' : props.stepper ? strings.next : strings.confirm}
        </Button>
      </CardActions>
    </form>
  );
};

CardPayment.defaultProps = {
  stepper: false,
  handlePrevious: () => {},
  handleNext: () => {},
};

CardPayment.propTypes = {
  registeredCard: PropTypes.arrayOf(PropTypes.object),
  createPaymentMethod: PropTypes.func.isRequired,
  finishEditingPaymentMethodForm: PropTypes.func.isRequired,
  stepper: PropTypes.bool,
  handlePrevious: PropTypes.func,
  handleNext: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    registeredCard: state.payment.registeredCard,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...paymentActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CardPayment);
