import React from 'react';
import PropTypes from 'prop-types';

import { Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';

import { strings } from './../../assets/values';

class MyDashboardDialog extends React.Component {
  render() {
    return (
      <div>
        <Table selectable={false}>
          <TableHead displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableCell>{strings.name}</TableCell>
              <TableCell>{strings.email}</TableCell>
              <TableCell>{strings.contact}</TableCell>
              <TableCell>{strings.merchandise}</TableCell>
              <TableCell>{strings.purchaseAmount}</TableCell>
              <TableCell>{strings.merchandisePrice}</TableCell>
              <TableCell>{strings.shippingPrice}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody displayRowCheckbox={false}>
            <TableRow>
              <TableCell>
                {this.props.buyer_name}
              </TableCell>
              <TableCell>
                {this.props.buyer_email}
              </TableCell>
              <TableCell>
                {this.props.buyer_contact}
              </TableCell>
              <TableCell>
                {this.props.merchandise.name}
              </TableCell>
              <TableCell>
                {this.props.amount}
              </TableCell>
              <TableCell>
                {this.props.merchandise.price}
              </TableCell>
              <TableCell>
                {this.props.merchandise.shipping_price}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table selectable={false}>
          <TableHead displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableCell>{strings.postcode}</TableCell>
              <TableCell>{strings.address}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody displayRowCheckbox={false}>
            <TableRow>
              <TableCell>
                {this.props.address.postcode}
              </TableCell>
              <TableCell>
                {this.props.address.address}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table selectable={false}>
          <TableHead displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableCell>{strings.buyerMemo}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody displayRowCheckbox={false}>
            <TableRow>
              <TableCell>
                {this.props.memo}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

MyDashboardDialog.propTypes = {
  buyer_name: PropTypes.string,
  buyer_contact: PropTypes.string,
  buyer_email: PropTypes.string,
  merchandise: PropTypes.object,
  amount: PropTypes.number,
  address: PropTypes.object,
  memo: PropTypes.string,
};

export default MyDashboardDialog;
