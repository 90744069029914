import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ArtistPostsFeed from './ArtistPostsFeed';
import ArtistPostsBoard from './ArtistPostsBoard';

class ArtistPosts extends React.Component {
  render() {
    if (this.props.artist.post_style === 'board') {
      return (
        <ArtistPostsBoard match={this.props.match} variant="primary" />
      );
    }

    return (
      <ArtistPostsFeed match={this.props.match} variant="primary" />
    );
  }
}

ArtistPosts.propTypes = {
  user: PropTypes.object,
  artist: PropTypes.object,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    artist: state.artist.data[state.artist.active],
  };
}

export default connect(mapStateToProps)(ArtistPosts);
