// Forked from https://github.com/instructure-react/react-tinymce/blob/master/lib/components/TinyMCE.js
import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import clone from 'lodash/clone';

const ucFirst = str => str[0].toUpperCase() + str.substring(1);

// Include all of the Native DOM and custom events from:
// https://github.com/tinymce/tinymce/blob/master/tools/docs/tinymce.Editor.js#L5-L12
const EVENTS = [
  'focusin', 'focusout', 'click', 'dblclick', 'mousedown', 'mouseup',
  'mousemove', 'mouseover', 'beforepaste', 'paste', 'cut', 'copy',
  'selectionchange', 'mouseout', 'mouseenter', 'mouseleave', 'keydown',
  'keypress', 'keyup', 'contextmenu', 'dragend', 'dragover', 'draggesture',
  'dragdrop', 'drop', 'drag', 'BeforeRenderUI', 'SetAttrib', 'PreInit',
  'PostRender', 'init', 'deactivate', 'activate', 'NodeChange',
  'BeforeExecCommand', 'ExecCommand', 'show', 'hide', 'ProgressState',
  'LoadContent', 'SaveContent', 'BeforeSetContent', 'SetContent',
  'BeforeGetContent', 'GetContent', 'VisualAid', 'remove', 'submit', 'reset',
  'BeforeAddUndo', 'AddUndo', 'change', 'undo', 'redo', 'ClearUndos',
  'ObjectSelected', 'ObjectResizeStart', 'ObjectResized', 'PreProcess',
  'PostProcess', 'focus', 'blur', 'dirty',
];

// Note: because the capitalization of the events is weird, we're going to get
// some inconsistently-named handlers, for example compare:
// 'onMouseleave' and 'onNodeChange'
const HANDLER_NAMES = EVENTS.map(event => `on${ucFirst(event)}`);


class TinyMCE extends React.Component {
  UNSAFE_componentWillMount() {
    this.id = this.id || this.props.id;
  }

  componentDidMount() {
    const config = clone(this.props.config);
    const script = document.createElement('script');
    script.src = 'https://cdn.tiny.cloud/1/no-api-key/tinymce/5/tinymce.min.js';
    script.onload = () => this._init(config);
    document.body.appendChild(script);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.config, nextProps.config)) {
      this._init(nextProps.config, nextProps.content);
    }
    if (!isEqual(this.props.id, nextProps.id)) {
      this.id = nextProps.id;
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      !isEqual(this.props.content, nextProps.content) ||
      !isEqual(this.props.config, nextProps.config)
    );
  }

  componentWillUnmount() {
    this._remove();
  }

  _remove() {
    const { tinymce } = global;

    tinymce.EditorManager.execCommand('mceRemoveEditor', true, this.id);
    this._isInit = false;
  }

  _init(_config, content) {
    const { tinymce } = global;
    const config = _config;

    if (this._isInit) {
      this._remove();
    }

    // hide the textarea that is me so that no one sees it
    this.textArea.style.hidden = 'hidden';

    const setupCallback = config.setup;
    const hasSetupCallback = (typeof setupCallback === 'function');

    config.selector = `#${this.id}`;
    config.setup = (editor) => {
      EVENTS.forEach((event, index) => {
        const handler = this.props[HANDLER_NAMES[index]];
        if (typeof handler !== 'function') return;
        editor.on(event, (e) => {
          // native DOM events don't have access to the editor so we pass it here
          handler(e, editor);
        });
      });
      // need to set content here because the textarea will still have the
      // old `this.props.content`
      if (content) {
        editor.on('init', () => {
          editor.setContent(content);
        });
      }
      if (hasSetupCallback) {
        setupCallback(editor);
      }
    };

    tinymce.init(config);

    this.textArea.style.hidden = '';

    this._isInit = true;
  }

  render() {
    return (
      <textarea
        ref={(textArea) => { this.textArea = textArea; }}
        id={this.id}
        style={{ display: 'hidden' }}
        className={this.props.className}
        defaultValue={this.props.content}
      />
    );
  }
}

TinyMCE.propTypes = {
  config: PropTypes.object,
  content: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
};

TinyMCE.defaultProps = {
  config: {},
  content: '',
  id: 'react-tinymce',
};

// add handler propTypes
// HANDLER_NAMES.forEach((name) => {
//   TinyMCE.propTypes[name] = PropTypes.func;
// });

export default TinyMCE;
