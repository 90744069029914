import React from 'react';

import Button from '@material-ui/core/Button';

import { strings, styles } from './../../assets/values';

import history from './../../history';


const style = {
  wrapper: {
    textAlign: 'center',
    paddingTop: 100,
  },
  icon: {
    fontSize: styles.fontSize.xl * 4,
  },
  message: {
    padding: 16,
    fontSize: styles.fontSize.xl,
  },
};

class NotFound extends React.Component {
  render() {
    return (
      <div style={style.wrapper}>
        <div style={style.icon}>X_X</div>
        <div style={style.message}>{strings.notFound}</div>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={() => history.push(strings.routes.artists)}
        >
          {strings.header.artists}
        </Button>
      </div>
    );
  }
}

export default NotFound;
