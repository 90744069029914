import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { MyArtistInformation } from './../../components';

import * as userActions from './../../actions/user';
import * as paymentActions from './../../actions/payment';

import { strings } from '../../assets/values';
import { getStyle } from './style';

import history from './../../history';


const paths = strings.routes;

class MyArtists extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.readUser().then((response) => {
      if (!response.action.payload.ok) history.push('/');
      else this.props.readSupports();
    });
  }

  handleSupportUpdate = (data) => {
    this.props.setSupportForm({ ...data, updating: true });
    history.push(`/${data.artist.url_artist}/${paths.artistPurchase}`);
  };

  handleSupportCancel = (data) => {
    this.props.supportCancelSupport(data)
      .then(() => this.props.readSupports());
  };

  render() {
    const style = getStyle();
    return (
      <div style={style.innerCard}>
        {this.props.supports &&
        this.props.supports.length > 0 ?
          (this.props.supports.map(support =>
            <MyArtistInformation
              handleRoute={() => history.push(`/${support.artist.url_artist}`)}
              handleUpdate={() => this.handleSupportUpdate(support)}
              handleCancel={() => this.handleSupportCancel(support)}
              key={support.artist.id}
              {...support}
            />)
          ) :
          <Card>
            <CardContent style={{ textAlign: 'center' }}>
              <div>{strings.emptyMyArtists}</div>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 20 }}
                onClick={(event) => { event.preventDefault(); history.push(strings.routes.artists); }}
              >
                아티스트 보러가기
              </Button>
            </CardContent>
          </Card>
        }
      </div>
    );
  }
}

MyArtists.propTypes = {
  supports: PropTypes.arrayOf(PropTypes.object),
  readUser: PropTypes.func.isRequired,
  readSupports: PropTypes.func.isRequired,
  setSupportForm: PropTypes.func.isRequired,
  supportCancelSupport: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    supports: state.payment.supports,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...userActions,
    ...paymentActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyArtists);
