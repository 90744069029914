import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import history from './../../history';


const style = {
  card: {
    cursor: 'pointer',
  },
};

class PlaceholderList extends React.Component {
  render() {
    return (
      <div>
        { new Array(this.props.max).fill(0).map((v, i) => {
          const id = i + 1;
          const path = history.location;
          const target = path[path.length - 1] === '/' ? `${path}${id}` : `${path}/${id}`;
          return (
            <Card key={id} style={style.card} onClick={() => history.push(target)}>
              <CardHeader title={target}/>
              <CardContent>{`Go to "${target}"`}</CardContent>g
            </Card>
          );
        })}
      </div>
    );
  }
}

PlaceholderList.propTypes = {
  max: PropTypes.number,
};

PlaceholderList.defaultProps = {
  max: 10,
};

export default PlaceholderList;
