import { createMuiTheme } from '@material-ui/core/styles';

import { colors } from './../assets/values';

export const themeStyle = {
  palette: {
    primary: {
      main: colors.primaryPink,
    },
    secondary: {
      main: colors.primaryOrange,
    },
    // error: will use the default color
    typography: {
      useNextVariants: true,
    },
  },
};

export default createMuiTheme(themeStyle);
