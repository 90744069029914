import React from 'react';
import PropTypes from 'prop-types';
import { Inspector } from 'react-inspector';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

class CardInspector extends React.Component {
  render() {
    return (
      <Card>
        <CardHeader title={this.props.title}/>
        <CardContent>
          <Inspector data={this.props.data} expandLevel={this.props.expandLevel} sortObjectKeys/>
        </CardContent>
      </Card>
    );
  }
}

CardInspector.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  expandLevel: PropTypes.number,
};

CardInspector.defaultProps = {
  title: '',
  expandLevel: 1,
};

export default CardInspector;
