import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import { strings, styles } from './../../assets/values';

import history from './../../history';

const style = {
  wrapper: {
    textAlign: 'center',
    paddingTop: 100,
  },
  icon: {
    fontSize: styles.fontSize.xl * 4,
  },
  message: {
    padding: 16,
    fontSize: styles.fontSize.xl,
  },
};

class Forbidden extends React.Component {
  render() {
    return (
      <div style={style.wrapper}>
        <div style={style.icon}>O_o</div>
        <div style={style.message}>{strings.forbidden}</div>
        { this.props.user ?
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => history.push(strings.routes.artists)}
          >
            {strings.header.artists}
          </Button> :
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={() => history.push(strings.routes.signIn)}
          >
            {strings.signIn}
          </Button>
        }
      </div>
    );
  }
}

Forbidden.propTypes = {
  user: PropTypes.object,
};

export default Forbidden;
