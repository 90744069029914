import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Row, Col } from 'react-grid-system';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';

import { presets } from './../../assets/values';


class ErrorTemplate extends React.Component {
  render() {
    return (
      <Row>
        <Helmet title={this.props.statusCode}/>
        <Col md={2}/>
        <Col md={8}>
          <Card>
            <CardHeader title={`${this.props.statusCode} : ${this.props.message}`}/>
            <CardMedia>
              <img src={`https://http.cat/${this.props.statusCode}`} alt={this.props.message}/>
            </CardMedia>
          </Card>
        </Col>
      </Row>
    );
  }
}

ErrorTemplate.propTypes = {
  statusCode: PropTypes.oneOf(presets.httpStatusCodes).isRequired,
  message: PropTypes.string.isRequired,
};

export default ErrorTemplate;
