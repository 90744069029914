import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Row, Col } from 'react-grid-system';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { CardPayment } from './../../containers';
import { RowWithLabel, PostcodeDialog } from './../../components';

import { strings, presets, colors } from './../../assets/values';

import * as paymentActions from './../../actions/payment';
import * as userActions from './../../actions/user';

import { getStyle } from './style';

import history from './../../history';


const paths = strings.routes;

class ArtistPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_amount: this.props.supportForm.reward.amount,
      nickname: this.props.user.nickname,
      address: '',
      contact: this.props.user.contact,
      progress: false,
      daumAddress: null,
      postcode: null,
      stepIndex: 0,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.readPaymentMethod();
  }

  onSubmit = () => {
    this.setState({ progress: true });
    const profile = {
      nickname: this.state.nickname,
      address: [{
        address: `${this.state.daumAddress}, ${this.state.address}`,
        addressHead: this.state.daumAddress,
        addressExtra: this.state.address,
        postcode: this.state.postcode,
      }],
      contact: this.state.contact,
    };
    const support = {
      reward_id: this.props.supportForm.reward.id,
      payment_amount: this.state.payment_amount,
      billing_card_number: this.props.registeredCard[0].billing_card_number,
    };
    this.props.updateUser(profile);
    if (this.props.supportForm.updating) {
      const data = { ...support, support_id: this.props.supportForm.id };
      this.props.supportAddUpdate(data)
        .then((response) => {
          this.setState({ progress: false });
          if (!response.action.payload.ok) toastr.error(strings.fail, strings.updateSupportFail);
          else history.push(`/${this.props.match.params.url}/${paths.artistPurchaseSuccess}`);
        });
    } else {
      let data = support;
      if (this.props.supportForm.gs_card_no) {
        data = { ...support, gs_card_no: this.props.supportForm.gs_card_no, occur_pt: this.props.supportForm.occur_pt };
      }
      this.props.createSupport(data)
        .then((response) => {
          this.setState({ progress: false });
          if (!response.action.payload.ok) toastr.error(strings.fail, response.action.payload.data.detail);
          else history.push(`/${this.props.match.params.url}/${paths.artistPurchaseSuccess}`);
        });
    }
  };

  getCardNumber = () => {
    if (this.props.registeredCard[0]) {
      return `${this.props.registeredCard[0].billing_card_number}****`;
    }
    return strings.cardNotExist;
  };
  getCardCode = () => {
    if (this.props.registeredCard[0]) {
      const choice = presets.cards.filter(card =>
        card.code === this.props.registeredCard[0].billing_card_code);
      return choice[0].name;
    }
    return null;
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handlePaymentEdit = () => {
    this.props.startEditingPaymentMethodForm();
  };

  handleNext = () => {
    const stepIndex = this.state.stepIndex + 1;
    this.setState({ stepIndex });
  };

  handlePrevious = () => {
    const stepIndex = this.state.stepIndex - 1;
    this.setState({ stepIndex });
  }

  render() {
    const style = getStyle();
    const defaultRowStyle = style.rowWithLabel(25);
    const steps = [
      <div style={style.purchase.card}>
        <CardHeader
          subtitle={this.props.supportForm.updating ?
            strings.support.update.message :
            null}
        />
        <CardContent>
          <RowWithLabel style={defaultRowStyle} label={strings.paymentAmountPerMonth}>
            <TextField
              name="payment_amount"
              value={this.state.payment_amount}
              onChange={this.handleChange}
              margin="dense"
            />
          </RowWithLabel>
        </CardContent>
        <CardContent>
          {this.props.supportForm.reward.body}
        </CardContent>
        {this.props.supportForm.updating ?
          <Button
            variant="contained"
            label={strings.selectOtherReward}
            onClick={(event) => { event.preventDefault(); history.push(`/${this.props.supportForm.artist.url_artist}/rewards`); }}
          /> :
          null
        }
        <CardActions style={{ textAlign: 'right' }}>
          <Button
            disabled={this.state.stepIndex === 0}
            onClick={this.handlePrevious}
            style={{ marginRight: 12 }}
          >
            이전
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleNext}
          >
            다음
          </Button>
        </CardActions>
      </div >,
      <div style={style.purchase.card}>
        <CardHeader
          title={strings.paymentMethod}
        />
        <CardPayment
          stepper
          handlePrevious={this.handlePrevious}
          handleNext={this.handleNext}
        />
      </div>,
      <div style={style.purchase.card}>
        <CardHeader
          title={strings.myProfile}
        />
        <CardContent>
          <RowWithLabel style={defaultRowStyle} label={`${strings.name}/${strings.nickname}`}>
            <TextField
              name="nickname"
              value={this.state.nickname}
              onChange={this.handleChange}
              margin="dense"
            />
          </RowWithLabel>
          <PostcodeDialog onComplete={(address, postcode) => this.setState({ daumAddress: address, postcode })}/>
          <RowWithLabel style={defaultRowStyle} label={strings.addressExtra}>
            <TextField
              name="address"
              value={this.state.address}
              onChange={this.handleChange}
              fullWidth
              margin="dense"
            />
          </RowWithLabel>
          <RowWithLabel style={defaultRowStyle} label={strings.contact}>
            <TextField
              name="contact"
              value={this.state.contact}
              onChange={this.handleChange}
              margin="dense"
            />
          </RowWithLabel>
          <Typography>{strings.usageOfInformation}</Typography>
        </CardContent>
        <CardContent>
          <Typography>
            문제가 있으신가요?
            <a href={strings.kakaoChatUrl} target="blank" style={{ color: colors.primaryPink }}>
              {' 카카오톡 채널로 문의'}
            </a>
            해주시면 도와드리겠습니다 :)
          </Typography>
        </CardContent>
        <CardActions style={{ textAlign: 'right' }}>
          <Button
            disabled={this.state.stepIndex === 0}
            onClick={this.handlePrevious}
            style={{ marginRight: 12 }}
          >
            {strings.previous}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="button"
            disabled={this.state.progress}
            onClick={(event) => { event.preventDefault(); this.onSubmit(); }}
          >
            {this.state.progress ? '처리중...' : strings.pay}
          </Button>
        </CardActions>
      </div>,
    ];
    return (
      <div style={style.wrapper}>
        <Row>
          <Col lg={3}/>
          <Col lg={7}>
            <div style={style.rightContainer}>
              <Card style={style.purchase.container}>
                <Stepper activeStep={this.state.stepIndex}>
                  <Step completed={this.state.stepIndex > 0}>
                    <StepLabel>결제금액 확인</StepLabel>
                  </Step>
                  <Step completed={this.state.stepIndex > 1}>
                    <StepLabel>결제수단 선택</StepLabel>
                  </Step>
                  <Step completed={this.state.stepIndex > 2}>
                    <StepLabel>정보 입력</StepLabel>
                  </Step>
                </Stepper>
                {steps[this.state.stepIndex]}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ArtistPurchase.propTypes = {
  editing: PropTypes.bool,
  user: PropTypes.object,
  supportForm: PropTypes.object,
  registeredCard: PropTypes.array,
  createSupport: PropTypes.func.isRequired,
  supportAddUpdate: PropTypes.func.isRequired,
  readPaymentMethod: PropTypes.func.isRequired,
  startEditingPaymentMethodForm: PropTypes.func.isRequired,
  finishEditingPaymentMethodForm: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    editing: state.payment.editing,
    user: state.user.data,
    supportForm: state.payment.supportForm,
    registeredCard: state.payment.registeredCard,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...paymentActions,
    ...userActions,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtistPurchase);
