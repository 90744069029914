/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LabelOutlined from '@material-ui/icons/LabelOutlined';

import { ProfileImage, EncourageSupport } from './../../components';
// import { SocialShare, ProfileImage } from './../../components';
import { DateTime } from './../../containers/Utils/DateTimeUtil';


const bodyStyle = {
  paddingTop: 12,
  wordWrap: 'break-word',
};

const encourageSupportStyle = {
  maxWidth: 320,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 30,
  marginBottom: 10,
};

class Post extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (this.props.updated_at !== nextProps.updated_at);
  }

  render() {
    // eslint-disable-next-line react/no-danger
    return (
      <div>
        {this.props.artist &&
          <div style={this.props.style.left}>
            <Link href={`/${this.props.artist.url_artist}`} to={`/${this.props.artist.url_artist}`}>
              <ProfileImage
                style={{ cursor: 'pointer' }}
                url={this.props.artist.profile_image}
                width={60} height={60}
              />
            </Link>
          </div>
        }
        <div style={this.props.artist && this.props.style.right}>
          {this.props.artist &&
            <Link href={`/${this.props.artist.url_artist}`} to={`/${this.props.artist.url_artist}`}>
              <div style={this.props.style.artistName}>
                {this.props.user ? this.props.user.nickname : this.props.artist.name}
              </div>
            </Link>
          }
          <div style={this.props.style.title}>{this.props.title}</div>
          {this.props.user &&
            <div style={this.props.style.userName}>{this.props.user.nickname}</div>
          }
          {this.props.schedule && <div style={this.props.style.schedule}>@{DateTime.dateFormat(this.props.schedule.date)}</div>}
        </div>
        <div style={this.props.style.timestamp}>{DateTime.dateFormat(this.props.created_at)}</div>
        {this.props.minimum_reward && <div style={this.props.style.secret}>이 포스트는 함께하는 사람에게만 공개되는 시크릿포스트입니다</div>}
        {!this.props.is_readable && (
          <div style={encourageSupportStyle}>
            <EncourageSupport
              name={this.props.artist.name}
              url_artist={this.props.artist.url_artist}
              supporting={false}
            />
          </div>
        )}
        <div style={this.props.style.body}>
          {this.props.is_readable && (
            <div style={bodyStyle} className="artist-text-content" dangerouslySetInnerHTML={{ __html: this.props.body }}/>
          )}
        </div>
        <div style={{ paddingBottom: 8 }}>
          {(this.props.tags && this.props.tags.length > 0) &&
            <div style={this.props.style.tags}>
              <LabelOutlined style={this.props.style.tagIcon}/>
              {this.props.tags.map(tag => <span key={tag.id} style={this.props.style.tag}>#{tag.name}</span>)}
            </div>
          }
          <div>
            {/* <SocialShare url={this.props.url}/> */}
          </div>
        </div>
      </div>
    );
  }
}

Post.propTypes = {
  url: PropTypes.string,
  body: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  user: PropTypes.object,
  artist: PropTypes.object,
  schedule: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.object),
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  minimum_reward: PropTypes.number,
  is_readable: PropTypes.bool,
  style: PropTypes.object,
};

export default Post;
